export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSONObject: any;
  _Any: any;
}

export interface Account {
  id?: Maybe<Scalars['Int']>;
  role: AccountRole;
  email: Scalars['String'];
  status: AccountStatus;
  username: Scalars['String'];
  preferences: AccountPreferences;
  date_last_token_generated?: Maybe<Scalars['Date']>;
  last_token_generated_ip?: Maybe<Scalars['String']>;
  date_created: Scalars['Date'];
  ops_account_id?: Maybe<Scalars['Int']>;
  update_ops_request?: Maybe<AccountUpdateOpsRequest>;
  autoapprove?: Maybe<Array<AccountAutoapproveValues>>;
  registered_ip?: Maybe<Scalars['String']>;
  linked_account_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  ip2location?: Maybe<Ip2Location>;
  regip2location?: Maybe<Ip2Location>;
}

export enum AccountAutoapproveValues {
  NewProfiles = 'NEW_PROFILES',
  NewPhotos = 'NEW_PHOTOS',
  NewNaturalPhotos = 'NEW_NATURAL_PHOTOS',
  NewVideos = 'NEW_VIDEOS',
  ProfileChanges = 'PROFILE_CHANGES',
  PhoneNumbers = 'PHONE_NUMBERS',
  Emails = 'EMAILS',
  Verification_100 = 'VERIFICATION_100',
  Story = 'STORY'
}

export interface AccountConfigs {
  password?: Maybe<AccountConfigsPassword>;
  username?: Maybe<AccountConfigsUsername>;
  email?: Maybe<AccountConfigsEmail>;
}

export interface AccountConfigsEmail {
  valid_pattern: Scalars['String'];
}

export interface AccountConfigsPassword {
  valid_pattern: Scalars['String'];
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface AccountConfigsUsername {
  valid_pattern: Scalars['String'];
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface AccountDeletedResponse {
  account_id: Scalars['Int'];
}

export interface AccountDisabledResponse {
  account_id: Scalars['Int'];
}

export enum AccountFlags {
  Test = 'TEST'
}

export interface AccountForOps {
  id?: Maybe<Scalars['Int']>;
  role: AccountRole;
  email: Scalars['String'];
  status: AccountStatus;
  username: Scalars['String'];
  preferences: AccountPreferences;
  date_last_token_generated?: Maybe<Scalars['Date']>;
  last_token_generated_ip?: Maybe<Scalars['String']>;
  date_created: Scalars['Date'];
  ops_account_id?: Maybe<Scalars['Int']>;
  permission_extender: AccountPermissionExtender;
  flags: Array<Maybe<AccountFlags>>;
  autoapprove?: Maybe<Array<AccountAutoapproveValues>>;
  update_ops_request?: Maybe<AccountUpdateOpsRequest>;
  status_before_delete?: Maybe<AccountStatus>;
  registered_ip?: Maybe<Scalars['String']>;
  linked_account_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  ip2location?: Maybe<Ip2Location>;
  regip2location?: Maybe<Ip2Location>;
}

export interface AccountMatchedCidFraud {
  account_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export interface AccountMatchedFraud {
  _id?: Maybe<Scalars['String']>;
  _score?: Maybe<Scalars['Float']>;
  type: FraudType;
  role?: Maybe<Scalars['String']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['String']>;
  reference_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<Scalars['String']>;
  document_id?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['Int']>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  phone_numbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  id_number?: Maybe<Scalars['String']>;
  doc_status?: Maybe<ProfileVerificationStatuses>;
  doc_profile_reference_id?: Maybe<Scalars['Int']>;
  verification_id?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  highlight?: Maybe<MatchedFraudSourceHighlight>;
}

export interface AccountMatchedIp {
  ip?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  ip2location?: Maybe<Ip2Location>;
}

export interface AccountMatchedIpFraud {
  account_id?: Maybe<Scalars['Int']>;
  ips?: Maybe<Array<Maybe<FraudIp>>>;
}

export interface AccountMutation {
  createSupportTicket?: Maybe<SupportCreateTicketResponse>;
  createSupportTicketComment?: Maybe<SupportCreateCommentResponse>;
  setSupportTicketIsClientRead?: Maybe<SupportTicketBooleanResponse>;
  createSupportProblemReport?: Maybe<SupportCreateProblemReportResponse>;
  createSupportSuspiciousPhotoRequest?: Maybe<SupportSuspiciousPhotoRequestResponse>;
  supportTellAFriend?: Maybe<SupportTellAFriendResponse>;
  createMemberWatchedProfile?: Maybe<StatisticsMemberWatchedProfileResponse>;
  createCommentReaction?: Maybe<StatisticsCommentReactionResponse>;
  createStatisticProfileView?: Maybe<StatisticsCreateProfileActivityResponse>;
  createStatisticCall?: Maybe<StatisticsCreateProfileActivityResponse>;
  createStatisticWhatsappCall?: Maybe<StatisticsCreateProfileActivityResponse>;
  createCompanyProfile?: Maybe<CompanyProfileCreateResponse>;
  updateCompanyProfile?: Maybe<CompanyProfileUpdateResponse>;
  applyCompanyProfilePhoneToAds?: Maybe<CompanyProfileApplyPhoneToAdsResponse>;
  setCompanyProfileIsContactInfoHidden?: Maybe<CompanyProfileIsContactInfoHiddenSetResponse>;
  unsetCompanyProfileIsContactInfoHidden?: Maybe<CompanyProfileIsContactInfoHiddenUnsetResponse>;
  createCompanyProfileComment?: Maybe<CompanyProfileCommentCreateResponse>;
  setCompanyProfileIsCompanyHidden?: Maybe<CompanyProfileIsCompanyHiddenSetResponse>;
  unsetCompanyProfileIsCompanyHidden?: Maybe<CompanyProfileIsCompanyHiddenUnsetResponse>;
  createCompanyProfilePhotos?: Maybe<CompanyProfilePhotoCreateResponse>;
  deleteCompanyProfilePhotos?: Maybe<CompanyProfilePhotoDeleteResponse>;
  moveCompanyProfilePhotos?: Maybe<CompanyProfilePhotoMoveResponse>;
  reorderCompanyProfilePhotos?: Maybe<CompanyProfilePhotoReorderResponse>;
  setCompanyProfileMainPhoto?: Maybe<CompanyProfilePhotoSetMainResponse>;
  updateCompanyProfilePhoto?: Maybe<CompanyProfilePhotoUpdateResponse>;
  createProfile?: Maybe<ProfileCreateResponse>;
  updateProfile?: Maybe<ProfileUpdateResponse>;
  disableProfile?: Maybe<ProfileDisableResponse>;
  enableProfile?: Maybe<ProfileEnableResponse>;
  deleteProfile?: Maybe<ProfileDeleteResponse>;
  createProfileComment?: Maybe<ProfileCommentCreateResponse>;
  setProfileAllPhotosRotatable?: Maybe<ProfileAllPhotosRotatableSetResponse>;
  unsetProfileAllPhotosRotatable?: Maybe<ProfileAllPhotosRotatableUnsetResponse>;
  enableProfilePeriodicBumps?: Maybe<ProfilePeriodicBumpsEnableResponse>;
  disableProfilePeriodicBumps?: Maybe<ProfilePeriodicBumpsDisableResponse>;
  setProfileSmsNotifyProfileOnline?: Maybe<ProfileSmsNotifyProfileOnlineSetResponse>;
  unsetProfileSmsNotifyProfileOnline?: Maybe<ProfileSmsNotifyProfileOnlineUnsetResponse>;
  createProfilePhotos?: Maybe<ProfilePhotoCreateResponse>;
  deleteProfilePhotos?: Maybe<ProfilePhotoDeleteResponse>;
  moveProfilePhotos?: Maybe<ProfilePhotoMoveResponse>;
  reorderProfilePhotos?: Maybe<ProfilePhotoReorderResponse>;
  setProfileMainPhoto?: Maybe<ProfilePhotoSetMainResponse>;
  setProfileGotdPhoto?: Maybe<ProfilePhotoSetGotdResponse>;
  unsetProfileGotdPhoto?: Maybe<ProfilePhotoUnsetGotdResponse>;
  setProfileVotdPhoto?: Maybe<ProfilePhotoSetVotdResponse>;
  unsetProfileVotdPhoto?: Maybe<ProfilePhotoUnsetVotdResponse>;
  setProfileRotatablePhotos?: Maybe<ProfilePhotoSetRotatableResponse>;
  unsetProfileRotatablePhotos?: Maybe<ProfilePhotoUnsetRotatableResponse>;
  updateProfileGallery?: Maybe<ProfileGalleryUpdateResponse>;
  updateProfilePhoto?: Maybe<ProfilePhotoUpdateResponse>;
  createProfileVideos?: Maybe<ProfileVideoCreateResponse>;
  updateProfileVideo?: Maybe<ProfileVideoUpdateResponse>;
  deleteProfileVideos?: Maybe<ProfileVideoDeleteResponse>;
  disableProfileVideos?: Maybe<ProfileVideoDisableResponse>;
  enableProfileVideos?: Maybe<ProfileVideoEnableResponse>;
  createProfileVideoCovers?: Maybe<ProfileVideoCoverCreateResponse>;
  deleteProfileVideoCovers?: Maybe<ProfileVideoCoverDeleteResponse>;
  createMemberProfile?: Maybe<MemberProfileCreateResponse>;
  updateMemberProfile?: Maybe<MemberProfileUpdateResponse>;
  createMemberProfilePhoto?: Maybe<MemberProfilePhotoCreateResponse>;
  deleteMemberProfilePhoto?: Maybe<MemberProfilePhotoDeleteResponse>;
  updateMemberProfilePhoto?: Maybe<MemberProfilePhotoUpdateResponse>;
  createProfileNaturalPhotos?: Maybe<ProfileNaturalPhotoCreateResponse>;
  deleteProfileNaturalPhotos?: Maybe<ProfileNaturalPhotoDeleteResponse>;
  createProfileVacation?: Maybe<ProfileVacationCreateResponse>;
  updateProfileVacation?: Maybe<ProfileVacationUpdateResponse>;
  deleteProfileVacation?: Maybe<ProfileVacationDeleteResponse>;
  setBillingInfo?: Maybe<SetBillingInfoResponse>;
  storyCreate?: Maybe<StoryIdsResponseWithWarning>;
  storyPublish?: Maybe<StoryIdsResponseWithWarning>;
  storyUpdateCaption?: Maybe<Array<Maybe<StoryIdResponse>>>;
  storyDelete?: Maybe<Array<Maybe<StoryIdResponse>>>;
  storyShareAgain?: Maybe<Story>;
  createProfileHappyHour?: Maybe<ProfileHappyHourCreateResult>;
  modifyProfileHappyHour?: Maybe<ProfileHappyHourModifyResponse>;
  resetProfileHappyHour?: Maybe<ProfileHappyHourResetResponse>;
  deleteProfileHappyHour?: Maybe<ProfileHappyHourDeleteResponse>;
  activateHappyHour?: Maybe<ProfileHappyHourModifyResponse>;
  expireHappyHour?: Maybe<ProfileHappyHourExpireResponse>;
  changeVisibilityHappyHour?: Maybe<ProfileHappyHourVisibilityResponse>;
  voteGotm?: Maybe<ProfileGotmVoteResponse>;
  cancelBaseService?: Maybe<ProductManagerMutationResponse>;
  cancelGotdService?: Maybe<ProductManagerMutationResponse>;
  cancelVotdService?: Maybe<ProductManagerMutationResponse>;
  cancelClassifiedAdService?: Maybe<ProductManagerMutationResponse>;
  updateBaseServiceAdditionalGeoNodes?: Maybe<ProductManagerMutationResponse>;
  reassignBaseService?: Maybe<ProductManagerMutationResponse>;
  updateGotdService?: Maybe<ProductManagerMutationResponse>;
  updateVotdService?: Maybe<ProductManagerMutationResponse>;
  createRecurringBumpSchedule: BumpScheduleCreateResponse;
  updateRecurringBumpSchedule: BumpScheduleUpdateResponse;
  createOneTimeBumpSchedule: OneTimeBumpScheduleCreateResponse;
  createInstantBump: InstantBumpCreateResponse;
  deleteBumpSchedule: BumpScheduleDeleteResponse;
  notificationMessageStatusSeen: NotificationMessageStatusSeenResponse;
  createProfileStatusMessage?: Maybe<ProfileStatusCreateResponse>;
  updateProfileStatusMessage?: Maybe<ProfileStatusUpdateResponse>;
  deleteProfileStatusMessages?: Maybe<ProfileStatusDeleteResponse>;
  createInstantBookAvailability?: Maybe<InstantBookAvailabilityCreateResponse>;
  createInstantBookAvailabilities?: Maybe<InstantBookAvailabilitiesCreateResponse>;
  updateInstantBookAvailability?: Maybe<InstantBookAvailabilityUpdateResponse>;
  updateEscortInstantBookSettings?: Maybe<InstantBookSettingsUpdateResponse>;
  updateAgencyInstantBookSettings?: Maybe<InstantBookSettingsUpdateResponse>;
  cancelInstantBookAvailability?: Maybe<InstantBookAvailabilityCancelResponse>;
  cancelInstantBookAvailabilitiesByAccount?: Maybe<InstantBookAvailabilityCancelResponse>;
  cancelInstantBookAvailabilitiesByProfileIds?: Maybe<InstantBookAvailabilityCancelResponse>;
  declineInstantBookRequest?: Maybe<InstantBookRequestDeclineResponse>;
  acceptInstantBookRequest?: Maybe<InstantBookRequestAcceptResponse>;
  createInstantBookRequest?: Maybe<InstantBookRequestCreateResponse>;
  createProfileReview?: Maybe<ProfileReviewCreateResponse>;
  respondProfileReview?: Maybe<ProfileReviewRespondResponse>;
  profileReviewReply?: Maybe<ProfileReviewReplyResponse>;
  checkForBlacklist?: Maybe<Array<Maybe<CheckForBlacklistResponse>>>;
  createClassifiedAd?: Maybe<CreateClassifiedAdResponse>;
  removeClassifiedAd?: Maybe<UpdateClassifiedAdResponse>;
  repostClassifiedAd?: Maybe<UpdateClassifiedAdResponse>;
  prepareClassifiedAdForCart?: Maybe<UpdateClassifiedAdResponse>;
  createBlacklistedClient?: Maybe<BlacklistedClientResponse>;
  updateVerification?: Maybe<UpdateVerificationResponse>;
  addUnalteredPhotos?: Maybe<AddUnalteredPhotosResponse>;
  markWinnerAsSeenByAccountId?: Maybe<LotteryWinnerMarkSeenResult>;
  markOfferAsSeenByAccountId?: Maybe<LotteryOfferMarkSeenResult>;
  setMessengerDisabled: SetMessengerDisabledResponse;
  setMessengerThreadRead: SetMessengerThreadReadResponse;
  unsetMessengerDisabled: UnsetMessengerDisabledResponse;
  createMessengerMessage?: Maybe<CreateMessengerMessageResponse>;
  createMessengerThread?: Maybe<CreateMessengerThreadResponse>;
  deleteMessengerThread?: Maybe<DeleteMessengerThreadResponse>;
  blockMessengerThread?: Maybe<BlockMessengerThreadResponse>;
  unblockMessengerThread?: Maybe<UnblockMessengerThreadResponse>;
  setMessengerThreadFavorite?: Maybe<SetMessengerThreadFavoriteResponse>;
  unsetMessengerThreadFavorite?: Maybe<UnsetMessengerThreadFavoriteResponse>;
  updateIsConnected?: Maybe<UpdateIsConnectedResponse>;
  createMemberAlert?: Maybe<CreateMemberAlertResponse>;
  updateMemberAlert?: Maybe<UpdateMemberAlertResponse>;
  deleteMemberAlert?: Maybe<DeleteMemberAlertResponse>;
  createMemberCityAlert?: Maybe<CreateMemberCityAlertResponse>;
  createMemberCityAlerts?: Maybe<CreateMemberCityAlertsResponse>;
  deleteMemberCityAlert?: Maybe<DeleteMemberCityAlertResponse>;
  createTour?: Maybe<ProfileTourCreateResponse>;
  updateTour?: Maybe<ProfileTourUpdateResponse>;
  deleteTour?: Maybe<ProfileTourDeleteResponse>;
  createMemberFavoriteProfile: MemberFavoriteProfileCreateResponse;
  updateMemberFavoriteProfile: MemberFavoriteProfileUpdateResponse;
  deleteMemberFavoriteProfile: MemberFavoriteProfileDeleteResponse;
  addMemberFavoriteProfileToTop10: MemberFavoriteProfileAddToTopTenResponse;
  deleteMemberFavoriteProfileFromTop10: MemberFavoriteProfileDeleteFromTopTenResponse;
  moveUpMemberFavoriteProfileTop10: MemberFavoriteProfileMoveUpTopTenResponse;
  moveDownMemberFavoriteProfileTop10: MemberFavoriteProfileMoveDownTopTenResponse;
  setMemberFavoriteProfileTop10CommentType: MemberFavoriteProfileSetTopTenCommentTypeResponse;
  createMemberFavoriteProfileTop10CommentRequest: MemberFavoriteProfileTopTenCommentRequestResponse;
  declineMemberFavoriteProfileTop10CommentRequest: MemberFavoriteProfileTopTenCommentRequestDeclineResponse;
  shareMemberFavoriteProfileTop10CommentRequestByChat: MemberFavoriteProfileTopTenCommentRequestShareByChatResponse;
  shareMemberFavoriteProfileTop10CommentRequest: MemberFavoriteProfileTopTenCommentRequestShareResponse;
  sendEmailVerification?: Maybe<SendEmailVerificationResponse>;
  sendPhoneNumberVerification?: Maybe<SendPhoneNumberVerificationResponse>;
  sendCallPhoneNumberVerification?: Maybe<SendPhoneNumberVerificationResponse>;
  verifyPhoneNumber?: Maybe<VerifyPhoneNumberResponse>;
  moveProfile?: Maybe<MoveProfileResponse>;
  createBanner?: Maybe<BannerCreateResponse>;
  modifyBanner?: Maybe<BannerModifyResponse>;
  authenticate_linked_account?: Maybe<AccountToken>;
  updateAccountEmail?: Maybe<UpdateEmailResponse>;
  updateAccountPassword?: Maybe<MutationResponse>;
  deleteAccount?: Maybe<MutationResponse>;
  deleteAccountToken?: Maybe<MutationResponse>;
  updateAccountPreferences?: Maybe<MutationResponse>;
  createAccountUpdateOpsRequest?: Maybe<MutationResponse>;
  deleteAccountUpdateOpsRequest?: Maybe<MutationResponse>;
  verifyAccountUpdateOpsRequest?: Maybe<MutationResponse>;
  addProduct?: Maybe<AddProductResponse>;
  addProducts?: Maybe<Array<Maybe<AddProductResponse>>>;
  updateProduct?: Maybe<AddProductResponse>;
  removeProduct?: Maybe<CommerceMutationResponse>;
  markOrderWaitingForPayment?: Maybe<MarkOrderWaitingForPaymentResponse>;
  checkOrderBeforePayment?: Maybe<CheckOrderBeforePaymentResponse>;
  markOrderInCart?: Maybe<CommerceMutationResponse>;
  deleteOrder?: Maybe<CommerceMutationResponse>;
  applyPromoCodeDiscount?: Maybe<CommerceMutationResponse>;
  dummy?: Maybe<Scalars['String']>;
}


export interface AccountMutationCreateSupportTicketArgs {
  account_id: Scalars['Int'];
  subject: Scalars['String'];
  message: Scalars['String'];
  attached_files?: Maybe<Array<Scalars['ID']>>;
}


export interface AccountMutationCreateSupportTicketCommentArgs {
  ticket_id: Scalars['ID'];
  comment: Scalars['String'];
  attached_files?: Maybe<Array<Scalars['ID']>>;
}


export interface AccountMutationSetSupportTicketIsClientReadArgs {
  ticket_id: Scalars['ID'];
}


export interface AccountMutationCreateSupportProblemReportArgs {
  data?: Maybe<SupportProblemReportCreateInput>;
}


export interface AccountMutationCreateSupportSuspiciousPhotoRequestArgs {
  profile_id: Scalars['ID'];
  links: Array<Scalars['String']>;
  comment: Scalars['String'];
}


export interface AccountMutationSupportTellAFriendArgs {
  data?: Maybe<SupportTellAFriendInput>;
}


export interface AccountMutationCreateMemberWatchedProfileArgs {
  profile_id: Scalars['ID'];
  token: Scalars['String'];
}


export interface AccountMutationCreateCommentReactionArgs {
  comment_id?: Maybe<Scalars['String']>;
  type?: Maybe<StatisticCommentReactionType>;
}


export interface AccountMutationCreateStatisticProfileViewArgs {
  account_id: Scalars['Int'];
  profile_id: Scalars['ID'];
}


export interface AccountMutationCreateStatisticCallArgs {
  account_id: Scalars['Int'];
  profile_id: Scalars['ID'];
}


export interface AccountMutationCreateStatisticWhatsappCallArgs {
  account_id: Scalars['Int'];
  profile_id: Scalars['ID'];
}


export interface AccountMutationCreateCompanyProfileArgs {
  account_id: Scalars['Int'];
  data?: Maybe<CompanyProfileInput>;
}


export interface AccountMutationUpdateCompanyProfileArgs {
  data?: Maybe<CompanyProfileInput>;
  profile_id: Scalars['String'];
}


export interface AccountMutationApplyCompanyProfilePhoneToAdsArgs {
  profile_id: Scalars['String'];
  phone_number_id: Scalars['String'];
}


export interface AccountMutationSetCompanyProfileIsContactInfoHiddenArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationUnsetCompanyProfileIsContactInfoHiddenArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationCreateCompanyProfileCommentArgs {
  profile_id: Scalars['String'];
  parent_comment_id?: Maybe<Scalars['String']>;
  body: Scalars['String'];
}


export interface AccountMutationSetCompanyProfileIsCompanyHiddenArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationUnsetCompanyProfileIsCompanyHiddenArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationCreateCompanyProfilePhotosArgs {
  profile_id: Scalars['String'];
  gallery_id: Scalars['String'];
  image_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationDeleteCompanyProfilePhotosArgs {
  profile_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationMoveCompanyProfilePhotosArgs {
  profile_id: Scalars['String'];
  gallery_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationReorderCompanyProfilePhotosArgs {
  profile_id: Scalars['String'];
  gallery_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationSetCompanyProfileMainPhotoArgs {
  profile_id: Scalars['String'];
  photo_id: Scalars['String'];
}


export interface AccountMutationUpdateCompanyProfilePhotoArgs {
  profile_id: Scalars['String'];
  photo_id: Scalars['String'];
}


export interface AccountMutationCreateProfileArgs {
  account_id: Scalars['Int'];
  data?: Maybe<ProfileInput>;
}


export interface AccountMutationUpdateProfileArgs {
  data?: Maybe<ProfileInput>;
  profile_id: Scalars['String'];
}


export interface AccountMutationDisableProfileArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationEnableProfileArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationDeleteProfileArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationCreateProfileCommentArgs {
  profile_id: Scalars['String'];
  parent_comment_id?: Maybe<Scalars['String']>;
  body: Scalars['String'];
}


export interface AccountMutationSetProfileAllPhotosRotatableArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationUnsetProfileAllPhotosRotatableArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationEnableProfilePeriodicBumpsArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationDisableProfilePeriodicBumpsArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationSetProfileSmsNotifyProfileOnlineArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationUnsetProfileSmsNotifyProfileOnlineArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationCreateProfilePhotosArgs {
  profile_id: Scalars['String'];
  gallery_id: Scalars['String'];
  image_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationDeleteProfilePhotosArgs {
  profile_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationMoveProfilePhotosArgs {
  profile_id: Scalars['String'];
  gallery_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationReorderProfilePhotosArgs {
  profile_id: Scalars['String'];
  gallery_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationSetProfileMainPhotoArgs {
  profile_id: Scalars['String'];
  photo_id: Scalars['String'];
}


export interface AccountMutationSetProfileGotdPhotoArgs {
  profile_id: Scalars['String'];
  photo_id: Scalars['String'];
}


export interface AccountMutationUnsetProfileGotdPhotoArgs {
  profile_id: Scalars['String'];
  photo_id: Scalars['String'];
}


export interface AccountMutationSetProfileVotdPhotoArgs {
  profile_id: Scalars['String'];
  photo_id: Scalars['String'];
}


export interface AccountMutationUnsetProfileVotdPhotoArgs {
  profile_id: Scalars['String'];
  photo_id: Scalars['String'];
}


export interface AccountMutationSetProfileRotatablePhotosArgs {
  profile_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationUnsetProfileRotatablePhotosArgs {
  profile_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationUpdateProfileGalleryArgs {
  profile_id: Scalars['String'];
  gallery_id: Scalars['String'];
  data: ProfileGalleryInput;
}


export interface AccountMutationUpdateProfilePhotoArgs {
  profile_id: Scalars['String'];
  photo_id: Scalars['String'];
}


export interface AccountMutationCreateProfileVideosArgs {
  profile_id: Scalars['String'];
  data?: Maybe<Array<ProfileVideoInput>>;
}


export interface AccountMutationUpdateProfileVideoArgs {
  profile_id: Scalars['String'];
  video_id: Scalars['String'];
  data?: Maybe<ProfileVideoUpdateInput>;
}


export interface AccountMutationDeleteProfileVideosArgs {
  profile_id: Scalars['String'];
  video_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationDisableProfileVideosArgs {
  profile_id: Scalars['String'];
  video_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationEnableProfileVideosArgs {
  profile_id: Scalars['String'];
  video_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationCreateProfileVideoCoversArgs {
  video_id: Scalars['String'];
  image_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationDeleteProfileVideoCoversArgs {
  video_id: Scalars['String'];
  video_cover_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationCreateMemberProfileArgs {
  account_id: Scalars['Int'];
  data?: Maybe<MemberProfileInput>;
}


export interface AccountMutationUpdateMemberProfileArgs {
  data?: Maybe<MemberProfileInput>;
  profile_id: Scalars['String'];
}


export interface AccountMutationCreateMemberProfilePhotoArgs {
  profile_id: Scalars['String'];
  image_id: Scalars['String'];
}


export interface AccountMutationDeleteMemberProfilePhotoArgs {
  profile_id: Scalars['String'];
  photo_id: Scalars['String'];
}


export interface AccountMutationUpdateMemberProfilePhotoArgs {
  profile_id: Scalars['String'];
  photo_id: Scalars['String'];
}


export interface AccountMutationCreateProfileNaturalPhotosArgs {
  profile_id: Scalars['String'];
  image_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationDeleteProfileNaturalPhotosArgs {
  profile_id: Scalars['String'];
  natural_photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountMutationCreateProfileVacationArgs {
  profile_id: Scalars['String'];
  date_from: Scalars['Date'];
  date_to: Scalars['Date'];
}


export interface AccountMutationUpdateProfileVacationArgs {
  id: Scalars['String'];
  date_from: Scalars['Date'];
  date_to: Scalars['Date'];
}


export interface AccountMutationDeleteProfileVacationArgs {
  id: Scalars['String'];
}


export interface AccountMutationSetBillingInfoArgs {
  profile_id: Scalars['String'];
  data: BillingInfoInput;
}


export interface AccountMutationStoryCreateArgs {
  data?: Maybe<CreateStoryInput>;
}


export interface AccountMutationStoryPublishArgs {
  ids: Array<Scalars['ID']>;
}


export interface AccountMutationStoryUpdateCaptionArgs {
  data: Array<UpdateCaption>;
}


export interface AccountMutationStoryDeleteArgs {
  ids: Array<Scalars['ID']>;
}


export interface AccountMutationStoryShareAgainArgs {
  _id: Scalars['ID'];
}


export interface AccountMutationCreateProfileHappyHourArgs {
  data?: Maybe<ProfileHappyHourInput>;
}


export interface AccountMutationModifyProfileHappyHourArgs {
  id: Scalars['String'];
  data?: Maybe<ProfileHappyHourModifyInput>;
}


export interface AccountMutationResetProfileHappyHourArgs {
  id: Scalars['String'];
}


export interface AccountMutationDeleteProfileHappyHourArgs {
  id: Scalars['String'];
}


export interface AccountMutationActivateHappyHourArgs {
  id: Scalars['String'];
}


export interface AccountMutationExpireHappyHourArgs {
  id: Scalars['String'];
}


export interface AccountMutationChangeVisibilityHappyHourArgs {
  id: Scalars['String'];
  is_visible?: Maybe<Scalars['Boolean']>;
}


export interface AccountMutationVoteGotmArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationCancelBaseServiceArgs {
  id: Scalars['Int'];
  reason: Scalars['String'];
}


export interface AccountMutationCancelGotdServiceArgs {
  id: Scalars['Int'];
  reason: Scalars['String'];
}


export interface AccountMutationCancelVotdServiceArgs {
  id: Scalars['Int'];
  reason: Scalars['String'];
}


export interface AccountMutationCancelClassifiedAdServiceArgs {
  id: Scalars['Int'];
  reason: Scalars['String'];
}


export interface AccountMutationUpdateBaseServiceAdditionalGeoNodesArgs {
  id: Scalars['Int'];
  additional_geo_nodes: Array<Maybe<Scalars['Int']>>;
}


export interface AccountMutationReassignBaseServiceArgs {
  id: Scalars['Int'];
  target_ref_id: Scalars['Int'];
}


export interface AccountMutationUpdateGotdServiceArgs {
  id: Scalars['Int'];
  data: UpdateGotdServiceInput;
}


export interface AccountMutationUpdateVotdServiceArgs {
  id: Scalars['Int'];
  data: UpdateVotdServiceInput;
}


export interface AccountMutationCreateRecurringBumpScheduleArgs {
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  city_id: Scalars['Int'];
  weekday: Scalars['Int'];
  frequency: Scalars['Int'];
  status: RecurringBumpScheduleStatus;
  start_time: Scalars['String'];
  end_time?: Maybe<Scalars['String']>;
}


export interface AccountMutationUpdateRecurringBumpScheduleArgs {
  schedule_id: Scalars['String'];
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  city_id: Scalars['Int'];
  weekday: Scalars['Int'];
  frequency: Scalars['Int'];
  status: RecurringBumpScheduleStatus;
  start_time: Scalars['String'];
  end_time?: Maybe<Scalars['String']>;
}


export interface AccountMutationCreateOneTimeBumpScheduleArgs {
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  city_ids: Array<Scalars['Int']>;
  execution_date: Scalars['Date'];
}


export interface AccountMutationCreateInstantBumpArgs {
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  city_ids: Array<Scalars['Int']>;
}


export interface AccountMutationDeleteBumpScheduleArgs {
  account_id: Scalars['Int'];
  schedule_id: Scalars['String'];
}


export interface AccountMutationNotificationMessageStatusSeenArgs {
  id: Scalars['ID'];
}


export interface AccountMutationCreateProfileStatusMessageArgs {
  short_message: Scalars['String'];
  long_message: Scalars['String'];
  profile_id: Scalars['String'];
}


export interface AccountMutationUpdateProfileStatusMessageArgs {
  short_message: Scalars['String'];
  long_message: Scalars['String'];
  profile_id: Scalars['String'];
}


export interface AccountMutationDeleteProfileStatusMessagesArgs {
  profile_ids: Array<Maybe<Scalars['String']>>;
}


export interface AccountMutationCreateInstantBookAvailabilityArgs {
  profile_id: Scalars['String'];
  hours: Scalars['Int'];
  available_for: Array<InstantBookAvailabilities>;
}


export interface AccountMutationCreateInstantBookAvailabilitiesArgs {
  profile_ids: Array<Maybe<Scalars['String']>>;
  hours: Scalars['Int'];
  available_for: Array<InstantBookAvailabilities>;
}


export interface AccountMutationUpdateInstantBookAvailabilityArgs {
  id: Scalars['String'];
  hours: Scalars['Int'];
  available_for: Array<InstantBookAvailabilities>;
}


export interface AccountMutationUpdateEscortInstantBookSettingsArgs {
  account_id: Scalars['Int'];
  data: UpdateEscortInstantBookSettingsInput;
}


export interface AccountMutationUpdateAgencyInstantBookSettingsArgs {
  account_id: Scalars['Int'];
  data: UpdateAgencyInstantBookSettingsInput;
}


export interface AccountMutationCancelInstantBookAvailabilityArgs {
  id: Scalars['String'];
}


export interface AccountMutationCancelInstantBookAvailabilitiesByAccountArgs {
  account_id: Scalars['Int'];
}


export interface AccountMutationCancelInstantBookAvailabilitiesByProfileIdsArgs {
  profile_ids: Array<Maybe<Scalars['String']>>;
}


export interface AccountMutationDeclineInstantBookRequestArgs {
  request_id: Scalars['String'];
}


export interface AccountMutationAcceptInstantBookRequestArgs {
  request_id: Scalars['String'];
}


export interface AccountMutationCreateInstantBookRequestArgs {
  data: CreateInstantBookRequestInput;
}


export interface AccountMutationCreateProfileReviewArgs {
  profile_id: Scalars['String'];
  data: ProfileReviewInput;
}


export interface AccountMutationRespondProfileReviewArgs {
  id: Scalars['String'];
  response: ProfileReviewResponse;
}


export interface AccountMutationProfileReviewReplyArgs {
  review_id: Scalars['String'];
  body: Scalars['String'];
}


export interface AccountMutationCheckForBlacklistArgs {
  data?: Maybe<Array<Maybe<CheckForBlacklistInput>>>;
}


export interface AccountMutationCreateClassifiedAdArgs {
  data?: Maybe<ClassifiedAdInput>;
}


export interface AccountMutationRemoveClassifiedAdArgs {
  id?: Maybe<Scalars['String']>;
}


export interface AccountMutationRepostClassifiedAdArgs {
  id?: Maybe<Scalars['String']>;
}


export interface AccountMutationPrepareClassifiedAdForCartArgs {
  reference_id: Scalars['Int'];
  in_cart: Scalars['Boolean'];
  duration?: Maybe<Scalars['Int']>;
}


export interface AccountMutationCreateBlacklistedClientArgs {
  data: BlacklistedClientInput;
}


export interface AccountMutationUpdateVerificationArgs {
  data: UpdateVerificationInput;
}


export interface AccountMutationAddUnalteredPhotosArgs {
  data: AddUnalteredPhotosInput;
}


export interface AccountMutationMarkWinnerAsSeenByAccountIdArgs {
  account_id: Scalars['Int'];
}


export interface AccountMutationMarkOfferAsSeenByAccountIdArgs {
  account_id: Scalars['Int'];
}


export interface AccountMutationSetMessengerDisabledArgs {
  account_id: Scalars['Int'];
}


export interface AccountMutationSetMessengerThreadReadArgs {
  id: Scalars['String'];
}


export interface AccountMutationUnsetMessengerDisabledArgs {
  account_id: Scalars['Int'];
}


export interface AccountMutationCreateMessengerMessageArgs {
  thread_id: Scalars['String'];
  from_profile_id: Scalars['String'];
  from_profile_type: MessengerParticipantType;
  body?: Maybe<Scalars['String']>;
}


export interface AccountMutationCreateMessengerThreadArgs {
  from_profile_id: Scalars['String'];
  from_profile_type: MessengerParticipantType;
  to_profile_id: Scalars['String'];
  to_profile_type: MessengerParticipantType;
}


export interface AccountMutationDeleteMessengerThreadArgs {
  id: Scalars['String'];
}


export interface AccountMutationBlockMessengerThreadArgs {
  id: Scalars['String'];
}


export interface AccountMutationUnblockMessengerThreadArgs {
  id: Scalars['String'];
}


export interface AccountMutationSetMessengerThreadFavoriteArgs {
  id: Scalars['String'];
}


export interface AccountMutationUnsetMessengerThreadFavoriteArgs {
  id: Scalars['String'];
}


export interface AccountMutationCreateMemberAlertArgs {
  profile_id: Scalars['String'];
  events: MemberAlertEventsInput;
}


export interface AccountMutationUpdateMemberAlertArgs {
  id: Scalars['String'];
  events: MemberAlertEventsInput;
}


export interface AccountMutationDeleteMemberAlertArgs {
  id: Scalars['String'];
}


export interface AccountMutationCreateMemberCityAlertArgs {
  geo_node_id: Scalars['Int'];
  events: MemberCityAlertEventsInput;
}


export interface AccountMutationCreateMemberCityAlertsArgs {
  geo_node_ids: Array<Scalars['Int']>;
  events: MemberCityAlertEventsInput;
}


export interface AccountMutationDeleteMemberCityAlertArgs {
  id: Scalars['String'];
}


export interface AccountMutationCreateTourArgs {
  profile_id: Scalars['String'];
  data?: Maybe<ProfileTourInput>;
}


export interface AccountMutationUpdateTourArgs {
  id: Scalars['String'];
  data: ProfileTourInput;
}


export interface AccountMutationDeleteTourArgs {
  id: Scalars['String'];
}


export interface AccountMutationCreateMemberFavoriteProfileArgs {
  profile_id: Scalars['String'];
}


export interface AccountMutationUpdateMemberFavoriteProfileArgs {
  id: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
}


export interface AccountMutationDeleteMemberFavoriteProfileArgs {
  id: Scalars['String'];
}


export interface AccountMutationAddMemberFavoriteProfileToTop10Args {
  id: Scalars['String'];
}


export interface AccountMutationDeleteMemberFavoriteProfileFromTop10Args {
  id: Scalars['String'];
}


export interface AccountMutationMoveUpMemberFavoriteProfileTop10Args {
  id: Scalars['String'];
}


export interface AccountMutationMoveDownMemberFavoriteProfileTop10Args {
  id: Scalars['String'];
}


export interface AccountMutationSetMemberFavoriteProfileTop10CommentTypeArgs {
  id: Scalars['String'];
  type: MemberFavoriteTopTenCommentTypes;
}


export interface AccountMutationCreateMemberFavoriteProfileTop10CommentRequestArgs {
  favorite_id: Scalars['String'];
}


export interface AccountMutationDeclineMemberFavoriteProfileTop10CommentRequestArgs {
  id: Scalars['String'];
}


export interface AccountMutationShareMemberFavoriteProfileTop10CommentRequestByChatArgs {
  id: Scalars['String'];
}


export interface AccountMutationShareMemberFavoriteProfileTop10CommentRequestArgs {
  id: Scalars['String'];
}


export interface AccountMutationSendEmailVerificationArgs {
  account_id: Scalars['Int'];
  email: Scalars['String'];
  purpose?: Maybe<ContactVerificationEmailPurposes>;
}


export interface AccountMutationSendPhoneNumberVerificationArgs {
  account_id: Scalars['Int'];
  country_code: Scalars['String'];
  number: Scalars['String'];
  purpose: ContactVerificationPhoneNumberPurposes;
}


export interface AccountMutationSendCallPhoneNumberVerificationArgs {
  account_id: Scalars['Int'];
  country_code: Scalars['String'];
  number: Scalars['String'];
  purpose: ContactVerificationPhoneNumberPurposes;
}


export interface AccountMutationVerifyPhoneNumberArgs {
  account_id: Scalars['Int'];
  verification_code: Scalars['String'];
  purpose: ContactVerificationPhoneNumberPurposes;
}


export interface AccountMutationMoveProfileArgs {
  data: MoveProfileData;
}


export interface AccountMutationCreateBannerArgs {
  account_id: Scalars['Int'];
  data?: Maybe<BannerInput>;
}


export interface AccountMutationModifyBannerArgs {
  id: Scalars['String'];
  data?: Maybe<BannerModifyInput>;
}


export interface AccountMutationAuthenticate_Linked_AccountArgs {
  linked_account_id: Scalars['Int'];
  lifetime?: Maybe<Scalars['Int']>;
}


export interface AccountMutationUpdateAccountEmailArgs {
  email: Scalars['String'];
}


export interface AccountMutationUpdateAccountPasswordArgs {
  password: Scalars['String'];
  new_password: Scalars['String'];
}


export interface AccountMutationDeleteAccountArgs {
  account_id: Scalars['Int'];
}


export interface AccountMutationDeleteAccountTokenArgs {
  token_id: Scalars['String'];
}


export interface AccountMutationUpdateAccountPreferencesArgs {
  account_id: Scalars['Int'];
  preferences?: Maybe<UpdateAccountPreferencesInput>;
}


export interface AccountMutationCreateAccountUpdateOpsRequestArgs {
  ops_account_id: Scalars['Int'];
}


export interface AccountMutationVerifyAccountUpdateOpsRequestArgs {
  secret: Scalars['String'];
}


export interface AccountMutationAddProductArgs {
  data: AddProductInput;
}


export interface AccountMutationAddProductsArgs {
  data: Array<Maybe<AddProductInput>>;
}


export interface AccountMutationUpdateProductArgs {
  data: UpdateProductInput;
}


export interface AccountMutationRemoveProductArgs {
  order_id: Scalars['Int'];
  order_product_id: Scalars['Int'];
}


export interface AccountMutationMarkOrderWaitingForPaymentArgs {
  order_id: Scalars['Int'];
}


export interface AccountMutationCheckOrderBeforePaymentArgs {
  order_id: Scalars['Int'];
}


export interface AccountMutationMarkOrderInCartArgs {
  order_id: Scalars['Int'];
}


export interface AccountMutationDeleteOrderArgs {
  order_id: Scalars['Int'];
}


export interface AccountMutationApplyPromoCodeDiscountArgs {
  order_id: Scalars['Int'];
  promo_code: Scalars['String'];
}

export interface AccountOpsContactData {
  skype?: Maybe<AccountOpsContactDataSkype>;
  phone_numbers?: Maybe<Array<Maybe<AccountOpsContactDataPhoneNumber>>>;
  spoken_languages?: Maybe<Array<Maybe<ProfileSpokenLanguage>>>;
}

export interface AccountOpsContactDataInput {
  skype?: Maybe<AccountOpsContactDataSkypeInput>;
  phone_numbers?: Maybe<Array<Maybe<AccountOpsContactDataPhoneNumberInput>>>;
  spoken_languages?: Maybe<Array<Maybe<ProfileSpokenLanguage>>>;
}

export interface AccountOpsContactDataPhoneNumber {
  value: Scalars['String'];
  tags: Array<Maybe<AccountPhoneNumberTags>>;
}

export interface AccountOpsContactDataPhoneNumberInput {
  value: Scalars['String'];
  tags: Array<Maybe<AccountPhoneNumberTags>>;
}

export interface AccountOpsContactDataSkype {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<AccountSkypeStatus>;
}

export interface AccountOpsContactDataSkypeInput {
  name: Scalars['String'];
  status: AccountSkypeStatus;
}

export enum AccountPasswordRecoveryMethod {
  Sms = 'SMS',
  Email = 'EMAIL'
}

export interface AccountPermissionExtender {
  add?: Maybe<Array<Maybe<Scalars['String']>>>;
  remove?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum AccountPhoneNumberTags {
  Whatsapp = 'WHATSAPP'
}

export interface AccountPreferences {
  enable_two_factor_auth?: Maybe<Scalars['Boolean']>;
  newsletters?: Maybe<AccountPreferencesNewsletter>;
  watched_profiles_ttl?: Maybe<AccountPreferencesWatchedProfilesTtl>;
  language?: Maybe<AccountPreferencesLanguage>;
  alert_delivery?: Maybe<Array<Maybe<AccountPreferencesAlertDelivery>>>;
}

export enum AccountPreferencesAlertDelivery {
  Dashboard = 'DASHBOARD',
  Email = 'EMAIL'
}

export enum AccountPreferencesLanguage {
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  Ro = 'RO',
  It = 'IT',
  Hu = 'HU'
}

export enum AccountPreferencesNewsletter {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED'
}

export enum AccountPreferencesWatchedProfilesTtl {
  CurrentSession = 'CURRENT_SESSION',
  Today = 'TODAY',
  LastThreeDays = 'LAST_THREE_DAYS',
  ThisWeek = 'THIS_WEEK',
  AllTime = 'ALL_TIME'
}

export interface AccountQuery {
  supportTicketComments: Array<Maybe<Comment>>;
  supportTicket?: Maybe<SupportTicket>;
  supportTickets?: Maybe<Array<SupportTicket>>;
  supportTicketsByAccount?: Maybe<SupportTicketsByAccountResponse>;
  watchedProfilesByAccount?: Maybe<Array<Maybe<StatisticsMemberWatchedProfile>>>;
  profileActivitiesCount?: Maybe<Array<Maybe<StatisticProfileActivitiesCount>>>;
  memberProfile?: Maybe<MemberProfile>;
  memberProfileByRef?: Maybe<MemberProfile>;
  memberProfileByAccount?: Maybe<MemberProfile>;
  memberProfileLastTopicFirstPost?: Maybe<MemberProfileLastTopicFirstPost>;
  companyProfile?: Maybe<CompanyProfile>;
  companyProfileByRef?: Maybe<CompanyProfile>;
  companyProfileByAccount?: Maybe<CompanyProfile>;
  checkCompanyPhoneNumberUniqueness?: Maybe<CompanyProfilePhoneUniquenessCheckResult>;
  checkCompanyEmailUniqueness?: Maybe<CompanyProfileEmailUniquenessCheckResult>;
  companyProfileLastTopicFirstPost?: Maybe<CompanyProfileLastTopicFirstPost>;
  companyProfilePhotos?: Maybe<Array<Maybe<CompanyProfilePhoto>>>;
  profile?: Maybe<Profile>;
  profileByRef?: Maybe<Profile>;
  profileByAccount?: Maybe<Profile>;
  profilesByCompany?: Maybe<Array<Profile>>;
  profilesCountByCompany?: Maybe<ProfilesCountByCompanyResult>;
  checkProfileEmailUniqueness?: Maybe<ProfileEmailUniquenessCheckResult>;
  checkProfilePhoneNumberUniqueness?: Maybe<ProfilePhoneUniquenessCheckResult>;
  profileLastTopicFirstPost?: Maybe<ProfileLastTopicFirstPost>;
  profilePhoto?: Maybe<ProfilePhoto>;
  profilePhotos?: Maybe<Array<Maybe<ProfilePhoto>>>;
  profileVideo?: Maybe<ProfileVideo>;
  profileVideos?: Maybe<Array<Maybe<ProfileVideo>>>;
  profileNaturalPhoto?: Maybe<ProfileNaturalPhoto>;
  profileNaturalPhotos?: Maybe<Array<Maybe<ProfileNaturalPhoto>>>;
  profileVacationByProfileId?: Maybe<ProfileVacation>;
  profileVacationByProfileIds?: Maybe<Array<Maybe<ProfileVacation>>>;
  getBillingInfoByProfileId?: Maybe<BillingInfo>;
  storyByProfile?: Maybe<StoriesInterior>;
  storyApprovedHistoryByProfile?: Maybe<Array<Maybe<Story>>>;
  storyByAccountId?: Maybe<StoriesByAccount>;
  profileHappyHour?: Maybe<ProfileHappyHour>;
  profileHappyHourByProfileAndStatuses?: Maybe<Array<Maybe<ProfileHappyHour>>>;
  profileHappyHourByAccount?: Maybe<Array<Maybe<ProfileHappyHour>>>;
  profileHappyHourHistoryByProfileId?: Maybe<ProfileHappyHourByFiltersForCellarResponse>;
  checkVotedGotmByProfile: Scalars['Boolean'];
  baseService?: Maybe<BaseService>;
  baseServices: BaseServicesResponse;
  baseServiceAdditionalGeoNodes?: Maybe<Array<Maybe<BaseServiceAdditionalGeoNode>>>;
  gotdService?: Maybe<GotdService>;
  gotdServices: GotdServicesResponse;
  votdService?: Maybe<VotdService>;
  votdServices: VotdServicesResponse;
  classifiedAdService?: Maybe<ClassifiedAdService>;
  classifiedAdServices: ClassifiedAdServicesResponse;
  bumpSchedules: BumpSchedulesResponse;
  bumpCredits: BumpCreditsResponse;
  notificationMessagesByAccount: Array<Maybe<NotificationMessage>>;
  statusMessageByProfile?: Maybe<ProfileStatusMessage>;
  profileStatusMessageConfigs?: Maybe<ProfileStatusMessageConfig>;
  instantBookCheckRequestByAccount?: Maybe<InstantBookCheckRequestResponse>;
  instantBookAvailabilityById?: Maybe<InstantBookAvailability>;
  instantBookRequestById?: Maybe<InstantBookRequest>;
  instantBookSettingsByAccount?: Maybe<InstantBookSettings>;
  instantBookRequestsByAccount?: Maybe<Array<Maybe<InstantBookRequest>>>;
  instantBookPendingRequests?: Maybe<Array<Maybe<InstantBookRequest>>>;
  instantBookLatestAcceptedRequests?: Maybe<Array<Maybe<InstantBookRequest>>>;
  instantBookRequestHistoryByFilter?: Maybe<InstantBookRequestsPagination>;
  availabilitiesForInstantBookByAccount?: Maybe<Array<Maybe<InstantBookAvailability>>>;
  profileReviewsByProfile?: Maybe<Array<Maybe<ProfileReview>>>;
  profileReviewsByAccount?: Maybe<ProfileReviewsPaginationResponse>;
  profileReviewsByFilter?: Maybe<ProfileReviewsPaginationResponse>;
  profileReviewsByMemberAccount?: Maybe<ProfileReviewsPaginationResponse>;
  profileReviewsNeedConfirmation?: Maybe<Array<Maybe<ProfileReview>>>;
  profileReview?: Maybe<ProfileReview>;
  profileReviewForMember?: Maybe<ProfileReview>;
  profileReviewConfigs?: Maybe<ProfileReviewConfigs>;
  faq?: Maybe<Faq>;
  faqsByAccount?: Maybe<Array<Maybe<Faq>>>;
  checkPushAdsPaymentStatus: CheckPushAdsPaymentStatusResponse;
  classifiedAd?: Maybe<ClassifiedAd>;
  classifiedAdByRefId?: Maybe<ClassifiedAd>;
  classifiedAdsByFilter?: Maybe<ClassifiedAdsFilterResponse>;
  blacklistedClients?: Maybe<BlacklistedClientList>;
  adminEntries?: Maybe<Array<Maybe<BlacklistedEntries>>>;
  blacklistedClientConfigs?: Maybe<BlacklistedClientConfig>;
  profileVerificationById?: Maybe<ProfileVerification>;
  profileVerificationsByIds?: Maybe<Array<Maybe<ProfileVerification>>>;
  profileVerificationByProfileId?: Maybe<ProfileVerification>;
  profileVerificationsByProfileIds?: Maybe<Array<Maybe<ProfileVerification>>>;
  pendingOneHundredVerificationsByAccount?: Maybe<PendingOneHundredVerificationsResponse>;
  pendingAgeVerificationsByAccount?: Maybe<PendingAgeVerificationsResponse>;
  products?: Maybe<Array<Maybe<Product>>>;
  baseServiceProducts?: Maybe<Array<Maybe<BaseServiceProduct>>>;
  bannerProducts?: Maybe<Array<Maybe<BannerProduct>>>;
  classifiedAdProducts?: Maybe<Array<Maybe<BannerProduct>>>;
  gotdProduct?: Maybe<GotdProduct>;
  votdProduct?: Maybe<VotdProduct>;
  bumpBundleProduct?: Maybe<BumpBundleProduct>;
  productAdditionalOptions?: Maybe<Array<Maybe<ProductOption>>>;
  reservations?: Maybe<Array<Maybe<ReservationResponse>>>;
  availableBaseServiceProducts?: Maybe<Array<Maybe<ProductListItem>>>;
  inventory?: Maybe<Inventory>;
  dashboardNotificationsByAccount?: Maybe<DashboardNotificationPagination>;
  winnerByAccountId?: Maybe<LotteryWinnerEvent>;
  offerByAccountId?: Maybe<LotteryOfferResult>;
  messengerAvailability: MessengerAvailabilityResponse;
  messengerThreads?: Maybe<MessengerThreadsResponse>;
  messengerBlockedThreads?: Maybe<MessengerThreadsResponse>;
  messengerOnlineAccounts: Array<Maybe<MessengerOnlineAccount>>;
  messengerThreadsByParticipants?: Maybe<MessengerThreadsResponse>;
  messengerMessages?: Maybe<MessengerMessagesResponse>;
  unreadMessengerMessagesCount?: Maybe<UnreadMessengerMessagesCountResponse>;
  messengerConfigs: MessengerConfigResponse;
  cityAlertsByAccount?: Maybe<Array<Maybe<CityAlert>>>;
  memberAlertsByAccount?: Maybe<MemberAlertPagination>;
  memberAlertByProfile?: Maybe<MemberAlert>;
  memberAlert?: Maybe<MemberAlert>;
  memberAlertConfigs?: Maybe<MemberAlertConfigs>;
  tourById?: Maybe<ProfileTour>;
  toursByIds?: Maybe<Array<Maybe<ProfileTour>>>;
  toursByProfile?: Maybe<Array<Maybe<ProfileTour>>>;
  toursByAccount?: Maybe<Array<Maybe<ProfileTour>>>;
  tourHistoryByAccount?: Maybe<ProfileTourHistory>;
  tourHistoryByProfile?: Maybe<ProfileTourHistory>;
  tourHistoryByFilter?: Maybe<ProfileTourHistory>;
  memberFavoriteProfilesByAccount: MemberFavoriteProfilesResponse;
  memberFavoriteProfileById: MemberFavoriteProfile;
  checkProfileIsInFavorite?: Maybe<MemberFavoriteProfile>;
  memberFavoriteProfilesTop10ListByAccount: MemberFavoriteProfileTopTenListResponse;
  memberFavoriteProfilesTop10CommentRequestsByFilter: MemberFavoriteProfilesTop10CommentRequestsByFilterResponse;
  checkEmailsVerification?: Maybe<Array<Maybe<CheckEmailsVerificationResponse>>>;
  checkPhoneNumbersVerification?: Maybe<Array<Maybe<CheckPhoneNumbersVerificationResponse>>>;
  checkEmailVerification?: Maybe<CheckEmailVerificationResponse>;
  checkPhoneNumberVerification?: Maybe<CheckPhoneNumberVerificationResponse>;
  image?: Maybe<Image>;
  images?: Maybe<Array<Maybe<Image>>>;
  document?: Maybe<Document>;
  documents?: Maybe<Array<Maybe<Document>>>;
  video?: Maybe<Video>;
  videos?: Maybe<Array<Maybe<Video>>>;
  bannerById?: Maybe<Banner>;
  bannerByRefId?: Maybe<Banner>;
  account?: Maybe<Account>;
  accountValidTokens?: Maybe<Array<Maybe<AccountValidToken>>>;
  cart?: Maybe<FullOrder>;
  order?: Maybe<FullOrder>;
  balance?: Maybe<Balance>;
  orders?: Maybe<OrdersResponse>;
  transfers?: Maybe<TransfersResponse>;
  inCartProducts?: Maybe<Array<Maybe<InCartProduct>>>;
  enabledPaymentMethods: Array<PaymentEnabledMethod>;
  paymentUrl?: Maybe<PaymentUrlResponse>;
  paymentByReferenceId?: Maybe<PaymentByReferenceIdResponse>;
  dummy?: Maybe<Scalars['String']>;
}


export interface AccountQuerySupportTicketCommentsArgs {
  ticket_id: Scalars['String'];
}


export interface AccountQuerySupportTicketArgs {
  ticket_id: Scalars['ID'];
}


export interface AccountQuerySupportTicketsArgs {
  ticket_ids: Array<Scalars['ID']>;
}


export interface AccountQuerySupportTicketsByAccountArgs {
  account_id: Scalars['Int'];
  filter?: Maybe<SupportTicketFilterInput>;
}


export interface AccountQueryWatchedProfilesByAccountArgs {
  profile_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  token?: Maybe<Scalars['String']>;
}


export interface AccountQueryProfileActivitiesCountArgs {
  account_id: Scalars['Int'];
  profile_id?: Maybe<Scalars['ID']>;
  date_from?: Maybe<Scalars['String']>;
  date_to?: Maybe<Scalars['String']>;
  tz?: Maybe<Scalars['String']>;
}


export interface AccountQueryMemberProfileArgs {
  id: Scalars['String'];
}


export interface AccountQueryMemberProfileByRefArgs {
  id: Scalars['Int'];
}


export interface AccountQueryMemberProfileByAccountArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryCompanyProfileArgs {
  id: Scalars['String'];
}


export interface AccountQueryCompanyProfileByRefArgs {
  id: Scalars['Int'];
}


export interface AccountQueryCompanyProfileByAccountArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryCheckCompanyPhoneNumberUniquenessArgs {
  country_code: Scalars['String'];
  number: Scalars['String'];
  exclude_company_profile_id?: Maybe<Scalars['String']>;
  account_id: Scalars['Int'];
}


export interface AccountQueryCheckCompanyEmailUniquenessArgs {
  email: Scalars['String'];
  exclude_company_profile_id?: Maybe<Scalars['String']>;
  account_id: Scalars['Int'];
}


export interface AccountQueryCompanyProfilePhotosArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountQueryProfileArgs {
  id: Scalars['String'];
}


export interface AccountQueryProfileByRefArgs {
  id: Scalars['Int'];
}


export interface AccountQueryProfileByAccountArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryProfilesByCompanyArgs {
  company_id: Scalars['String'];
}


export interface AccountQueryProfilesCountByCompanyArgs {
  company_id: Scalars['String'];
}


export interface AccountQueryCheckProfileEmailUniquenessArgs {
  email: Scalars['String'];
  exclude_profile_id: Scalars['String'];
  account_id: Scalars['Int'];
}


export interface AccountQueryCheckProfilePhoneNumberUniquenessArgs {
  country_code: Scalars['String'];
  number: Scalars['String'];
  exclude_profile_id: Scalars['String'];
  account_id: Scalars['Int'];
}


export interface AccountQueryProfilePhotoArgs {
  id: Scalars['String'];
}


export interface AccountQueryProfilePhotosArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountQueryProfileVideoArgs {
  id: Scalars['String'];
}


export interface AccountQueryProfileVideosArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountQueryProfileNaturalPhotoArgs {
  id: Scalars['String'];
}


export interface AccountQueryProfileNaturalPhotosArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountQueryProfileVacationByProfileIdArgs {
  profile_id: Scalars['String'];
}


export interface AccountQueryProfileVacationByProfileIdsArgs {
  profile_ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountQueryGetBillingInfoByProfileIdArgs {
  id: Scalars['String'];
}


export interface AccountQueryStoryByProfileArgs {
  profile_id: Scalars['ID'];
}


export interface AccountQueryStoryApprovedHistoryByProfileArgs {
  data: StoryHistoryByProfile;
}


export interface AccountQueryStoryByAccountIdArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryProfileHappyHourArgs {
  id: Scalars['String'];
}


export interface AccountQueryProfileHappyHourByProfileAndStatusesArgs {
  id: Scalars['String'];
  statuses?: Maybe<Array<ProfileHappyHourStatuses>>;
}


export interface AccountQueryProfileHappyHourByAccountArgs {
  id: Scalars['Int'];
}


export interface AccountQueryProfileHappyHourHistoryByProfileIdArgs {
  filter?: Maybe<ProfileHappyHourHistoryFiltersInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryCheckVotedGotmByProfileArgs {
  profile_id: Scalars['String'];
}


export interface AccountQueryBaseServiceArgs {
  id: Scalars['Int'];
}


export interface AccountQueryBaseServicesArgs {
  account_id: Scalars['Int'];
  filter: QueryBaseServicesFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface AccountQueryBaseServiceAdditionalGeoNodesArgs {
  id: Scalars['Int'];
}


export interface AccountQueryGotdServiceArgs {
  id: Scalars['Int'];
}


export interface AccountQueryGotdServicesArgs {
  account_id: Scalars['Int'];
  filter: QueryGotdServicesFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface AccountQueryVotdServiceArgs {
  id: Scalars['Int'];
}


export interface AccountQueryVotdServicesArgs {
  account_id: Scalars['Int'];
  filter: QueryVotdServicesFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface AccountQueryClassifiedAdServiceArgs {
  id: Scalars['Int'];
}


export interface AccountQueryClassifiedAdServicesArgs {
  account_id: Scalars['Int'];
  filter: QueryClassifiedAdServicesFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface AccountQueryBumpSchedulesArgs {
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
}


export interface AccountQueryBumpCreditsArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryNotificationMessagesByAccountArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryStatusMessageByProfileArgs {
  profile_id: Scalars['String'];
}


export interface AccountQueryInstantBookCheckRequestByAccountArgs {
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
}


export interface AccountQueryInstantBookAvailabilityByIdArgs {
  id: Scalars['String'];
}


export interface AccountQueryInstantBookRequestByIdArgs {
  id: Scalars['String'];
}


export interface AccountQueryInstantBookSettingsByAccountArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryInstantBookRequestsByAccountArgs {
  account_id: Scalars['Int'];
  statuses: Array<Maybe<InstantBookRequestStatuses>>;
}


export interface AccountQueryInstantBookPendingRequestsArgs {
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
}


export interface AccountQueryInstantBookLatestAcceptedRequestsArgs {
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
}


export interface AccountQueryInstantBookRequestHistoryByFilterArgs {
  data: InstantBookRequestHistoryInput;
}


export interface AccountQueryAvailabilitiesForInstantBookByAccountArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryProfileReviewsByProfileArgs {
  profile_id: Scalars['String'];
}


export interface AccountQueryProfileReviewsByAccountArgs {
  account_id: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryProfileReviewsByFilterArgs {
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryProfileReviewsByMemberAccountArgs {
  account_id: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryProfileReviewsNeedConfirmationArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryProfileReviewArgs {
  id: Scalars['String'];
}


export interface AccountQueryProfileReviewForMemberArgs {
  id: Scalars['String'];
}


export interface AccountQueryFaqArgs {
  id: Scalars['ID'];
}


export interface AccountQueryFaqsByAccountArgs {
  account_role?: Maybe<FaqAccountRole>;
}


export interface AccountQueryCheckPushAdsPaymentStatusArgs {
  account_id: Scalars['Int'];
  transaction_id: Scalars['String'];
}


export interface AccountQueryClassifiedAdArgs {
  id?: Maybe<Scalars['String']>;
}


export interface AccountQueryClassifiedAdByRefIdArgs {
  id?: Maybe<Scalars['Int']>;
}


export interface AccountQueryClassifiedAdsByFilterArgs {
  filter: ClassifiedAdsForAccountFilterInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryBlacklistedClientsArgs {
  query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryProfileVerificationByIdArgs {
  id: Scalars['String'];
}


export interface AccountQueryProfileVerificationsByIdsArgs {
  ids: Array<Maybe<Scalars['String']>>;
}


export interface AccountQueryProfileVerificationByProfileIdArgs {
  id: Scalars['String'];
}


export interface AccountQueryProfileVerificationsByProfileIdsArgs {
  ids: Array<Maybe<Scalars['String']>>;
}


export interface AccountQueryPendingOneHundredVerificationsByAccountArgs {
  account_id: Scalars['Int'];
  profile_id?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryPendingAgeVerificationsByAccountArgs {
  account_id: Scalars['Int'];
  profile_id?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryProductsArgs {
  entities: Array<Maybe<RequestEntity>>;
}


export interface AccountQueryBaseServiceProductsArgs {
  entities: Array<Maybe<RequestEntity>>;
  load_inventory?: Maybe<Scalars['Boolean']>;
}


export interface AccountQueryBannerProductsArgs {
  entities: Array<Maybe<RequestEntity>>;
  load_inventory?: Maybe<Scalars['Boolean']>;
}


export interface AccountQueryClassifiedAdProductsArgs {
  entities: Array<Maybe<RequestEntity>>;
  load_inventory?: Maybe<Scalars['Boolean']>;
}


export interface AccountQueryGotdProductArgs {
  entities: Array<Maybe<RequestEntity>>;
}


export interface AccountQueryVotdProductArgs {
  entities: Array<Maybe<RequestEntity>>;
}


export interface AccountQueryBumpBundleProductArgs {
  entities: Array<Maybe<RequestEntity>>;
}


export interface AccountQueryProductAdditionalOptionsArgs {
  product_id: Scalars['Int'];
  entities: Array<Maybe<RequestEntity>>;
}


export interface AccountQueryReservationsArgs {
  inventory_id: Scalars['Int'];
  date_from?: Maybe<Scalars['Date']>;
  date_to: Scalars['Date'];
}


export interface AccountQueryInventoryArgs {
  id: Scalars['Int'];
}


export interface AccountQueryDashboardNotificationsByAccountArgs {
  account_id: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryWinnerByAccountIdArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryOfferByAccountIdArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryMessengerAvailabilityArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryMessengerThreadsByParticipantsArgs {
  from_profile_id: Scalars['String'];
  from_profile_type: MessengerParticipantType;
  to_profile_id: Scalars['String'];
  to_profile_type: MessengerParticipantType;
}


export interface AccountQueryMessengerMessagesArgs {
  id: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}


export interface AccountQueryCityAlertsByAccountArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryMemberAlertsByAccountArgs {
  account_id: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryMemberAlertByProfileArgs {
  profile_id: Scalars['String'];
}


export interface AccountQueryMemberAlertArgs {
  id: Scalars['String'];
}


export interface AccountQueryTourByIdArgs {
  id: Scalars['String'];
}


export interface AccountQueryToursByIdsArgs {
  ids: Array<Maybe<Scalars['String']>>;
}


export interface AccountQueryToursByProfileArgs {
  profile_id: Scalars['String'];
  statuses?: Maybe<Array<Maybe<ProfileTourStatuses>>>;
}


export interface AccountQueryToursByAccountArgs {
  account_id: Scalars['Int'];
  statuses: Array<Maybe<ProfileTourStatuses>>;
}


export interface AccountQueryTourHistoryByAccountArgs {
  account_id: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryTourHistoryByProfileArgs {
  id: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryTourHistoryByFilterArgs {
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface AccountQueryMemberFavoriteProfilesByAccountArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryMemberFavoriteProfileByIdArgs {
  id: Scalars['String'];
}


export interface AccountQueryCheckProfileIsInFavoriteArgs {
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
}


export interface AccountQueryMemberFavoriteProfilesTop10ListByAccountArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryMemberFavoriteProfilesTop10CommentRequestsByFilterArgs {
  requester_account_id: Scalars['Int'];
  profile_id?: Maybe<Scalars['Int']>;
  favorite_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
}


export interface AccountQueryCheckEmailsVerificationArgs {
  account_id: Scalars['Int'];
  emails: Array<Maybe<CheckEmailsVerificationInput>>;
}


export interface AccountQueryCheckPhoneNumbersVerificationArgs {
  account_id: Scalars['Int'];
  phone_numbers: Array<Maybe<CheckPhoneNumbersVerificationInput>>;
  purpose: ContactVerificationPhoneNumberPurposes;
  re_verify?: Maybe<Scalars['Boolean']>;
}


export interface AccountQueryCheckEmailVerificationArgs {
  account_id: Scalars['Int'];
  email: Scalars['String'];
}


export interface AccountQueryCheckPhoneNumberVerificationArgs {
  account_id: Scalars['Int'];
  country_code: Scalars['String'];
  number: Scalars['String'];
  purpose: ContactVerificationPhoneNumberPurposes;
  re_verify?: Maybe<Scalars['Boolean']>;
}


export interface AccountQueryImageArgs {
  id: Scalars['String'];
}


export interface AccountQueryImagesArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountQueryDocumentArgs {
  id: Scalars['String'];
}


export interface AccountQueryDocumentsArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountQueryVideoArgs {
  id: Scalars['String'];
}


export interface AccountQueryVideosArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface AccountQueryBannerByIdArgs {
  id: Scalars['String'];
}


export interface AccountQueryBannerByRefIdArgs {
  id: Scalars['Int'];
}


export interface AccountQueryAccountArgs {
  id: Scalars['Int'];
}


export interface AccountQueryCartArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryOrderArgs {
  id: Scalars['Int'];
}


export interface AccountQueryBalanceArgs {
  account_id: Scalars['Int'];
}


export interface AccountQueryOrdersArgs {
  account_id: Scalars['Int'];
  filter: QueryOrderFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}


export interface AccountQueryTransfersArgs {
  account_id: Scalars['Int'];
  filter: QueryTransferFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}


export interface AccountQueryInCartProductsArgs {
  ref_type: ProductRefType;
  ref_id: Scalars['Int'];
}


export interface AccountQueryEnabledPaymentMethodsArgs {
  account_id?: Maybe<Scalars['Int']>;
}


export interface AccountQueryPaymentUrlArgs {
  order_id: Scalars['Int'];
  account_id: Scalars['Int'];
  payment_method: Scalars['String'];
}


export interface AccountQueryPaymentByReferenceIdArgs {
  account_id: Scalars['Int'];
  id: Scalars['Int'];
}

export enum AccountRole {
  Company = 'COMPANY',
  Escort = 'ESCORT',
  Member = 'MEMBER',
  Sales = 'SALES',
  Manager = 'MANAGER',
  Admin = 'ADMIN'
}

export enum AccountSkypeStatus {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
  Away = 'AWAY',
  DoNotDisturb = 'DO_NOT_DISTURB'
}

export enum AccountStatus {
  NotVerified = 'NOT_VERIFIED',
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Deleted = 'DELETED'
}

export interface AccountSubscription {
  dummy?: Maybe<Scalars['String']>;
}

export interface AccountToken {
  token: Scalars['String'];
}

export interface AccountUpdateOpsRequest {
  current_ops_account_id: Scalars['Int'];
  target_ops_account_id: Scalars['Int'];
  initiator_account_id: Scalars['Int'];
  approver_ops_account_id: Scalars['Int'];
  date_requested: Scalars['Date'];
  date_expiration: Scalars['Date'];
  status?: Maybe<AccountUpdateOpsRequestStatus>;
}

export enum AccountUpdateOpsRequestStatus {
  WaitingOps = 'WAITING_OPS',
  WaitingAdvertiser = 'WAITING_ADVERTISER'
}

export interface AccountValidToken {
  id: Scalars['String'];
  date_issued: Scalars['Date'];
  date_expiration: Scalars['Date'];
  meta: AccountValidTokenMeta;
}

export interface AccountValidTokenMeta {
  ip?: Maybe<Scalars['String']>;
}

export interface AccountsFilterResponse {
  count: Scalars['Int'];
  result: Array<Maybe<AccountForOps>>;
}

export interface ActivityAlert {
  _id: Scalars['String'];
  account_id: Scalars['Int'];
  profile_id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  purpose?: Maybe<ActivityAlertPurpose>;
  ordering: Scalars['Int'];
  created_by?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Date']>;
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
}

export interface ActivityAlertCreateResponse {
  id: Scalars['ID'];
}

export interface ActivityAlertDeleteResponse {
  deleted: Scalars['Boolean'];
}

export enum ActivityAlertPurpose {
  Account = 'ACCOUNT',
  Profile = 'PROFILE',
  AgeVerification = 'AGE_VERIFICATION',
  SuspiciousPhoto = 'SUSPICIOUS_PHOTO'
}

export interface ActivityAlertReorderResponse {
  reordered: Scalars['Boolean'];
}

export interface ActivityAlertUpdateResponse {
  updated: Scalars['Boolean'];
}

export interface ActivityLog {
  id: Scalars['String'];
  account_id: Scalars['Int'];
  token_account_id: Scalars['Int'];
  reference_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
  type: ActivityLogType;
  profile_type?: Maybe<ProfileMetaType>;
  action: Scalars['String'];
  template: Scalars['String'];
  photo_ids?: Maybe<Array<Maybe<ActivityLogPhoto>>>;
  tour_id?: Maybe<Scalars['String']>;
  review_id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  date_created: Scalars['Date'];
  new_email?: Maybe<Scalars['String']>;
  old_email?: Maybe<Scalars['String']>;
  new_username?: Maybe<Scalars['String']>;
  old_username?: Maybe<Scalars['String']>;
}

export interface ActivityLogConfigs {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ActivityLogCreateResponse {
  result: Scalars['Boolean'];
}

export interface ActivityLogFilter {
  account_id: Scalars['Int'];
  token_account_id?: Maybe<Scalars['Int']>;
  type?: Maybe<ActivityLogType>;
  action?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}

export interface ActivityLogFilterResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<ActivityLog>>>;
}

export interface ActivityLogPhoto {
  id?: Maybe<Scalars['String']>;
  photo?: Maybe<ProfilePhoto>;
}

export enum ActivityLogType {
  Advertiser = 'ADVERTISER',
  Account = 'ACCOUNT',
  Commerce = 'COMMERCE',
  System = 'SYSTEM',
  Moderator = 'MODERATOR'
}

export interface ActivityTemplate {
  _id: Scalars['String'];
  slug: Scalars['String'];
  body: Scalars['String'];
  created_by: Scalars['Int'];
  date_created: Scalars['Date'];
  modified_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
}

export interface AddNoteResponse {
  body: Scalars['String'];
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
}

export interface AddProductInput {
  order_id: Scalars['Int'];
  product: AddProductProductInput;
}

export interface AddProductOptionInput {
  base_duration?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  date_activation?: Maybe<Scalars['Date']>;
  additional_geo_nodes_count?: Maybe<Scalars['Int']>;
  additional_geo_nodes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  geo_node_id?: Maybe<Scalars['Int']>;
  additional_days_count?: Maybe<Scalars['Int']>;
  zone_id?: Maybe<Scalars['Int']>;
}

export interface AddProductProductInput {
  id: Scalars['Int'];
  reference?: Maybe<AddProductReferenceInput>;
  options: AddProductOptionInput;
}

export interface AddProductReferenceInput {
  type: ProductRefType;
  id?: Maybe<Scalars['Int']>;
}

export interface AddProductResponse {
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
}

export interface AddUnalteredDocumentInput {
  photo_id: Scalars['String'];
  media_document_id: Scalars['String'];
}

export interface AddUnalteredPhotosInput {
  profile_id: Scalars['String'];
  documents: Array<Maybe<AddUnalteredDocumentInput>>;
}

export interface AddUnalteredPhotosResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface AgeVerificationFilterInput {
  ops_account_id?: Maybe<Scalars['Int']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  status?: Maybe<ProfileVerificationStatuses>;
  need_additional_documents?: Maybe<Scalars['Boolean']>;
  need_additional_visa_documents?: Maybe<Scalars['Boolean']>;
  special_case?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['String']>;
  birth_day?: Maybe<Scalars['String']>;
  birth_month?: Maybe<Scalars['String']>;
  birth_year?: Maybe<Scalars['String']>;
  id_number?: Maybe<Scalars['String']>;
  date_filter_by?: Maybe<ProfileVerificationDateFilterType>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export interface AgeVerificationsResponse {
  count: Scalars['Int'];
  result: Array<Maybe<ProfileVerification>>;
}

export interface AggregatorAvailableFilters {
  now_open?: Maybe<Scalars['Int']>;
  is_instant_book_available?: Maybe<Scalars['Int']>;
  has_video?: Maybe<Scalars['Int']>;
  has_story?: Maybe<Scalars['Int']>;
  has_natural_photo?: Maybe<Scalars['Int']>;
  has_review?: Maybe<Scalars['Int']>;
  has_comment?: Maybe<Scalars['Int']>;
  is_verified?: Maybe<Scalars['Int']>;
  is_vip?: Maybe<Scalars['Int']>;
  is_available_for_chat?: Maybe<Scalars['Int']>;
  has_virtual_service?: Maybe<Scalars['Int']>;
  is_cam_booking_enabled?: Maybe<Scalars['Int']>;
  is_live_cam_available?: Maybe<Scalars['Int']>;
  type_single?: Maybe<Scalars['Int']>;
  type_duo?: Maybe<Scalars['Int']>;
  gender_female?: Maybe<Scalars['Int']>;
  gender_male?: Maybe<Scalars['Int']>;
  gender_trans?: Maybe<Scalars['Int']>;
  hair_color_blond?: Maybe<Scalars['Int']>;
  hair_color_light_brown?: Maybe<Scalars['Int']>;
  hair_color_brunette?: Maybe<Scalars['Int']>;
  hair_color_black?: Maybe<Scalars['Int']>;
  hair_color_red?: Maybe<Scalars['Int']>;
  hair_color_other?: Maybe<Scalars['Int']>;
  available_for_incall_private_apartment?: Maybe<Scalars['Int']>;
  available_for_incall_hotel_room?: Maybe<Scalars['Int']>;
  available_for_incall_club_studio?: Maybe<Scalars['Int']>;
  available_for_incall_other?: Maybe<Scalars['Int']>;
  available_for_outcall_hotel_visits_only?: Maybe<Scalars['Int']>;
  available_for_outcall_home_visits_only?: Maybe<Scalars['Int']>;
  available_for_outcall_hotel_and_home_visits?: Maybe<Scalars['Int']>;
  available_for_outcall_other?: Maybe<Scalars['Int']>;
  services_for_men?: Maybe<Scalars['Int']>;
  services_for_women?: Maybe<Scalars['Int']>;
  services_for_couples?: Maybe<Scalars['Int']>;
  services_for_trans?: Maybe<Scalars['Int']>;
  services_for_gays?: Maybe<Scalars['Int']>;
  services_for_plus2?: Maybe<Scalars['Int']>;
  cup_size_a?: Maybe<Scalars['Int']>;
  cup_size_b?: Maybe<Scalars['Int']>;
  cup_size_c?: Maybe<Scalars['Int']>;
  cup_size_d?: Maybe<Scalars['Int']>;
  cup_size_dd?: Maybe<Scalars['Int']>;
  cup_size_f?: Maybe<Scalars['Int']>;
  cup_size_ff?: Maybe<Scalars['Int']>;
  cup_size_g?: Maybe<Scalars['Int']>;
  cup_size_h?: Maybe<Scalars['Int']>;
  cup_size_j?: Maybe<Scalars['Int']>;
  age_18_20?: Maybe<Scalars['Int']>;
  age_21_24?: Maybe<Scalars['Int']>;
  age_25_29?: Maybe<Scalars['Int']>;
  age_30_35?: Maybe<Scalars['Int']>;
  age_36_40?: Maybe<Scalars['Int']>;
  age_41_45?: Maybe<Scalars['Int']>;
  age_46_49?: Maybe<Scalars['Int']>;
  age_50_100?: Maybe<Scalars['Int']>;
  nationality_afghan?: Maybe<Scalars['Int']>;
  nationality_albanian?: Maybe<Scalars['Int']>;
  nationality_algerian?: Maybe<Scalars['Int']>;
  nationality_american?: Maybe<Scalars['Int']>;
  nationality_andorran?: Maybe<Scalars['Int']>;
  nationality_angolan?: Maybe<Scalars['Int']>;
  nationality_antiguan?: Maybe<Scalars['Int']>;
  nationality_argentinian?: Maybe<Scalars['Int']>;
  nationality_armenian?: Maybe<Scalars['Int']>;
  nationality_australian?: Maybe<Scalars['Int']>;
  nationality_austrian?: Maybe<Scalars['Int']>;
  nationality_azerbaijani?: Maybe<Scalars['Int']>;
  nationality_bahamian?: Maybe<Scalars['Int']>;
  nationality_bahraini?: Maybe<Scalars['Int']>;
  nationality_bangladeshi?: Maybe<Scalars['Int']>;
  nationality_barbadian?: Maybe<Scalars['Int']>;
  nationality_barbudan?: Maybe<Scalars['Int']>;
  nationality_batswana?: Maybe<Scalars['Int']>;
  nationality_belarussian?: Maybe<Scalars['Int']>;
  nationality_belgian?: Maybe<Scalars['Int']>;
  nationality_belizean?: Maybe<Scalars['Int']>;
  nationality_beninese?: Maybe<Scalars['Int']>;
  nationality_bhutanese?: Maybe<Scalars['Int']>;
  nationality_bolivian?: Maybe<Scalars['Int']>;
  nationality_bosnian?: Maybe<Scalars['Int']>;
  nationality_brazilian?: Maybe<Scalars['Int']>;
  nationality_british?: Maybe<Scalars['Int']>;
  nationality_bruneian?: Maybe<Scalars['Int']>;
  nationality_bulgarian?: Maybe<Scalars['Int']>;
  nationality_burkinabe?: Maybe<Scalars['Int']>;
  nationality_burmese?: Maybe<Scalars['Int']>;
  nationality_burundian?: Maybe<Scalars['Int']>;
  nationality_cambodian?: Maybe<Scalars['Int']>;
  nationality_cameroonian?: Maybe<Scalars['Int']>;
  nationality_canadian?: Maybe<Scalars['Int']>;
  nationality_cape_verdean?: Maybe<Scalars['Int']>;
  nationality_central_african?: Maybe<Scalars['Int']>;
  nationality_chadian?: Maybe<Scalars['Int']>;
  nationality_chilean?: Maybe<Scalars['Int']>;
  nationality_chinese?: Maybe<Scalars['Int']>;
  nationality_colombian?: Maybe<Scalars['Int']>;
  nationality_comoran?: Maybe<Scalars['Int']>;
  nationality_congolese?: Maybe<Scalars['Int']>;
  nationality_costa_rican?: Maybe<Scalars['Int']>;
  nationality_croatian?: Maybe<Scalars['Int']>;
  nationality_cuban?: Maybe<Scalars['Int']>;
  nationality_cypriot?: Maybe<Scalars['Int']>;
  nationality_czech?: Maybe<Scalars['Int']>;
  nationality_danish?: Maybe<Scalars['Int']>;
  nationality_djibouti?: Maybe<Scalars['Int']>;
  nationality_dominican?: Maybe<Scalars['Int']>;
  nationality_dutch?: Maybe<Scalars['Int']>;
  nationality_east_timorese?: Maybe<Scalars['Int']>;
  nationality_ecuadorean?: Maybe<Scalars['Int']>;
  nationality_egyptian?: Maybe<Scalars['Int']>;
  nationality_emirian?: Maybe<Scalars['Int']>;
  nationality_equatorial_guinean?: Maybe<Scalars['Int']>;
  nationality_eritrean?: Maybe<Scalars['Int']>;
  nationality_estonian?: Maybe<Scalars['Int']>;
  nationality_ethiopian?: Maybe<Scalars['Int']>;
  nationality_fijian?: Maybe<Scalars['Int']>;
  nationality_filipino?: Maybe<Scalars['Int']>;
  nationality_finnish?: Maybe<Scalars['Int']>;
  nationality_french?: Maybe<Scalars['Int']>;
  nationality_gabonese?: Maybe<Scalars['Int']>;
  nationality_gambian?: Maybe<Scalars['Int']>;
  nationality_georgian?: Maybe<Scalars['Int']>;
  nationality_german?: Maybe<Scalars['Int']>;
  nationality_ghanaian?: Maybe<Scalars['Int']>;
  nationality_greek?: Maybe<Scalars['Int']>;
  nationality_grenadian?: Maybe<Scalars['Int']>;
  nationality_guatemalan?: Maybe<Scalars['Int']>;
  nationality_guinea_bissauan?: Maybe<Scalars['Int']>;
  nationality_guinean?: Maybe<Scalars['Int']>;
  nationality_guyanese?: Maybe<Scalars['Int']>;
  nationality_haitian?: Maybe<Scalars['Int']>;
  nationality_herzegovinian?: Maybe<Scalars['Int']>;
  nationality_honduran?: Maybe<Scalars['Int']>;
  nationality_hungarian?: Maybe<Scalars['Int']>;
  nationality_i_kiribati?: Maybe<Scalars['Int']>;
  nationality_icelander?: Maybe<Scalars['Int']>;
  nationality_indian?: Maybe<Scalars['Int']>;
  nationality_indonesian?: Maybe<Scalars['Int']>;
  nationality_iranian?: Maybe<Scalars['Int']>;
  nationality_iraqi?: Maybe<Scalars['Int']>;
  nationality_irish?: Maybe<Scalars['Int']>;
  nationality_israeli?: Maybe<Scalars['Int']>;
  nationality_italian?: Maybe<Scalars['Int']>;
  nationality_ivorian?: Maybe<Scalars['Int']>;
  nationality_jamaican?: Maybe<Scalars['Int']>;
  nationality_japanese?: Maybe<Scalars['Int']>;
  nationality_jordanian?: Maybe<Scalars['Int']>;
  nationality_kazakhstani?: Maybe<Scalars['Int']>;
  nationality_kenyan?: Maybe<Scalars['Int']>;
  nationality_kittian_and_nevisian?: Maybe<Scalars['Int']>;
  nationality_korean?: Maybe<Scalars['Int']>;
  nationality_kuwaiti?: Maybe<Scalars['Int']>;
  nationality_kyrgyz?: Maybe<Scalars['Int']>;
  nationality_laotian?: Maybe<Scalars['Int']>;
  nationality_latvian?: Maybe<Scalars['Int']>;
  nationality_lebanese?: Maybe<Scalars['Int']>;
  nationality_liberian?: Maybe<Scalars['Int']>;
  nationality_libyan?: Maybe<Scalars['Int']>;
  nationality_liechtensteiner?: Maybe<Scalars['Int']>;
  nationality_lithuanian?: Maybe<Scalars['Int']>;
  nationality_luxembourger?: Maybe<Scalars['Int']>;
  nationality_macedonian?: Maybe<Scalars['Int']>;
  nationality_malagasy?: Maybe<Scalars['Int']>;
  nationality_malawian?: Maybe<Scalars['Int']>;
  nationality_malaysian?: Maybe<Scalars['Int']>;
  nationality_maldivan?: Maybe<Scalars['Int']>;
  nationality_malian?: Maybe<Scalars['Int']>;
  nationality_maltese?: Maybe<Scalars['Int']>;
  nationality_marshallese?: Maybe<Scalars['Int']>;
  nationality_mauritanian?: Maybe<Scalars['Int']>;
  nationality_mauritian?: Maybe<Scalars['Int']>;
  nationality_mexican?: Maybe<Scalars['Int']>;
  nationality_micronesian?: Maybe<Scalars['Int']>;
  nationality_moldovian?: Maybe<Scalars['Int']>;
  nationality_monacan?: Maybe<Scalars['Int']>;
  nationality_mongolian?: Maybe<Scalars['Int']>;
  nationality_montenegrian?: Maybe<Scalars['Int']>;
  nationality_moroccan?: Maybe<Scalars['Int']>;
  nationality_mosotho?: Maybe<Scalars['Int']>;
  nationality_motswana?: Maybe<Scalars['Int']>;
  nationality_mozambican?: Maybe<Scalars['Int']>;
  nationality_namibian?: Maybe<Scalars['Int']>;
  nationality_nauruan?: Maybe<Scalars['Int']>;
  nationality_nepalese?: Maybe<Scalars['Int']>;
  nationality_new_zealander?: Maybe<Scalars['Int']>;
  nationality_nicaraguan?: Maybe<Scalars['Int']>;
  nationality_nigerian?: Maybe<Scalars['Int']>;
  nationality_nigerien?: Maybe<Scalars['Int']>;
  nationality_north_korean?: Maybe<Scalars['Int']>;
  nationality_northern_irish?: Maybe<Scalars['Int']>;
  nationality_norwegian?: Maybe<Scalars['Int']>;
  nationality_omani?: Maybe<Scalars['Int']>;
  nationality_pakistani?: Maybe<Scalars['Int']>;
  nationality_palauan?: Maybe<Scalars['Int']>;
  nationality_panamanian?: Maybe<Scalars['Int']>;
  nationality_papua_new_guinean?: Maybe<Scalars['Int']>;
  nationality_paraguayan?: Maybe<Scalars['Int']>;
  nationality_peruvian?: Maybe<Scalars['Int']>;
  nationality_polish?: Maybe<Scalars['Int']>;
  nationality_portuguese?: Maybe<Scalars['Int']>;
  nationality_puerto_rican?: Maybe<Scalars['Int']>;
  nationality_qatari?: Maybe<Scalars['Int']>;
  nationality_romanian?: Maybe<Scalars['Int']>;
  nationality_russian?: Maybe<Scalars['Int']>;
  nationality_rwandan?: Maybe<Scalars['Int']>;
  nationality_saint_lucian?: Maybe<Scalars['Int']>;
  nationality_salvadoran?: Maybe<Scalars['Int']>;
  nationality_samoan?: Maybe<Scalars['Int']>;
  nationality_san_marinese?: Maybe<Scalars['Int']>;
  nationality_sao_tomean?: Maybe<Scalars['Int']>;
  nationality_saudi?: Maybe<Scalars['Int']>;
  nationality_scottish?: Maybe<Scalars['Int']>;
  nationality_senegalese?: Maybe<Scalars['Int']>;
  nationality_serbian?: Maybe<Scalars['Int']>;
  nationality_seychellois?: Maybe<Scalars['Int']>;
  nationality_sierra_leonean?: Maybe<Scalars['Int']>;
  nationality_singaporean?: Maybe<Scalars['Int']>;
  nationality_slovak?: Maybe<Scalars['Int']>;
  nationality_slovenian?: Maybe<Scalars['Int']>;
  nationality_solomon_islander?: Maybe<Scalars['Int']>;
  nationality_somali?: Maybe<Scalars['Int']>;
  nationality_south_african?: Maybe<Scalars['Int']>;
  nationality_south_korean?: Maybe<Scalars['Int']>;
  nationality_spanish?: Maybe<Scalars['Int']>;
  nationality_sri_lankan?: Maybe<Scalars['Int']>;
  nationality_sudanese?: Maybe<Scalars['Int']>;
  nationality_surinamer?: Maybe<Scalars['Int']>;
  nationality_swazi?: Maybe<Scalars['Int']>;
  nationality_swedish?: Maybe<Scalars['Int']>;
  nationality_swiss?: Maybe<Scalars['Int']>;
  nationality_syrian?: Maybe<Scalars['Int']>;
  nationality_taiwanese?: Maybe<Scalars['Int']>;
  nationality_tajik?: Maybe<Scalars['Int']>;
  nationality_tanzanian?: Maybe<Scalars['Int']>;
  nationality_thai?: Maybe<Scalars['Int']>;
  nationality_togolese?: Maybe<Scalars['Int']>;
  nationality_tongan?: Maybe<Scalars['Int']>;
  nationality_trinidadian_or_tobagonian?: Maybe<Scalars['Int']>;
  nationality_tunisian?: Maybe<Scalars['Int']>;
  nationality_turkish?: Maybe<Scalars['Int']>;
  nationality_tuvaluan?: Maybe<Scalars['Int']>;
  nationality_ugandan?: Maybe<Scalars['Int']>;
  nationality_ukrainian?: Maybe<Scalars['Int']>;
  nationality_uruguayan?: Maybe<Scalars['Int']>;
  nationality_uzbekistani?: Maybe<Scalars['Int']>;
  nationality_venezuelan?: Maybe<Scalars['Int']>;
  nationality_vietnamese?: Maybe<Scalars['Int']>;
  nationality_welsh?: Maybe<Scalars['Int']>;
  nationality_yemenite?: Maybe<Scalars['Int']>;
  nationality_zambian?: Maybe<Scalars['Int']>;
  nationality_zimbabwean?: Maybe<Scalars['Int']>;
  provided_service_sixty_nine_position?: Maybe<Scalars['Int']>;
  provided_service_anal_sex?: Maybe<Scalars['Int']>;
  provided_service_cum_in_mouth?: Maybe<Scalars['Int']>;
  provided_service_cum_on_face?: Maybe<Scalars['Int']>;
  provided_service_dildo_play_toys?: Maybe<Scalars['Int']>;
  provided_service_french_kissing?: Maybe<Scalars['Int']>;
  provided_service_girlfriend_experience_gfe?: Maybe<Scalars['Int']>;
  provided_service_kissing?: Maybe<Scalars['Int']>;
  provided_service_oral_with_condom?: Maybe<Scalars['Int']>;
  provided_service_oral_without_condom?: Maybe<Scalars['Int']>;
  provided_service_oral_without_condom_to_completion?: Maybe<Scalars['Int']>;
  provided_service_sex_in_different_positions?: Maybe<Scalars['Int']>;
  provided_service_cum_on_body?: Maybe<Scalars['Int']>;
  provided_service_erotic_massage?: Maybe<Scalars['Int']>;
  provided_service_intimate_massage?: Maybe<Scalars['Int']>;
  provided_service_kissing_good_chemistry?: Maybe<Scalars['Int']>;
  provided_service_handjob?: Maybe<Scalars['Int']>;
  provided_service_ball_licking_and_sucking?: Maybe<Scalars['Int']>;
  provided_service_deep_throat?: Maybe<Scalars['Int']>;
  provided_service_dirtytalk?: Maybe<Scalars['Int']>;
  provided_service_double_penetration?: Maybe<Scalars['Int']>;
  provided_service_extraball?: Maybe<Scalars['Int']>;
  provided_service_facesitting?: Maybe<Scalars['Int']>;
  provided_service_fisting?: Maybe<Scalars['Int']>;
  provided_service_full_body_sensual_massage?: Maybe<Scalars['Int']>;
  provided_service_gangbang?: Maybe<Scalars['Int']>;
  provided_service_kamasutra?: Maybe<Scalars['Int']>;
  provided_service_lesbian_sex_games?: Maybe<Scalars['Int']>;
  provided_service_lingerie?: Maybe<Scalars['Int']>;
  provided_service_masturbate?: Maybe<Scalars['Int']>;
  provided_service_oral_without_condom_swallow?: Maybe<Scalars['Int']>;
  provided_service_outdoor_sex?: Maybe<Scalars['Int']>;
  provided_service_pornstar_experience_pse?: Maybe<Scalars['Int']>;
  provided_service_private_photos?: Maybe<Scalars['Int']>;
  provided_service_private_videos?: Maybe<Scalars['Int']>;
  provided_service_prostate_massage?: Maybe<Scalars['Int']>;
  provided_service_rimming_give?: Maybe<Scalars['Int']>;
  provided_service_rimming_recieve?: Maybe<Scalars['Int']>;
  provided_service_snowballing?: Maybe<Scalars['Int']>;
  provided_service_striptease_lapdance?: Maybe<Scalars['Int']>;
  provided_service_tantric?: Maybe<Scalars['Int']>;
  provided_service_foam_massage?: Maybe<Scalars['Int']>;
  provided_service_titjob?: Maybe<Scalars['Int']>;
  provided_service_anal_massage_give?: Maybe<Scalars['Int']>;
  provided_service_anal_massage_receive?: Maybe<Scalars['Int']>;
  provided_service_shower_service?: Maybe<Scalars['Int']>;
  provided_service_whirlpool?: Maybe<Scalars['Int']>;
  provided_service_intimate_shaving?: Maybe<Scalars['Int']>;
  provided_service_due?: Maybe<Scalars['Int']>;
  provided_service_bdsm?: Maybe<Scalars['Int']>;
  provided_service_bondage?: Maybe<Scalars['Int']>;
  provided_service_clinic_sex?: Maybe<Scalars['Int']>;
  provided_service_fetish?: Maybe<Scalars['Int']>;
  provided_service_foot_fetish?: Maybe<Scalars['Int']>;
  provided_service_golden_shower_give?: Maybe<Scalars['Int']>;
  provided_service_golden_shower_recieve?: Maybe<Scalars['Int']>;
  provided_service_lather_latex_pvc?: Maybe<Scalars['Int']>;
  provided_service_mistress_hard?: Maybe<Scalars['Int']>;
  provided_service_mistress_soft?: Maybe<Scalars['Int']>;
  provided_service_role_play_and_fantasy?: Maybe<Scalars['Int']>;
  provided_service_spanking_give?: Maybe<Scalars['Int']>;
  provided_service_spanking_recieve?: Maybe<Scalars['Int']>;
  provided_service_strap_on?: Maybe<Scalars['Int']>;
  provided_service_submissive_slave_hard?: Maybe<Scalars['Int']>;
  provided_service_submissive_slave_soft?: Maybe<Scalars['Int']>;
  provided_service_scat_give?: Maybe<Scalars['Int']>;
  provided_service_scat_receive?: Maybe<Scalars['Int']>;
  provided_service_squirting?: Maybe<Scalars['Int']>;
  provided_service_webcam?: Maybe<Scalars['Int']>;
  provided_service_video_chat?: Maybe<Scalars['Int']>;
  provided_service_phonesex?: Maybe<Scalars['Int']>;
  provided_service_video?: Maybe<Scalars['Int']>;
  provided_service_photos?: Maybe<Scalars['Int']>;
  provided_service_online_dildo_bj?: Maybe<Scalars['Int']>;
  provided_service_online_golden_shower_active?: Maybe<Scalars['Int']>;
  provided_service_online_anal_dildo_play?: Maybe<Scalars['Int']>;
  provided_service_online_masturbation?: Maybe<Scalars['Int']>;
  provided_service_online_role_play?: Maybe<Scalars['Int']>;
  provided_service_online_striptease?: Maybe<Scalars['Int']>;
  provided_service_online_caviar_active?: Maybe<Scalars['Int']>;
  provided_service_online_slave?: Maybe<Scalars['Int']>;
  provided_service_online_domina?: Maybe<Scalars['Int']>;
  provided_service_online_footfetish?: Maybe<Scalars['Int']>;
  provided_service_online_dirty_talk?: Maybe<Scalars['Int']>;
  provided_service_classical_massage?: Maybe<Scalars['Int']>;
  provided_service_thai_massage?: Maybe<Scalars['Int']>;
  provided_service_hot_stone_massage?: Maybe<Scalars['Int']>;
  provided_service_shiatsu_massage?: Maybe<Scalars['Int']>;
  provided_service_singing_bowl_massage?: Maybe<Scalars['Int']>;
  provided_service_foot_reflex_massage?: Maybe<Scalars['Int']>;
  provided_service_acupressure?: Maybe<Scalars['Int']>;
  provided_service_tuina_massage?: Maybe<Scalars['Int']>;
  provided_service_brush_massage?: Maybe<Scalars['Int']>;
  provided_service_wellness_visit_hotel?: Maybe<Scalars['Int']>;
  provided_service_sports_activities?: Maybe<Scalars['Int']>;
  provided_service_lunch_dinner?: Maybe<Scalars['Int']>;
  provided_service_meeting?: Maybe<Scalars['Int']>;
  hair_length_very_short?: Maybe<Scalars['Int']>;
  hair_length_short?: Maybe<Scalars['Int']>;
  hair_length_medium?: Maybe<Scalars['Int']>;
  hair_length_long?: Maybe<Scalars['Int']>;
  hair_length_very_long?: Maybe<Scalars['Int']>;
  spoken_language_english?: Maybe<Scalars['Int']>;
  spoken_language_french?: Maybe<Scalars['Int']>;
  spoken_language_german?: Maybe<Scalars['Int']>;
  spoken_language_italian?: Maybe<Scalars['Int']>;
  spoken_language_portuguese?: Maybe<Scalars['Int']>;
  spoken_language_russian?: Maybe<Scalars['Int']>;
  spoken_language_spanish?: Maybe<Scalars['Int']>;
  spoken_language_arabic?: Maybe<Scalars['Int']>;
  spoken_language_bulgarian?: Maybe<Scalars['Int']>;
  spoken_language_chinese?: Maybe<Scalars['Int']>;
  spoken_language_czech?: Maybe<Scalars['Int']>;
  spoken_language_croatian?: Maybe<Scalars['Int']>;
  spoken_language_dutch?: Maybe<Scalars['Int']>;
  spoken_language_finnisch?: Maybe<Scalars['Int']>;
  spoken_language_greek?: Maybe<Scalars['Int']>;
  spoken_language_hungarian?: Maybe<Scalars['Int']>;
  spoken_language_indian?: Maybe<Scalars['Int']>;
  spoken_language_japanese?: Maybe<Scalars['Int']>;
  spoken_language_latvinian?: Maybe<Scalars['Int']>;
  spoken_language_polish?: Maybe<Scalars['Int']>;
  spoken_language_romanian?: Maybe<Scalars['Int']>;
  spoken_language_turkish?: Maybe<Scalars['Int']>;
  spoken_language_slovak?: Maybe<Scalars['Int']>;
  spoken_language_slovenian?: Maybe<Scalars['Int']>;
  spoken_language_danish?: Maybe<Scalars['Int']>;
  spoken_language_norwegian?: Maybe<Scalars['Int']>;
  spoken_language_swedish?: Maybe<Scalars['Int']>;
  spoken_language_albanian?: Maybe<Scalars['Int']>;
  ethnicity_asian?: Maybe<Scalars['Int']>;
  ethnicity_black?: Maybe<Scalars['Int']>;
  ethnicity_white?: Maybe<Scalars['Int']>;
  ethnicity_latin?: Maybe<Scalars['Int']>;
  ethnicity_mixed?: Maybe<Scalars['Int']>;
  ethnicity_indian?: Maybe<Scalars['Int']>;
  ethnicity_arabian?: Maybe<Scalars['Int']>;
  ethnicity_caucasian?: Maybe<Scalars['Int']>;
  height_135_159?: Maybe<Scalars['Int']>;
  height_160_165?: Maybe<Scalars['Int']>;
  height_166_170?: Maybe<Scalars['Int']>;
  height_171_175?: Maybe<Scalars['Int']>;
  height_176_180?: Maybe<Scalars['Int']>;
  height_181_185?: Maybe<Scalars['Int']>;
  height_186_190?: Maybe<Scalars['Int']>;
  height_191_195?: Maybe<Scalars['Int']>;
  weight_40_45?: Maybe<Scalars['Int']>;
  weight_46_50?: Maybe<Scalars['Int']>;
  weight_51_55?: Maybe<Scalars['Int']>;
  weight_56_60?: Maybe<Scalars['Int']>;
  weight_61_65?: Maybe<Scalars['Int']>;
  weight_66_70?: Maybe<Scalars['Int']>;
  weight_71_75?: Maybe<Scalars['Int']>;
  weight_76_200?: Maybe<Scalars['Int']>;
  eye_color_black?: Maybe<Scalars['Int']>;
  eye_color_brown?: Maybe<Scalars['Int']>;
  eye_color_green?: Maybe<Scalars['Int']>;
  eye_color_blue?: Maybe<Scalars['Int']>;
  eye_color_gray?: Maybe<Scalars['Int']>;
  smoking_yes?: Maybe<Scalars['Int']>;
  smoking_no?: Maybe<Scalars['Int']>;
  smoking_occasionally?: Maybe<Scalars['Int']>;
  dress_size_xs?: Maybe<Scalars['Int']>;
  dress_size_s?: Maybe<Scalars['Int']>;
  dress_size_m?: Maybe<Scalars['Int']>;
  dress_size_l?: Maybe<Scalars['Int']>;
  dress_size_xl?: Maybe<Scalars['Int']>;
  dress_size_xxl?: Maybe<Scalars['Int']>;
  shoe_size_34?: Maybe<Scalars['Int']>;
  shoe_size_35?: Maybe<Scalars['Int']>;
  shoe_size_36?: Maybe<Scalars['Int']>;
  shoe_size_37?: Maybe<Scalars['Int']>;
  shoe_size_38?: Maybe<Scalars['Int']>;
  shoe_size_39?: Maybe<Scalars['Int']>;
  shoe_size_40?: Maybe<Scalars['Int']>;
  shoe_size_41?: Maybe<Scalars['Int']>;
  shoe_size_42?: Maybe<Scalars['Int']>;
  shoe_size_43?: Maybe<Scalars['Int']>;
  shoe_size_44?: Maybe<Scalars['Int']>;
  shoe_size_45?: Maybe<Scalars['Int']>;
  shoe_size_46?: Maybe<Scalars['Int']>;
  pubic_hair_shaved_completely?: Maybe<Scalars['Int']>;
  pubic_hair_shaved_mostly?: Maybe<Scalars['Int']>;
  pubic_hair_trimmed?: Maybe<Scalars['Int']>;
  pubic_hair_all_natural?: Maybe<Scalars['Int']>;
  drinking_yes?: Maybe<Scalars['Int']>;
  drinking_no?: Maybe<Scalars['Int']>;
  drinking_occasionally?: Maybe<Scalars['Int']>;
  breast_type_natural?: Maybe<Scalars['Int']>;
  breast_type_silicone?: Maybe<Scalars['Int']>;
}

export interface AggregatorCategoriesInput {
  main?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<Scalars['Boolean']>;
  new?: Maybe<Scalars['Boolean']>;
  independent?: Maybe<Scalars['Boolean']>;
  trans?: Maybe<Scalars['Boolean']>;
  male?: Maybe<Scalars['Boolean']>;
  city_tour?: Maybe<Scalars['Boolean']>;
  upcoming_city_tour?: Maybe<Scalars['Boolean']>;
  video?: Maybe<Scalars['Boolean']>;
  chat_online?: Maybe<Scalars['Boolean']>;
  happy_hours?: Maybe<Scalars['Boolean']>;
}

export interface AggregatorCompanyCategoriesInput {
  regular?: Maybe<Scalars['Boolean']>;
  new?: Maybe<Scalars['Boolean']>;
  independent?: Maybe<Scalars['Boolean']>;
  trans?: Maybe<Scalars['Boolean']>;
  male?: Maybe<Scalars['Boolean']>;
  city_tour?: Maybe<Scalars['Boolean']>;
  upcoming_city_tour?: Maybe<Scalars['Boolean']>;
  video?: Maybe<Scalars['Boolean']>;
}

export interface AggregatorCompanyGeoInput {
  region_id?: Maybe<Scalars['Int']>;
  region_name?: Maybe<Scalars['String']>;
  area_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['Int']>;
  city_name?: Maybe<Scalars['String']>;
  cityzone_id?: Maybe<Scalars['Int']>;
  cityzone_name?: Maybe<Scalars['String']>;
  cityzone_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  cityzone_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  visitor_country_iso?: Maybe<Scalars['String']>;
  type?: Maybe<AggregatorGeoNodeType>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
}

export interface AggregatorCompanyGeoNode {
  type: AggregatorGeoNodeType;
  region_id?: Maybe<Scalars['Int']>;
  region_name?: Maybe<Scalars['String']>;
  area_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['Int']>;
  city_name?: Maybe<Scalars['String']>;
  cityzone_id?: Maybe<Scalars['Int']>;
  cityzone_name?: Maybe<Scalars['String']>;
  company_profile_count?: Maybe<Scalars['Int']>;
}

export interface AggregatorCompanyProfile {
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
  showname?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  type?: Maybe<AggregatorCompanyProfileType>;
  about_me_de?: Maybe<Scalars['String']>;
  about_me_en?: Maybe<Scalars['String']>;
  about_me_es?: Maybe<Scalars['String']>;
  about_me_fr?: Maybe<Scalars['String']>;
  about_me_ro?: Maybe<Scalars['String']>;
  about_me_it?: Maybe<Scalars['String']>;
  about_me_hu?: Maybe<Scalars['String']>;
  is_new?: Maybe<Scalars['Boolean']>;
  is_club?: Maybe<Scalars['Boolean']>;
  is_premium?: Maybe<Scalars['Boolean']>;
  online_profiles_count?: Maybe<Scalars['Int']>;
  are_comments_disabled?: Maybe<Scalars['Boolean']>;
  are_reviews_disabled?: Maybe<Scalars['Boolean']>;
  photos?: Maybe<Array<Maybe<AggregatorCompanyProfilePhoto>>>;
  photo_hash?: Maybe<Scalars['String']>;
  photo_ext?: Maybe<Scalars['String']>;
  photo_path?: Maybe<Scalars['String']>;
  photo_v?: Maybe<Scalars['Int']>;
  website?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  is_contact_info_hidden?: Maybe<Scalars['Boolean']>;
  entrance_fee?: Maybe<AggregatorCompanyProfileEntranceFee>;
  wellness?: Maybe<AggregatorCompanyProfileWellness>;
  food_and_drinks?: Maybe<AggregatorCompanyProfileFoodAndDrinks>;
  outdoor_area?: Maybe<AggregatorCompanyProfileOutdoorArea>;
  area_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  date_modified?: Maybe<Scalars['Date']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
}

export interface AggregatorCompanyProfileCountResult {
  count?: Maybe<Scalars['Int']>;
}

export enum AggregatorCompanyProfileEntranceFee {
  Na = 'NA',
  Free = 'FREE',
  WithCost = 'WITH_COST'
}

export interface AggregatorCompanyProfileFiltersInput {
  is_premium?: Maybe<Scalars['Boolean']>;
  type?: Maybe<AggregatorCompanyProfileType>;
  ignore_geo_node?: Maybe<Scalars['Boolean']>;
}

export enum AggregatorCompanyProfileFoodAndDrinks {
  Na = 'NA',
  Yes = 'YES',
  No = 'NO',
  Free = 'FREE',
  WithCost = 'WITH_COST'
}

export enum AggregatorCompanyProfileOrdering {
  Default = 'DEFAULT',
  Alphabetical = 'ALPHABETICAL',
  NewestFirst = 'NEWEST_FIRST',
  TopListing = 'TOP_LISTING',
  Random = 'RANDOM'
}

export enum AggregatorCompanyProfileOutdoorArea {
  Na = 'NA',
  Yes = 'YES',
  No = 'NO'
}

export interface AggregatorCompanyProfilePagingResult {
  previous_id?: Maybe<Scalars['Int']>;
  previous_showname?: Maybe<Scalars['String']>;
  next_id?: Maybe<Scalars['Int']>;
  next_showname?: Maybe<Scalars['String']>;
}

export interface AggregatorCompanyProfilePhoto {
  v?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
}

export interface AggregatorCompanyProfileResult {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<AggregatorCompanyProfile>>>;
}

export interface AggregatorCompanyProfileSearchInput {
  language?: Maybe<GlobalEnabledLanguage>;
  text?: Maybe<Scalars['String']>;
}

export enum AggregatorCompanyProfileType {
  ClubStudio = 'CLUB_STUDIO',
  Kontaktbar = 'KONTAKTBAR',
  Laufhaus = 'LAUFHAUS',
  Nightclub = 'NIGHTCLUB'
}

export enum AggregatorCompanyProfileWellness {
  Na = 'NA',
  Yes = 'YES',
  No = 'NO',
  Free = 'FREE',
  WithCost = 'WITH_COST'
}

export interface AggregatorCompanyProfilesResult {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<AggregatorProfile>>>;
}

export interface AggregatorConfigDefaultCategory {
  field: Scalars['String'];
  value: Scalars['Boolean'];
}

export interface AggregatorConfigDefaultFilter {
  field: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
}

export interface AggregatorConfigFilterAgeInterval {
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
}

export interface AggregatorConfigFilterHeightInterval {
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
}

export interface AggregatorConfigFilterWeightInterval {
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
}

export interface AggregatorConfigFullTextSearch {
  length?: Maybe<AggregatorConfigFullTextSearchLength>;
}

export interface AggregatorConfigFullTextSearchLength {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface AggregatorConfigLastCommentPagination {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface AggregatorConfigReviewDateType {
  value?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
}

export interface AggregatorConfigReviewDefaultFilter {
  field: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
}

export interface AggregatorConfigReviewLookRatingInterval {
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
}

export interface AggregatorConfigReviewRateInterval {
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
}

export interface AggregatorConfigReviewServiceRatingInterval {
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
}

export interface AggregatorConfigReviewsPagination {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface AggregatorConfigs {
  default_categories?: Maybe<Array<Maybe<AggregatorConfigDefaultCategory>>>;
  default_filters?: Maybe<Array<Maybe<AggregatorConfigDefaultFilter>>>;
  review_default_filters?: Maybe<Array<Maybe<AggregatorConfigReviewDefaultFilter>>>;
  profile_review_date_type?: Maybe<Array<Maybe<AggregatorConfigReviewDateType>>>;
  profile_review_service_rating_intervals?: Maybe<Array<Maybe<AggregatorConfigReviewServiceRatingInterval>>>;
  profile_review_look_rating_intervals?: Maybe<Array<Maybe<AggregatorConfigReviewLookRatingInterval>>>;
  profile_review_rate_intervals?: Maybe<Array<Maybe<AggregatorConfigReviewRateInterval>>>;
  profile_filter_age_intervals?: Maybe<Array<Maybe<AggregatorConfigFilterAgeInterval>>>;
  profile_filter_height_intervals?: Maybe<Array<Maybe<AggregatorConfigFilterHeightInterval>>>;
  profile_filter_weight_intervals?: Maybe<Array<Maybe<AggregatorConfigFilterWeightInterval>>>;
  geo_node_types?: Maybe<Array<Maybe<AggregatorGeoNodeType>>>;
  latest_comments_pagination?: Maybe<AggregatorConfigLastCommentPagination>;
  reviews_pagination?: Maybe<AggregatorConfigReviewsPagination>;
  filter_max_size?: Maybe<Scalars['Int']>;
  filter_price?: Maybe<AggregatorFilterPrice>;
  full_text_search?: Maybe<AggregatorConfigFullTextSearch>;
}

export interface AggregatorFilterPrice {
  min: Scalars['Int'];
  max: Scalars['Int'];
  step: Scalars['Int'];
}

export interface AggregatorGeoInput {
  region_id?: Maybe<Scalars['Int']>;
  region_name?: Maybe<Scalars['String']>;
  area_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['Int']>;
  city_name?: Maybe<Scalars['String']>;
  cityzone_id?: Maybe<Scalars['Int']>;
  cityzone_name?: Maybe<Scalars['String']>;
  cityzone_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  cityzone_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  visitor_country_iso?: Maybe<Scalars['String']>;
  type?: Maybe<AggregatorGeoNodeType>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
}

export interface AggregatorGeoNode {
  type: AggregatorGeoNodeType;
  region_id?: Maybe<Scalars['Int']>;
  region_name?: Maybe<Scalars['String']>;
  area_id?: Maybe<Scalars['Int']>;
  area_old_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['Int']>;
  city_name?: Maybe<Scalars['String']>;
  cityzone_id?: Maybe<Scalars['Int']>;
  cityzone_name?: Maybe<Scalars['String']>;
  profile_count: Scalars['Int'];
  distance_in_km?: Maybe<Scalars['Float']>;
}

export enum AggregatorGeoNodeType {
  Region = 'REGION',
  City = 'CITY',
  Cityzone = 'CITYZONE',
  Area = 'AREA'
}

export interface AggregatorGeoNodesByProfile {
  type?: Maybe<AggregatorGeoNodeType>;
  region_id?: Maybe<Scalars['Int']>;
  region_name?: Maybe<Scalars['String']>;
  area_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['Int']>;
  city_name?: Maybe<Scalars['String']>;
  cityzone_id?: Maybe<Scalars['Int']>;
  cityzone_name?: Maybe<Scalars['String']>;
  is_base_city?: Maybe<Scalars['Int']>;
  is_base_area?: Maybe<Scalars['Int']>;
  is_tour?: Maybe<Scalars['Int']>;
  date_bumped?: Maybe<Scalars['Date']>;
  is_gotd?: Maybe<Scalars['Int']>;
  is_premium?: Maybe<Scalars['Int']>;
}

export interface AggregatorGotdProfileResult {
  result?: Maybe<Array<Maybe<AggregatorProfile>>>;
}

export interface AggregatorHappyHourRates {
  type?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  base_price?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
}

export interface AggregatorLateNightGirl {
  id?: Maybe<Scalars['Int']>;
  showname?: Maybe<Scalars['String']>;
  photo_hash?: Maybe<Scalars['String']>;
  photo_ext?: Maybe<Scalars['String']>;
  photo_path?: Maybe<Scalars['String']>;
  photo_v?: Maybe<Scalars['String']>;
  night_escort?: Maybe<Scalars['Int']>;
  working_hour_from?: Maybe<Scalars['String']>;
  working_hour_to?: Maybe<Scalars['String']>;
  is_available_24_7?: Maybe<Scalars['Int']>;
  is_24_7_night_escort?: Maybe<Scalars['Int']>;
}

export interface AggregatorLateNightGirlsResult {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<AggregatorLateNightGirl>>>;
}

export interface AggregatorLatestCommentProfile {
  id?: Maybe<Scalars['Int']>;
  showname?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['Int']>;
  city_name?: Maybe<Scalars['String']>;
  area_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
  is_available_for_chat?: Maybe<Scalars['Boolean']>;
  last_comment_date?: Maybe<Scalars['Date']>;
  photo_hash?: Maybe<Scalars['String']>;
  photo_ext?: Maybe<Scalars['String']>;
  photo_path?: Maybe<Scalars['String']>;
  photo_v?: Maybe<Scalars['Int']>;
  latest_comments?: Maybe<Array<Maybe<AggregatorProfileLatestComment>>>;
}

export interface AggregatorLatestCommentProfileResult {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<AggregatorLatestCommentProfile>>>;
}

export interface AggregatorOnlineProfile {
  id: Scalars['Int'];
  profile_id: Scalars['String'];
  is_online?: Maybe<Scalars['Boolean']>;
}

export interface AggregatorProfile {
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
  showname?: Maybe<Scalars['String']>;
  about_me_de?: Maybe<Scalars['String']>;
  about_me_en?: Maybe<Scalars['String']>;
  about_me_es?: Maybe<Scalars['String']>;
  about_me_fr?: Maybe<Scalars['String']>;
  about_me_ro?: Maybe<Scalars['String']>;
  about_me_it?: Maybe<Scalars['String']>;
  about_me_hu?: Maybe<Scalars['String']>;
  short_status_message?: Maybe<Scalars['String']>;
  slogan?: Maybe<Scalars['String']>;
  is_new?: Maybe<Scalars['Boolean']>;
  is_under_investigation?: Maybe<Scalars['Boolean']>;
  are_photos_suspicious?: Maybe<Scalars['Boolean']>;
  are_comments_disabled?: Maybe<Scalars['Boolean']>;
  are_company_comments_disabled?: Maybe<Scalars['Boolean']>;
  are_reviews_disabled?: Maybe<Scalars['Boolean']>;
  are_company_reviews_disabled?: Maybe<Scalars['Boolean']>;
  tour_area_id?: Maybe<Scalars['Int']>;
  tour_area_name?: Maybe<Scalars['String']>;
  tour_date_from?: Maybe<Scalars['Date']>;
  tour_date_to?: Maybe<Scalars['Date']>;
  upcoming_tour_date_from?: Maybe<Scalars['Date']>;
  upcoming_tour_date_to?: Maybe<Scalars['Date']>;
  photos?: Maybe<Array<Maybe<AggregatorProfilePhoto>>>;
  photo_hash?: Maybe<Scalars['String']>;
  photo_ext?: Maybe<Scalars['String']>;
  photo_path?: Maybe<Scalars['String']>;
  photo_v?: Maybe<Scalars['Int']>;
  video_cover_number?: Maybe<Scalars['Int']>;
  video_custom_cover_image?: Maybe<AggregatorVideoCustomCoverImage>;
  video_thumbs?: Maybe<Array<Maybe<VideoThumb>>>;
  base_city_id?: Maybe<Scalars['Int']>;
  base_city_name?: Maybe<Scalars['String']>;
  company_base_city_id?: Maybe<Scalars['Int']>;
  company_base_city_name?: Maybe<Scalars['String']>;
  area_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
  is_gotd?: Maybe<Scalars['Boolean']>;
  is_votd?: Maybe<Scalars['Boolean']>;
  has_video?: Maybe<Scalars['Boolean']>;
  is_verified?: Maybe<Scalars['Boolean']>;
  is_available_for_chat?: Maybe<Scalars['Boolean']>;
  date_profile_created?: Maybe<Scalars['Date']>;
  date_service_activated?: Maybe<Scalars['Date']>;
  age?: Maybe<Scalars['Int']>;
  comments_count?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Date']>;
  date_modified?: Maybe<Scalars['Date']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  phone_instruction?: Maybe<Scalars['String']>;
  show_phone_number?: Maybe<Scalars['Boolean']>;
  view_count?: Maybe<Scalars['Int']>;
  is_available_24_7?: Maybe<Scalars['Boolean']>;
  is_24_7_night_escort?: Maybe<Scalars['Boolean']>;
  night_escort?: Maybe<Scalars['Boolean']>;
  working_hour_from?: Maybe<Scalars['String']>;
  working_hour_to?: Maybe<Scalars['String']>;
  distance_in_km?: Maybe<Scalars['Float']>;
  happy_hour_date_activated?: Maybe<Scalars['Date']>;
  happy_hour_date_expiration?: Maybe<Scalars['Date']>;
  happy_hour_rates_incall?: Maybe<Array<Maybe<AggregatorHappyHourRates>>>;
  happy_hour_rates_outcall?: Maybe<Array<Maybe<AggregatorHappyHourRates>>>;
}

export enum AggregatorProfileAvailableFor {
  IncallPrivateApartment = 'INCALL_PRIVATE_APARTMENT',
  IncallHotelRoom = 'INCALL_HOTEL_ROOM',
  IncallClubStudio = 'INCALL_CLUB_STUDIO',
  IncallOther = 'INCALL_OTHER',
  OutcallHotelVisitsOnly = 'OUTCALL_HOTEL_VISITS_ONLY',
  OutcallHomeVisitsOnly = 'OUTCALL_HOME_VISITS_ONLY',
  OutcallHotelAndHomeVisits = 'OUTCALL_HOTEL_AND_HOME_VISITS',
  OutcallOther = 'OUTCALL_OTHER'
}

export interface AggregatorProfileCountResult {
  count?: Maybe<Scalars['Int']>;
}

export interface AggregatorProfileFiltersInput {
  publish_date?: Maybe<AggregatorProfilePublishDateValues>;
  now_open?: Maybe<Scalars['Boolean']>;
  has_video?: Maybe<Scalars['Boolean']>;
  has_story?: Maybe<Scalars['Boolean']>;
  has_review?: Maybe<Scalars['Boolean']>;
  has_comment?: Maybe<Scalars['Boolean']>;
  has_natural_photo?: Maybe<Scalars['Boolean']>;
  is_verified?: Maybe<Scalars['Boolean']>;
  is_vip?: Maybe<Scalars['Boolean']>;
  is_available_for_chat?: Maybe<Scalars['Boolean']>;
  is_instant_book_available?: Maybe<Scalars['Boolean']>;
  has_virtual_service?: Maybe<Scalars['Boolean']>;
  available_for?: Maybe<Array<Maybe<AggregatorProfileAvailableFor>>>;
  nationality?: Maybe<Array<Maybe<ProfileNationality>>>;
  services_for?: Maybe<Array<Maybe<ProfileServiceFor>>>;
  age?: Maybe<Array<Maybe<Scalars['String']>>>;
  hair_color?: Maybe<Array<Maybe<ProfileHairColor>>>;
  cup_size?: Maybe<Array<Maybe<ProfileCupSize>>>;
  provided_service?: Maybe<Array<Maybe<ProfileProvidedServiceValue>>>;
  hair_length?: Maybe<Array<Maybe<ProfileHairLength>>>;
  spoken_language?: Maybe<Array<Maybe<ProfileSpokenLanguage>>>;
  ethnicity?: Maybe<Array<Maybe<ProfileEthnicity>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  weight?: Maybe<Array<Maybe<Scalars['String']>>>;
  eye_color?: Maybe<Array<Maybe<ProfileEyeColor>>>;
  smoking?: Maybe<Array<Maybe<ProfileSmoking>>>;
  price_min?: Maybe<Scalars['Int']>;
  price_max?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  dress_size?: Maybe<Array<Maybe<ProfileDressSize>>>;
  shoe_size?: Maybe<Array<Maybe<Scalars['Int']>>>;
  pubic_hair?: Maybe<Array<Maybe<ProfilePubicHair>>>;
  breast_type?: Maybe<Array<Maybe<ProfileBreastType>>>;
  drinking?: Maybe<Array<Maybe<ProfileDrinking>>>;
}

export interface AggregatorProfileLatestComment {
  profile_id?: Maybe<Scalars['Int']>;
  body?: Maybe<Scalars['String']>;
  showname?: Maybe<Scalars['String']>;
  member_profile_id?: Maybe<Scalars['Int']>;
  member_showname?: Maybe<Scalars['String']>;
  rating?: Maybe<ProfileMemberRating>;
  date_created?: Maybe<Scalars['Date']>;
}

export enum AggregatorProfileOrdering {
  Default = 'DEFAULT',
  Alphabetical = 'ALPHABETICAL',
  MostViewed = 'MOST_VIEWED',
  NewestFirst = 'NEWEST_FIRST',
  LastModified = 'LAST_MODIFIED',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  CityName = 'CITY_NAME'
}

export interface AggregatorProfilePagingResult {
  previous_id?: Maybe<Scalars['Int']>;
  previous_showname?: Maybe<Scalars['String']>;
  next_id?: Maybe<Scalars['Int']>;
  next_showname?: Maybe<Scalars['String']>;
}

export interface AggregatorProfilePhoto {
  v?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  gotd?: Maybe<Scalars['Boolean']>;
  votd?: Maybe<Scalars['Boolean']>;
}

export enum AggregatorProfilePublishDateValues {
  One = 'ONE',
  Two = 'TWO',
  Three = 'THREE',
  Seven = 'SEVEN',
  Thirty = 'THIRTY'
}

export interface AggregatorProfileResult {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<AggregatorProfile>>>;
}

export interface AggregatorProfileSearchInput {
  language?: Maybe<GlobalEnabledLanguage>;
  text?: Maybe<Scalars['String']>;
  profile_showname?: Maybe<Scalars['String']>;
  company_showname?: Maybe<Scalars['String']>;
}

export interface AggregatorProfileVideoFeedResult {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<AggregatorProfile>>>;
}

export interface AggregatorStatusMessage {
  id?: Maybe<Scalars['Int']>;
  showname?: Maybe<Scalars['String']>;
  photo_ext?: Maybe<Scalars['String']>;
  photo_hash?: Maybe<Scalars['String']>;
  photo_path?: Maybe<Scalars['String']>;
  photo_v?: Maybe<Scalars['String']>;
  long_status_message?: Maybe<Scalars['String']>;
  status_message_modified_date?: Maybe<Scalars['Date']>;
  area_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
}

export interface AggregatorStatusMessagesResult {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<AggregatorStatusMessage>>>;
}

export interface AggregatorStoriesItem {
  id?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  mediaHash?: Maybe<Scalars['String']>;
  mediaPath?: Maybe<Scalars['String']>;
  mediaExt?: Maybe<Scalars['String']>;
  ordering?: Maybe<Scalars['Int']>;
  linkText?: Maybe<Scalars['String']>;
  isMuted?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  dateApproved?: Maybe<Scalars['String']>;
  liked?: Maybe<Scalars['Boolean']>;
  seen?: Maybe<Scalars['Boolean']>;
}

export interface AggregatorStoriesResult {
  profileId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  seen?: Maybe<Scalars['Boolean']>;
  lastUpdated?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  photoExt?: Maybe<Scalars['String']>;
  photoPath?: Maybe<Scalars['String']>;
  photoV?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneNumberInternational?: Maybe<Scalars['String']>;
  phoneAppWhatsapp?: Maybe<Scalars['Boolean']>;
  phoneAppTelegram?: Maybe<Scalars['Boolean']>;
  phoneAppViber?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<AggregatorStoriesItem>>>;
}

export interface AggregatorTop10Profile {
  profile_id: Scalars['Int'];
  showname?: Maybe<Scalars['String']>;
  city_name?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['Int']>;
  top_10_rating?: Maybe<Scalars['Int']>;
  photo_hash?: Maybe<Scalars['String']>;
  photo_ext?: Maybe<Scalars['String']>;
  photo_path?: Maybe<Scalars['String']>;
  photo_v?: Maybe<Scalars['Int']>;
  top1?: Maybe<Scalars['Int']>;
  top2?: Maybe<Scalars['Int']>;
  top3?: Maybe<Scalars['Int']>;
  top10?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  gender_female?: Maybe<Scalars['Boolean']>;
  gender_male?: Maybe<Scalars['Boolean']>;
  gender_trans?: Maybe<Scalars['Boolean']>;
  is_online?: Maybe<Scalars['Boolean']>;
}

export interface AggregatorTop10ProfileResult {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<AggregatorTop10Profile>>>;
}

export enum AggregatorTop10ProfileShow {
  Top1Only = 'TOP1_ONLY',
  Top3Only = 'TOP3_ONLY',
  MostGirls = 'MOST_GIRLS',
  Gainers = 'GAINERS',
  Loosers = 'LOOSERS'
}

export enum AggregatorTop10ProfileSortBy {
  DateAdded = 'DATE_ADDED',
  Showname = 'SHOWNAME',
  Rating = 'RATING',
  Random = 'RANDOM'
}

export interface AggregatorVideo {
  ext?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface AggregatorVideoCustomCoverImage {
  v?: Maybe<Scalars['Int']>;
  ext?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['Int']>;
  purpose?: Maybe<Scalars['String']>;
}

export interface AggregatorVotdProfileResult {
  result?: Maybe<Array<Maybe<AggregatorProfile>>>;
}

export interface AggregatorWorkerProfileOfflineEvent {
  id: Scalars['String'];
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  profile_type: ProfileMetaType;
}

export interface AggregatorWorkerProfileOnlineEvent {
  id: Scalars['String'];
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  profile_type: ProfileMetaType;
  is_new: Scalars['Boolean'];
}

export interface AiClassifiedAdModerations {
  _id?: Maybe<Scalars['ID']>;
  classified_ad_id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['ID']>;
  classified_ad_reference_id?: Maybe<Scalars['Int']>;
  profile?: Maybe<Profile>;
  company_profile?: Maybe<CompanyProfile>;
  moderation_type?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  title_clean?: Maybe<Scalars['String']>;
  classified_ad?: Maybe<ClassifiedAd>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  image_id?: Maybe<Scalars['ID']>;
  image?: Maybe<Image>;
  ai_text_moderation_response?: Maybe<ClassifiedAdAiTextFieldsModeration>;
  ai_media_moderation_response?: Maybe<AiProfileMediaModerationSummaryResponse>;
}

export interface AiClassifiedAdResultsResponse {
  result?: Maybe<Array<Maybe<AiClassifiedAdModerations>>>;
  count?: Maybe<Scalars['Int']>;
}

export interface AiCompanyModerations {
  _id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['ID']>;
  company_profile?: Maybe<CompanyProfile>;
  moderation_type?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  image_id?: Maybe<Scalars['ID']>;
  image?: Maybe<Image>;
  ai_text_moderation_response?: Maybe<CompanyProfileTextFieldsModerationsResponses>;
  ai_media_moderation_response?: Maybe<AiProfileMediaModerationSummaryResponse>;
}

export interface AiCompanyResultsResponse {
  result?: Maybe<Array<Maybe<AiCompanyModerations>>>;
  count?: Maybe<Scalars['Int']>;
}

export interface AiLink {
  link?: Maybe<AiTextFieldMatches>;
  blacklist?: Maybe<AiTextFieldMatches>;
}

export interface AiMediaModeration {
  _id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  classified_ad_id?: Maybe<Scalars['ID']>;
  image_id?: Maybe<Scalars['ID']>;
  image_path?: Maybe<Scalars['String']>;
  moderation_type?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  ai_moderation_response?: Maybe<AiMediaModerationSummaryResponse>;
}

export interface AiMediaModerationSummary {
  action?: Maybe<Scalars['String']>;
  reject_prob?: Maybe<Scalars['Float']>;
  reject_reason?: Maybe<Array<Maybe<AiRejectReasons>>>;
}

export interface AiMediaModerationSummaryResponse {
  summary?: Maybe<AiMediaModerationSummary>;
}

export interface AiModerationFiltersInput {
  account_id?: Maybe<Scalars['Int']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['ID']>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  moderation_type?: Maybe<AiModerationTypesEnum>;
}

export enum AiModerationTypesEnum {
  TextField = 'TEXT_FIELD',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export interface AiProfileLinkBlacklist {
  link?: Maybe<AiProfileTextFieldMatches>;
  blacklist?: Maybe<AiProfileTextFieldMatches>;
}

export interface AiProfileMediaModerationSummary {
  action?: Maybe<Scalars['String']>;
  reject_prob?: Maybe<Scalars['Float']>;
  reject_reason?: Maybe<Array<Maybe<AiProfileRejectReasons>>>;
}

export interface AiProfileMediaModerationSummaryResponse {
  summary?: Maybe<AiProfileMediaModerationSummary>;
}

export interface AiProfileModerations {
  _id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['ID']>;
  profile?: Maybe<Profile>;
  image_id?: Maybe<Scalars['ID']>;
  image?: Maybe<Image>;
  media_video_id?: Maybe<Scalars['ID']>;
  video?: Maybe<Video>;
  image_path?: Maybe<Scalars['String']>;
  moderation_type?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  ai_text_moderation_response?: Maybe<ProfileTextFieldsModerationsResponses>;
  ai_media_moderation_response?: Maybe<AiProfileMediaModerationSummaryResponse>;
}

export interface AiProfileRejectReasons {
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
}

export interface AiProfileResultsResponse {
  result?: Maybe<Array<Maybe<AiProfileModerations>>>;
  count?: Maybe<Scalars['Int']>;
}

export interface AiProfileTextFieldMatch {
  match?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
}

export interface AiProfileTextFieldMatches {
  matches?: Maybe<Array<Maybe<AiProfileTextFieldMatch>>>;
}

export interface AiRejectReasons {
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
}

export interface AiStoryMediaTypeImage extends AiStoryMediaTypeInterface {
  media_id?: Maybe<Scalars['ID']>;
  image?: Maybe<Image>;
}

export interface AiStoryMediaTypeInterface {
  media_id?: Maybe<Scalars['ID']>;
}

export interface AiStoryMediaTypeVideo extends AiStoryMediaTypeInterface {
  media_id?: Maybe<Scalars['ID']>;
  video?: Maybe<Video>;
}

export interface AiStoryModerations {
  _id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['ID']>;
  story_id?: Maybe<Scalars['ID']>;
  moderation_type?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  field_value?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
  image_id?: Maybe<Scalars['ID']>;
  media_video_id?: Maybe<Scalars['ID']>;
  media_Id?: Maybe<Scalars['ID']>;
  media?: Maybe<AiStoryMediaTypeInterface>;
  ai_text_moderation_response?: Maybe<AiProfileLinkBlacklist>;
  ai_media_moderation_response?: Maybe<AiProfileMediaModerationSummaryResponse>;
}

export interface AiStoryResultsResponse {
  result?: Maybe<Array<Maybe<AiStoryModerations>>>;
  count?: Maybe<Scalars['Int']>;
}

export interface AiTextFieldMatch {
  match?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
}

export interface AiTextFieldMatches {
  matches?: Maybe<Array<Maybe<AiTextFieldMatch>>>;
}

export interface AiTextFieldsModerationResponse {
  _id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  classified_ad_id?: Maybe<Scalars['ID']>;
  moderation_type?: Maybe<Scalars['String']>;
  field_value?: Maybe<Scalars['String']>;
  classified_ad_reference_id?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Date']>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  ai_moderation_response?: Maybe<AiLink>;
}

export interface AppliedDiscount {
  _id: Scalars['ID'];
  promo_code?: Maybe<Scalars['String']>;
}

export interface ApprovePhotosInput {
  id: Scalars['String'];
}

export interface ApproveStories {
  ids?: Maybe<Array<StoryId>>;
}

export interface AvailableForService {
  service_name: Scalars['String'];
}

export interface AvailableForServicesAndAmounts {
  service_name: Scalars['String'];
  amount: Scalars['Float'];
}

export interface Balance {
  amount: Scalars['Float'];
}

export interface BannedAccountsInput {
  id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<AccountRole>;
  username?: Maybe<Scalars['String']>;
}

export interface Banner {
  _id: Scalars['String'];
  reference_id: Scalars['Int'];
  account_id: Scalars['Int'];
  ops_account_id: Scalars['Int'];
  description: Scalars['String'];
  phone_number: BannerPhoneNumber;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  date_created: Scalars['Date'];
  date_modified?: Maybe<Scalars['Date']>;
  created_by: Scalars['Int'];
  modified_by?: Maybe<Scalars['Int']>;
  status: BannerStatuses;
  date_status_changed?: Maybe<Scalars['Date']>;
  status_changed_by?: Maybe<Scalars['Int']>;
  is_paid: Scalars['Boolean'];
  inventory_id?: Maybe<Scalars['Int']>;
  inventory_zone_id?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  media?: Maybe<Array<Maybe<BannerMedia>>>;
}

export interface BannerConfigResponse {
  media_limit?: Maybe<Scalars['Int']>;
  description?: Maybe<BannerDescriptionConfig>;
}

export interface BannerCountResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface BannerCreateResponse {
  id: Scalars['String'];
  reference_id: Scalars['Int'];
}

export interface BannerDescriptionConfig {
  allowed_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
}

export interface BannerFilterInput {
  reference_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  is_paid?: Maybe<Scalars['Boolean']>;
  status?: Maybe<BannerStatuses>;
  phone_number?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
}

export interface BannerFilterResponse {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Banner>>;
}

export interface BannerInput {
  description: Scalars['String'];
  phone_number: BannerPhoneNumberInput;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  media?: Maybe<Array<Scalars['String']>>;
}

export interface BannerMedia {
  media_id: Scalars['String'];
  document?: Maybe<Document>;
}

export interface BannerModifyInput {
  description: Scalars['String'];
  phone_number: BannerPhoneNumberInput;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  media?: Maybe<Array<Scalars['String']>>;
}

export interface BannerModifyResponse {
  result: Scalars['Boolean'];
}

export interface BannerPhoneNumber {
  number: Scalars['String'];
  country_code: Scalars['String'];
}

export interface BannerPhoneNumberInput {
  number: Scalars['String'];
  country_code: Scalars['String'];
}

export interface BannerProduct {
  id: Scalars['Int'];
  name: Scalars['String'];
  group: ProductGroup;
  inventory?: Maybe<Array<Maybe<Inventory>>>;
}

export enum BannerStatuses {
  Pending = 'PENDING',
  Done = 'DONE',
  Deleted = 'DELETED'
}

export interface BannerZoneConfig {
  zone_id: Scalars['Int'];
  name: Scalars['String'];
}

export interface BaseService {
  id: Scalars['Int'];
  name: Scalars['String'];
  ref_type?: Maybe<Scalars['String']>;
  ref_id?: Maybe<Scalars['Int']>;
  status: ServiceStatus;
  date_activation: Scalars['Date'];
  date_expiration: Scalars['Date'];
  account_id: Scalars['Int'];
  product_id: Scalars['Int'];
  additional_geo_nodes_count: Scalars['Int'];
  profile?: Maybe<Profile>;
  additional_geo_node_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  reassign_history?: Maybe<Array<Maybe<BaseServiceReassignHistory>>>;
  order_id: Scalars['Int'];
  order_product_id: Scalars['Int'];
  base_duration: Scalars['Int'];
  reason?: Maybe<Scalars['String']>;
  date_canceled?: Maybe<Scalars['Date']>;
  canceled_by?: Maybe<Scalars['Int']>;
}

export interface BaseServiceAdditionalGeoNode {
  geo_node_id: Scalars['Int'];
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
}

export interface BaseServiceProduct {
  id: Scalars['Int'];
  name: Scalars['String'];
  group: ProductGroup;
  inventory?: Maybe<Array<Maybe<Inventory>>>;
}

export interface BaseServiceReassignHistory {
  from_ref_id: Scalars['Int'];
  from_profile?: Maybe<Profile>;
  to_ref_id: Scalars['Int'];
  to_profile?: Maybe<Profile>;
  date_performed: Scalars['Date'];
  performed_by: Scalars['Int'];
}

export interface BaseServicesResponse {
  result: Array<Maybe<BaseService>>;
  count: Scalars['Int'];
}

export interface BillingInfo {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['Int']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
}

export interface BillingInfoInput {
  name: Scalars['String'];
  surname: Scalars['String'];
  zip_code: Scalars['String'];
  address: Scalars['String'];
}

export interface BlacklistedClient {
  _id: Scalars['String'];
  _meta: BlacklistedClientMeta;
  name?: Maybe<Scalars['String']>;
  geo_node_id?: Maybe<Scalars['Int']>;
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
  date: Scalars['Date'];
  phone_numbers?: Maybe<Array<Maybe<BlacklistedClientPhoneNumber>>>;
  emails?: Maybe<Array<Maybe<BlacklistedClientEmail>>>;
  image_ids?: Maybe<Array<Maybe<BlacklistedClientImage>>>;
  comment?: Maybe<Scalars['String']>;
}

export interface BlacklistedClientApproveResponse {
  result: Scalars['Boolean'];
}

export interface BlacklistedClientCommentConfig {
  min: Scalars['Int'];
  max: Scalars['Int'];
  allowed_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface BlacklistedClientConfig {
  name: BlacklistedClientNameConfig;
  comment: BlacklistedClientCommentConfig;
  blacklisted_client: BlacklistedClientMainConfig;
  images_limit?: Maybe<Scalars['Int']>;
}

export interface BlacklistedClientDeclineResponse {
  result: Scalars['Boolean'];
}

export interface BlacklistedClientDeleteResponse {
  result: Scalars['Boolean'];
}

export interface BlacklistedClientEmail {
  value?: Maybe<Scalars['String']>;
}

export interface BlacklistedClientEmailInput {
  value?: Maybe<Scalars['String']>;
}

export interface BlacklistedClientImage {
  id: Scalars['String'];
  image?: Maybe<Image>;
}

export interface BlacklistedClientImageInput {
  id: Scalars['String'];
}

export interface BlacklistedClientInput {
  account_id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  geo_node_id?: Maybe<Scalars['Int']>;
  phone_numbers?: Maybe<Array<Maybe<BlacklistedClientPhoneNumberInput>>>;
  emails?: Maybe<Array<Maybe<BlacklistedClientEmailInput>>>;
  date: Scalars['Date'];
  image_ids?: Maybe<Array<Maybe<BlacklistedClientImageInput>>>;
}

export interface BlacklistedClientList {
  result?: Maybe<Array<Maybe<BlacklistedClient>>>;
  total_count: Scalars['Int'];
}

export interface BlacklistedClientListConfig {
  query: BlacklistedClientListQueryConfig;
}

export interface BlacklistedClientListQueryConfig {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface BlacklistedClientMainConfig {
  list: BlacklistedClientListConfig;
}

export interface BlacklistedClientMeta {
  account_id: Scalars['Int'];
  ops_account_id?: Maybe<Scalars['Int']>;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  status: BlacklistedClientStatuses;
  is_deleted: Scalars['Boolean'];
}

export interface BlacklistedClientNameConfig {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface BlacklistedClientPhoneNumber {
  code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  international_number?: Maybe<Scalars['String']>;
  masked_international_number?: Maybe<Scalars['String']>;
}

export interface BlacklistedClientPhoneNumberInput {
  code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
}

export interface BlacklistedClientResponse {
  id: Scalars['String'];
}

export enum BlacklistedClientStatuses {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export interface BlacklistedClientUpdateResponse {
  result: Scalars['Boolean'];
}

export interface BlacklistedClientsFilterInput {
  statuses: Array<Maybe<BlacklistedClientStatuses>>;
  text?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}

export interface BlacklistedClientsFilterResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<BlacklistedClient>>>;
}

export interface BlacklistedEntries {
  _id: Scalars['String'];
  comment: Scalars['String'];
  image_ids?: Maybe<Array<Maybe<BlacklistedClientImage>>>;
  created_by?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Date']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
}

export interface BlacklistedEntryCreateResponse {
  id?: Maybe<Scalars['String']>;
}

export interface BlacklistedEntryDisableResponse {
  disabled?: Maybe<Scalars['Boolean']>;
}

export interface BlacklistedEntryEnableResponse {
  enabled?: Maybe<Scalars['Boolean']>;
}

export interface BlacklistedEntryUpdateInput {
  comment: Scalars['String'];
  image_ids?: Maybe<Array<Maybe<BlacklistedClientImageInput>>>;
}

export interface BlacklistedEntryUpdateResponse {
  modified?: Maybe<Scalars['Boolean']>;
}

export interface BlacklistedItem {
  _id: Scalars['String'];
  type?: Maybe<BlacklistedTypes>;
  value?: Maybe<Scalars['String']>;
  created_by: Scalars['Int'];
  date_created: Scalars['Date'];
  modified_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
  word_types?: Maybe<Array<Maybe<BlacklistedWordTypes>>>;
  match_type?: Maybe<BlacklistedWordMatchTypes>;
}

export interface BlacklistedItemResponseBoolean {
  result: Scalars['Boolean'];
}

export enum BlacklistedTypes {
  Word = 'WORD',
  Email = 'EMAIL',
  Phone = 'PHONE',
  Domain = 'DOMAIN',
  EmailDomain = 'EMAIL_DOMAIN'
}

export enum BlacklistedWordMatchTypes {
  Contain = 'CONTAIN',
  Exact = 'EXACT'
}

export enum BlacklistedWordTypes {
  Slogan = 'SLOGAN',
  Feedback = 'FEEDBACK',
  AboutMe = 'ABOUT_ME',
  StatusMessage = 'STATUS_MESSAGE',
  ClassifiedsAd = 'CLASSIFIEDS_AD',
  Message = 'MESSAGE',
  SpecialCharacteristic = 'SPECIAL_CHARACTERISTIC',
  PhoneInstruction = 'PHONE_INSTRUCTION',
  AdditionalServices = 'ADDITIONAL_SERVICES',
  Showname = 'SHOWNAME'
}

export interface BlockMessengerThreadResponse {
  result: Scalars['Boolean'];
}

export interface BumpBundleInventory {
  id: Scalars['Int'];
  price: Scalars['Int'];
  count: Scalars['Int'];
}

export interface BumpBundleProduct {
  id: Scalars['Int'];
  inventory: Array<Maybe<BumpBundleInventory>>;
}

export interface BumpCreditsHistoryInput {
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface BumpCreditsHistoryRecord {
  id: Scalars['Int'];
  account_id: Scalars['Int'];
  order_product_id?: Maybe<Scalars['Int']>;
  credits: Scalars['Int'];
  reference_id?: Maybe<Scalars['Int']>;
  profile?: Maybe<Profile>;
  order_id?: Maybe<Scalars['Int']>;
  credits_before: Scalars['Int'];
  credits_after: Scalars['Int'];
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
}

export interface BumpCreditsHistoryResponse {
  result: Array<BumpCreditsHistoryRecord>;
  count: Scalars['Int'];
}

export interface BumpCreditsResponse {
  credits: Scalars['Int'];
}

export enum BumpDateByFilters {
  DateBumped = 'DATE_BUMPED',
  DateBumpExpired = 'DATE_BUMP_EXPIRED'
}

export enum BumpHistoryStatuses {
  Active = 'ACTIVE',
  Expired = 'EXPIRED'
}

export enum BumpHistoryTypes {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING',
  Instant = 'INSTANT'
}

export interface BumpScheduleCreateResponse {
  id: Scalars['String'];
}

export interface BumpScheduleDeleteResponse {
  result: Scalars['Boolean'];
}

export enum BumpScheduleType {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING'
}

export interface BumpScheduleUpdateResponse {
  result: Scalars['Boolean'];
}

export interface BumpSchedulesResponse {
  one_time: Array<Maybe<OneTimeBumpSchedule>>;
  recurring: Array<Maybe<RecurringBumpSchedule>>;
}

export interface BumpsHistoryInput {
  date_by?: Maybe<BumpDateByFilters>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  type?: Maybe<BumpHistoryTypes>;
  status?: Maybe<BumpHistoryStatuses>;
  city_id?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface BumpsHistoryRecord {
  reference_id?: Maybe<Scalars['Int']>;
  city_id?: Maybe<Scalars['Int']>;
  geo_node?: Maybe<GeoNode>;
  date_bumped?: Maybe<Scalars['Date']>;
  status?: Maybe<BumpHistoryStatuses>;
  type?: Maybe<BumpHistoryTypes>;
  date_bump_expired?: Maybe<Scalars['Date']>;
  bump_count?: Maybe<Scalars['Int']>;
}

export interface BumpsHistoryResponse {
  result: Array<BumpsHistoryRecord>;
  count: Scalars['Int'];
}

export interface CartUpdatedForWsResponse {
  order_id: Scalars['Int'];
  account_id: Scalars['Int'];
}

export interface CellarAggregatorAccountSearchItem extends CellarAggregatorSearchItem {
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<AccountRole>;
  type?: Maybe<CellarAggregatorSearchItemType>;
  account_status?: Maybe<AccountStatus>;
  highlight?: Maybe<CellarAggregatorSearchItemHighlight>;
}

export enum CellarAggregatorActiveAds {
  WithActiveAds = 'WITH_ACTIVE_ADS',
  WithNoActiveAds = 'WITH_NO_ACTIVE_ADS'
}

export interface CellarAggregatorBanner {
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  price_currency?: Maybe<Scalars['String']>;
  final_price_currency?: Maybe<Scalars['String']>;
  discount_percentage?: Maybe<Scalars['Int']>;
  discount_fixed_amount?: Maybe<Scalars['Int']>;
}

export interface CellarAggregatorBannerFilterInput {
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
}

export interface CellarAggregatorBannerResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<CellarAggregatorBanner>>>;
}

export interface CellarAggregatorBaseService {
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  real_profile_type?: Maybe<ProfileMetaType>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  status?: Maybe<ServiceStatus>;
  base_duration?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  date_activation?: Maybe<Scalars['Date']>;
  date_expiration?: Maybe<Scalars['Date']>;
  price?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  price_currency?: Maybe<Scalars['String']>;
  final_price_currency?: Maybe<Scalars['String']>;
  discount_percentage?: Maybe<Scalars['Int']>;
  discount_fixed_amount?: Maybe<Scalars['Int']>;
  showname?: Maybe<Scalars['String']>;
  company_showname?: Maybe<Scalars['String']>;
}

export enum CellarAggregatorBaseServiceDateFilter {
  DateActivation = 'DATE_ACTIVATION',
  DateExpiration = 'DATE_EXPIRATION'
}

export interface CellarAggregatorBaseServiceFilterInput {
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<ProfileMetaType>;
  status?: Maybe<ServiceStatus>;
  product_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  company_reference_id?: Maybe<Scalars['Int']>;
  showname?: Maybe<Scalars['String']>;
  date_filter_by?: Maybe<CellarAggregatorBaseServiceDateFilter>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export interface CellarAggregatorBaseServiceResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<CellarAggregatorBaseService>>>;
}

export interface CellarAggregatorBumpBundle {
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  price_currency?: Maybe<Scalars['String']>;
  final_price_currency?: Maybe<Scalars['String']>;
  discount_percentage?: Maybe<Scalars['Int']>;
  discount_fixed_amount?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Date']>;
}

export enum CellarAggregatorBumpBundleDateFilter {
  DateCreated = 'DATE_CREATED'
}

export interface CellarAggregatorBumpBundleFilterInput {
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
  date_filter_by?: Maybe<CellarAggregatorBumpBundleDateFilter>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export interface CellarAggregatorBumpBundleResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<CellarAggregatorBumpBundle>>>;
}

export interface CellarAggregatorChatMessage {
  id?: Maybe<Scalars['Int']>;
  message_id?: Maybe<Scalars['String']>;
  thread_id?: Maybe<Scalars['String']>;
  from_profile_type?: Maybe<ProfileMetaType>;
  from_profile_reference_id?: Maybe<Scalars['Int']>;
  from_showname?: Maybe<Scalars['String']>;
  from_account_id?: Maybe<Scalars['Int']>;
  from_account_username?: Maybe<Scalars['String']>;
  to_profile_type?: Maybe<ProfileMetaType>;
  to_profile_reference_id?: Maybe<Scalars['Int']>;
  to_showname?: Maybe<Scalars['String']>;
  to_account_id?: Maybe<Scalars['Int']>;
  to_account_username?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  is_pm?: Maybe<Scalars['Boolean']>;
}

export interface CellarAggregatorChatMessageFilterInput {
  from_type?: Maybe<CellarAggregatorChatMessageFromToType>;
  from_value?: Maybe<Scalars['String']>;
  to_type?: Maybe<CellarAggregatorChatMessageFromToType>;
  to_value?: Maybe<Scalars['String']>;
  vice_versa?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  date_created_from?: Maybe<Scalars['Date']>;
  date_created_to?: Maybe<Scalars['Date']>;
}

export enum CellarAggregatorChatMessageFromToType {
  Showname = 'SHOWNAME',
  AccountUsername = 'ACCOUNT_USERNAME',
  AccountId = 'ACCOUNT_ID',
  ProfileReferenceId = 'PROFILE_REFERENCE_ID'
}

export interface CellarAggregatorChatMessagesResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<CellarAggregatorChatMessage>>>;
}

export interface CellarAggregatorClassifiedAdService {
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  status?: Maybe<ServiceStatus>;
  date_activation?: Maybe<Scalars['Date']>;
  date_expiration?: Maybe<Scalars['Date']>;
  price?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  price_currency?: Maybe<Scalars['String']>;
  final_price_currency?: Maybe<Scalars['String']>;
  discount_percentage?: Maybe<Scalars['Int']>;
  discount_fixed_amount?: Maybe<Scalars['Int']>;
}

export enum CellarAggregatorClassifiedAdServiceDateFilter {
  DateActivation = 'DATE_ACTIVATION',
  DateExpiration = 'DATE_EXPIRATION'
}

export interface CellarAggregatorClassifiedAdServiceFilterInput {
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  status?: Maybe<CellarAggregatorClassifiedAdServiceStatus>;
  order_id?: Maybe<Scalars['String']>;
  date_filter_by?: Maybe<CellarAggregatorClassifiedAdServiceDateFilter>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export interface CellarAggregatorClassifiedAdServiceResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<CellarAggregatorClassifiedAdService>>>;
}

export enum CellarAggregatorClassifiedAdServiceStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Canceled = 'CANCELED'
}

export interface CellarAggregatorCompanyProfile {
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  account_status?: Maybe<AccountStatus>;
  email?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  showname?: Maybe<Scalars['String']>;
  is_incomplete?: Maybe<Scalars['Boolean']>;
  is_incomplete_pending?: Maybe<Scalars['Boolean']>;
  is_initial_create_done?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_admin_disabled?: Maybe<Scalars['Boolean']>;
  is_phone_number_verified?: Maybe<Scalars['Int']>;
  can_be_online?: Maybe<Scalars['Boolean']>;
  not_enough_approved_photos?: Maybe<Scalars['Boolean']>;
  are_comments_disabled?: Maybe<Scalars['Boolean']>;
  are_reviews_disabled?: Maybe<Scalars['Boolean']>;
  profiles_count?: Maybe<Scalars['Int']>;
  website_status?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  photo_hash?: Maybe<Scalars['String']>;
  photo_ext?: Maybe<Scalars['String']>;
  photo_path?: Maybe<Scalars['String']>;
  photo_v?: Maybe<Scalars['Int']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  phone_number_international?: Maybe<Scalars['String']>;
  phone_instruction?: Maybe<GlobalPhoneInstruction>;
  is_club?: Maybe<Scalars['Boolean']>;
  show_in_club_directory?: Maybe<Scalars['Boolean']>;
  is_premium?: Maybe<Scalars['Boolean']>;
  zip_code?: Maybe<Scalars['String']>;
  type?: Maybe<ProfileCompanyType>;
  date_created?: Maybe<Scalars['Date']>;
  date_registration?: Maybe<Scalars['Date']>;
  date_modified?: Maybe<Scalars['Date']>;
  last_login_date?: Maybe<Scalars['Date']>;
  deleted_by?: Maybe<Scalars['Int']>;
  alert_note_message?: Maybe<Scalars['String']>;
  alert_note_date_created?: Maybe<Scalars['Date']>;
  alert_note_created_by?: Maybe<Scalars['Int']>;
}

export enum CellarAggregatorCompanyProfileDateFilter {
  DateRegistration = 'DATE_REGISTRATION',
  LastLoginDate = 'LAST_LOGIN_DATE',
  DateModified = 'DATE_MODIFIED'
}

export interface CellarAggregatorCompanyProfileFilterInput {
  company_reference_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  account_status?: Maybe<AccountStatus>;
  has_escorts?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  showname?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  is_club?: Maybe<Scalars['Boolean']>;
  show_in_club_directory?: Maybe<Scalars['Boolean']>;
  zip_code?: Maybe<Scalars['String']>;
  is_premium?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  has_admin_comment?: Maybe<Scalars['Boolean']>;
  alert_note_message?: Maybe<Scalars['String']>;
  alert_note_created_by?: Maybe<Scalars['Int']>;
  type?: Maybe<ProfileCompanyType>;
  website_status?: Maybe<ProfileMetaWebsiteStatus>;
  not_verified_website?: Maybe<Scalars['Boolean']>;
  has_package?: Maybe<Scalars['Boolean']>;
  date_filter_by?: Maybe<CellarAggregatorCompanyProfileDateFilter>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export interface CellarAggregatorCompanyProfilesResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<CellarAggregatorCompanyProfile>>>;
}

export interface CellarAggregatorGotdService {
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  status?: Maybe<ServiceStatus>;
  area_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
  date_activation?: Maybe<Scalars['Date']>;
  date_expiration?: Maybe<Scalars['Date']>;
  price?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  price_currency?: Maybe<Scalars['String']>;
  final_price_currency?: Maybe<Scalars['String']>;
  discount_percentage?: Maybe<Scalars['Int']>;
  discount_fixed_amount?: Maybe<Scalars['Int']>;
  showname?: Maybe<Scalars['String']>;
  company_showname?: Maybe<Scalars['String']>;
  company_reference_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<ProfileMetaType>;
}

export enum CellarAggregatorGotdServiceDateFilter {
  DateActivation = 'DATE_ACTIVATION',
  DateExpiration = 'DATE_EXPIRATION'
}

export interface CellarAggregatorGotdServiceFilterInput {
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  status?: Maybe<ServiceStatus>;
  area_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  company_reference_id?: Maybe<Scalars['Int']>;
  showname?: Maybe<Scalars['String']>;
  date_filter_by?: Maybe<CellarAggregatorGotdServiceDateFilter>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export interface CellarAggregatorGotdServiceResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<CellarAggregatorGotdService>>>;
}

export interface CellarAggregatorMemberProfile {
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  account_status?: Maybe<AccountStatus>;
  email?: Maybe<Scalars['String']>;
  showname?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_admin_disabled?: Maybe<Scalars['Boolean']>;
  is_trusted?: Maybe<Scalars['Boolean']>;
  is_suspicious?: Maybe<Scalars['Boolean']>;
  rating?: Maybe<ProfileMemberRating>;
  rating_changed_from_cellar?: Maybe<Scalars['Boolean']>;
  country_id?: Maybe<Scalars['Int']>;
  country_name?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['Int']>;
  city_name?: Maybe<Scalars['String']>;
  area_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  date_registration?: Maybe<Scalars['Date']>;
  date_modified?: Maybe<Scalars['Date']>;
  last_login_date?: Maybe<Scalars['Date']>;
  last_login_ip?: Maybe<Scalars['String']>;
  last_login_ip_geo?: Maybe<Scalars['String']>;
  deleted_by?: Maybe<Scalars['Int']>;
  reviews_count?: Maybe<Scalars['Int']>;
  comments_count?: Maybe<Scalars['Int']>;
  photo_hash?: Maybe<Scalars['String']>;
  photo_ext?: Maybe<Scalars['String']>;
  photo_path?: Maybe<Scalars['String']>;
  photo_v?: Maybe<Scalars['Int']>;
  photo_status?: Maybe<Scalars['String']>;
  alert_note_message?: Maybe<Scalars['String']>;
  alert_note_date_created?: Maybe<Scalars['Date']>;
  alert_note_created_by?: Maybe<Scalars['Int']>;
}

export enum CellarAggregatorMemberProfileDateFilter {
  DateRegistration = 'DATE_REGISTRATION',
  LastLoginDate = 'LAST_LOGIN_DATE',
  DateModified = 'DATE_MODIFIED'
}

export interface CellarAggregatorMemberProfileFilterInput {
  id?: Maybe<Scalars['Int']>;
  account_status?: Maybe<AccountStatus>;
  has_comment?: Maybe<Scalars['Boolean']>;
  has_review?: Maybe<Scalars['Boolean']>;
  is_suspicious?: Maybe<Scalars['Boolean']>;
  is_trusted?: Maybe<Scalars['Boolean']>;
  ip?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rating?: Maybe<CellarAggregatorMemberProfileRatingFilter>;
  username?: Maybe<Scalars['String']>;
  showname?: Maybe<Scalars['String']>;
  has_admin_comment?: Maybe<Scalars['Boolean']>;
  date_filter_by?: Maybe<CellarAggregatorMemberProfileDateFilter>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export enum CellarAggregatorMemberProfileRatingFilter {
  Trusted = 'TRUSTED',
  Junior = 'JUNIOR',
  Senior = 'SENIOR',
  Diamond = 'DIAMOND',
  Vip = 'VIP',
  VipPlus = 'VIP_PLUS'
}

export interface CellarAggregatorMemberProfilesResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<CellarAggregatorMemberProfile>>>;
}

export interface CellarAggregatorOrder {
  id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  account_role?: Maybe<AccountRole>;
  payment_id?: Maybe<Scalars['String']>;
  is_paid_from_balance?: Maybe<Scalars['Boolean']>;
  is_discounted?: Maybe<Scalars['Boolean']>;
  products_count?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  price?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  price_currency?: Maybe<Scalars['String']>;
  final_price_currency?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  date_paid?: Maybe<Scalars['Date']>;
  payment_type?: Maybe<PaymentType>;
  details_first_name?: Maybe<Scalars['String']>;
  details_last_name?: Maybe<Scalars['String']>;
  details_credit_card_holder?: Maybe<Scalars['String']>;
  additional_data?: Maybe<Array<Maybe<CellarAggregatorOrderAdditionalData>>>;
  transfer_vendor_account_id?: Maybe<Scalars['Int']>;
  is_moved?: Maybe<Scalars['Boolean']>;
}

export interface CellarAggregatorOrderAdditionalData {
  showname?: Maybe<Scalars['String']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
  areas?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum CellarAggregatorOrderDateFilter {
  DateCreated = 'DATE_CREATED',
  DatePaid = 'DATE_PAID'
}

export interface CellarAggregatorOrderFilterInput {
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  account_role?: Maybe<AccountRole>;
  status?: Maybe<OrderStatus>;
  transaction_id?: Maybe<Scalars['String']>;
  is_paid_from_balance?: Maybe<Scalars['Boolean']>;
  is_discounted?: Maybe<Scalars['Boolean']>;
  hash?: Maybe<Scalars['String']>;
  date_filter_by?: Maybe<CellarAggregatorOrderDateFilter>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  moved_orders?: Maybe<Scalars['Boolean']>;
}

export interface CellarAggregatorOrderResponse {
  count: Scalars['Int'];
  total_price_sum?: Maybe<Scalars['Float']>;
  total_final_price_sum?: Maybe<Scalars['Float']>;
  total_difference?: Maybe<Scalars['Float']>;
  result?: Maybe<Array<Maybe<CellarAggregatorOrder>>>;
}

export interface CellarAggregatorPayment {
  id?: Maybe<Scalars['Int']>;
  payment_id?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  account_role?: Maybe<AccountRole>;
  gateway?: Maybe<Scalars['String']>;
  responses?: Maybe<Scalars['String']>;
  checkout_type?: Maybe<PaymentCheckoutType>;
  type?: Maybe<PaymentType>;
  status?: Maybe<CellarAggregatorPaymentFilterStatus>;
  products_canceled?: Maybe<Scalars['Boolean']>;
  details_first_name?: Maybe<Scalars['String']>;
  details_last_name?: Maybe<Scalars['String']>;
  details_country?: Maybe<Scalars['String']>;
  details_city?: Maybe<Scalars['String']>;
  details_var_symbol?: Maybe<Scalars['String']>;
  details_pull_person?: Maybe<Scalars['String']>;
  details_mtcn?: Maybe<Scalars['String']>;
  details_transaction_id?: Maybe<Scalars['String']>;
  details_webmoney_account?: Maybe<Scalars['String']>;
  details_website?: Maybe<Scalars['String']>;
  details_gateway_ip?: Maybe<Scalars['String']>;
  details_credit_card_holder?: Maybe<Scalars['String']>;
  details_credit_card_provider?: Maybe<Scalars['String']>;
  details_credit_card_number?: Maybe<Scalars['String']>;
  details_credit_card_address?: Maybe<Scalars['String']>;
  details_credit_card_expiration_date?: Maybe<Scalars['String']>;
  details_credit_card_email?: Maybe<Scalars['String']>;
  requested_amount?: Maybe<Scalars['Float']>;
  requested_currency?: Maybe<Scalars['String']>;
  actual_amount?: Maybe<Scalars['Float']>;
  actual_currency?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['Int']>;
  order_hash?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  date_approved?: Maybe<Scalars['Date']>;
  date_paid?: Maybe<Scalars['Date']>;
  date_declined?: Maybe<Scalars['Date']>;
  date_charged_back?: Maybe<Scalars['Date']>;
  created_by?: Maybe<Scalars['Int']>;
  approved_by?: Maybe<Scalars['Int']>;
  declined_by?: Maybe<Scalars['Int']>;
  charged_back_by?: Maybe<Scalars['Int']>;
  approve_reason?: Maybe<Scalars['String']>;
  decline_reason?: Maybe<Scalars['String']>;
  charged_back_reason?: Maybe<Scalars['String']>;
  order_additional_data?: Maybe<Array<Maybe<CellarAggregatorOrderAdditionalData>>>;
}

export enum CellarAggregatorPaymentDateFilter {
  DateCreated = 'DATE_CREATED',
  DateApproved = 'DATE_APPROVED',
  DatePaid = 'DATE_PAID',
  DateDeclined = 'DATE_DECLINED',
  DateChargedBack = 'DATE_CHARGED_BACK'
}

export interface CellarAggregatorPaymentFilterInput {
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  status?: Maybe<CellarAggregatorPaymentFilterStatus>;
  account_role?: Maybe<AccountRole>;
  order_id?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentType>;
  checkout_type?: Maybe<PaymentCheckoutType>;
  gateway?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  var_symbol?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  mtcn?: Maybe<Scalars['String']>;
  pull_person?: Maybe<Scalars['String']>;
  date_filter_by?: Maybe<CellarAggregatorPaymentDateFilter>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export enum CellarAggregatorPaymentFilterStatus {
  Pending = 'PENDING',
  Paid = 'PAID',
  Approved = 'APPROVED',
  AutoApproved = 'AUTO_APPROVED',
  Declined = 'DECLINED',
  ChargedBack = 'CHARGED_BACK',
  Failed = 'FAILED',
  AnyApproved = 'ANY_APPROVED'
}

export interface CellarAggregatorPaymentResponse {
  count: Scalars['Int'];
  total_amount_sum?: Maybe<Scalars['Float']>;
  total_actual_amount_sum?: Maybe<Scalars['Float']>;
  total_difference?: Maybe<Scalars['Float']>;
  result?: Maybe<Array<Maybe<CellarAggregatorPayment>>>;
}

export interface CellarAggregatorProfile {
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
  company_profile_id?: Maybe<Scalars['String']>;
  company_reference_id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  gender?: Maybe<ProfileGender>;
  account_status?: Maybe<AccountStatus>;
  email?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  type?: Maybe<ProfileType>;
  profile_type?: Maybe<CellarAggregatorProfileMetaType>;
  showname?: Maybe<Scalars['String']>;
  company_showname?: Maybe<Scalars['String']>;
  about_me_de?: Maybe<Scalars['String']>;
  about_me_en?: Maybe<Scalars['String']>;
  about_me_es?: Maybe<Scalars['String']>;
  about_me_fr?: Maybe<Scalars['String']>;
  about_me_ro?: Maybe<Scalars['String']>;
  about_me_it?: Maybe<Scalars['String']>;
  about_me_hu?: Maybe<Scalars['String']>;
  age_verification_status?: Maybe<ProfileVerificationStatuses>;
  age_verification_doc_never_submitted?: Maybe<Scalars['Boolean']>;
  age_verification_documents_required?: Maybe<Scalars['Boolean']>;
  is_verified?: Maybe<Scalars['Boolean']>;
  is_incomplete?: Maybe<Scalars['Boolean']>;
  is_incomplete_pending?: Maybe<Scalars['Boolean']>;
  is_initial_create_done?: Maybe<Scalars['Boolean']>;
  have_pending_changes?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  deleted_by?: Maybe<Scalars['Int']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
  is_admin_disabled?: Maybe<Scalars['Boolean']>;
  is_online?: Maybe<Scalars['Boolean']>;
  is_under_investigation?: Maybe<Scalars['Boolean']>;
  are_photos_suspicious?: Maybe<Scalars['Boolean']>;
  can_be_online?: Maybe<Scalars['Boolean']>;
  not_enough_approved_photos?: Maybe<Scalars['Boolean']>;
  is_vip?: Maybe<Scalars['Boolean']>;
  is_instant_book_available?: Maybe<Scalars['Boolean']>;
  is_phone_number_verified?: Maybe<Scalars['Boolean']>;
  is_website_checked?: Maybe<Scalars['Boolean']>;
  is_map_activated?: Maybe<Scalars['Boolean']>;
  is_auto_approve?: Maybe<Scalars['Boolean']>;
  has_about_me?: Maybe<Scalars['Boolean']>;
  has_video?: Maybe<Scalars['Boolean']>;
  has_phone_number?: Maybe<Scalars['Boolean']>;
  has_email?: Maybe<Scalars['Boolean']>;
  has_slogan?: Maybe<Scalars['Boolean']>;
  has_status_message?: Maybe<Scalars['Boolean']>;
  no_withheld_numbers?: Maybe<Scalars['Boolean']>;
  zip_code?: Maybe<Scalars['String']>;
  home_city_id?: Maybe<Scalars['Int']>;
  has_comment?: Maybe<Scalars['Boolean']>;
  nationality?: Maybe<ProfileNationality>;
  has_website?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
  last_login_ip?: Maybe<Scalars['String']>;
  is_imported?: Maybe<Scalars['Boolean']>;
  tour_area_id?: Maybe<Scalars['Int']>;
  tour_area_name?: Maybe<Scalars['String']>;
  tour_date_from?: Maybe<Scalars['Date']>;
  tour_date_to?: Maybe<Scalars['Date']>;
  photos?: Maybe<Array<Maybe<CellarAggregatorProfilePhoto>>>;
  photo_hash?: Maybe<Scalars['String']>;
  photo_ext?: Maybe<Scalars['String']>;
  photo_path?: Maybe<Scalars['String']>;
  photo_v?: Maybe<Scalars['Int']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  phone_number_international?: Maybe<Scalars['String']>;
  phone_instruction?: Maybe<GlobalPhoneInstruction>;
  product_manager_id?: Maybe<Scalars['Int']>;
  service_name?: Maybe<Scalars['String']>;
  service_date_activation?: Maybe<Scalars['Date']>;
  service_date_expiration?: Maybe<Scalars['Date']>;
  base_duration?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Date']>;
  date_registration?: Maybe<Scalars['Date']>;
  date_modified?: Maybe<Scalars['Date']>;
  last_login_date?: Maybe<Scalars['Date']>;
  city_id?: Maybe<Scalars['Int']>;
  city_name?: Maybe<Scalars['String']>;
  area_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
  cityzone_id?: Maybe<Scalars['Int']>;
  cityzone_name?: Maybe<Scalars['String']>;
  is_base_city?: Maybe<Scalars['Boolean']>;
  is_base_area?: Maybe<Scalars['Boolean']>;
  is_zona_rossa?: Maybe<Scalars['Boolean']>;
  alert_note_message?: Maybe<Scalars['String']>;
  alert_note_date_created?: Maybe<Scalars['Date']>;
  alert_note_created_by?: Maybe<Scalars['Int']>;
  website_status?: Maybe<Scalars['String']>;
  date_website_status_changed?: Maybe<Scalars['Date']>;
  website_status_changed_by?: Maybe<Scalars['Int']>;
  country_id?: Maybe<Scalars['Int']>;
}

export enum CellarAggregatorProfileDateFilter {
  DateRegistration = 'DATE_REGISTRATION',
  LastLoginDate = 'LAST_LOGIN_DATE',
  DateModified = 'DATE_MODIFIED',
  ServiceDateExpiration = 'SERVICE_DATE_EXPIRATION'
}

export interface CellarAggregatorProfileFilterInput {
  ops_account_id?: Maybe<Scalars['Int']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  gender?: Maybe<ProfileGender>;
  profile_type?: Maybe<ProfileType>;
  profile_meta_type?: Maybe<CellarAggregatorProfileMetaType>;
  company_showname?: Maybe<Scalars['String']>;
  showname?: Maybe<Scalars['String']>;
  account_status?: Maybe<AccountStatus>;
  profile_status?: Maybe<CellarAggregatorProfileStatus>;
  age_verification_status?: Maybe<ProfileVerificationStatuses>;
  base_city_id?: Maybe<Scalars['Int']>;
  base_area_id?: Maybe<Scalars['Int']>;
  cityzone_id?: Maybe<Scalars['Int']>;
  active_ads?: Maybe<CellarAggregatorActiveAds>;
  website_status?: Maybe<CellarAggregatorWebsiteStatuses>;
  date_website_status_changed?: Maybe<Scalars['Date']>;
  website_status_changed_by?: Maybe<Scalars['Int']>;
  country_id?: Maybe<Scalars['Int']>;
  ever_login?: Maybe<Scalars['Boolean']>;
  can_be_online?: Maybe<Scalars['Boolean']>;
  is_website_checked?: Maybe<Scalars['Boolean']>;
  is_map_activated?: Maybe<Scalars['Boolean']>;
  is_zona_rossa?: Maybe<Scalars['Boolean']>;
  is_verified?: Maybe<Scalars['Boolean']>;
  is_tour?: Maybe<Scalars['Boolean']>;
  is_auto_approve?: Maybe<Scalars['Boolean']>;
  is_vip?: Maybe<Scalars['Boolean']>;
  is_instant_book_available?: Maybe<Scalars['Boolean']>;
  is_phone_number_verified?: Maybe<Scalars['Boolean']>;
  is_under_investigation?: Maybe<Scalars['Boolean']>;
  are_photos_suspicious?: Maybe<Scalars['Boolean']>;
  has_website?: Maybe<Scalars['Boolean']>;
  has_video?: Maybe<Scalars['Boolean']>;
  is_imported?: Maybe<Scalars['Boolean']>;
  last_login_ip?: Maybe<Scalars['String']>;
  additional_geo_node_id?: Maybe<Scalars['Int']>;
  has_about_me?: Maybe<Scalars['Boolean']>;
  has_phone_number?: Maybe<Scalars['Boolean']>;
  has_email?: Maybe<Scalars['Boolean']>;
  has_slogan?: Maybe<Scalars['Boolean']>;
  has_status_message?: Maybe<Scalars['Boolean']>;
  zip_code?: Maybe<Scalars['String']>;
  home_city_id?: Maybe<Scalars['Int']>;
  no_withheld_numbers?: Maybe<Scalars['Boolean']>;
  has_comment?: Maybe<Scalars['Boolean']>;
  nationality?: Maybe<ProfileNationality>;
  alert_note_message?: Maybe<Scalars['String']>;
  alert_note_created_by?: Maybe<Scalars['Int']>;
  phone_country_code?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  phone_instruction?: Maybe<GlobalPhoneInstruction>;
  website?: Maybe<Scalars['String']>;
  about_me_text?: Maybe<Scalars['String']>;
  about_me_language?: Maybe<GlobalEnabledLanguage>;
  base_service_name?: Maybe<Scalars['String']>;
  date_filter_by?: Maybe<CellarAggregatorProfileDateFilter>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  had_running_package?: Maybe<Scalars['Boolean']>;
  is_pseudo_escort?: Maybe<Scalars['Boolean']>;
}

export enum CellarAggregatorProfileMetaType {
  Ad = 'AD',
  CompanyAd = 'COMPANY_AD'
}

export interface CellarAggregatorProfilePhoto {
  v?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  status?: Maybe<ProfilePhotoStatus>;
}

export interface CellarAggregatorProfileSearchItem extends CellarAggregatorSearchItem {
  profile_id?: Maybe<Scalars['String']>;
  reference_id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  type?: Maybe<CellarAggregatorSearchItemType>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  profile_type?: Maybe<ProfileMetaType>;
  account_status?: Maybe<AccountStatus>;
  showname?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_numbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  highlight?: Maybe<CellarAggregatorSearchItemHighlight>;
}

export enum CellarAggregatorProfileStatus {
  IsIncompletePending = 'IS_INCOMPLETE_PENDING',
  IsDeleted = 'IS_DELETED',
  IsDisabled = 'IS_DISABLED',
  IsAdminDisabled = 'IS_ADMIN_DISABLED',
  IsOnline = 'IS_ONLINE',
  IsActive = 'IS_ACTIVE',
  NotEnoughApprovedPhotos = 'NOT_ENOUGH_APPROVED_PHOTOS',
  HavePendingChanges = 'HAVE_PENDING_CHANGES'
}

export interface CellarAggregatorProfilesResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<CellarAggregatorProfile>>>;
}

export interface CellarAggregatorSalesMonthlyReports {
  id: Scalars['Int'];
  ops_account_id: Scalars['Int'];
  filename: Scalars['String'];
  path: Scalars['String'];
  date_from: Scalars['Date'];
  date_to: Scalars['Date'];
  date_created: Scalars['Date'];
  token: Scalars['String'];
}

export interface CellarAggregatorSalesMonthlyReportsFilterInput {
  ops_account_id?: Maybe<Scalars['Int']>;
  year: Scalars['Int'];
  month?: Maybe<Scalars['Int']>;
}

export interface CellarAggregatorSearchItem {
  account_id?: Maybe<Scalars['Int']>;
  type?: Maybe<CellarAggregatorSearchItemType>;
  highlight?: Maybe<CellarAggregatorSearchItemHighlight>;
}

export interface CellarAggregatorSearchItemHighlight {
  reference_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  account_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  profile_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  username?: Maybe<Array<Maybe<Scalars['String']>>>;
  showname?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Array<Maybe<Scalars['String']>>>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_numbers?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum CellarAggregatorSearchItemType {
  Account = 'ACCOUNT',
  Profile = 'PROFILE'
}

export interface CellarAggregatorSearchResponse {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<CellarAggregatorSearchItem>>>;
}

export interface CellarAggregatorStatusMessage {
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  price_currency?: Maybe<Scalars['String']>;
  final_price_currency?: Maybe<Scalars['String']>;
  discount_percentage?: Maybe<Scalars['Int']>;
  discount_fixed_amount?: Maybe<Scalars['Int']>;
  showname?: Maybe<Scalars['String']>;
  company_showname?: Maybe<Scalars['String']>;
  company_reference_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<ProfileMetaType>;
}

export interface CellarAggregatorStatusMessageFilterInput {
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  company_reference_id?: Maybe<Scalars['Int']>;
  showname?: Maybe<Scalars['String']>;
}

export interface CellarAggregatorStatusMessageResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<CellarAggregatorStatusMessage>>>;
}

export interface CellarAggregatorVotdService {
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  status?: Maybe<ServiceStatus>;
  area_id?: Maybe<Scalars['Int']>;
  area_name?: Maybe<Scalars['String']>;
  date_activation?: Maybe<Scalars['Date']>;
  date_expiration?: Maybe<Scalars['Date']>;
  price?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  price_currency?: Maybe<Scalars['String']>;
  final_price_currency?: Maybe<Scalars['String']>;
  discount_percentage?: Maybe<Scalars['Int']>;
  discount_fixed_amount?: Maybe<Scalars['Int']>;
  showname?: Maybe<Scalars['String']>;
  company_showname?: Maybe<Scalars['String']>;
  company_reference_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<ProfileMetaType>;
}

export enum CellarAggregatorVotdServiceDateFilter {
  DateActivation = 'DATE_ACTIVATION',
  DateExpiration = 'DATE_EXPIRATION'
}

export interface CellarAggregatorVotdServiceFilterInput {
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  status?: Maybe<ServiceStatus>;
  area_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['String']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  company_reference_id?: Maybe<Scalars['Int']>;
  showname?: Maybe<Scalars['String']>;
  date_filter_by?: Maybe<CellarAggregatorVotdServiceDateFilter>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export interface CellarAggregatorVotdServiceResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<CellarAggregatorVotdService>>>;
}

export enum CellarAggregatorWebsiteStatuses {
  Checked = 'CHECKED',
  Blocked = 'BLOCKED',
  NotChecked = 'NOT_CHECKED'
}

export interface CellarSalesMonthlyReportsFilterResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<CellarAggregatorSalesMonthlyReports>>>;
}

export interface CheckEmailResponse {
  exists: Scalars['Boolean'];
}

export interface CheckEmailVerificationResponse {
  is_verified?: Maybe<Scalars['Boolean']>;
}

export interface CheckEmailsVerificationInput {
  email: Scalars['String'];
}

export interface CheckEmailsVerificationResponse {
  email: Scalars['String'];
  is_verified: Scalars['Boolean'];
}

export interface CheckForBlacklistInput {
  types?: Maybe<Array<Maybe<BlacklistedTypes>>>;
  key: Scalars['String'];
  value: Scalars['String'];
  word_type?: Maybe<BlacklistedWordTypes>;
}

export interface CheckForBlacklistResponse {
  value?: Maybe<Scalars['String']>;
  startIndex?: Maybe<Scalars['Int']>;
  endIndex?: Maybe<Scalars['Int']>;
  type?: Maybe<BlacklistedTypes>;
  key?: Maybe<Scalars['String']>;
}

export interface CheckOrderBeforePaymentResponse {
  result: Scalars['Int'];
  invalid_products?: Maybe<Array<Maybe<InvalidProduct>>>;
}

export interface CheckPhoneNumberVerificationResponse {
  is_verified?: Maybe<Scalars['Boolean']>;
}

export interface CheckPhoneNumbersVerificationInput {
  country_code: Scalars['String'];
  number: Scalars['String'];
}

export interface CheckPhoneNumbersVerificationResponse {
  country_code: Scalars['String'];
  number: Scalars['String'];
  is_verified: Scalars['Boolean'];
}

export interface CheckUsernameResponse {
  exists: Scalars['Boolean'];
}

export interface CityAlert {
  _id: Scalars['String'];
  geo_node_id: Scalars['Int'];
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
  events: MemberCityAlertEvents;
  date_created: Scalars['String'];
  created_by: Scalars['Int'];
}

export interface ClassifiedAd {
  _id: Scalars['String'];
  account_id?: Maybe<Scalars['Int']>;
  category: ClassifiedAdCategory;
  geo_node_ids: Array<ClassifiedAdGeoNode>;
  services?: Maybe<Array<Maybe<ClassifiedAdProvidedService>>>;
  contact_availability?: Maybe<Array<Maybe<ClassifiedAdContactAvailability>>>;
  duration: Scalars['Int'];
  description: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  ethnicity?: Maybe<ProfileEthnicity>;
  ip?: Maybe<Scalars['String']>;
  phone_number?: Maybe<ClassifiedAdPhoneNumber>;
  photos: Array<ClassifiedAdImage>;
  ai_text_fields_moderation?: Maybe<ClassifiedAdAiTextFieldsModeration>;
  status: ClassifiedAdStatus;
  title: Scalars['String'];
  title_clean?: Maybe<Scalars['String']>;
  date_created: Scalars['Date'];
  created_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
  date_approved?: Maybe<Scalars['Date']>;
  approved_by?: Maybe<Scalars['Int']>;
  date_declined?: Maybe<Scalars['Date']>;
  declined_by?: Maybe<Scalars['Int']>;
  date_deleted?: Maybe<Scalars['Date']>;
  deleted_by?: Maybe<Scalars['Int']>;
  is_deleted: Scalars['Boolean'];
  premium_activation_date?: Maybe<Scalars['Date']>;
  date_expired?: Maybe<Scalars['Date']>;
  reference_id: Scalars['Int'];
  is_premium?: Maybe<Scalars['Boolean']>;
  is_free?: Maybe<Scalars['Boolean']>;
  is_paid?: Maybe<Scalars['Boolean']>;
}

export interface ClassifiedAdAiTextFields {
  _id: Scalars['String'];
  text_fields_moderation?: Maybe<ClassifiedAdAiTextFieldsModeration>;
}

export interface ClassifiedAdAiTextFieldsModeration {
  email?: Maybe<AiTextFieldsModerationResponse>;
  website?: Maybe<AiTextFieldsModerationResponse>;
  title_clean?: Maybe<AiTextFieldsModerationResponse>;
  description_clean?: Maybe<AiTextFieldsModerationResponse>;
}

export enum ClassifiedAdCategory {
  SheSeeks = 'SHE_SEEKS',
  HeSeeks = 'HE_SEEKS',
  CoupleSeeks = 'COUPLE_SEEKS',
  TsTv = 'TS_TV',
  Jobs = 'JOBS',
  FlatsProperties = 'FLATS_PROPERTIES',
  Diverse = 'DIVERSE',
  Fetish = 'FETISH',
  Gay = 'GAY',
  Gangbang = 'GANGBANG'
}

export interface ClassifiedAdConfigs {
  categories: Array<ClassifiedAdCategory>;
  statuses: Array<ClassifiedAdStatus>;
  durations: Array<Scalars['Int']>;
  services: Array<ClassifiedAdProvidedService>;
  date_filter_types: Array<ClassifiedAdDateFilter>;
  title: ClassifiedAdMinMaxConfig;
  description: ClassifiedAdDescriptionConfig;
  max_geo_nodes: Scalars['Int'];
  max_photos: Scalars['Int'];
}

export enum ClassifiedAdContactAvailability {
  Sms = 'SMS',
  Whatsapp = 'WHATSAPP',
  Signal = 'SIGNAL',
  Telegram = 'TELEGRAM',
  Viber = 'VIBER'
}

export enum ClassifiedAdDateFilter {
  DateApproved = 'DATE_APPROVED',
  DateExpired = 'DATE_EXPIRED',
  PremiumActivationDate = 'PREMIUM_ACTIVATION_DATE',
  PremiumExpirationDate = 'PREMIUM_EXPIRATION_DATE'
}

export interface ClassifiedAdDescriptionConfig {
  min: Scalars['Int'];
  max: Scalars['Int'];
  allowed_tags: Array<Scalars['String']>;
}

export interface ClassifiedAdFeedbackInput {
  entity_id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  message: Scalars['String'];
  re_captcha_token: Scalars['String'];
}

export interface ClassifiedAdGeoNode {
  id: Scalars['Int'];
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
}

export interface ClassifiedAdImage {
  media_image_id: Scalars['String'];
  image?: Maybe<Image>;
  ai_media_moderation?: Maybe<AiMediaModeration>;
}

export interface ClassifiedAdInput {
  title: Scalars['String'];
  description: Scalars['String'];
  phone_number: ClassifiedAdPhoneNumberInput;
  contact_availability?: Maybe<Array<Maybe<ClassifiedAdContactAvailability>>>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  category: ClassifiedAdCategory;
  geo_node_ids: Array<Scalars['Int']>;
  ethnicity?: Maybe<ProfileEthnicity>;
  services?: Maybe<Array<Maybe<ClassifiedAdProvidedService>>>;
  photos?: Maybe<Array<Maybe<Scalars['String']>>>;
  duration: Scalars['Int'];
  account_id?: Maybe<Scalars['Int']>;
  is_free?: Maybe<Scalars['Boolean']>;
}

export interface ClassifiedAdMinMaxConfig {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ClassifiedAdPhoneNumber {
  country_code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
}

export interface ClassifiedAdPhoneNumberInput {
  country_code: Scalars['String'];
  number: Scalars['String'];
}

export interface ClassifiedAdProduct {
  id: Scalars['Int'];
  name: Scalars['String'];
  group: ProductGroup;
  inventory?: Maybe<Array<Maybe<Inventory>>>;
}

export enum ClassifiedAdProvidedService {
  SixtyNinePosition = 'SIXTY_NINE_POSITION',
  AnalSex = 'ANAL_SEX',
  DeepThroat = 'DEEP_THROAT',
  BallLickingAndSucking = 'BALL_LICKING_AND_SUCKING',
  EroticMassage = 'EROTIC_MASSAGE',
  Escort = 'ESCORT',
  Fetish = 'FETISH',
  Fisting = 'FISTING',
  BlowjobWithCondom = 'BLOWJOB_WITH_CONDOM',
  Gangbang = 'GANGBANG',
  GirlfriendSex = 'GIRLFRIEND_SEX',
  BigBoobs = 'BIG_BOOBS',
  OnlineCaviarActive = 'ONLINE_CAVIAR_ACTIVE',
  Masturbate = 'MASTURBATE',
  NsGamesActive = 'NS_GAMES_ACTIVE',
  OutdoorSex = 'OUTDOOR_SEX',
  Lingerie = 'LINGERIE',
  SexInDifferentPositions = 'SEX_IN_DIFFERENT_POSITIONS',
  StrapOn = 'STRAP_ON',
  OnlineStriptease = 'ONLINE_STRIPTEASE',
  RimmingReceive = 'RIMMING_RECEIVE'
}

export interface ClassifiedAdService {
  id: Scalars['Int'];
  ref_id?: Maybe<Scalars['Int']>;
  status: ServiceStatus;
  date_activation: Scalars['Date'];
  date_expiration: Scalars['Date'];
  inventory_id: Scalars['Int'];
  account_id: Scalars['Int'];
  order_product_id: Scalars['Int'];
  order_id?: Maybe<Scalars['Int']>;
  classified_ad?: Maybe<ClassifiedAd>;
  reason?: Maybe<Scalars['String']>;
  date_canceled?: Maybe<Scalars['Date']>;
  canceled_by?: Maybe<Scalars['Int']>;
}

export interface ClassifiedAdServicesResponse {
  result: Array<Maybe<ClassifiedAdService>>;
  count: Scalars['Int'];
}

export enum ClassifiedAdStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Expired = 'EXPIRED',
  Declined = 'DECLINED',
  Deleted = 'DELETED'
}

export interface ClassifiedAdsFilterInput {
  reference_id?: Maybe<Scalars['Int']>;
  category?: Maybe<ClassifiedAdCategory>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  geo_node_id?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  phone_country_code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  service?: Maybe<ClassifiedAdProvidedService>;
  contact_availability?: Maybe<ClassifiedAdContactAvailability>;
  status?: Maybe<ClassifiedAdStatus>;
  duration?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  is_premium?: Maybe<Scalars['Boolean']>;
  ethnicity?: Maybe<ProfileEthnicity>;
  filter_by_date?: Maybe<ClassifiedAdDateFilter>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export interface ClassifiedAdsFilterPagingResponse {
  previous_id?: Maybe<Scalars['Int']>;
  next_id?: Maybe<Scalars['Int']>;
}

export interface ClassifiedAdsFilterResponse {
  count: Scalars['Int'];
  result: Array<Maybe<ClassifiedAd>>;
}

export interface ClassifiedAdsForAccountFilterInput {
  title?: Maybe<Scalars['String']>;
  status?: Maybe<ClassifiedAdStatus>;
  is_premium?: Maybe<Scalars['Boolean']>;
  account_id?: Maybe<Scalars['Int']>;
}

export interface ClassifiedAdsPublicFilterInput {
  category?: Maybe<ClassifiedAdCategory>;
  ethnicity?: Maybe<ProfileEthnicity>;
  geo_node_id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  is_premium?: Maybe<Scalars['Boolean']>;
}

export interface Comment {
  _id: Scalars['String'];
  entity_id: Scalars['ID'];
  entity_type: CommentEntityType;
  entity_account_id: Scalars['Int'];
  account_id: Scalars['Int'];
  status: CommentStatus;
  body: Scalars['String'];
  attachments?: Maybe<Array<Maybe<CommentAttachment>>>;
  date_created: Scalars['Date'];
  replies?: Maybe<Array<Maybe<Comment>>>;
  member_profile?: Maybe<PublicMemberProfile>;
  reactions?: Maybe<StatisticCommentReactionsCount>;
}

export interface CommentAttachment {
  id?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
}

export interface CommentBodyConfigPartial {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export enum CommentEntityType {
  Profile = 'PROFILE',
  Review = 'REVIEW',
  SupportTicket = 'SUPPORT_TICKET',
  CompanyProfile = 'COMPANY_PROFILE'
}

export enum CommentStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export interface CommentUpdateResponse {
  result: Scalars['Boolean'];
}

export interface CommentsApproveResponse {
  result: Scalars['Boolean'];
}

export interface CommentsDeclineResponse {
  result: Scalars['Boolean'];
}

export interface CommentsDeleteResponse {
  result: Scalars['Boolean'];
}

export interface CommentsRestoreResponse {
  result: Scalars['Boolean'];
}

export interface CommerceConfigCartTotal {
  max: Scalars['Int'];
}

export interface CommerceConfigCreditDebitBalanceAmount {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface CommerceConfigCreditDebitBalanceNote {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface CommerceConfigDiscountNote {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface CommerceConfigs {
  cart_total?: Maybe<CommerceConfigCartTotal>;
  discount_note?: Maybe<CommerceConfigDiscountNote>;
  credit_debit_balance_amount?: Maybe<CommerceConfigCreditDebitBalanceAmount>;
  credit_debit_balance_note?: Maybe<CommerceConfigCreditDebitBalanceNote>;
}

export interface CommerceDiscount {
  id?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['String']>;
  applied_discount_id?: Maybe<Scalars['String']>;
  applied_discount?: Maybe<AppliedDiscount>;
}

export interface CommerceDiscountForOps {
  id?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['Int']>;
  applied_discount_id?: Maybe<Scalars['String']>;
  applied_discount?: Maybe<AppliedDiscount>;
}

export interface CommerceMutationResponse {
  result: Scalars['Boolean'];
}

export interface CompanyPendingCountResponse {
  count: Scalars['Int'];
}

export interface CompanyProfile {
  _id: Scalars['String'];
  ai_text_fields_moderation?: Maybe<CompanyProfileTextFieldsModerationsResponses>;
  _meta?: Maybe<CompanyProfileMeta>;
  showname?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  type?: Maybe<ProfileCompanyType>;
  zip_code?: Maybe<Scalars['String']>;
  is_club?: Maybe<Scalars['Boolean']>;
  entrance_fee?: Maybe<ProfileCompanyEntranceFee>;
  wellness?: Maybe<ProfileCompanyWellness>;
  food_and_drinks?: Maybe<ProfileCompanyFoodDrink>;
  outdoor_area?: Maybe<ProfileCompanyOutdoorArea>;
  about_me?: Maybe<Array<CompanyProfileAboutMe>>;
  cities?: Maybe<Array<CompanyProfileCity>>;
  areas?: Maybe<Array<CompanyProfileArea>>;
  available_24_7?: Maybe<Scalars['Boolean']>;
  working_hours?: Maybe<Array<Maybe<CompanyProfileWorkingHour>>>;
  rates_incall?: Maybe<Array<Maybe<CompanyProfileRateIncall>>>;
  rates_outcall?: Maybe<Array<Maybe<CompanyProfileRateOutcall>>>;
  phone_numbers?: Maybe<Array<Maybe<CompanyProfilePhoneNumber>>>;
  phone_numbers_pending?: Maybe<Array<Maybe<CompanyProfilePhoneNumber>>>;
  emails?: Maybe<Array<Maybe<CompanyProfileEmail>>>;
  emails_pending?: Maybe<Array<Maybe<CompanyProfileEmail>>>;
  website?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  address_additional_details?: Maybe<Scalars['String']>;
  galleries?: Maybe<Array<CompanyProfileGallery>>;
  _changes?: Maybe<CompanyProfileChanges>;
  main_photo?: Maybe<CompanyProfilePhoto>;
}

export interface CompanyProfileAboutMe {
  id: Scalars['String'];
  language: GlobalEnabledLanguage;
  value?: Maybe<Scalars['String']>;
}

export interface CompanyProfileAboutMeInput {
  id: Scalars['String'];
  language: GlobalEnabledLanguage;
  value?: Maybe<Scalars['String']>;
}

export interface CompanyProfileAiLinkBlacklist {
  link?: Maybe<CompanyProfileAiTextFieldMatches>;
  blacklist?: Maybe<CompanyProfileAiTextFieldMatches>;
}

export interface CompanyProfileAiTextFieldMatch {
  match?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
}

export interface CompanyProfileAiTextFieldMatches {
  matches?: Maybe<Array<Maybe<ProfileAiTextFieldMatch>>>;
}

export interface CompanyProfileAiTextFieldsModerationResponse {
  _id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  moderation_type?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  field_value?: Maybe<Scalars['String']>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  ai_moderation_response?: Maybe<CompanyProfileAiLinkBlacklist>;
}

export interface CompanyProfileApplyPhoneToAdsResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileArea {
  id: Scalars['String'];
  value: Scalars['Int'];
  is_base?: Maybe<Scalars['Boolean']>;
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
}

export interface CompanyProfileAreaInput {
  id: Scalars['String'];
  value: Scalars['Int'];
  is_base?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileChanges {
  status: ProfileChangeStatus;
  global_status: ProfileChangeGlobalStatus;
  v?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  fields?: Maybe<CompanyProfileChangesField>;
}

export interface CompanyProfileChangesField {
  showname?: Maybe<Array<Maybe<CompanyProfileChangesFieldScalar>>>;
  display_name?: Maybe<Array<Maybe<CompanyProfileChangesFieldScalar>>>;
  about_me?: Maybe<Array<Maybe<CompanyProfileChangesFieldCollection>>>;
  phone_numbers?: Maybe<Array<Maybe<CompanyProfileChangesFieldCollection>>>;
  street?: Maybe<Array<Maybe<CompanyProfileChangesFieldScalar>>>;
  street_number?: Maybe<Array<Maybe<CompanyProfileChangesFieldScalar>>>;
  website?: Maybe<Array<Maybe<CompanyProfileChangesFieldScalar>>>;
  address_additional_details?: Maybe<Array<Maybe<CompanyProfileChangesFieldScalar>>>;
}

export interface CompanyProfileChangesFieldCollection {
  value?: Maybe<CompanyProfileChangesFieldCollectionValue>;
  status?: Maybe<ProfileChangeFieldStatus>;
  date_changed?: Maybe<Scalars['Date']>;
  changed_by?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface CompanyProfileChangesFieldCollectionValue {
  id?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface CompanyProfileChangesFieldNested {
  value?: Maybe<CompanyProfileChangesFieldNestedValue>;
  status?: Maybe<ProfileChangeFieldStatus>;
  date_changed?: Maybe<Scalars['Date']>;
  changed_by?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface CompanyProfileChangesFieldNestedValue {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface CompanyProfileChangesFieldProcess {
  showname?: Maybe<Array<Maybe<CompanyProfileChangesFieldProcessScalar>>>;
  display_name?: Maybe<Array<Maybe<CompanyProfileChangesFieldProcessScalar>>>;
  about_me?: Maybe<Array<Maybe<ProfileChangesFieldProcessCollection>>>;
  phone_numbers?: Maybe<Array<Maybe<ProfileChangesFieldProcessCollection>>>;
  website?: Maybe<Array<Maybe<CompanyProfileChangesFieldProcessScalar>>>;
  street?: Maybe<Array<Maybe<CompanyProfileChangesFieldProcessScalar>>>;
  street_number?: Maybe<Array<Maybe<CompanyProfileChangesFieldProcessScalar>>>;
  address_additional_details?: Maybe<Array<Maybe<CompanyProfileChangesFieldProcessScalar>>>;
}

export interface CompanyProfileChangesFieldProcessScalar {
  action?: Maybe<ProfilePartialProcessAction>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface CompanyProfileChangesFieldScalar {
  value?: Maybe<CompanyProfileChangesFieldScalarValue>;
  status?: Maybe<ProfileChangeFieldStatus>;
  date_changed?: Maybe<Scalars['Date']>;
  changed_by?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface CompanyProfileChangesFieldScalarValue {
  value?: Maybe<Scalars['String']>;
}

export interface CompanyProfileCity {
  id: Scalars['String'];
  value: Scalars['Int'];
  is_base?: Maybe<Scalars['Boolean']>;
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
}

export interface CompanyProfileCityInput {
  id: Scalars['String'];
  value: Scalars['Int'];
  is_base?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileComment {
  _id: Scalars['String'];
  entity_id: Scalars['ID'];
  entity_type: CommentEntityType;
  entity_account_id: Scalars['Int'];
  status: CommentStatus;
  is_deleted: Scalars['Boolean'];
  company_profile?: Maybe<CompanyProfile>;
  account_id: Scalars['Int'];
  body: Scalars['String'];
  date_created: Scalars['Date'];
  member_profile?: Maybe<MemberProfile>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  processed_by?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
}

export interface CompanyProfileCommentCreateResponse {
  id?: Maybe<Scalars['String']>;
}

export interface CompanyProfileCommentsDisableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileCommentsEnableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileCommentsResponse {
  result: Array<Maybe<Comment>>;
  count: Scalars['Int'];
}

export interface CompanyProfileCreateResponse {
  id: Scalars['String'];
}

export interface CompanyProfileEmail {
  id: Scalars['String'];
  value: Scalars['String'];
}

export interface CompanyProfileEmailInput {
  id: Scalars['String'];
  value: Scalars['String'];
}

export interface CompanyProfileEmailPublic {
  id: Scalars['String'];
}

export interface CompanyProfileEmailUniquenessCheckResult {
  unique: Scalars['Boolean'];
}

export interface CompanyProfileGallery {
  _id: Scalars['String'];
  title: Scalars['String'];
  is_public: Scalars['Boolean'];
  is_available_for_customer: Scalars['Boolean'];
  can_be_modified: Scalars['Boolean'];
  can_be_uploaded_into: Scalars['Boolean'];
  can_be_moved_into: Scalars['Boolean'];
  ordering: Scalars['Int'];
  profile_id: Scalars['String'];
  date_created?: Maybe<Scalars['Date']>;
  created_by: Scalars['Int'];
  photos?: Maybe<Array<CompanyProfilePhoto>>;
}

export interface CompanyProfileInput {
  showname?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  type?: Maybe<ProfileCompanyType>;
  zip_code?: Maybe<Scalars['String']>;
  is_club?: Maybe<Scalars['Boolean']>;
  entrance_fee?: Maybe<ProfileCompanyEntranceFee>;
  wellness?: Maybe<ProfileCompanyWellness>;
  food_and_drinks?: Maybe<ProfileCompanyFoodDrink>;
  outdoor_area?: Maybe<ProfileCompanyOutdoorArea>;
  about_me?: Maybe<Array<CompanyProfileAboutMeInput>>;
  cities?: Maybe<Array<CompanyProfileCityInput>>;
  areas?: Maybe<Array<CompanyProfileAreaInput>>;
  available_24_7?: Maybe<Scalars['Boolean']>;
  working_hours?: Maybe<Array<Maybe<CompanyProfileWorkingHourInput>>>;
  rates_incall?: Maybe<Array<Maybe<CompanyProfileRateIncallInput>>>;
  rates_outcall?: Maybe<Array<Maybe<CompanyProfileRateOutcallInput>>>;
  phone_numbers?: Maybe<Array<Maybe<CompanyProfilePhoneNumberInput>>>;
  emails?: Maybe<Array<Maybe<CompanyProfileEmailInput>>>;
  website?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  address_additional_details?: Maybe<Scalars['String']>;
}

export interface CompanyProfileIsApprovedSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileIsApprovedUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileIsCompanyHiddenSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileIsCompanyHiddenUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileIsContactInfoHiddenSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileIsContactInfoHiddenUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileIsPremiumSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileIsPremiumUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileLastTopicFirstPost {
  post?: Maybe<ForumPost>;
  topic_slug?: Maybe<Scalars['String']>;
}

export interface CompanyProfileMeta {
  reference_id: Scalars['Int'];
  account_id: Scalars['Int'];
  account?: Maybe<Account>;
  ops_account_id: Scalars['Int'];
  type: ProfileMetaType;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
  is_initial_create_done: Scalars['Boolean'];
  is_incomplete: Scalars['Boolean'];
  is_incomplete_pending: Scalars['Boolean'];
  is_deleted: Scalars['Boolean'];
  date_deleted?: Maybe<Scalars['Date']>;
  is_admin_disabled: Scalars['Boolean'];
  date_admin_disabled?: Maybe<Scalars['Date']>;
  not_enough_approved_photos: Scalars['Boolean'];
  can_be_online: Scalars['Boolean'];
  are_comments_disabled: Scalars['Boolean'];
  are_reviews_disabled: Scalars['Boolean'];
  website_status?: Maybe<ProfileMetaWebsiteStatus>;
  old_reference_id?: Maybe<Scalars['Int']>;
  is_premium?: Maybe<Scalars['Boolean']>;
  show_in_club_directory?: Maybe<Scalars['Boolean']>;
  is_contact_info_hidden?: Maybe<Scalars['Boolean']>;
  is_approved?: Maybe<Scalars['Boolean']>;
  is_company_hidden?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfilePhoneNumber {
  id: Scalars['String'];
  country_code: Scalars['String'];
  number: Scalars['String'];
  apps?: Maybe<Array<GlobalPhoneApp>>;
  instruction?: Maybe<GlobalPhoneInstruction>;
  instruction_other?: Maybe<Scalars['String']>;
  no_withheld_numbers?: Maybe<Scalars['Boolean']>;
  international_number: Scalars['String'];
  is_visible?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfilePhoneNumberInput {
  id: Scalars['String'];
  country_code: Scalars['String'];
  number: Scalars['String'];
  apps?: Maybe<Array<GlobalPhoneApp>>;
  instruction?: Maybe<GlobalPhoneInstruction>;
  instruction_other?: Maybe<Scalars['String']>;
  no_withheld_numbers?: Maybe<Scalars['Boolean']>;
  is_visible?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfilePhoneUniquenessCheckResult {
  unique: Scalars['Boolean'];
}

export interface CompanyProfilePhoto {
  _id: Scalars['String'];
  account_id: Scalars['Int'];
  sales_account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  gallery_id: Scalars['String'];
  image_id: Scalars['String'];
  image?: Maybe<Image>;
  ai_media_moderation?: Maybe<CompanyProfilePhotoAiMediaModeration>;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
  is_main: Scalars['Boolean'];
  status: ProfileCompanyPhotoStatus;
  is_rotatable: Scalars['Boolean'];
  need_to_be_retouched: Scalars['Boolean'];
  ordering: Scalars['Int'];
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface CompanyProfilePhotoAiMediaModeration {
  _id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['ID']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<Scalars['String']>;
  image_id?: Maybe<Scalars['ID']>;
  image_path?: Maybe<Scalars['String']>;
  moderation_type?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  ai_moderation_response?: Maybe<CompanyProfilePhotoAiMediaModerationSummaryResponse>;
}

export interface CompanyProfilePhotoAiMediaModerationSummary {
  action?: Maybe<Scalars['String']>;
  reject_prob?: Maybe<Scalars['Float']>;
  reject_reason?: Maybe<Array<Maybe<CompanyProfilePhotoAiRejectReasons>>>;
}

export interface CompanyProfilePhotoAiMediaModerationSummaryResponse {
  summary?: Maybe<CompanyProfilePhotoAiMediaModerationSummary>;
}

export interface CompanyProfilePhotoAiRejectReasons {
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
}

export interface CompanyProfilePhotoApproveResponse {
  result: Scalars['Boolean'];
}

export interface CompanyProfilePhotoCreateResponse {
  ids?: Maybe<Array<Scalars['String']>>;
}

export interface CompanyProfilePhotoDeclineInput {
  id: Scalars['String'];
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface CompanyProfilePhotoDeclineResponse {
  result: Scalars['Boolean'];
}

export interface CompanyProfilePhotoDeleteResponse {
  result: Scalars['Boolean'];
}

export interface CompanyProfilePhotoMoveResponse {
  result: Scalars['Boolean'];
}

export interface CompanyProfilePhotoReorderResponse {
  result: Scalars['Boolean'];
}

export interface CompanyProfilePhotoRestoreResponse {
  result: Scalars['Boolean'];
}

export interface CompanyProfilePhotoSetMainResponse {
  result: Scalars['Boolean'];
}

export interface CompanyProfilePhotoUpdateResponse {
  result: Scalars['Boolean'];
}

export interface CompanyProfileProcessInput {
  action: ProfileProcessAction;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  fields?: Maybe<CompanyProfileChangesFieldProcess>;
}

export interface CompanyProfileProcessResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileRateIncall {
  id: Scalars['String'];
  time?: Maybe<Scalars['Int']>;
  type: ProfileRateType;
  price?: Maybe<Scalars['Int']>;
  currency: GlobalCurrency;
}

export interface CompanyProfileRateIncallInput {
  id: Scalars['String'];
  time?: Maybe<Scalars['Int']>;
  type: ProfileRateType;
  price?: Maybe<Scalars['Int']>;
  currency: GlobalCurrency;
}

export interface CompanyProfileRateOutcall {
  id: Scalars['String'];
  time?: Maybe<Scalars['Int']>;
  type: ProfileRateType;
  price?: Maybe<Scalars['Int']>;
  currency: GlobalCurrency;
}

export interface CompanyProfileRateOutcallInput {
  id: Scalars['String'];
  time?: Maybe<Scalars['Int']>;
  type: ProfileRateType;
  price?: Maybe<Scalars['Int']>;
  currency: GlobalCurrency;
}

export interface CompanyProfileReviewsDisableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileReviewsEnableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileShowInClubDirectorySetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileShowInClubDirectoryUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileTextFieldsModerationsResponses {
  showname?: Maybe<CompanyProfileAiTextFieldsModerationResponse>;
  display_name?: Maybe<CompanyProfileAiTextFieldsModerationResponse>;
  about_me?: Maybe<CompanyProfileAiTextFieldsModerationResponse>;
  phone_numbers?: Maybe<CompanyProfileAiTextFieldsModerationResponse>;
  website?: Maybe<CompanyProfileAiTextFieldsModerationResponse>;
  street?: Maybe<CompanyProfileAiTextFieldsModerationResponse>;
  street_number?: Maybe<CompanyProfileAiTextFieldsModerationResponse>;
  address_additional_details?: Maybe<CompanyProfileAiTextFieldsModerationResponse>;
}

export interface CompanyProfileUpdateResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileUpdateWebsiteStatusResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface CompanyProfileWorkingHour {
  id: Scalars['String'];
  week_day: GlobalWeekDay;
  from: Scalars['String'];
  to: Scalars['String'];
}

export interface CompanyProfileWorkingHourInput {
  id: Scalars['String'];
  week_day: GlobalWeekDay;
  from: Scalars['String'];
  to: Scalars['String'];
}

export interface ConfigResponse {
  comment_body?: Maybe<CommentBodyConfigPartial>;
}

export interface Configs {
  service_cancellation_reason?: Maybe<ConfigsServiceCancellationReason>;
  bump_types: Array<Scalars['String']>;
  bump_statuses: Array<Scalars['String']>;
  bump_frequency_options: Array<ConfigsBumpScheduleFrequencyOption>;
  bump_weekdays: ConfigsBumpScheduleWeekday;
  hour_based_product_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  base_service_product_ids_eligible_for_reassign?: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export interface ConfigsBumpScheduleFrequencyOption {
  text: Scalars['String'];
  value: Scalars['Int'];
}

export interface ConfigsBumpScheduleWeekday {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ConfigsServiceCancellationReason {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ContactFilterInput {
  phone?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  contact_state?: Maybe<ContactState>;
  is_blacklisted?: Maybe<Scalars['Int']>;
}

export enum ContactState {
  NotContacted = 'NOT_CONTACTED',
  ContactPending = 'CONTACT_PENDING',
  Contacted = 'CONTACTED',
  ContactFailed = 'CONTACT_FAILED',
  Buffered = 'BUFFERED',
  Sent = 'SENT',
  NotDelivered = 'NOT_DELIVERED',
  InvalidPhone = 'INVALID_PHONE',
  Blacklist = 'BLACKLIST',
  QuotaExceeded = 'QUOTA_EXCEEDED',
  NoAcknowledgment = 'NO_ACKNOWLEDGMENT',
  Spam = 'SPAM',
  Duplicate = 'DUPLICATE',
  Deleted = 'DELETED',
  Postponed = 'POSTPONED'
}

export enum ContactVerificationEmailPurposes {
  AccountRegistration = 'ACCOUNT_REGISTRATION',
  ContactEmail = 'CONTACT_EMAIL'
}

export enum ContactVerificationPhoneNumberPurposes {
  Profile = 'PROFILE',
  OpsChange = 'OPS_CHANGE'
}

export interface CreateAccountInput {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
  role: PublicRole;
}

export interface CreateAccountResponse {
  id: Scalars['Int'];
}

export interface CreateActivityTemplateResponse {
  id?: Maybe<Scalars['String']>;
}

export interface CreateBlacklistedItemResponse {
  id: Scalars['String'];
}

export interface CreateClassifiedAdFeedbackResponse {
  id: Scalars['String'];
}

export interface CreateClassifiedAdResponse {
  id: Scalars['String'];
  reference_id: Scalars['Int'];
}

export interface CreateContactGroupResponse {
  id: Scalars['Int'];
}

export interface CreateContactResponse {
  id: Scalars['Int'];
}

export interface CreateDeclineReasonResponse {
  id?: Maybe<Scalars['String']>;
}

export interface CreateDeclineReasonValueInput {
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  ro?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  hu?: Maybe<Scalars['String']>;
}

export interface CreateInstantBookRequestInput {
  availability_id: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  duration_unit?: Maybe<InstantBookDurationUnits>;
  meeting_date: Scalars['Date'];
  location: InstantBookAvailabilities;
  outcall_address?: Maybe<Scalars['String']>;
  special_requests?: Maybe<Scalars['String']>;
  phone_numbers: Array<Maybe<InstantBookRequestPhoneNumberInput>>;
  must_reply_in: Scalars['Int'];
}

export interface CreateManualPaymentInput {
  amount: Scalars['Float'];
  transaction_id: Scalars['String'];
  gateway: PaymentGateways;
  credit_card_holder: Scalars['String'];
  payment_date: Scalars['String'];
  gateway_ip?: Maybe<Scalars['String']>;
  credit_card_number?: Maybe<Scalars['String']>;
  credit_card_expiration_month?: Maybe<Scalars['String']>;
  credit_card_expiration_year?: Maybe<Scalars['String']>;
  admin_comment?: Maybe<Scalars['String']>;
}

export interface CreateManualPaymentResponse {
  result: Scalars['Boolean'];
}

export interface CreateMemberAlertResponse {
  id: Scalars['String'];
}

export interface CreateMemberCityAlertResponse {
  id: Scalars['String'];
}

export interface CreateMemberCityAlertsResponse {
  ids: Array<Maybe<Scalars['String']>>;
}

export interface CreateMessengerMessageResponse {
  id: Scalars['String'];
  body: Scalars['String'];
}

export interface CreateMessengerThreadResponse {
  id: Scalars['String'];
}

export interface CreateOnlineNewsResponse {
  id?: Maybe<Scalars['ID']>;
}

export interface CreateOpsAccountInput {
  email: Scalars['String'];
  password: Scalars['String'];
  role: OpsRole;
  showname: Scalars['String'];
  username: Scalars['String'];
}

export interface CreatePaymentLinkInput {
  account_id: Scalars['Int'];
  amount?: Maybe<Scalars['Int']>;
  payment_methods?: Maybe<Array<Maybe<Scalars['String']>>>;
  internal_note?: Maybe<Scalars['String']>;
  external_note?: Maybe<Scalars['String']>;
}

export interface CreatePaymentLinkResponse {
  result: Scalars['Boolean'];
}

export interface CreateRegularTemplateResponse {
  id?: Maybe<Scalars['String']>;
}

export interface CreateStoryInput {
  profile_id: Scalars['ID'];
  stories?: Maybe<Array<StoryDataType>>;
}

export interface CreateSystemEmailTemplateBodyInput {
  de: CreateSystemEmailTemplateBodyLanguageInput;
  en: CreateSystemEmailTemplateBodyLanguageInput;
  es: CreateSystemEmailTemplateBodyLanguageInput;
  fr: CreateSystemEmailTemplateBodyLanguageInput;
  ro: CreateSystemEmailTemplateBodyLanguageInput;
  it: CreateSystemEmailTemplateBodyLanguageInput;
  hu: CreateSystemEmailTemplateBodyLanguageInput;
}

export interface CreateSystemEmailTemplateBodyLanguageInput {
  plain?: Maybe<Scalars['String']>;
  html: Scalars['String'];
}

export interface CreateSystemEmailTemplateInput {
  slug: Scalars['String'];
  from?: Maybe<Scalars['String']>;
  body: CreateSystemEmailTemplateBodyInput;
  subject?: Maybe<CreateSystemEmailTemplateSubjectInput>;
  is_layout: Scalars['Boolean'];
  is_default_layout: Scalars['Boolean'];
  layout?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  purpose?: Maybe<Array<Maybe<SystemEmailPurposeType>>>;
}

export interface CreateSystemEmailTemplateResponse {
  id: Scalars['String'];
}

export interface CreateSystemEmailTemplateSubjectInput {
  de: Scalars['String'];
  en: Scalars['String'];
  es: Scalars['String'];
  fr: Scalars['String'];
  ro: Scalars['String'];
  it: Scalars['String'];
  hu: Scalars['String'];
}

export interface CreateSystemPushTemplateBodyInput {
  de: Scalars['String'];
  en: Scalars['String'];
  es: Scalars['String'];
  fr: Scalars['String'];
  ro: Scalars['String'];
  it: Scalars['String'];
  hu: Scalars['String'];
}

export interface CreateSystemPushTemplateHeadingInput {
  de: Scalars['String'];
  en: Scalars['String'];
  es: Scalars['String'];
  fr: Scalars['String'];
  ro: Scalars['String'];
  it: Scalars['String'];
  hu: Scalars['String'];
}

export interface CreateSystemPushTemplateResponse {
  id: Scalars['String'];
}

export interface CreateSystemSmsTemplateBodyInput {
  de: Scalars['String'];
  en: Scalars['String'];
  es: Scalars['String'];
  fr: Scalars['String'];
  ro: Scalars['String'];
  it: Scalars['String'];
  hu: Scalars['String'];
}

export interface CreateSystemSmsTemplateResponse {
  id: Scalars['String'];
}

export interface DashboardNotification {
  type: Scalars['String'];
  account_id: Scalars['Int'];
  member_profile?: Maybe<PublicMemberProfile>;
  escort_profile?: Maybe<PublicProfile>;
  token_member_profile?: Maybe<PublicMemberProfile>;
  token_account_id?: Maybe<Scalars['Int']>;
  entity_account_id: Scalars['Int'];
  entity_id?: Maybe<Scalars['String']>;
  entity_profile_id?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['Int']>;
  city_name?: Maybe<Scalars['String']>;
  city_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  date_created: Scalars['Date'];
}

export interface DashboardNotificationPagination {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<DashboardNotification>>>;
}


export interface DeclinePhotosInput {
  id: Scalars['String'];
  decline_reasons: Array<Maybe<Scalars['String']>>;
}

export interface DeclineReason {
  _id: Scalars['ID'];
  types: Array<DeclineReasonType>;
  key: Scalars['String'];
  value?: Maybe<DeclineReasonValue>;
  dictionary_id: Scalars['ID'];
  is_disabled: Scalars['Boolean'];
  created_by?: Maybe<Scalars['Int']>;
  date_created: Scalars['Date'];
  modified_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
}

export interface DeclineReasonResponseBoolean {
  result?: Maybe<Scalars['Boolean']>;
}

export enum DeclineReasonType {
  Profile = 'PROFILE',
  Photo = 'PHOTO',
  MemberPhoto = 'MEMBER_PHOTO',
  Video = 'VIDEO',
  Review = 'REVIEW',
  NaturalPhoto = 'NATURAL_PHOTO',
  CustomVideoCover = 'CUSTOM_VIDEO_COVER',
  AgeVerification = 'AGE_VERIFICATION',
  OneHundredVerification = 'ONE_HUNDRED_VERIFICATION',
  Story = 'STORY'
}

export interface DeclineReasonValue {
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  ro?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  hu?: Maybe<Scalars['String']>;
}

export interface DeclineStories {
  _id: Scalars['ID'];
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface DeleteContactGroupResponse {
  result: Scalars['Boolean'];
}

export interface DeleteContactResponse {
  result: Scalars['Boolean'];
}

export interface DeleteEmailsResponse {
  result: Scalars['Boolean'];
}

export interface DeleteMemberAlertResponse {
  count: Scalars['Int'];
}

export interface DeleteMemberCityAlertResponse {
  count: Scalars['Int'];
}

export interface DeleteMessengerThreadResponse {
  result: Scalars['Boolean'];
}

export interface DeleteNoteResponse {
  result: Scalars['Boolean'];
}

export interface DeleteOnlineNewsResponse {
  result: Scalars['Boolean'];
}

export interface DeletePaymentLinkResponse {
  result: Scalars['Boolean'];
}

export interface DeleteSmsesResponse {
  result: Scalars['Boolean'];
}

export interface Dictionary {
  _id: Scalars['String'];
  key: Scalars['String'];
  category: DictionaryCategories;
  value_de: Scalars['String'];
  value_en: Scalars['String'];
  value_es: Scalars['String'];
  value_fr: Scalars['String'];
  value_ro: Scalars['String'];
  value_it: Scalars['String'];
  value_hu: Scalars['String'];
  slug_de?: Maybe<Scalars['String']>;
  slug_en?: Maybe<Scalars['String']>;
  slug_es?: Maybe<Scalars['String']>;
  slug_fr?: Maybe<Scalars['String']>;
  slug_ro?: Maybe<Scalars['String']>;
  slug_it?: Maybe<Scalars['String']>;
  slug_hu?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  created_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
}

export enum DictionaryCategories {
  Exterior = 'EXTERIOR',
  Interior = 'INTERIOR',
  Cellar = 'CELLAR',
  System = 'SYSTEM',
  ExteriorStaticPage = 'EXTERIOR_STATIC_PAGE',
  InteriorStaticPage = 'INTERIOR_STATIC_PAGE',
  Geography = 'GEOGRAPHY',
  SwitzerlandGeography = 'SWITZERLAND_GEOGRAPHY',
  OldAnd6 = 'OLD_AND6',
  Messenger = 'MESSENGER'
}

export interface DictionaryCreateResponse {
  _id?: Maybe<Scalars['String']>;
}

export interface DictionaryDeleteResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface DictionaryInput {
  key: Scalars['String'];
  category: DictionaryCategories;
  value_de: Scalars['String'];
  value_en: Scalars['String'];
  value_es: Scalars['String'];
  value_fr: Scalars['String'];
  value_ro: Scalars['String'];
  value_it: Scalars['String'];
  value_hu: Scalars['String'];
  slug_de?: Maybe<Scalars['String']>;
  slug_en?: Maybe<Scalars['String']>;
  slug_es?: Maybe<Scalars['String']>;
  slug_fr?: Maybe<Scalars['String']>;
  slug_ro?: Maybe<Scalars['String']>;
  slug_it?: Maybe<Scalars['String']>;
  slug_hu?: Maybe<Scalars['String']>;
}

export interface DictionaryUpdateResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface Discount {
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  system_max?: Maybe<Scalars['Int']>;
  account_max?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Float']>;
  used: Scalars['Int'];
  start_date?: Maybe<Scalars['Date']>;
  end_date?: Maybe<Scalars['Date']>;
  status: DiscountStatusType;
  rules?: Maybe<Array<Maybe<DiscountRule>>>;
  is_disabled: Scalars['Boolean'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  date_created?: Maybe<Scalars['Date']>;
  created_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
}

export interface DiscountConfig {
  rule_configs: Array<DiscountRuleConfig>;
  promo_code: DiscountPromoCodeConfig;
}

export interface DiscountCreateInput {
  system_max?: Maybe<Scalars['Int']>;
  account_max?: Maybe<Scalars['Int']>;
  percentage: Scalars['Float'];
  start_date: Scalars['Date'];
  end_date: Scalars['Date'];
  promo_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rules: Array<DiscountRuleInput>;
}

export interface DiscountCreateResponse {
  id: Scalars['ID'];
}

export interface DiscountDeleteResponse {
  result: Scalars['Boolean'];
}

export interface DiscountDisableResponse {
  result: Scalars['Boolean'];
}

export interface DiscountEnableResponse {
  result: Scalars['Boolean'];
}

export interface DiscountFilterInput {
  name?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<Array<Maybe<DiscountStatusType>>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface DiscountPromoCodeConfig {
  valid_pattern: Scalars['String'];
}

export interface DiscountRule {
  type: DiscountRuleType;
  operator: DiscountRuleOperator;
  value: Scalars['_Any'];
}

export interface DiscountRuleConfig {
  name: DiscountRuleType;
  operators: Array<DiscountRuleOperator>;
  values?: Maybe<Array<Maybe<DiscountRuleConfigValue>>>;
}

export enum DiscountRuleConfigAccountRole {
  Escort = 'ESCORT',
  Company = 'COMPANY'
}

export enum DiscountRuleConfigProfileType {
  Ad = 'AD',
  CompanyAd = 'COMPANY_AD'
}

export interface DiscountRuleConfigValue {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
}

export interface DiscountRuleInput {
  type: DiscountRuleType;
  operator: DiscountRuleOperator;
  value: Scalars['_Any'];
}

export enum DiscountRuleOperator {
  Is = 'IS',
  IsNot = 'IS_NOT'
}

export enum DiscountRuleType {
  Account = 'ACCOUNT',
  AccountRole = 'ACCOUNT_ROLE',
  ProfileType = 'PROFILE_TYPE',
  Product = 'PRODUCT'
}

export enum DiscountStatusType {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Expired = 'EXPIRED'
}

export interface DiscountUpdateResponse {
  result: Scalars['Boolean'];
}

export interface DiscountWithPromoCodeExistResponse {
  exist: Scalars['Boolean'];
}

export interface DiscountsListResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<Discount>>>;
}

export interface Document {
  _id: Scalars['String'];
  account_id: Scalars['Int'];
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  purpose: Scalars['String'];
  originalname?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  hash: Scalars['String'];
  ext: Scalars['String'];
  path: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  is_portrait?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  thumbs?: Maybe<Array<Maybe<DocumentVideoThumb>>>;
  videos?: Maybe<Array<Maybe<DocumentVideo>>>;
  source_meta?: Maybe<DocumentSourceMeta>;
  progress?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
}

export interface DocumentInput {
  photo_id?: Maybe<Scalars['String']>;
  type: ProfileVerificationDocumentTypes;
  media_document_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['String']>;
  id_number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
}

export interface DocumentSourceMeta {
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  file_type?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  video_bit_rate?: Maybe<Scalars['Int']>;
  audio_bit_rate?: Maybe<Scalars['Int']>;
}

export interface DocumentVideo {
  _id?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface DocumentVideoThumb {
  _id?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
}

export enum EntityType {
  Account = 'ACCOUNT',
  Ad = 'AD',
  ClassifiedAd = 'CLASSIFIED_AD',
  Banner = 'BANNER'
}

export interface ExistsByPhoneResponse {
  exists?: Maybe<Scalars['Boolean']>;
}

export interface Faq {
  _id: Scalars['ID'];
  account_roles: Array<FaqAccountRole>;
  title: Scalars['String'];
  de?: Maybe<FaqLanguage>;
  en?: Maybe<FaqLanguage>;
  es?: Maybe<FaqLanguage>;
  fr?: Maybe<FaqLanguage>;
  ro?: Maybe<FaqLanguage>;
  it?: Maybe<FaqLanguage>;
  hu?: Maybe<FaqLanguage>;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
  is_deleted: Scalars['Boolean'];
  is_disabled?: Maybe<Scalars['Boolean']>;
}

export enum FaqAccountRole {
  Escort = 'ESCORT',
  Company = 'COMPANY',
  Member = 'MEMBER',
  Sales = 'SALES',
  Manager = 'MANAGER'
}

export interface FaqAttachedImage {
  _id: Scalars['ID'];
  image?: Maybe<Image>;
}

export interface FaqAttachedVideo {
  _id: Scalars['ID'];
  video?: Maybe<Video>;
}

export interface FaqCreateResponse {
  id: Scalars['ID'];
}

export interface FaqDataInput {
  account_roles: Array<FaqAccountRole>;
  title: Scalars['String'];
  question: FaqLanguageInput;
  answer: FaqLanguageInput;
  attached_images?: Maybe<FaqFilesLanguageInput>;
  attached_videos?: Maybe<FaqFilesLanguageInput>;
}

export interface FaqDeleteResponse {
  deleted: Scalars['Boolean'];
}

export interface FaqDisableResponse {
  disabled: Scalars['Boolean'];
}

export interface FaqEnableResponse {
  enabled: Scalars['Boolean'];
}

export interface FaqFilesLanguageInput {
  de?: Maybe<Array<Maybe<Scalars['ID']>>>;
  en?: Maybe<Array<Maybe<Scalars['ID']>>>;
  es?: Maybe<Array<Maybe<Scalars['ID']>>>;
  fr?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ro?: Maybe<Array<Maybe<Scalars['ID']>>>;
  it?: Maybe<Array<Maybe<Scalars['ID']>>>;
  hu?: Maybe<Array<Maybe<Scalars['ID']>>>;
}

export interface FaqLanguage {
  question: Scalars['String'];
  answer: Scalars['String'];
  attached_images?: Maybe<Array<Maybe<FaqAttachedImage>>>;
  attached_videos?: Maybe<Array<Maybe<FaqAttachedVideo>>>;
}

export interface FaqLanguageInput {
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  ro?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  hu?: Maybe<Scalars['String']>;
}

export interface FaqRestoreResponse {
  restored: Scalars['Boolean'];
}

export interface FaqUpdateResponse {
  updated: Scalars['Boolean'];
}

export interface ForumPost {
  author_id?: Maybe<Scalars['Int']>;
  author_name?: Maybe<Scalars['String']>;
  post?: Maybe<Scalars['String']>;
  post_date?: Maybe<Scalars['Int']>;
  topic_id?: Maybe<Scalars['Int']>;
}

export interface FraudIp {
  key?: Maybe<Scalars['String']>;
  doc_count?: Maybe<Scalars['Int']>;
}

export interface FraudRequest {
  _id: Scalars['ID'];
  type: Scalars['String'];
  event: Scalars['String'];
  account_id: Scalars['Int'];
  ip?: Maybe<Scalars['String']>;
  ip2location?: Maybe<Ip2Location>;
  cid?: Maybe<Scalars['String']>;
  date_created: Scalars['Date'];
  account_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export enum FraudRequestTrackingEvent {
  Cookie = 'COOKIE',
  Ip = 'IP'
}

export enum FraudType {
  Account = 'ACCOUNT',
  Profile = 'PROFILE',
  Document = 'DOCUMENT'
}

export interface FullOrder {
  id: Scalars['Int'];
  status: OrderStatus;
  hash: Scalars['String'];
  account_id: Scalars['Int'];
  date_created: Scalars['Date'];
  date_paid?: Maybe<Scalars['Date']>;
  products?: Maybe<Array<Maybe<OrderProduct>>>;
  price: Price;
  created_by: Scalars['Int'];
  payment_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface FullOrderForOps {
  id: Scalars['Int'];
  status: OrderStatus;
  hash: Scalars['String'];
  account_id: Scalars['Int'];
  date_created: Scalars['Date'];
  date_paid?: Maybe<Scalars['Date']>;
  products?: Maybe<Array<Maybe<OrderProductForOps>>>;
  price: PriceForOps;
  created_by: Scalars['Int'];
  payment_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface GeoFilter {
  max_distance?: Maybe<Scalars['Int']>;
  type?: Maybe<GeoNodeTypes>;
}

export interface GeoNode {
  id: Scalars['Int'];
  parent_id: Scalars['Int'];
  type: GeoNodeTypes;
  name: Scalars['String'];
  iso?: Maybe<Scalars['String']>;
  lft?: Maybe<Scalars['Int']>;
  rgt?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  timezone_id?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  is_zona_rossa?: Maybe<Scalars['Boolean']>;
  is_french?: Maybe<Scalars['Boolean']>;
  old_id?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
}

export interface GeoNodeCoordinates {
  id: Scalars['Int'];
  name: Scalars['String'];
  type: GeoNodeTypes;
  iso?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
}

export interface GeoNodeCreateResponse {
  id?: Maybe<Scalars['Int']>;
}

export interface GeoNodeDeleteResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface GeoNodeInput {
  type: GeoNodeTypes;
  name: Scalars['String'];
  parent_id: Scalars['Int'];
  iso?: Maybe<Scalars['String']>;
  is_visible?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  timezone_id?: Maybe<Scalars['Int']>;
  old_id?: Maybe<Scalars['Int']>;
  is_zona_rossa?: Maybe<Scalars['Boolean']>;
}

export interface GeoNodeMoveInput {
  to_node_id: Scalars['Int'];
  position: GeoNodePositions;
}

export interface GeoNodeMoveResponse {
  count?: Maybe<Scalars['Int']>;
}

export enum GeoNodePositions {
  After = 'AFTER',
  Before = 'BEFORE',
  Into = 'INTO'
}

export enum GeoNodeTypes {
  Continent = 'CONTINENT',
  Country = 'COUNTRY',
  State = 'STATE',
  Region = 'REGION',
  Canton = 'CANTON',
  Area = 'AREA',
  City = 'CITY',
  Cityzone = 'CITYZONE'
}

export interface GeoNodeUpdateInput {
  name: Scalars['String'];
  iso?: Maybe<Scalars['String']>;
  is_visible?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
}

export interface GeoNodeUpdateResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface GlobalConfig {
  country_ids?: Maybe<Array<Scalars['Int']>>;
  enabled_languages?: Maybe<Array<GlobalConfigEnabledLanguge>>;
  email: GlobalConfigEmail;
  url: GlobalConfigUrl;
  phone_number: GlobalConfigPhoneNumber;
  phone_instruction_other: GlobalConfigPhoneInstructionOther;
  phone_country_codes?: Maybe<Array<GlobalConfigPhoneCountryCode>>;
  week_days?: Maybe<Array<GlobalWeekDay>>;
  phone_apps?: Maybe<Array<GlobalPhoneApp>>;
  phone_instructions?: Maybe<Array<GlobalPhoneInstruction>>;
  currencies?: Maybe<Array<GlobalConfigCurrency>>;
  timezone: Scalars['String'];
  re_captcha: GlobalConfigReCaptcha;
}

export interface GlobalConfigCurrency {
  value: GlobalCurrency;
  symbol: Scalars['String'];
}

export interface GlobalConfigEmail {
  valid_pattern: Scalars['String'];
}

export interface GlobalConfigEnabledLanguge {
  iso: GlobalEnabledLanguage;
  is_default?: Maybe<Scalars['Boolean']>;
}

export interface GlobalConfigPhoneCountryCode {
  id: Scalars['String'];
  country: Scalars['String'];
  code: Scalars['String'];
  ndd: Scalars['String'];
}

export interface GlobalConfigPhoneInstructionOther {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface GlobalConfigPhoneNumber {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface GlobalConfigReCaptcha {
  site_key: Scalars['String'];
}

export interface GlobalConfigUrl {
  valid_pattern: Scalars['String'];
}

export enum GlobalCurrency {
  Chf = 'CHF'
}

export enum GlobalEnabledLanguage {
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  Ro = 'RO',
  It = 'IT',
  Hu = 'HU'
}

export enum GlobalPhoneApp {
  Viber = 'VIBER',
  Whatsapp = 'WHATSAPP',
  Telegram = 'TELEGRAM'
}

export enum GlobalPhoneInstruction {
  SmsAndCall = 'SMS_AND_CALL',
  SmsOnly = 'SMS_ONLY',
  NoSms = 'NO_SMS'
}

export enum GlobalWeekDay {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export interface GotdInventory {
  id: Scalars['Int'];
  price: Scalars['Int'];
  slots_limit: Scalars['Int'];
  geo_node_id: Scalars['Int'];
}

export interface GotdProduct {
  id: Scalars['Int'];
  inventory: Array<Maybe<GotdInventory>>;
}

export interface GotdService {
  id: Scalars['Int'];
  ref_id?: Maybe<Scalars['Int']>;
  status: ServiceStatus;
  date_activation: Scalars['Date'];
  date_expiration: Scalars['Date'];
  inventory_id: Scalars['Int'];
  account_id: Scalars['Int'];
  order_product_id: Scalars['Int'];
  geo_node_id: Scalars['Int'];
  order_id?: Maybe<Scalars['Int']>;
  profile?: Maybe<Profile>;
  reason?: Maybe<Scalars['String']>;
  date_canceled?: Maybe<Scalars['Date']>;
  canceled_by?: Maybe<Scalars['Int']>;
}

export interface GotdServicesResponse {
  result: Array<Maybe<GotdService>>;
  count: Scalars['Int'];
}

export interface Ip2Location {
  ip?: Maybe<Scalars['String']>;
  location?: Maybe<Ip2LocationData>;
}

export interface Ip2LocationData {
  country_short?: Maybe<Scalars['String']>;
  country_long?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
}

export interface Image {
  _id: Scalars['String'];
  account_id: Scalars['Int'];
  created_by: Scalars['Int'];
  purpose: Scalars['String'];
  originalname?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  hash: Scalars['String'];
  ext: Scalars['String'];
  path: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  is_portrait: Scalars['Boolean'];
  size: Scalars['Int'];
  date_created: Scalars['Date'];
  v?: Maybe<Scalars['Int']>;
  is_original_modified?: Maybe<Scalars['Boolean']>;
  modified_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
}

export interface ImportContactsResponse {
  result: Scalars['Boolean'];
}

export interface InCartProduct {
  id: Scalars['Int'];
  name: Scalars['String'];
  product_id: Scalars['Int'];
  created_by: Scalars['Int'];
  options?: Maybe<Array<Maybe<OrderProductOption>>>;
}

export enum InstantBookAvailabilities {
  Incall = 'INCALL',
  Outcall = 'OUTCALL'
}

export interface InstantBookAvailabilitiesCreateResponse {
  ids: Array<Maybe<Scalars['String']>>;
}

export interface InstantBookAvailability {
  _id: Scalars['String'];
  profile_id: Scalars['String'];
  profile?: Maybe<Profile>;
  account_id: Scalars['Int'];
  ops_account_id: Scalars['Int'];
  available_for: Array<Maybe<InstantBookAvailabilities>>;
  status: InstantBookAvailabilityStatuses;
  date_from: Scalars['Date'];
  date_to: Scalars['Date'];
  date_created: Scalars['Int'];
  created_by: Scalars['Int'];
}

export interface InstantBookAvailabilityCancelResponse {
  result: Scalars['Boolean'];
}

export interface InstantBookAvailabilityCreateResponse {
  id: Scalars['String'];
}

export interface InstantBookAvailabilityPublic {
  _id: Scalars['String'];
  profile_id: Scalars['String'];
  available_for: Array<Maybe<InstantBookAvailabilities>>;
  status: InstantBookAvailabilityStatuses;
  date_from: Scalars['Date'];
  date_to: Scalars['Date'];
  date_created: Scalars['Int'];
}

export enum InstantBookAvailabilityStatuses {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Canceled = 'CANCELED'
}

export interface InstantBookAvailabilityUpdateResponse {
  result: Scalars['Boolean'];
}

export interface InstantBookCellarRequestHistoryInput {
  account_id?: Maybe<Scalars['Int']>;
  reference_id?: Maybe<Scalars['Int']>;
  status?: Maybe<InstantBookRequestStatuses>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  phone_number?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}

export interface InstantBookCheckRequestResponse {
  exists: Scalars['Boolean'];
}

export interface InstantBookConfig {
  allowed_available_hours: Array<Maybe<Scalars['Int']>>;
  special_request: InstantBookSpecialRequestConfig;
  outcall_address: InstantBookOutcallAddressConfig;
  reply_minutes: Array<Maybe<Scalars['Int']>>;
  phone_instructions: Array<Maybe<InstantBookPhoneInstructions>>;
  duration_units?: Maybe<Array<Maybe<InstantBookDurationUnits>>>;
}

export enum InstantBookDurationUnits {
  Hour = 'HOUR'
}

export interface InstantBookOutcallAddressConfig {
  min: Scalars['Int'];
  max: Scalars['Int'];
  allowed_tags: Array<Maybe<Scalars['String']>>;
  allowed_attributes: Scalars['JSONObject'];
}

export enum InstantBookPhoneInstructions {
  Viber = 'VIBER',
  Whatsapp = 'WHATSAPP',
  Sms = 'SMS',
  Call = 'CALL',
  Telegram = 'TELEGRAM'
}

export interface InstantBookRequest {
  _id: Scalars['String'];
  _meta: InstantBookRequestMeta;
  availability_id?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  duration: Scalars['Int'];
  duration_unit: InstantBookDurationUnits;
  meeting_date: Scalars['Date'];
  location: InstantBookAvailabilities;
  outcall_address?: Maybe<Scalars['String']>;
  special_requests?: Maybe<Scalars['String']>;
  must_reply_in?: Maybe<Scalars['Int']>;
  phone_numbers?: Maybe<Array<Maybe<InstantBookRequestPhoneNumber>>>;
  ip?: Maybe<Scalars['String']>;
  ip2location?: Maybe<Ip2Location>;
}

export interface InstantBookRequestAcceptResponse {
  result: Scalars['Boolean'];
}

export interface InstantBookRequestCreateResponse {
  id: Scalars['String'];
  hash: Scalars['String'];
}

export interface InstantBookRequestDeclineResponse {
  result: Scalars['Boolean'];
}

export interface InstantBookRequestHistoryInput {
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}

export interface InstantBookRequestMeta {
  profile?: Maybe<Profile>;
  profile_id: Scalars['String'];
  reference_id?: Maybe<Scalars['Int']>;
  profile_account_id: Scalars['Int'];
  profile_ops_account_id: Scalars['Int'];
  status: InstantBookRequestStatuses;
  date_created: Scalars['Date'];
  is_profile_deleted: Scalars['Boolean'];
  date_accepted?: Maybe<Scalars['Date']>;
  date_declined?: Maybe<Scalars['Date']>;
  date_not_answered?: Maybe<Scalars['Date']>;
}

export interface InstantBookRequestPhoneNumber {
  international_number?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  instructions?: Maybe<Array<Maybe<InstantBookPhoneInstructions>>>;
}

export interface InstantBookRequestPhoneNumberInput {
  country_code: Scalars['String'];
  number: Scalars['String'];
  instructions: Array<Maybe<InstantBookPhoneInstructions>>;
}

export enum InstantBookRequestStatuses {
  Pending = 'PENDING',
  NotAnswered = 'NOT_ANSWERED',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED'
}

export interface InstantBookRequestsPagination {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<InstantBookRequest>>>;
}

export interface InstantBookSettings {
  _id: Scalars['String'];
  account_id: Scalars['Int'];
  notifications: InstantBookSettingsNotifications;
  use_profile_phone_number: Scalars['Boolean'];
  use_account_email: Scalars['Boolean'];
  use_profile_email: Scalars['Boolean'];
  use_agency_profile_phone_number?: Maybe<Scalars['Boolean']>;
  use_agency_profile_email?: Maybe<Scalars['Boolean']>;
  phone_number: InstantBookSettingsPhoneNumber;
  date_created: Scalars['Date'];
}

export interface InstantBookSettingsNotifications {
  email: Scalars['Boolean'];
  sms: Scalars['Boolean'];
}

export interface InstantBookSettingsPhoneNumber {
  country_code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  international_number?: Maybe<Scalars['String']>;
}

export interface InstantBookSettingsUpdateResponse {
  result: Scalars['Boolean'];
}

export interface InstantBookSpecialRequestConfig {
  min: Scalars['Int'];
  max: Scalars['Int'];
  allowed_tags: Array<Maybe<Scalars['String']>>;
  allowed_attributes: Scalars['JSONObject'];
}

export interface InstantBumpCreateResponse {
  ids: Array<Scalars['String']>;
}

export interface InvalidProduct {
  product_id: Scalars['Int'];
  error: Scalars['String'];
}

export interface Inventory {
  id: Scalars['Int'];
  price: Scalars['Int'];
  slots_limit?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  geo_node_id?: Maybe<Scalars['Int']>;
  additional_geo_nodes_count?: Maybe<Scalars['Int']>;
  zone_id?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
}

export interface InventoryConfigs {
  max_activation_delay_in_days: Scalars['Int'];
  banner_zones: Array<Maybe<BannerZoneConfig>>;
}

export interface Ip {
  ip?: Maybe<Scalars['String']>;
}

export interface IxsBanner {
  _id?: Maybe<Scalars['Int']>;
  ixs_id?: Maybe<Scalars['Int']>;
  zone_id?: Maybe<Scalars['String']>;
  zone_rotate?: Maybe<Scalars['Boolean']>;
  zone_rotation?: Maybe<Scalars['Boolean']>;
  banner_id?: Maybe<Scalars['Int']>;
  filename?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  cmp_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['String']>;
  md5_hash?: Maybe<Scalars['String']>;
}

export interface IxsBannerConfig {
  category?: Maybe<Scalars['String']>;
  zone_name?: Maybe<Scalars['String']>;
  zone_id?: Maybe<Scalars['String']>;
  area_id?: Maybe<Scalars['Int']>;
  region_id?: Maybe<Scalars['Int']>;
}

export interface IxsBannerConfigResponse {
  ixs_banners?: Maybe<Array<Maybe<IxsBannerConfig>>>;
}


export interface LastAndNextBumps {
  lastBump?: Maybe<BumpsHistoryRecord>;
  nextBump?: Maybe<NextBump>;
}

export interface LastApprovedPhoto {
  _id: Scalars['String'];
  profile?: Maybe<Profile>;
  last_approved_photos?: Maybe<Array<ProfilePhoto>>;
}

export interface LotteryActiveDates {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface LotteryConfig {
  account_roles: Array<Maybe<AccountRole>>;
  active_dates: LotteryActiveDates;
  offer_active_dates: LotteryOfferActiveDates;
  prizes: Array<Maybe<LotteryPrizeType>>;
  service_extend_rules: LotteryServiceExtendRules;
  package_extend_active_dates: LotteryPackageExtendActiveDates;
}

export interface LotteryOfferActiveDates {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface LotteryOfferMarkSeenResult {
  result: Scalars['Boolean'];
}

export interface LotteryOfferResult {
  account_id: Scalars['Int'];
  is_seen: Scalars['Boolean'];
  date_seen: Scalars['Date'];
}

export interface LotteryPackageExtendActiveDates {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export enum LotteryPrizeType {
  ExtraBumpCredits = 'EXTRA_BUMP_CREDITS',
  Gotd = 'GOTD',
  Gotds = 'GOTDS',
  ExtraPackageDay = 'EXTRA_PACKAGE_DAY'
}

export interface LotteryServiceExtendRule {
  base_duration: Scalars['Int'];
  extend_for: Scalars['Int'];
}

export interface LotteryServiceExtendRules {
  diamond: Array<Maybe<LotteryServiceExtendRule>>;
  gold: Array<Maybe<LotteryServiceExtendRule>>;
  silver: Array<Maybe<LotteryServiceExtendRule>>;
  bronze: Array<Maybe<LotteryServiceExtendRule>>;
}

export interface LotteryWinnerEvent {
  account_id: Scalars['Int'];
  date_created: Scalars['Date'];
  login_number: Scalars['Int'];
  prize: LotteryPrizeType;
  is_seen: Scalars['Boolean'];
}

export interface LotteryWinnerMarkSeenResult {
  result: Scalars['Boolean'];
}

export interface MarkOrderWaitingForPaymentResponse {
  result: Scalars['Int'];
  invalid_products?: Maybe<Array<Maybe<InvalidProduct>>>;
}

export interface MarkThreadAsReadResponse {
  result: Scalars['Boolean'];
}

export interface MatchedFraudSourceHighlight {
  first_name?: Maybe<Array<Maybe<Scalars['String']>>>;
  last_name?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_keyword?: Maybe<Array<Maybe<Scalars['String']>>>;
  emails_keyword?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_numbers_keyword?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_number_keyword?: Maybe<Array<Maybe<Scalars['String']>>>;
  website_keyword?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface MatchedIpsCidsFilterInput {
  type?: Maybe<FraudRequestTrackingEvent>;
  ip?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface MatchedIpsCidsResult {
  result?: Maybe<Array<Maybe<FraudRequest>>>;
  count: Scalars['Int'];
}

export interface MediaConfig {
  replaceable_thumbs?: Maybe<Array<Scalars['String']>>;
  fieldname: Scalars['String'];
  file_size: MediaConfigFileSize;
  image_purposes?: Maybe<Array<Scalars['String']>>;
  document_purposes?: Maybe<Array<Scalars['String']>>;
  video_purposes?: Maybe<Array<Scalars['String']>>;
  square_image_width: MediaConfigSquareImage;
  square_image_height: MediaConfigSquareImage;
  portrait_image_width: MediaConfigPortraitImage;
  portrait_image_height: MediaConfigPortraitImage;
  landscape_image_width: MediaConfigLandscapeImage;
  landscape_image_height: MediaConfigLandscapeImage;
  video_width: MediaConfigVideoDimension;
  video_height: MediaConfigVideoDimension;
  video_duration: MediaConfigVideoDuration;
  base_thumb_name: Scalars['String'];
  base_thumb_width: Scalars['Int'];
  base_thumb_height: Scalars['Int'];
  base_thumb_landscape_name: Scalars['String'];
  base_thumb_landscape_width: Scalars['Int'];
  base_thumb_landscape_height: Scalars['Int'];
  thumb_sizes?: Maybe<Array<MediaConfigThumbSize>>;
  image_extensions?: Maybe<Array<Scalars['String']>>;
  video_extensions?: Maybe<Array<Scalars['String']>>;
  document_extensions?: Maybe<Array<Scalars['String']>>;
  document_image_extensions?: Maybe<Array<Scalars['String']>>;
  document_video_extensions?: Maybe<Array<Scalars['String']>>;
  document_non_image_extensions?: Maybe<Array<Scalars['String']>>;
  image_mimetypes?: Maybe<Array<Scalars['String']>>;
  video_mimetypes?: Maybe<Array<Scalars['String']>>;
  document_mimetypes?: Maybe<Array<Scalars['String']>>;
  document_image_mimetypes?: Maybe<Array<Scalars['String']>>;
  document_video_mimetypes?: Maybe<Array<Scalars['String']>>;
  document_non_image_mimetypes?: Maybe<Array<Scalars['String']>>;
}

export interface MediaConfigFileSize {
  image: Scalars['Int'];
  video: Scalars['Int'];
  document: Scalars['Int'];
}

export interface MediaConfigLandscapeImage {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface MediaConfigPortraitImage {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface MediaConfigSquareImage {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface MediaConfigThumbSize {
  name: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
}

export interface MediaConfigVideoDimension {
  min: Scalars['Int'];
}

export interface MediaConfigVideoDuration {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface MediaVideoProcessErrorEvent {
  account_id: Scalars['Int'];
  id: Scalars['String'];
}

export interface MediaVideoProcessFinishedEvent {
  account_id: Scalars['Int'];
  id: Scalars['String'];
}

export interface MediaVideoProgressEvent {
  account_id: Scalars['Int'];
  id: Scalars['String'];
  progress: Scalars['String'];
}

export interface MemberAlert {
  _id: Scalars['String'];
  profile_id: Scalars['String'];
  profile?: Maybe<Profile>;
  account_id: Scalars['Int'];
  events: MemberAlertsEvents;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
}

export interface MemberAlertConfigs {
  alert_max_limit?: Maybe<Scalars['Int']>;
  alert_event_geo_nodes_max_limit?: Maybe<Scalars['Int']>;
  city_alert_max_limit?: Maybe<Scalars['Int']>;
}

export interface MemberAlertEventGeoNodes {
  id: Scalars['Int'];
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
}

export interface MemberAlertEventGeoNodesInput {
  id: Scalars['Int'];
}

export enum MemberAlertEvents {
  Comments = 'COMMENTS',
  ProfileUpdates = 'PROFILE_UPDATES',
  Photos = 'PHOTOS',
  Reviews = 'REVIEWS',
  ProfileHappyHour = 'PROFILE_HAPPY_HOUR',
  GeoNodes = 'GEO_NODES'
}

export interface MemberAlertEventsInput {
  comments: Scalars['Boolean'];
  profile_updates: Scalars['Boolean'];
  photos: Scalars['Boolean'];
  reviews: Scalars['Boolean'];
  profile_happy_hour?: Maybe<Scalars['Boolean']>;
  geo_nodes?: Maybe<Array<Maybe<MemberAlertEventGeoNodesInput>>>;
}

export interface MemberAlertPagination {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<MemberAlert>>>;
}

export interface MemberAlertsEvents {
  comments: Scalars['Boolean'];
  profile_updates: Scalars['Boolean'];
  photos: Scalars['Boolean'];
  reviews: Scalars['Boolean'];
  profile_happy_hour?: Maybe<Scalars['Boolean']>;
  geo_nodes?: Maybe<Array<Maybe<MemberAlertEventGeoNodes>>>;
}

export interface MemberCityAlertEvents {
  escorts: Scalars['Boolean'];
  companies: Scalars['Boolean'];
}

export interface MemberCityAlertEventsInput {
  escorts: Scalars['Boolean'];
  companies: Scalars['Boolean'];
}

export interface MemberComment {
  _id: Scalars['String'];
  entity_id: Scalars['ID'];
  entity_type: CommentEntityType;
  profile?: Maybe<PublicProfile>;
  entity_account_id: Scalars['Int'];
  account_id: Scalars['Int'];
  body: Scalars['String'];
  date_created: Scalars['Date'];
}

export interface MemberFavoriteProfile {
  _id: Scalars['ID'];
  account_id: Scalars['Int'];
  profile_id: Scalars['ID'];
  profile?: Maybe<PublicProfile>;
  comment?: Maybe<Scalars['String']>;
  date_created: Scalars['Date'];
  is_in_top_10?: Maybe<Scalars['Boolean']>;
}

export interface MemberFavoriteProfileAddToTopTenResponse {
  result: Scalars['Boolean'];
}

export interface MemberFavoriteProfileApproveTopTenCommentResponse {
  result: Scalars['Boolean'];
}

export interface MemberFavoriteProfileCommentConfig {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
}

export interface MemberFavoriteProfileConfigs {
  max_favorite_profiles_count: Scalars['Int'];
  profile_types?: Maybe<Array<MemberFavoriteProfileTypes>>;
  comment?: Maybe<MemberFavoriteProfileCommentConfig>;
}

export interface MemberFavoriteProfileCreateResponse {
  id: Scalars['ID'];
}

export interface MemberFavoriteProfileDeclineTopTenCommentResponse {
  result: Scalars['Boolean'];
}

export interface MemberFavoriteProfileDeleteFromTopTenResponse {
  result: Scalars['Boolean'];
}

export interface MemberFavoriteProfileDeleteResponse {
  result: Scalars['Boolean'];
}

export interface MemberFavoriteProfileMoveDownTopTenResponse {
  result: Scalars['Boolean'];
}

export interface MemberFavoriteProfileMoveUpTopTenResponse {
  result: Scalars['Boolean'];
}

export interface MemberFavoriteProfileSetTopTenCommentTypeResponse {
  result: Scalars['Boolean'];
}

export interface MemberFavoriteProfileTopTen {
  _id: Scalars['ID'];
  account_id: Scalars['Int'];
  member_profile?: Maybe<PublicMemberProfile>;
  profile_id: Scalars['ID'];
  profile_reference_id?: Maybe<Scalars['Int']>;
  profile?: Maybe<PublicProfile>;
  comment?: Maybe<Scalars['String']>;
  date_comment_updated?: Maybe<Scalars['Date']>;
  date_created: Scalars['Date'];
  comment_type?: Maybe<MemberFavoriteTopTenCommentTypes>;
  comment_status?: Maybe<MemberFavoriteTopTenCommentStatuses>;
  is_in_top_10?: Maybe<Scalars['Boolean']>;
  top_10_rank: Scalars['Int'];
  comment_requests?: Maybe<Array<Maybe<MemberFavoriteProfileTopTenCommentRequest>>>;
}

export interface MemberFavoriteProfileTopTenCommentRequest {
  _id: Scalars['ID'];
  favorite_id: Scalars['String'];
  owner_account_id: Scalars['Int'];
  requester_account_id: Scalars['Int'];
  requester_profile?: Maybe<PublicMemberProfile>;
  status: MemberFavoriteTopTenCommentRequestStatuses;
  date_created: Scalars['Date'];
}

export interface MemberFavoriteProfileTopTenCommentRequestDeclineResponse {
  result: Scalars['Boolean'];
}

export interface MemberFavoriteProfileTopTenCommentRequestResponse {
  id: Scalars['String'];
}

export interface MemberFavoriteProfileTopTenCommentRequestShareByChatResponse {
  result: Scalars['Boolean'];
}

export interface MemberFavoriteProfileTopTenCommentRequestShareResponse {
  result: Scalars['Boolean'];
}

export interface MemberFavoriteProfileTopTenListResponse {
  result: Array<Maybe<MemberFavoriteProfileTopTen>>;
}

export enum MemberFavoriteProfileTypes {
  CompanyAd = 'COMPANY_AD',
  Ad = 'AD'
}

export interface MemberFavoriteProfileUpdateResponse {
  result: Scalars['Boolean'];
}

export interface MemberFavoriteProfilesResponse {
  count: Scalars['Int'];
  result: Array<Maybe<MemberFavoriteProfile>>;
}

export interface MemberFavoriteProfilesTop10CommentRequestsByFilterResponse {
  result?: Maybe<Array<Maybe<MemberFavoriteProfileTopTenCommentRequest>>>;
}

export enum MemberFavoriteTopTenCommentRequestStatuses {
  Pending = 'PENDING',
  Shared = 'SHARED',
  Declined = 'DECLINED'
}

export enum MemberFavoriteTopTenCommentStatuses {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export enum MemberFavoriteTopTenCommentTypes {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export interface MemberProfile {
  _id: Scalars['String'];
  _meta?: Maybe<MemberProfileMeta>;
  showname?: Maybe<Scalars['String']>;
  area_id?: Maybe<Scalars['Int']>;
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
  about_me?: Maybe<Scalars['String']>;
  photo?: Maybe<MemberProfilePhoto>;
  photos?: Maybe<Array<Maybe<MemberProfilePhoto>>>;
  _changes?: Maybe<MemberProfileChanges>;
  latest_admin_comment?: Maybe<ActivityAlert>;
}

export interface MemberProfileChanges {
  status: ProfileChangeStatus;
  global_status: ProfileChangeGlobalStatus;
  v?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  fields?: Maybe<MemberProfileChangesField>;
}

export interface MemberProfileChangesField {
  showname?: Maybe<Array<Maybe<MemberProfileChangesFieldScalar>>>;
  about_me?: Maybe<Array<Maybe<MemberProfileChangesFieldScalar>>>;
}

export interface MemberProfileChangesFieldScalar {
  value?: Maybe<MemberProfileChangesFieldScalarValue>;
  status?: Maybe<ProfileChangeFieldStatus>;
  date_changed?: Maybe<Scalars['Date']>;
  changed_by?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface MemberProfileChangesFieldScalarValue {
  value?: Maybe<Scalars['String']>;
}

export interface MemberProfileCreateResponse {
  id: Scalars['String'];
}

export interface MemberProfileInput {
  showname: Scalars['String'];
  area_id?: Maybe<Scalars['Int']>;
  about_me?: Maybe<Scalars['String']>;
}

export interface MemberProfileIsSuspiciousSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface MemberProfileIsSuspiciousUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface MemberProfileIsTrustedSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface MemberProfileIsTrustedUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface MemberProfileLastTopicFirstPost {
  post?: Maybe<ForumPost>;
  topic_slug?: Maybe<Scalars['String']>;
}

export interface MemberProfileMeta {
  reference_id: Scalars['Int'];
  account_id: Scalars['Int'];
  account?: Maybe<Account>;
  ops_account_id?: Maybe<Scalars['Int']>;
  type: ProfileMetaType;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
  date_deleted?: Maybe<Scalars['Date']>;
  is_admin_disabled: Scalars['Boolean'];
  date_admin_disabled?: Maybe<Scalars['Date']>;
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
  rating?: Maybe<ProfileMemberRating>;
  rating_changed_from_cellar?: Maybe<Scalars['Boolean']>;
  date_rating_changed_from_cellar?: Maybe<Scalars['Date']>;
  rating_changed_from_cellar_by?: Maybe<Scalars['Int']>;
  is_trusted?: Maybe<Scalars['Boolean']>;
  is_suspicious?: Maybe<Scalars['Boolean']>;
  old_reference_id?: Maybe<Scalars['Int']>;
}

export interface MemberProfilePendingPhoto {
  _id: Scalars['String'];
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  member_profile?: Maybe<MemberProfile>;
  image_id: Scalars['String'];
  image?: Maybe<Image>;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
  status: ProfileMemberPhotoStatus;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface MemberProfilePhoto {
  _id: Scalars['String'];
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  image_id: Scalars['String'];
  image?: Maybe<Image>;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
  status: ProfileMemberPhotoStatus;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface MemberProfilePhotoApproveResponse {
  result: Scalars['Boolean'];
}

export interface MemberProfilePhotoCreateResponse {
  id: Scalars['String'];
}

export interface MemberProfilePhotoDeclineResponse {
  result: Scalars['Boolean'];
}

export interface MemberProfilePhotoDeleteResponse {
  result: Scalars['Boolean'];
}

export interface MemberProfilePhotoUpdateResponse {
  result: Scalars['Boolean'];
}

export interface MemberProfileRatingUpdateResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface MemberProfileUpdateResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface MessengerAvailabilityResponse {
  is_disabled: Scalars['Boolean'];
  is_connected: Scalars['Boolean'];
  is_online: Scalars['Boolean'];
  is_admin_disabled: Scalars['Boolean'];
  is_available_for_chat?: Maybe<Scalars['Boolean']>;
}

export interface MessengerConfigResponse {
  body?: Maybe<MessengerConfigResponseBody>;
}

export interface MessengerConfigResponseBody {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface MessengerEventPayload {
  account: MessengerOnlineAccount;
}

export interface MessengerMessage {
  _id: Scalars['String'];
  thread_id: Scalars['String'];
  from_profile_id: Scalars['String'];
  date_created: Scalars['Date'];
  body: Scalars['String'];
}

export interface MessengerMessageCreatedResponse {
  account_id?: Maybe<Scalars['Int']>;
  thread: MessengerThread;
}

export interface MessengerMessagesResponse {
  messages: Array<Maybe<MessengerMessage>>;
}

export interface MessengerOnlineAccount {
  account_id: Scalars['Int'];
}

export interface MessengerOnlineAccountCompany extends MessengerOnlineAccount {
  account_id: Scalars['Int'];
  profile_id?: Maybe<Scalars['String']>;
  profile?: Maybe<PublicCompanyProfile>;
}

export interface MessengerOnlineAccountEscort extends MessengerOnlineAccount {
  account_id: Scalars['Int'];
  profile_id?: Maybe<Scalars['String']>;
  profile?: Maybe<SimpleProfile>;
}

export interface MessengerOnlineAccountMember extends MessengerOnlineAccount {
  account_id: Scalars['Int'];
  profile_id?: Maybe<Scalars['String']>;
  profile?: Maybe<PublicMemberProfile>;
}

export enum MessengerParticipantType {
  Company = 'COMPANY',
  Member = 'MEMBER',
  Ad = 'AD',
  CompanyAd = 'COMPANY_AD'
}

export interface MessengerThread {
  _id: Scalars['String'];
  participants: Array<MessengerThreadParticipant>;
  last_message?: Maybe<MessengerMessage>;
  date_created: Scalars['Date'];
}

export interface MessengerThreadParticipant {
  account_id: Scalars['Int'];
}

export interface MessengerThreadParticipantAd extends MessengerThreadParticipant {
  account_id: Scalars['Int'];
  type: MessengerParticipantType;
  profile_id: Scalars['String'];
  profile?: Maybe<SimpleProfile>;
  deleted_until?: Maybe<Scalars['String']>;
  is_blocked: Scalars['Boolean'];
  is_favorite: Scalars['Boolean'];
  unread_count: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
}

export interface MessengerThreadParticipantCompany extends MessengerThreadParticipant {
  account_id: Scalars['Int'];
  type: MessengerParticipantType;
  profile_id: Scalars['String'];
  profile?: Maybe<PublicCompanyProfile>;
  deleted_until?: Maybe<Scalars['String']>;
  is_blocked: Scalars['Boolean'];
  is_favorite: Scalars['Boolean'];
  unread_count: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
}

export interface MessengerThreadParticipantMember extends MessengerThreadParticipant {
  account_id: Scalars['Int'];
  profile?: Maybe<PublicMemberProfile>;
  type: MessengerParticipantType;
  profile_id: Scalars['String'];
  deleted_until?: Maybe<Scalars['String']>;
  is_blocked: Scalars['Boolean'];
  is_favorite: Scalars['Boolean'];
  unread_count: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
}

export interface MessengerThreadParticipantSelf extends MessengerThreadParticipant {
  account_id: Scalars['Int'];
  type: MessengerParticipantType;
  profile_id: Scalars['String'];
  deleted_until?: Maybe<Scalars['String']>;
  is_blocked: Scalars['Boolean'];
  is_favorite: Scalars['Boolean'];
  unread_count: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
}

export interface MessengerThreadsResponse {
  threads: Array<Maybe<MessengerThread>>;
}

export interface MoveProfileData {
  profile_reference_id: Scalars['Int'];
  to_account_id?: Maybe<Scalars['Int']>;
}

export interface MoveProfileOpsData {
  profile_reference_id: Scalars['Int'];
  to_account_id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone_number?: Maybe<MoveProfilePhoneNumber>;
}

export interface MoveProfilePhoneNumber {
  id: Scalars['String'];
  country_code: Scalars['String'];
  number: Scalars['String'];
}

export interface MoveProfileResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface MoveSmsesFromTrashResponse {
  result: Scalars['Boolean'];
}

export interface MoveSmsesToTrashResponse {
  result: Scalars['Boolean'];
}

export interface MoverProfileMovedResponse {
  from_account_id: Scalars['Int'];
  to_account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  profile_reference_id: Scalars['Int'];
}

export interface Mutation {
  my?: Maybe<AccountMutation>;
  public?: Maybe<PublicMutation>;
  ops?: Maybe<OpsMutation>;
}

export interface MutationResponse {
  result: Scalars['Boolean'];
}

export interface NewsletterCreateResponse {
  id: Scalars['ID'];
}

export interface NewsletterGroup {
  segment_id: Scalars['Int'];
  segment_name: Scalars['String'];
}

export interface NewsletterMutationResponse {
  result: Scalars['Boolean'];
}

export interface NewsletterPrototype {
  _id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  created_by: Scalars['Int'];
  date_created: Scalars['Date'];
  modified_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
}

export interface NewsletterSendTemplateData {
  escorts?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tour_escorts?: Maybe<Array<Maybe<Scalars['Int']>>>;
  editable_text?: Maybe<Scalars['String']>;
  editable_slug?: Maybe<Scalars['String']>;
}

export interface NewsletterSendTemplateInput {
  time: Scalars['Date'];
  template_id: Scalars['ID'];
  from_email?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  from_name?: Maybe<Scalars['String']>;
  template_data?: Maybe<NewsletterSendTemplateData>;
  groups: Array<Scalars['Int']>;
}

export interface NewsletterSendTemplateResponse {
  is_newsletter_scheduled: Scalars['Boolean'];
  is_newsletter_confirmation_sent: Scalars['Boolean'];
}

export interface NewsletterTemplate {
  _id: Scalars['ID'];
  title: Scalars['String'];
  from_email: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  body_plain?: Maybe<Scalars['String']>;
  can_be_constructed: Scalars['Boolean'];
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
}

export interface NewsletterTemplateInput {
  title: Scalars['String'];
  from_email: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  body_plain?: Maybe<Scalars['String']>;
  can_be_constructed: Scalars['Boolean'];
}

export interface NextBump {
  _id?: Maybe<Scalars['String']>;
  date_of_next_bumped?: Maybe<Scalars['Date']>;
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  city_id: Scalars['Int'];
}

export interface NotActiveAccountsInput {
  email?: Maybe<Scalars['String']>;
  status?: Maybe<NotActiveStatus>;
  role?: Maybe<PublicRole>;
  username?: Maybe<Scalars['String']>;
}

export enum NotActiveStatus {
  NotVerified = 'NOT_VERIFIED',
  Disabled = 'DISABLED',
  Deleted = 'DELETED'
}

export interface NotificationConfigs {
  sms: NotificationSmsConfigs;
}

export interface NotificationContact {
  id: Scalars['Int'];
  group_id?: Maybe<Scalars['Int']>;
  group?: Maybe<NotificationContactGroup>;
  contact_times: Scalars['Int'];
  is_blacklisted?: Maybe<Scalars['Int']>;
  phone: Scalars['String'];
  status: ContactState;
  created_by: Scalars['Int'];
  date_created: Scalars['Date'];
  modified_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
}

export interface NotificationContactDetailsResponse {
  sales: Array<Maybe<OpsAccountPublic>>;
  emails: Array<Maybe<NotificationSalesContactDetails>>;
}

export interface NotificationContactGroup {
  id: Scalars['Int'];
  name: Scalars['String'];
  contacts_count: Scalars['Int'];
  created_by: Scalars['Int'];
  date_created: Scalars['Date'];
  modified_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
}

export interface NotificationContactResponse {
  count: Scalars['Int'];
  result: Array<Maybe<NotificationContact>>;
}

export interface NotificationCreateMessageResponse {
  result: Array<Scalars['ID']>;
}

export interface NotificationEmailInput {
  account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['Int']>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}

export interface NotificationEmailResponse {
  result: Array<Maybe<NotificationEmailResponseData>>;
  count: Scalars['Int'];
}

export interface NotificationEmailResponseData {
  _id: Scalars['String'];
  status: NotificationEmailStatus;
  email?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  date_created?: Maybe<Scalars['Date']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['String']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
}

export enum NotificationEmailStatus {
  Pending = 'PENDING',
  SendingFailed = 'SENDING_FAILED',
  Sent = 'SENT'
}

export interface NotificationMessage {
  _id: Scalars['ID'];
  account_id: Scalars['Int'];
  message: Scalars['String'];
  status: NotificationMessageStatus;
  date_created: Scalars['Date'];
  date_seen?: Maybe<Scalars['Date']>;
}

export enum NotificationMessageStatus {
  Unseen = 'UNSEEN',
  Seen = 'SEEN'
}

export interface NotificationMessageStatusSeenResponse {
  result: Scalars['Boolean'];
}

export interface NotificationSmsConfigs {
  originator: Scalars['String'];
}

export interface NotificationSalesContactDetails {
  showname: Scalars['String'];
  email: Scalars['String'];
}

export interface NotificationSendContactEmailResult {
  result: Scalars['Boolean'];
}

export interface NotificationSms {
  _id: Scalars['String'];
  status: NotificationSmsStatus;
  type: NotificationSmsType;
  international_phone_number: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  is_read: Scalars['Boolean'];
  is_deleted: Scalars['Boolean'];
  is_in_trash: Scalars['Boolean'];
  is_replied?: Maybe<Scalars['Boolean']>;
  date_created: Scalars['Date'];
  date_delivered?: Maybe<Scalars['Date']>;
  profile_account_id?: Maybe<Scalars['Int']>;
  profile_ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<ProfileMetaType>;
  profile_id?: Maybe<Scalars['String']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  group_key: Scalars['String'];
  for_review: Scalars['Boolean'];
}

export interface NotificationSmsInboxInput {
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  for_review?: Maybe<Scalars['Boolean']>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}

export interface NotificationSmsInboxResponse {
  result: Array<Maybe<NotificationSmsInboxResponseData>>;
  count: Scalars['Int'];
}

export interface NotificationSmsInboxResponseData {
  sms_id?: Maybe<Scalars['String']>;
  status: NotificationSmsStatus;
  type: NotificationSmsType;
  international_phone_number: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  is_read?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_in_trash?: Maybe<Scalars['Boolean']>;
  is_replied?: Maybe<Scalars['Boolean']>;
  date_created: Scalars['Date'];
  profile_account_id?: Maybe<Scalars['Int']>;
  profile_ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<ProfileMetaType>;
  profile_id?: Maybe<Scalars['String']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  group_key: Scalars['String'];
  for_review: Scalars['Boolean'];
}

export interface NotificationSmsOutboxInput {
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  status?: Maybe<NotificationSmsStatus>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  for_review?: Maybe<Scalars['Boolean']>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}

export interface NotificationSmsOutboxResponse {
  result: Array<Maybe<NotificationSmsOutboxResponseData>>;
  count: Scalars['Int'];
}

export interface NotificationSmsOutboxResponseData {
  _id?: Maybe<Scalars['String']>;
  status: NotificationSmsStatus;
  type: NotificationSmsType;
  international_phone_number: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  is_read?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_in_trash?: Maybe<Scalars['Boolean']>;
  date_created: Scalars['Date'];
  date_delivered?: Maybe<Scalars['Date']>;
  profile_account_id?: Maybe<Scalars['Int']>;
  profile_ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<ProfileMetaType>;
  profile_id?: Maybe<Scalars['String']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  group_key: Scalars['String'];
  for_review: Scalars['Boolean'];
}

export enum NotificationSmsStatus {
  Pending = 'PENDING',
  Sent = 'SENT',
  SendingFailed = 'SENDING_FAILED',
  DeliveryFailed = 'DELIVERY_FAILED',
  Delivered = 'DELIVERED',
  Buffered = 'BUFFERED',
  Received = 'RECEIVED'
}

export enum NotificationSmsType {
  Outbox = 'OUTBOX',
  Inbox = 'INBOX'
}

export interface OneHundredVerificationFilterInput {
  ops_account_id?: Maybe<Scalars['Int']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  status?: Maybe<ProfileVerificationStatuses>;
  need_additional_documents?: Maybe<Scalars['Boolean']>;
  unaltered_photos_required?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['String']>;
  id_number?: Maybe<Scalars['String']>;
  date_filter_by?: Maybe<ProfileVerificationDateFilterType>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export interface OneHundredVerificationsResponse {
  count: Scalars['Int'];
  result: Array<Maybe<ProfileVerification>>;
}

export interface OneTimeBumpSchedule {
  _id?: Maybe<Scalars['String']>;
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  city_id: Scalars['Int'];
  geo_node?: Maybe<GeoNode>;
  execution_date: Scalars['Date'];
  type: BumpScheduleType;
}

export interface OneTimeBumpScheduleCreateResponse {
  ids: Array<Scalars['String']>;
}

export interface OnlineNews {
  _id: Scalars['ID'];
  de: OnlineNewsTitleBody;
  en: OnlineNewsTitleBody;
  es: OnlineNewsTitleBody;
  fr: OnlineNewsTitleBody;
  ro: OnlineNewsTitleBody;
  it: OnlineNewsTitleBody;
  hu: OnlineNewsTitleBody;
  status?: Maybe<OnlineNewsStatus>;
  activation_type?: Maybe<OnlineNewsActivationType>;
  start_date?: Maybe<Scalars['Date']>;
  end_date: Scalars['Date'];
  is_disabled: Scalars['Boolean'];
}

export enum OnlineNewsActivationType {
  Immediately = 'IMMEDIATELY',
  AtExactDay = 'AT_EXACT_DAY'
}

export interface OnlineNewsFilter {
  is_disabled?: Maybe<Scalars['Boolean']>;
  activation_type?: Maybe<OnlineNewsActivationType>;
  status?: Maybe<OnlineNewsStatus>;
}

export interface OnlineNewsInput {
  title: OnlineNewsLanguageInput;
  body: OnlineNewsLanguageInput;
  start_date?: Maybe<Scalars['Date']>;
  end_date: Scalars['Date'];
  activation_type?: Maybe<OnlineNewsActivationType>;
}

export interface OnlineNewsLanguageInput {
  de: Scalars['String'];
  en: Scalars['String'];
  es: Scalars['String'];
  fr: Scalars['String'];
  ro: Scalars['String'];
  it: Scalars['String'];
  hu: Scalars['String'];
}

export interface OnlineNewsResponse {
  result: Array<Maybe<OnlineNews>>;
  count: Scalars['Int'];
}

export enum OnlineNewsStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Expired = 'EXPIRED'
}

export interface OnlineNewsTitleBody {
  title: Scalars['String'];
  body: Scalars['String'];
}

export interface OpsAccount {
  id: Scalars['Int'];
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  status: AccountStatus;
  role: OpsRole;
  showname: Scalars['String'];
  skype?: Maybe<OpsAccountSkype>;
  ops_contact_data?: Maybe<AccountOpsContactData>;
  date_created: Scalars['Date'];
  last_active_date?: Maybe<Scalars['Date']>;
}

export interface OpsAccountPublic {
  id: Scalars['Int'];
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  role: OpsRole;
  showname: Scalars['String'];
  ops_contact_data?: Maybe<AccountOpsContactData>;
  last_active_date?: Maybe<Scalars['Date']>;
}

export interface OpsAccountSkype {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<AccountSkypeStatus>;
}

export interface OpsListInput {
  roles?: Maybe<Array<Maybe<OpsRole>>>;
  status?: Maybe<AccountStatus>;
}

export interface OpsMutation {
  updateComment?: Maybe<CommentUpdateResponse>;
  approveComment?: Maybe<CommentsApproveResponse>;
  declineComment?: Maybe<CommentsDeclineResponse>;
  deleteComment?: Maybe<CommentsDeleteResponse>;
  restoreComment?: Maybe<CommentsRestoreResponse>;
  createSupportTicket?: Maybe<SupportCreateTicketResponse>;
  createSupportTicketComment?: Maybe<SupportCreateCommentResponse>;
  deleteSupportTicket?: Maybe<SupportTicketBooleanResponse>;
  restoreSupportTicket?: Maybe<SupportTicketBooleanResponse>;
  disableSupportTicket?: Maybe<SupportTicketBooleanResponse>;
  enableSupportTicket?: Maybe<SupportTicketBooleanResponse>;
  closeSupportTicket?: Maybe<SupportTicketBooleanResponse>;
  openSupportTicket?: Maybe<SupportTicketBooleanResponse>;
  setSupportTicketProgressStatus?: Maybe<SupportTicketBooleanResponse>;
  changeSupportTicketOpsAccount?: Maybe<SupportTicketBooleanResponse>;
  setSupportProblemReportChecked?: Maybe<SupportProblemReportBooleanResponse>;
  deleteSupportProblemReport?: Maybe<SupportProblemReportBooleanResponse>;
  replySupportProblemReport?: Maybe<SupportProblemReportBooleanResponse>;
  setSuspiciousPhotoRequestUnderInvestigation?: Maybe<SupportSuspiciousPhotoRequestSetStatusResponse>;
  setSuspiciousPhotoRequestSuspicious?: Maybe<SupportSuspiciousPhotoRequestSetStatusResponse>;
  setSuspiciousPhotoRequestNotSuspicious?: Maybe<SupportSuspiciousPhotoRequestSetStatusResponse>;
  setSuspiciousPhotoRequestUnknown?: Maybe<SupportSuspiciousPhotoRequestSetStatusResponse>;
  supportFeedbackApproveAndSend?: Maybe<SupportFeedbackSetStatusResponse>;
  supportFeedbackDecline?: Maybe<SupportFeedbackSetStatusResponse>;
  processCompanyProfile?: Maybe<CompanyProfileProcessResponse>;
  disableCompanyProfileComments?: Maybe<CompanyProfileCommentsDisableResponse>;
  enableCompanyProfileComments?: Maybe<CompanyProfileCommentsEnableResponse>;
  disableCompanyProfileReviews?: Maybe<CompanyProfileReviewsDisableResponse>;
  enableCompanyProfileReviews?: Maybe<CompanyProfileReviewsEnableResponse>;
  updateCompanyProfileWebsiteStatus?: Maybe<CompanyProfileUpdateWebsiteStatusResponse>;
  setCompanyProfileIsApproved?: Maybe<CompanyProfileIsApprovedSetResponse>;
  unsetCompanyProfileIsApproved?: Maybe<CompanyProfileIsApprovedUnsetResponse>;
  setCompanyProfileIsContactInfoHidden?: Maybe<CompanyProfileIsContactInfoHiddenSetResponse>;
  unsetCompanyProfileIsContactInfoHidden?: Maybe<CompanyProfileIsContactInfoHiddenUnsetResponse>;
  setCompanyProfileIsPremium?: Maybe<CompanyProfileIsPremiumSetResponse>;
  unsetCompanyProfileIsPremium?: Maybe<CompanyProfileIsPremiumUnsetResponse>;
  setCompanyProfileShowInClubDirectory?: Maybe<CompanyProfileShowInClubDirectorySetResponse>;
  unsetCompanyProfileShowInClubDirectory?: Maybe<CompanyProfileShowInClubDirectoryUnsetResponse>;
  setCompanyProfileIsCompanyHidden?: Maybe<CompanyProfileIsCompanyHiddenSetResponse>;
  unsetCompanyProfileIsCompanyHidden?: Maybe<CompanyProfileIsCompanyHiddenUnsetResponse>;
  approveCompanyProfilePhotos?: Maybe<CompanyProfilePhotoApproveResponse>;
  declineCompanyProfilePhotos?: Maybe<CompanyProfilePhotoDeclineResponse>;
  restoreCompanyProfilePhotos?: Maybe<CompanyProfilePhotoRestoreResponse>;
  processProfile?: Maybe<ProfileProcessResponse>;
  disableProfile?: Maybe<ProfileDisableResponse>;
  enableProfile?: Maybe<ProfileEnableResponse>;
  deleteProfile?: Maybe<ProfileDeleteResponse>;
  restoreProfile?: Maybe<ProfileRestoreResponse>;
  adminDisableProfile?: Maybe<ProfileAdminDisableResponse>;
  adminEnableProfile?: Maybe<ProfileAdminEnableResponse>;
  setProfilePseudoEscort?: Maybe<ProfilePseudoEscortSetResponse>;
  unsetProfilePseudoEscort?: Maybe<ProfilePseudoEscortUnsetResponse>;
  disableProfileComments?: Maybe<ProfileCommentsDisableResponse>;
  enableProfileComments?: Maybe<ProfileCommentsEnableResponse>;
  disableProfileReviews?: Maybe<ProfileReviewsDisableResponse>;
  enableProfileReviews?: Maybe<ProfileReviewsEnableResponse>;
  enableProfilePeriodicBumps?: Maybe<ProfilePeriodicBumpsEnableResponse>;
  disableProfilePeriodicBumps?: Maybe<ProfilePeriodicBumpsDisableResponse>;
  setProfileKeep100Verification?: Maybe<ProfileKeepVerificationSetResponse>;
  unsetProfileKeep100Verification?: Maybe<ProfileKeepVerificationUnsetResponse>;
  setProfileAgeVerificationKeepOnline?: Maybe<ProfileAgeVerificationKeepOnlineSetResponse>;
  unsetProfileAgeVerificationKeepOnline?: Maybe<ProfileAgeVerificationKeepOnlineUnsetResponse>;
  setProfileMetaNoNew?: Maybe<ProfileAgeVerificationKeepOnlineSetResponse>;
  unsetProfileMetaNoNew?: Maybe<ProfileAgeVerificationKeepOnlineSetResponse>;
  setProfileShowBadPhotoHistoryIcon?: Maybe<ProfileShowBadHistoryIconSetResponse>;
  unsetProfileShowBadPhotoHistoryIcon?: Maybe<ProfileShowBadHistoryIconUnsetResponse>;
  setProfilePhotosSuspicious?: Maybe<ProfilePhotosSuspiciousSetResponse>;
  unsetProfilePhotosSuspicious?: Maybe<ProfilePhotosSuspiciousUnsetResponse>;
  setProfileUnderInvestigation?: Maybe<ProfileUnderInvestigationSetResponse>;
  unsetProfileUnderInvestigation?: Maybe<ProfileUnderInvestigationUnsetResponse>;
  setProfileVip?: Maybe<ProfileVipSetResponse>;
  unsetProfileVip?: Maybe<ProfileVipUnsetResponse>;
  updateProfileWebsiteStatus?: Maybe<ProfileUpdateWebsiteStatusResponse>;
  disableProfileEscortbook?: Maybe<ProfileEscortbookDisableResponse>;
  enableProfileEscortbook?: Maybe<ProfileEscortbookEnableResponse>;
  disableProfileCamplace?: Maybe<ProfileCamplaceDisableResponse>;
  enableProfileCamplace?: Maybe<ProfileCamplaceEnableResponse>;
  setProfileSmsNotifyPackageExpiration?: Maybe<ProfileSmsNotifyPackageExpirationSetResponse>;
  unsetProfileSmsNotifyPackageExpiration?: Maybe<ProfileSmsNotifyPackageExpirationUnsetResponse>;
  setProfileSmsNotifyProfileOnline?: Maybe<ProfileSmsNotifyProfileOnlineSetResponse>;
  unsetProfileSmsNotifyProfileOnline?: Maybe<ProfileSmsNotifyProfileOnlineUnsetResponse>;
  reIndexOnlineProfiles?: Maybe<ProfileReindexOnlineProfilesResponse>;
  approveProfilePhotos?: Maybe<ProfilePhotoApproveResponse>;
  declineProfilePhotos?: Maybe<ProfilePhotoDeclineResponse>;
  restoreProfilePhotos?: Maybe<ProfilePhotoRestoreResponse>;
  setProfilePhotosToRetouch?: Maybe<ProfilePhotoRetouchResponse>;
  unsetProfilePhotosToRetouch?: Maybe<ProfilePhotoUnsetRetouchResponse>;
  approveProfileVideos?: Maybe<ProfileVideoApproveResponse>;
  declineProfileVideos?: Maybe<ProfileVideoDeclineResponse>;
  restoreProfileVideos?: Maybe<ProfileVideoRestoreResponse>;
  approveProfileVideoCovers?: Maybe<ProfileVideoCoverApproveResponse>;
  declineProfileVideoCovers?: Maybe<ProfileVideoCoverDeclineResponse>;
  setMemberProfileIsSuspicious?: Maybe<MemberProfileIsSuspiciousSetResponse>;
  unsetMemberProfileIsSuspicious?: Maybe<MemberProfileIsSuspiciousUnsetResponse>;
  setMemberProfileIsTrusted?: Maybe<MemberProfileIsTrustedSetResponse>;
  unsetMemberProfileIsTrusted?: Maybe<MemberProfileIsTrustedUnsetResponse>;
  updateMemberProfileRating?: Maybe<MemberProfileRatingUpdateResponse>;
  approveMemberProfilePhoto?: Maybe<MemberProfilePhotoApproveResponse>;
  declineMemberProfilePhoto?: Maybe<MemberProfilePhotoDeclineResponse>;
  approveProfileNaturalPhotos?: Maybe<ProfileNaturalPhotoApproveResponse>;
  declineProfileNaturalPhotos?: Maybe<ProfileNaturalPhotoDeclineResponse>;
  restoreProfileNaturalPhotos?: Maybe<ProfileNaturalPhotoRestoreResponse>;
  storyApprove?: Maybe<StoryIdsResponseWithWarning>;
  storyDecline?: Maybe<Array<Maybe<StoryIdResponse>>>;
  storyPublish?: Maybe<StoryIdsResponseWithWarning>;
  storyMute?: Maybe<Array<Maybe<StoryIdResponse>>>;
  storyUnmute?: Maybe<Array<Maybe<StoryIdResponse>>>;
  storyUpdateCaption?: Maybe<Array<Maybe<StoryIdResponse>>>;
  storyDelete?: Maybe<Array<Maybe<StoryIdResponse>>>;
  storyShareAgain?: Maybe<Story>;
  storyRestore?: Maybe<Story>;
  changeVisibilityHappyHour?: Maybe<ProfileHappyHourVisibilityResponse>;
  setGotmWinner?: Maybe<ProfileGotmSetWinnerResponse>;
  addGotmVotes?: Maybe<ProfileGotmAddVotesResponse>;
  cancelBaseService?: Maybe<ProductManagerMutationResponse>;
  cancelGotdService?: Maybe<ProductManagerMutationResponse>;
  cancelVotdService?: Maybe<ProductManagerMutationResponse>;
  cancelClassifiedAdService?: Maybe<ProductManagerMutationResponse>;
  updateBaseServiceAdditionalGeoNodes?: Maybe<ProductManagerMutationResponse>;
  reassignBaseService?: Maybe<ProductManagerMutationResponse>;
  updateBaseServiceDates?: Maybe<ProductManagerMutationResponse>;
  updateGotdService?: Maybe<ProductManagerMutationResponse>;
  updateVotdService?: Maybe<ProductManagerMutationResponse>;
  createRecurringBumpSchedule: BumpScheduleCreateResponse;
  updateRecurringBumpSchedule: BumpScheduleUpdateResponse;
  createOneTimeBumpSchedule: OneTimeBumpScheduleCreateResponse;
  createInstantBump: InstantBumpCreateResponse;
  deleteBumpSchedule: BumpScheduleDeleteResponse;
  sendEmail: SendEmailResponse;
  sendEmails: SendEmailsResponse;
  sendSms: SendSmsResponse;
  sendPush: SendPushResponse;
  sendSmses: SendSmsesResponse;
  createNotificationMessage?: Maybe<NotificationCreateMessageResponse>;
  createOnlineNews?: Maybe<CreateOnlineNewsResponse>;
  updateOnlineNews?: Maybe<UpdateOnlineNewsResponse>;
  enableOnlineNews?: Maybe<UpdateOnlineNewsResponse>;
  disableOnlineNews?: Maybe<UpdateOnlineNewsResponse>;
  deleteOnlineNews?: Maybe<DeleteOnlineNewsResponse>;
  createContactGroup?: Maybe<CreateContactGroupResponse>;
  updateContactGroup?: Maybe<UpdateContactGroupResponse>;
  deleteContactGroup?: Maybe<DeleteContactGroupResponse>;
  createContact?: Maybe<CreateContactResponse>;
  updateContact?: Maybe<UpdateContactResponse>;
  deleteContact?: Maybe<DeleteContactResponse>;
  sendSMSesToContacts: SendSmSesToContactsResponse;
  importContacts: ImportContactsResponse;
  createRegularTemplate?: Maybe<CreateRegularTemplateResponse>;
  updateRegularTemplate?: Maybe<RegularTemplateResponseBoolean>;
  removeRegularTemplate?: Maybe<RegularTemplateResponseBoolean>;
  createSystemEmailTemplate?: Maybe<CreateSystemEmailTemplateResponse>;
  createSystemSmsTemplate?: Maybe<CreateSystemSmsTemplateResponse>;
  createSystemPushTemplate?: Maybe<CreateSystemPushTemplateResponse>;
  updateSystemEmailTemplate?: Maybe<SystemEmailTemplateResponseBoolean>;
  updateSystemSmsTemplate?: Maybe<SystemSmsTemplateResponseBoolean>;
  updateSystemPushTemplate?: Maybe<SystemSmsTemplateResponseBoolean>;
  removeSystemEmailTemplate?: Maybe<SystemEmailTemplateResponseBoolean>;
  removeSystemSmsTemplate?: Maybe<SystemSmsTemplateResponseBoolean>;
  removeSystemPushTemplate?: Maybe<SystemPushTemplateResponseBoolean>;
  createActivityTemplate: CreateActivityTemplateResponse;
  updateActivityTemplate?: Maybe<UpdateActivityTemplateResponse>;
  createProfileStatusMessage?: Maybe<ProfileStatusCreateResponse>;
  updateProfileStatusMessage?: Maybe<ProfileStatusUpdateResponse>;
  deleteProfileStatusMessages?: Maybe<ProfileStatusDeleteResponse>;
  declineProfileStatusMessages?: Maybe<ProfileStatusDeclineResponse>;
  approveProfileStatusMessages?: Maybe<ProfileStatusApproveResponse>;
  createProfileReview?: Maybe<ProfileReviewCreateResponse>;
  updateProfileReview?: Maybe<ProfileReviewUpdateResponse>;
  approveProfileReview?: Maybe<ProfileReviewApproveResponse>;
  declineProfileReview?: Maybe<ProfileReviewDeclineResponse>;
  deleteProfileReview?: Maybe<ProfileReviewDeleteResponse>;
  restoreProfileReview?: Maybe<ProfileReviewRestoreResponse>;
  respondProfileReview?: Maybe<ProfileReviewRespondResponse>;
  profileReviewReply?: Maybe<ProfileReviewReplyResponse>;
  setFakeFree?: Maybe<ProfileReviewFakeFreeResponse>;
  unsetFakeFree?: Maybe<ProfileReviewFakeFreeResponse>;
  createFaq?: Maybe<FaqCreateResponse>;
  updateFaq?: Maybe<FaqUpdateResponse>;
  deleteFaq?: Maybe<FaqDeleteResponse>;
  restoreFaq?: Maybe<FaqRestoreResponse>;
  enableFaq?: Maybe<FaqEnableResponse>;
  disableFaq?: Maybe<FaqDisableResponse>;
  approvePayment: PaymentStatusUpdateResponse;
  declinePayment: PaymentStatusUpdateResponse;
  chargebackPayment: PaymentStatusUpdateResponse;
  createPaymentLink: CreatePaymentLinkResponse;
  createManualPayment: CreateManualPaymentResponse;
  deletePaymentLink: DeletePaymentLinkResponse;
  updatePaymentLink: UpdatePaymentLinkResponse;
  addNote: AddNoteResponse;
  updateNote: UpdateNoteResponse;
  deleteNote: DeleteNoteResponse;
  restrictPaymentMethods: RestrictPaymentMethodsResponse;
  createBlacklistedItem?: Maybe<CreateBlacklistedItemResponse>;
  deleteBlacklistedItem?: Maybe<BlacklistedItemResponseBoolean>;
  updateBlacklistedItem?: Maybe<BlacklistedItemResponseBoolean>;
  checkForBlacklist?: Maybe<Array<Maybe<CheckForBlacklistResponse>>>;
  createDiscount?: Maybe<DiscountCreateResponse>;
  updateDiscount?: Maybe<DiscountUpdateResponse>;
  deleteDiscount?: Maybe<DiscountDeleteResponse>;
  disableDiscount?: Maybe<DiscountDisableResponse>;
  enableDiscount?: Maybe<DiscountEnableResponse>;
  updateClassifiedAd?: Maybe<UpdateClassifiedAdResponse>;
  approveClassifiedAd?: Maybe<UpdateClassifiedAdResponse>;
  declineClassifiedAd?: Maybe<UpdateClassifiedAdResponse>;
  removeClassifiedAd?: Maybe<UpdateClassifiedAdResponse>;
  createBlacklistedEntry?: Maybe<BlacklistedEntryCreateResponse>;
  enableBlacklistedEntry?: Maybe<BlacklistedEntryEnableResponse>;
  disableBlacklistedEntry?: Maybe<BlacklistedEntryDisableResponse>;
  updateBlacklistedEntry?: Maybe<BlacklistedEntryUpdateResponse>;
  createBlacklistedClient?: Maybe<BlacklistedClientResponse>;
  updateBlacklistedClient?: Maybe<BlacklistedClientUpdateResponse>;
  deleteBlacklistedClient?: Maybe<BlacklistedClientDeleteResponse>;
  approveBlacklistedClient?: Maybe<BlacklistedClientApproveResponse>;
  declineBlacklistedClient?: Maybe<BlacklistedClientDeclineResponse>;
  profileVerificationPhotosApprove?: Maybe<ProfileVerificationPhotosApproveResponse>;
  profileVerificationPhotosDecline?: Maybe<ProfileVerificationPhotosDeclineResponse>;
  profileVerificationApprove?: Maybe<ProfileVerificationApproveResponse>;
  profileVerificationDecline?: Maybe<ProfileVerificationDeclineResponse>;
  requestUnalteredPhotos?: Maybe<RequestUnalteredPhotosResponse>;
  profileVerificationUpdateIDDocument?: Maybe<ProfileVerificationUpdateIdDocumentResponse>;
  profileVerificationUpdateIDDocumentHistory?: Maybe<ProfileVerificationUpdateIdDocumentResponse>;
  updateVerificationSpecialCase?: Maybe<UpdateVerificationSpecialCaseResponse>;
  dictionaryCreate?: Maybe<DictionaryCreateResponse>;
  dictionaryUpdate?: Maybe<DictionaryUpdateResponse>;
  dictionaryDelete?: Maybe<DictionaryDeleteResponse>;
  createActivityAlert?: Maybe<ActivityAlertCreateResponse>;
  updateActivityAlert?: Maybe<ActivityAlertUpdateResponse>;
  reorderActivityAlerts?: Maybe<ActivityAlertReorderResponse>;
  deleteActivityAlert?: Maybe<ActivityAlertDeleteResponse>;
  createActivityLog?: Maybe<ActivityLogCreateResponse>;
  createSeoRobotText?: Maybe<SeoCreateRobotsTextResponse>;
  updateSeoRobotText: SeoUpdateRobotsTextResponse;
  createSeoTemplate?: Maybe<SeoCreateTemplateResponse>;
  updateSeoTemplate?: Maybe<SeoUpdateTemplateResponse>;
  deleteSeoTemplate?: Maybe<SeoDeleteTemplateResponse>;
  createSeoTemplateInstance?: Maybe<SeoCreateTemplateInstanceResponse>;
  updateSeoTemplateInstance?: Maybe<SeoUpdateTemplateInstanceResponse>;
  deleteSeoTemplateInstance?: Maybe<SeoDeleteTemplateInstanceResponse>;
  setMessengerAdminDisabled: SetMessengerAdminDisabledResponse;
  unsetMessengerAdminDisabled: UnsetMessengerAdminDisabledResponse;
  createTour?: Maybe<ProfileTourCreateResponse>;
  updateTour?: Maybe<ProfileTourUpdateResponse>;
  deleteTour?: Maybe<ProfileTourDeleteResponse>;
  approveMemberFavoriteProfileTop10Comment: MemberFavoriteProfileApproveTopTenCommentResponse;
  declineMemberFavoriteProfileTop10Comment: MemberFavoriteProfileDeclineTopTenCommentResponse;
  sendEmailVerification?: Maybe<SendEmailVerificationResponse>;
  sendPhoneNumberVerification?: Maybe<SendPhoneNumberVerificationResponse>;
  sendCallPhoneNumberVerification?: Maybe<SendPhoneNumberVerificationResponse>;
  verifyPhoneNumber?: Maybe<VerifyPhoneNumberResponse>;
  createNewsletterPrototype: NewsletterCreateResponse;
  updateNewsletterPrototype: NewsletterMutationResponse;
  deleteNewsletterPrototype: NewsletterMutationResponse;
  createNewsletterTemplate: NewsletterCreateResponse;
  updateNewsletterTemplate: NewsletterMutationResponse;
  deleteNewsletterTemplate: NewsletterMutationResponse;
  sendNewsletter: NewsletterSendTemplateResponse;
  opsToolsVacationCreate?: Maybe<OpsToolsVacationCreateResponse>;
  opsToolsVacationUpdate?: Maybe<OpsToolsMutationResponse>;
  opsToolsVacationApprove?: Maybe<OpsToolsMutationResponse>;
  opsToolsVacationRemove?: Maybe<OpsToolsMutationResponse>;
  opsToolsPrivateMessageCreate?: Maybe<OpsToolsPrivateMessageCreateResponse>;
  opsToolsPrivateMessageForward?: Maybe<OpsToolsPrivateMessageCreateResponse>;
  opsToolsMarkPrivateMessageAsRead?: Maybe<OpsToolsMutationResponse>;
  opsToolsPrivateMessageDelete?: Maybe<OpsToolsMutationResponse>;
  rotateDocument?: Maybe<RotateDocumentResponse>;
  moveProfileOps?: Maybe<MoveProfileResponse>;
  createGeoNode?: Maybe<GeoNodeCreateResponse>;
  updateGeoNode?: Maybe<GeoNodeUpdateResponse>;
  moveGeoNode?: Maybe<GeoNodeMoveResponse>;
  deleteGeoNode?: Maybe<GeoNodeDeleteResponse>;
  createDeclineReason?: Maybe<CreateDeclineReasonResponse>;
  updateDeclineReason?: Maybe<DeclineReasonResponseBoolean>;
  enableDeclineReason?: Maybe<DeclineReasonResponseBoolean>;
  disableDeclineReason?: Maybe<DeclineReasonResponseBoolean>;
  deleteDeclineReason?: Maybe<DeclineReasonResponseBoolean>;
  modifyBanner?: Maybe<BannerModifyResponse>;
  bannerChangeStatus?: Maybe<BannerModifyResponse>;
  deletePendingBanner?: Maybe<BannerModifyResponse>;
  updateAccountEmail?: Maybe<UpdateEmailResponse>;
  updateAccountPassword?: Maybe<MutationResponse>;
  updateAccountPasswordByCurrent?: Maybe<MutationResponse>;
  updateAccountOps?: Maybe<MutationResponse>;
  recoverAccount?: Maybe<MutationResponse>;
  deleteAccount?: Maybe<MutationResponse>;
  deleteAccountToken?: Maybe<MutationResponse>;
  disableAccount?: Maybe<MutationResponse>;
  enableAccount?: Maybe<MutationResponse>;
  unblockAccount?: Maybe<MutationResponse>;
  verifyAccount?: Maybe<MutationResponse>;
  createOpsAccount?: Maybe<CreateAccountResponse>;
  updateOpsAccount?: Maybe<MutationResponse>;
  updateAccountPreferences?: Maybe<MutationResponse>;
  updateAccountFlags?: Maybe<MutationResponse>;
  updateAccountAutoapprove?: Maybe<MutationResponse>;
  createAccountUpdateOpsRequest?: Maybe<MutationResponse>;
  deleteAccountUpdateOpsRequest?: Maybe<MutationResponse>;
  approveAccountUpdateOpsRequest?: Maybe<MutationResponse>;
  declineAccountUpdateOpsRequest?: Maybe<MutationResponse>;
  updateOpsContactData?: Maybe<MutationResponse>;
  updateOpsSkypeStatus?: Maybe<MutationResponse>;
  updateAccountEmailForOps?: Maybe<MutationResponse>;
  updateAccountUsernameForOps?: Maybe<MutationResponse>;
  updateLastActiveDate?: Maybe<MutationResponse>;
  updateLinkedAccounts?: Maybe<MutationResponse>;
  deleteEmails?: Maybe<DeleteEmailsResponse>;
  restoreEmails?: Maybe<RestoreEmailsResponse>;
  deleteSmses?: Maybe<DeleteSmsesResponse>;
  restoreSmses?: Maybe<RestoreSmsesResponse>;
  moveSmsesToTrash?: Maybe<MoveSmsesToTrashResponse>;
  moveSmsesFromTrash?: Maybe<MoveSmsesFromTrashResponse>;
  markThreadAsRead: MarkThreadAsReadResponse;
  debitBalance?: Maybe<CommerceMutationResponse>;
  creditBalance?: Maybe<CommerceMutationResponse>;
  applyProductDiscount?: Maybe<CommerceMutationResponse>;
  applyOrderDiscount?: Maybe<CommerceMutationResponse>;
  resetProductDiscount?: Maybe<CommerceMutationResponse>;
  resetOrderDiscount?: Maybe<CommerceMutationResponse>;
  payOrderWithBalance?: Maybe<CommerceMutationResponse>;
  addProduct?: Maybe<AddProductResponse>;
  addProducts?: Maybe<Array<Maybe<AddProductResponse>>>;
  removeProduct?: Maybe<CommerceMutationResponse>;
  markOrderWaitingForPayment?: Maybe<CommerceMutationResponse>;
  checkOrderBeforePayment?: Maybe<CheckOrderBeforePaymentResponse>;
  markOrderInCart?: Maybe<CommerceMutationResponse>;
  deleteOrder?: Maybe<CommerceMutationResponse>;
  dummy?: Maybe<Scalars['String']>;
}


export interface OpsMutationUpdateCommentArgs {
  id: Scalars['String'];
  body: Scalars['String'];
}


export interface OpsMutationApproveCommentArgs {
  id: Scalars['String'];
}


export interface OpsMutationDeclineCommentArgs {
  id: Scalars['String'];
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}


export interface OpsMutationDeleteCommentArgs {
  id: Scalars['String'];
}


export interface OpsMutationRestoreCommentArgs {
  id: Scalars['String'];
}


export interface OpsMutationCreateSupportTicketArgs {
  account_id: Scalars['Int'];
  subject: Scalars['String'];
  message: Scalars['String'];
  attached_files?: Maybe<Array<Maybe<Scalars['ID']>>>;
}


export interface OpsMutationCreateSupportTicketCommentArgs {
  ticket_id: Scalars['ID'];
  comment: Scalars['String'];
  attached_files?: Maybe<Array<Scalars['ID']>>;
}


export interface OpsMutationDeleteSupportTicketArgs {
  ticket_id: Scalars['ID'];
}


export interface OpsMutationRestoreSupportTicketArgs {
  ticket_id: Scalars['ID'];
}


export interface OpsMutationDisableSupportTicketArgs {
  ticket_id: Scalars['ID'];
}


export interface OpsMutationEnableSupportTicketArgs {
  ticket_id: Scalars['ID'];
}


export interface OpsMutationCloseSupportTicketArgs {
  ticket_id: Scalars['ID'];
}


export interface OpsMutationOpenSupportTicketArgs {
  ticket_id: Scalars['ID'];
}


export interface OpsMutationSetSupportTicketProgressStatusArgs {
  ticket_id: Scalars['ID'];
}


export interface OpsMutationChangeSupportTicketOpsAccountArgs {
  ticket_id: Scalars['ID'];
  ops_account_id: Scalars['Int'];
}


export interface OpsMutationSetSupportProblemReportCheckedArgs {
  id: Scalars['ID'];
}


export interface OpsMutationDeleteSupportProblemReportArgs {
  id: Scalars['ID'];
}


export interface OpsMutationReplySupportProblemReportArgs {
  id: Scalars['ID'];
  reply: Scalars['String'];
}


export interface OpsMutationSetSuspiciousPhotoRequestUnderInvestigationArgs {
  id: Scalars['ID'];
}


export interface OpsMutationSetSuspiciousPhotoRequestSuspiciousArgs {
  id: Scalars['ID'];
}


export interface OpsMutationSetSuspiciousPhotoRequestNotSuspiciousArgs {
  id: Scalars['ID'];
}


export interface OpsMutationSetSuspiciousPhotoRequestUnknownArgs {
  id: Scalars['ID'];
}


export interface OpsMutationSupportFeedbackApproveAndSendArgs {
  id: Scalars['ID'];
}


export interface OpsMutationSupportFeedbackDeclineArgs {
  id: Scalars['ID'];
}


export interface OpsMutationProcessCompanyProfileArgs {
  data?: Maybe<CompanyProfileProcessInput>;
  profile_id: Scalars['String'];
}


export interface OpsMutationDisableCompanyProfileCommentsArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationEnableCompanyProfileCommentsArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationDisableCompanyProfileReviewsArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationEnableCompanyProfileReviewsArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUpdateCompanyProfileWebsiteStatusArgs {
  profile_id: Scalars['String'];
  status?: Maybe<ProfileMetaWebsiteStatus>;
}


export interface OpsMutationSetCompanyProfileIsApprovedArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetCompanyProfileIsApprovedArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetCompanyProfileIsContactInfoHiddenArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetCompanyProfileIsContactInfoHiddenArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetCompanyProfileIsPremiumArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetCompanyProfileIsPremiumArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetCompanyProfileShowInClubDirectoryArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetCompanyProfileShowInClubDirectoryArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetCompanyProfileIsCompanyHiddenArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetCompanyProfileIsCompanyHiddenArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationApproveCompanyProfilePhotosArgs {
  profile_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsMutationDeclineCompanyProfilePhotosArgs {
  profile_id: Scalars['String'];
  photos?: Maybe<Array<CompanyProfilePhotoDeclineInput>>;
}


export interface OpsMutationRestoreCompanyProfilePhotosArgs {
  profile_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsMutationProcessProfileArgs {
  data?: Maybe<ProfileProcessInput>;
  profile_id: Scalars['String'];
}


export interface OpsMutationDisableProfileArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationEnableProfileArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationDeleteProfileArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationRestoreProfileArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationAdminDisableProfileArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationAdminEnableProfileArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetProfilePseudoEscortArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetProfilePseudoEscortArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationDisableProfileCommentsArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationEnableProfileCommentsArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationDisableProfileReviewsArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationEnableProfileReviewsArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationEnableProfilePeriodicBumpsArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationDisableProfilePeriodicBumpsArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetProfileKeep100VerificationArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetProfileKeep100VerificationArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetProfileAgeVerificationKeepOnlineArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetProfileAgeVerificationKeepOnlineArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetProfileMetaNoNewArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetProfileMetaNoNewArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetProfileShowBadPhotoHistoryIconArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetProfileShowBadPhotoHistoryIconArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetProfilePhotosSuspiciousArgs {
  profile_id: Scalars['String'];
  suspicious_photos_reason: Scalars['String'];
  show_suspicious_photos_reason: Scalars['Boolean'];
}


export interface OpsMutationUnsetProfilePhotosSuspiciousArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetProfileUnderInvestigationArgs {
  profile_id: Scalars['String'];
  suspicious_photos_reason: Scalars['String'];
  show_suspicious_photos_reason: Scalars['Boolean'];
}


export interface OpsMutationUnsetProfileUnderInvestigationArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetProfileVipArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetProfileVipArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUpdateProfileWebsiteStatusArgs {
  profile_id: Scalars['String'];
  status?: Maybe<ProfileMetaWebsiteStatus>;
}


export interface OpsMutationDisableProfileEscortbookArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationEnableProfileEscortbookArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationDisableProfileCamplaceArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationEnableProfileCamplaceArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetProfileSmsNotifyPackageExpirationArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetProfileSmsNotifyPackageExpirationArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetProfileSmsNotifyProfileOnlineArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetProfileSmsNotifyProfileOnlineArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationApproveProfilePhotosArgs {
  profile_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsMutationDeclineProfilePhotosArgs {
  profile_id: Scalars['String'];
  photos?: Maybe<Array<ProfilePhotoDeclineInput>>;
}


export interface OpsMutationRestoreProfilePhotosArgs {
  profile_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsMutationSetProfilePhotosToRetouchArgs {
  profile_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsMutationUnsetProfilePhotosToRetouchArgs {
  profile_id: Scalars['String'];
  photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsMutationApproveProfileVideosArgs {
  profile_id: Scalars['String'];
  video_ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsMutationDeclineProfileVideosArgs {
  profile_id: Scalars['String'];
  videos?: Maybe<Array<ProfileVideoDeclineInput>>;
}


export interface OpsMutationRestoreProfileVideosArgs {
  profile_id: Scalars['String'];
  video_ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsMutationApproveProfileVideoCoversArgs {
  profile_id: Scalars['String'];
  video_cover_ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsMutationDeclineProfileVideoCoversArgs {
  profile_id: Scalars['String'];
  video_covers?: Maybe<Array<ProfileVideoCoverDeclineInput>>;
}


export interface OpsMutationSetMemberProfileIsSuspiciousArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetMemberProfileIsSuspiciousArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationSetMemberProfileIsTrustedArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUnsetMemberProfileIsTrustedArgs {
  profile_id: Scalars['String'];
}


export interface OpsMutationUpdateMemberProfileRatingArgs {
  profile_id: Scalars['String'];
  rating?: Maybe<ProfileMemberRating>;
}


export interface OpsMutationApproveMemberProfilePhotoArgs {
  profile_id: Scalars['String'];
  photo_id: Scalars['String'];
}


export interface OpsMutationDeclineMemberProfilePhotoArgs {
  profile_id: Scalars['String'];
  photo_id: Scalars['String'];
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}


export interface OpsMutationApproveProfileNaturalPhotosArgs {
  profile_id: Scalars['String'];
  natural_photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsMutationDeclineProfileNaturalPhotosArgs {
  profile_id: Scalars['String'];
  natural_photos?: Maybe<Array<ProfileNaturalPhotoDeclineInput>>;
}


export interface OpsMutationRestoreProfileNaturalPhotosArgs {
  profile_id: Scalars['String'];
  natural_photo_ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsMutationStoryApproveArgs {
  ids: Array<Scalars['ID']>;
}


export interface OpsMutationStoryDeclineArgs {
  data: Array<DeclineStories>;
}


export interface OpsMutationStoryPublishArgs {
  ids: Array<Scalars['ID']>;
}


export interface OpsMutationStoryMuteArgs {
  ids: Array<Scalars['ID']>;
}


export interface OpsMutationStoryUnmuteArgs {
  ids: Array<Scalars['ID']>;
}


export interface OpsMutationStoryUpdateCaptionArgs {
  data: Array<UpdateCaption>;
}


export interface OpsMutationStoryDeleteArgs {
  ids: Array<Scalars['ID']>;
}


export interface OpsMutationStoryShareAgainArgs {
  _id: Scalars['ID'];
}


export interface OpsMutationStoryRestoreArgs {
  _id: Scalars['ID'];
}


export interface OpsMutationChangeVisibilityHappyHourArgs {
  id: Scalars['String'];
  is_visible?: Maybe<Scalars['Boolean']>;
}


export interface OpsMutationSetGotmWinnerArgs {
  profile_id: Scalars['String'];
  date: Scalars['String'];
}


export interface OpsMutationAddGotmVotesArgs {
  profile_id: Scalars['String'];
  count: Scalars['Int'];
}


export interface OpsMutationCancelBaseServiceArgs {
  id: Scalars['Int'];
  reason: Scalars['String'];
}


export interface OpsMutationCancelGotdServiceArgs {
  id: Scalars['Int'];
  reason: Scalars['String'];
}


export interface OpsMutationCancelVotdServiceArgs {
  id: Scalars['Int'];
  reason: Scalars['String'];
}


export interface OpsMutationCancelClassifiedAdServiceArgs {
  id: Scalars['Int'];
  reason: Scalars['String'];
}


export interface OpsMutationUpdateBaseServiceAdditionalGeoNodesArgs {
  id: Scalars['Int'];
  additional_geo_nodes: Array<Maybe<Scalars['Int']>>;
}


export interface OpsMutationReassignBaseServiceArgs {
  id: Scalars['Int'];
  target_ref_id: Scalars['Int'];
}


export interface OpsMutationUpdateBaseServiceDatesArgs {
  id: Scalars['Int'];
  date_activation?: Maybe<Scalars['String']>;
  date_expiration?: Maybe<Scalars['String']>;
}


export interface OpsMutationUpdateGotdServiceArgs {
  id: Scalars['Int'];
  data: UpdateGotdServiceInput;
}


export interface OpsMutationUpdateVotdServiceArgs {
  id: Scalars['Int'];
  data: UpdateVotdServiceInput;
}


export interface OpsMutationCreateRecurringBumpScheduleArgs {
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  city_id: Scalars['Int'];
  weekday: Scalars['Int'];
  frequency: Scalars['Int'];
  status: RecurringBumpScheduleStatus;
  start_time: Scalars['String'];
  end_time: Scalars['String'];
}


export interface OpsMutationUpdateRecurringBumpScheduleArgs {
  schedule_id: Scalars['String'];
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  city_id: Scalars['Int'];
  weekday: Scalars['Int'];
  frequency: Scalars['Int'];
  status: RecurringBumpScheduleStatus;
  start_time: Scalars['String'];
  end_time: Scalars['String'];
}


export interface OpsMutationCreateOneTimeBumpScheduleArgs {
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  city_ids: Array<Scalars['Int']>;
  execution_date: Scalars['Date'];
}


export interface OpsMutationCreateInstantBumpArgs {
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  city_ids: Array<Scalars['Int']>;
}


export interface OpsMutationDeleteBumpScheduleArgs {
  account_id: Scalars['Int'];
  schedule_id: Scalars['String'];
}


export interface OpsMutationSendEmailArgs {
  email: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
}


export interface OpsMutationSendEmailsArgs {
  emails: Array<Scalars['String']>;
  subject: Scalars['String'];
  body: Scalars['String'];
}


export interface OpsMutationSendSmsArgs {
  international_phone_number: Scalars['String'];
  body: Scalars['String'];
  reply_to?: Maybe<Scalars['String']>;
  delivery_date?: Maybe<Scalars['String']>;
}


export interface OpsMutationSendPushArgs {
  account_id: Scalars['Int'];
  body: Scalars['String'];
  heading?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}


export interface OpsMutationSendSmsesArgs {
  international_phone_numbers: Array<Scalars['String']>;
  body: Scalars['String'];
  delivery_date?: Maybe<Scalars['String']>;
}


export interface OpsMutationCreateNotificationMessageArgs {
  message: Scalars['String'];
  account_ids: Array<Scalars['Int']>;
}


export interface OpsMutationCreateOnlineNewsArgs {
  data: OnlineNewsInput;
}


export interface OpsMutationUpdateOnlineNewsArgs {
  id: Scalars['ID'];
  data: OnlineNewsInput;
}


export interface OpsMutationEnableOnlineNewsArgs {
  id: Scalars['ID'];
}


export interface OpsMutationDisableOnlineNewsArgs {
  id?: Maybe<Scalars['ID']>;
}


export interface OpsMutationDeleteOnlineNewsArgs {
  id: Scalars['ID'];
}


export interface OpsMutationCreateContactGroupArgs {
  name: Scalars['String'];
}


export interface OpsMutationUpdateContactGroupArgs {
  id: Scalars['Int'];
  name: Scalars['String'];
}


export interface OpsMutationDeleteContactGroupArgs {
  id: Scalars['Int'];
}


export interface OpsMutationCreateContactArgs {
  phone: Scalars['String'];
  group_id: Scalars['Int'];
}


export interface OpsMutationUpdateContactArgs {
  id: Scalars['Int'];
  phone: Scalars['String'];
  group_id: Scalars['Int'];
}


export interface OpsMutationDeleteContactArgs {
  id: Scalars['Int'];
}


export interface OpsMutationSendSmSesToContactsArgs {
  data: SendSmSesToContactsInput;
}


export interface OpsMutationImportContactsArgs {
  phone_numbers: Scalars['String'];
  group_id?: Maybe<Scalars['Int']>;
  country_code?: Maybe<Scalars['String']>;
}


export interface OpsMutationCreateRegularTemplateArgs {
  type: RegularTemplateType;
  body: Scalars['String'];
  title: Scalars['String'];
}


export interface OpsMutationUpdateRegularTemplateArgs {
  id: Scalars['String'];
  type: RegularTemplateType;
  body: Scalars['String'];
  title: Scalars['String'];
}


export interface OpsMutationRemoveRegularTemplateArgs {
  id: Scalars['String'];
}


export interface OpsMutationCreateSystemEmailTemplateArgs {
  data: CreateSystemEmailTemplateInput;
}


export interface OpsMutationCreateSystemSmsTemplateArgs {
  slug: Scalars['String'];
  body: CreateSystemSmsTemplateBodyInput;
}


export interface OpsMutationCreateSystemPushTemplateArgs {
  slug: Scalars['String'];
  body: CreateSystemPushTemplateBodyInput;
  heading: CreateSystemPushTemplateHeadingInput;
}


export interface OpsMutationUpdateSystemEmailTemplateArgs {
  id: Scalars['String'];
  data: CreateSystemEmailTemplateInput;
}


export interface OpsMutationUpdateSystemSmsTemplateArgs {
  id: Scalars['String'];
  slug: Scalars['String'];
  body: CreateSystemSmsTemplateBodyInput;
}


export interface OpsMutationUpdateSystemPushTemplateArgs {
  id: Scalars['String'];
  slug: Scalars['String'];
  body: CreateSystemPushTemplateBodyInput;
  heading: CreateSystemPushTemplateHeadingInput;
}


export interface OpsMutationRemoveSystemEmailTemplateArgs {
  id: Scalars['String'];
}


export interface OpsMutationRemoveSystemSmsTemplateArgs {
  id: Scalars['String'];
}


export interface OpsMutationRemoveSystemPushTemplateArgs {
  id: Scalars['String'];
}


export interface OpsMutationCreateActivityTemplateArgs {
  slug: Scalars['String'];
  body: Scalars['String'];
}


export interface OpsMutationUpdateActivityTemplateArgs {
  id: Scalars['ID'];
  body: Scalars['String'];
}


export interface OpsMutationCreateProfileStatusMessageArgs {
  short_message: Scalars['String'];
  long_message: Scalars['String'];
  profile_id: Scalars['String'];
}


export interface OpsMutationUpdateProfileStatusMessageArgs {
  profile_id: Scalars['String'];
  short_message: Scalars['String'];
  long_message: Scalars['String'];
}


export interface OpsMutationDeleteProfileStatusMessagesArgs {
  profile_ids: Array<Maybe<Scalars['String']>>;
}


export interface OpsMutationDeclineProfileStatusMessagesArgs {
  profile_ids: Array<Maybe<Scalars['String']>>;
}


export interface OpsMutationApproveProfileStatusMessagesArgs {
  profile_ids: Array<Maybe<Scalars['String']>>;
}


export interface OpsMutationCreateProfileReviewArgs {
  profile_id: Scalars['String'];
  data: ProfileReviewInput;
}


export interface OpsMutationUpdateProfileReviewArgs {
  id: Scalars['String'];
  data: ProfileReviewInput;
}


export interface OpsMutationApproveProfileReviewArgs {
  id: Scalars['String'];
}


export interface OpsMutationDeclineProfileReviewArgs {
  id: Scalars['String'];
  decline_reasons: Array<Maybe<Scalars['String']>>;
}


export interface OpsMutationDeleteProfileReviewArgs {
  id: Scalars['String'];
  delete_reasons: Array<Maybe<Scalars['String']>>;
}


export interface OpsMutationRestoreProfileReviewArgs {
  id: Scalars['String'];
}


export interface OpsMutationRespondProfileReviewArgs {
  id: Scalars['String'];
  response: ProfileReviewResponse;
}


export interface OpsMutationProfileReviewReplyArgs {
  review_id: Scalars['String'];
  body: Scalars['String'];
}


export interface OpsMutationSetFakeFreeArgs {
  review_id: Scalars['String'];
}


export interface OpsMutationUnsetFakeFreeArgs {
  review_id: Scalars['String'];
}


export interface OpsMutationCreateFaqArgs {
  data: FaqDataInput;
}


export interface OpsMutationUpdateFaqArgs {
  id: Scalars['ID'];
  data: FaqDataInput;
}


export interface OpsMutationDeleteFaqArgs {
  id: Scalars['ID'];
}


export interface OpsMutationRestoreFaqArgs {
  id: Scalars['ID'];
}


export interface OpsMutationEnableFaqArgs {
  id: Scalars['ID'];
}


export interface OpsMutationDisableFaqArgs {
  id: Scalars['ID'];
}


export interface OpsMutationApprovePaymentArgs {
  id: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
}


export interface OpsMutationDeclinePaymentArgs {
  id: Scalars['String'];
  reason: Scalars['String'];
  cancel_products: Scalars['Boolean'];
}


export interface OpsMutationChargebackPaymentArgs {
  id: Scalars['String'];
  reason: Scalars['String'];
  cancel_products: Scalars['Boolean'];
}


export interface OpsMutationCreatePaymentLinkArgs {
  data: CreatePaymentLinkInput;
}


export interface OpsMutationCreateManualPaymentArgs {
  data: CreateManualPaymentInput;
  account_id: Scalars['Int'];
}


export interface OpsMutationDeletePaymentLinkArgs {
  id: Scalars['String'];
}


export interface OpsMutationUpdatePaymentLinkArgs {
  id: Scalars['String'];
  data: UpdatePaymentLinkInput;
}


export interface OpsMutationAddNoteArgs {
  id: Scalars['String'];
  body: Scalars['String'];
}


export interface OpsMutationUpdateNoteArgs {
  id: Scalars['String'];
  body: Scalars['String'];
}


export interface OpsMutationDeleteNoteArgs {
  id?: Maybe<Scalars['String']>;
}


export interface OpsMutationRestrictPaymentMethodsArgs {
  account_id: Scalars['Int'];
  payment_methods: Array<Maybe<PaymentGateways>>;
}


export interface OpsMutationCreateBlacklistedItemArgs {
  type: BlacklistedTypes;
  value: Scalars['String'];
  word_types?: Maybe<Array<Maybe<BlacklistedWordTypes>>>;
  match_type?: Maybe<BlacklistedWordMatchTypes>;
}


export interface OpsMutationDeleteBlacklistedItemArgs {
  id: Scalars['String'];
}


export interface OpsMutationUpdateBlacklistedItemArgs {
  id: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  word_types?: Maybe<Array<Maybe<BlacklistedWordTypes>>>;
  match_type?: Maybe<BlacklistedWordMatchTypes>;
}


export interface OpsMutationCheckForBlacklistArgs {
  data?: Maybe<Array<Maybe<CheckForBlacklistInput>>>;
}


export interface OpsMutationCreateDiscountArgs {
  data: DiscountCreateInput;
}


export interface OpsMutationUpdateDiscountArgs {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
}


export interface OpsMutationDeleteDiscountArgs {
  id: Scalars['ID'];
}


export interface OpsMutationDisableDiscountArgs {
  id: Scalars['ID'];
}


export interface OpsMutationEnableDiscountArgs {
  id: Scalars['ID'];
}


export interface OpsMutationUpdateClassifiedAdArgs {
  id?: Maybe<Scalars['String']>;
  data: ClassifiedAdInput;
}


export interface OpsMutationApproveClassifiedAdArgs {
  id?: Maybe<Scalars['String']>;
}


export interface OpsMutationDeclineClassifiedAdArgs {
  id?: Maybe<Scalars['String']>;
}


export interface OpsMutationRemoveClassifiedAdArgs {
  id?: Maybe<Scalars['String']>;
}


export interface OpsMutationCreateBlacklistedEntryArgs {
  comment: Scalars['String'];
  image_ids?: Maybe<Array<Maybe<BlacklistedClientImageInput>>>;
}


export interface OpsMutationEnableBlacklistedEntryArgs {
  id: Scalars['String'];
}


export interface OpsMutationDisableBlacklistedEntryArgs {
  id: Scalars['String'];
}


export interface OpsMutationUpdateBlacklistedEntryArgs {
  id: Scalars['String'];
  data: BlacklistedEntryUpdateInput;
}


export interface OpsMutationCreateBlacklistedClientArgs {
  data: BlacklistedClientInput;
}


export interface OpsMutationUpdateBlacklistedClientArgs {
  id: Scalars['String'];
  data?: Maybe<BlacklistedClientInput>;
}


export interface OpsMutationDeleteBlacklistedClientArgs {
  id: Scalars['String'];
}


export interface OpsMutationApproveBlacklistedClientArgs {
  id: Scalars['String'];
}


export interface OpsMutationDeclineBlacklistedClientArgs {
  id: Scalars['String'];
}


export interface OpsMutationProfileVerificationPhotosApproveArgs {
  data?: Maybe<ProfileVerificationPhotosApproveInput>;
}


export interface OpsMutationProfileVerificationPhotosDeclineArgs {
  data?: Maybe<ProfileVerificationPhotosDeclineInput>;
}


export interface OpsMutationProfileVerificationApproveArgs {
  data: ProfileVerificationApproveInput;
}


export interface OpsMutationProfileVerificationDeclineArgs {
  data: ProfileVerificationDeclineInput;
}


export interface OpsMutationRequestUnalteredPhotosArgs {
  data: RequestUnalteredPhotosInput;
}


export interface OpsMutationProfileVerificationUpdateIdDocumentArgs {
  id: Scalars['String'];
  data: ProfileVerificationUpdateIdDocumentInput;
}


export interface OpsMutationProfileVerificationUpdateIdDocumentHistoryArgs {
  id: Scalars['String'];
  data: ProfileVerificationUpdateIdDocumentInput;
}


export interface OpsMutationUpdateVerificationSpecialCaseArgs {
  profile_id: Scalars['String'];
  special_case: Scalars['Boolean'];
}


export interface OpsMutationDictionaryCreateArgs {
  data?: Maybe<DictionaryInput>;
}


export interface OpsMutationDictionaryUpdateArgs {
  id: Scalars['String'];
  data: DictionaryInput;
}


export interface OpsMutationDictionaryDeleteArgs {
  id: Scalars['String'];
}


export interface OpsMutationCreateActivityAlertArgs {
  account_id: Scalars['Int'];
  profile_id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  purpose: ActivityAlertPurpose;
}


export interface OpsMutationUpdateActivityAlertArgs {
  id: Scalars['String'];
  message: Scalars['String'];
}


export interface OpsMutationReorderActivityAlertsArgs {
  ordering: Array<Maybe<Scalars['String']>>;
}


export interface OpsMutationDeleteActivityAlertArgs {
  id: Scalars['String'];
}


export interface OpsMutationCreateActivityLogArgs {
  account_id: Scalars['Int'];
  profile_id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
}


export interface OpsMutationCreateSeoRobotTextArgs {
  body: Scalars['String'];
  google_id: Scalars['String'];
}


export interface OpsMutationUpdateSeoRobotTextArgs {
  body: Scalars['String'];
  google_id: Scalars['String'];
}


export interface OpsMutationCreateSeoTemplateArgs {
  key: Scalars['String'];
  entity_type?: Maybe<SeoTemplateEntityType>;
  can_have_instances: Scalars['Boolean'];
  title: SeoLanguageInput;
  keywords: SeoLanguageInput;
  description: SeoLanguageInput;
}


export interface OpsMutationUpdateSeoTemplateArgs {
  id: Scalars['ID'];
  can_have_instances?: Maybe<Scalars['Boolean']>;
  title: SeoLanguageInput;
  keywords?: Maybe<SeoLanguageInput>;
  description?: Maybe<SeoLanguageInput>;
}


export interface OpsMutationDeleteSeoTemplateArgs {
  id: Scalars['ID'];
}


export interface OpsMutationCreateSeoTemplateInstanceArgs {
  data: SeoTemplateInstanceInput;
}


export interface OpsMutationUpdateSeoTemplateInstanceArgs {
  id: Scalars['ID'];
  data: SeoTemplateInstanceInput;
}


export interface OpsMutationDeleteSeoTemplateInstanceArgs {
  id: Scalars['ID'];
}


export interface OpsMutationSetMessengerAdminDisabledArgs {
  account_id: Scalars['Int'];
}


export interface OpsMutationUnsetMessengerAdminDisabledArgs {
  account_id: Scalars['Int'];
}


export interface OpsMutationCreateTourArgs {
  profile_id: Scalars['String'];
  data?: Maybe<ProfileTourInput>;
}


export interface OpsMutationUpdateTourArgs {
  id: Scalars['String'];
  data?: Maybe<ProfileTourInput>;
}


export interface OpsMutationDeleteTourArgs {
  id: Scalars['String'];
}


export interface OpsMutationApproveMemberFavoriteProfileTop10CommentArgs {
  id: Scalars['String'];
}


export interface OpsMutationDeclineMemberFavoriteProfileTop10CommentArgs {
  id: Scalars['String'];
}


export interface OpsMutationSendEmailVerificationArgs {
  account_id: Scalars['Int'];
  email: Scalars['String'];
  purpose?: Maybe<ContactVerificationEmailPurposes>;
}


export interface OpsMutationSendPhoneNumberVerificationArgs {
  account_id: Scalars['Int'];
  country_code: Scalars['String'];
  number: Scalars['String'];
  purpose: ContactVerificationPhoneNumberPurposes;
}


export interface OpsMutationSendCallPhoneNumberVerificationArgs {
  account_id: Scalars['Int'];
  country_code: Scalars['String'];
  number: Scalars['String'];
  purpose: ContactVerificationPhoneNumberPurposes;
}


export interface OpsMutationVerifyPhoneNumberArgs {
  account_id: Scalars['Int'];
  verification_code: Scalars['String'];
  purpose: ContactVerificationPhoneNumberPurposes;
}


export interface OpsMutationCreateNewsletterPrototypeArgs {
  title: Scalars['String'];
  body: Scalars['String'];
}


export interface OpsMutationUpdateNewsletterPrototypeArgs {
  id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
}


export interface OpsMutationDeleteNewsletterPrototypeArgs {
  id: Scalars['ID'];
}


export interface OpsMutationCreateNewsletterTemplateArgs {
  data: NewsletterTemplateInput;
}


export interface OpsMutationUpdateNewsletterTemplateArgs {
  id: Scalars['ID'];
  data: NewsletterTemplateInput;
}


export interface OpsMutationDeleteNewsletterTemplateArgs {
  id: Scalars['ID'];
}


export interface OpsMutationSendNewsletterArgs {
  data: NewsletterSendTemplateInput;
}


export interface OpsMutationOpsToolsVacationCreateArgs {
  data: OpsToolsVacationCreateInput;
}


export interface OpsMutationOpsToolsVacationUpdateArgs {
  data: OpsToolsVacationUpdateInput;
}


export interface OpsMutationOpsToolsVacationApproveArgs {
  id: Scalars['ID'];
}


export interface OpsMutationOpsToolsVacationRemoveArgs {
  id: Scalars['ID'];
}


export interface OpsMutationOpsToolsPrivateMessageCreateArgs {
  data: OpsToolsPrivateMessageCreateInput;
}


export interface OpsMutationOpsToolsPrivateMessageForwardArgs {
  data: OpsToolsPrivateMessageCreateInput;
}


export interface OpsMutationOpsToolsMarkPrivateMessageAsReadArgs {
  id?: Maybe<Scalars['ID']>;
}


export interface OpsMutationOpsToolsPrivateMessageDeleteArgs {
  id?: Maybe<Scalars['ID']>;
}


export interface OpsMutationRotateDocumentArgs {
  id: Scalars['String'];
  degree: Scalars['Int'];
}


export interface OpsMutationMoveProfileOpsArgs {
  data: MoveProfileOpsData;
}


export interface OpsMutationCreateGeoNodeArgs {
  data: GeoNodeInput;
}


export interface OpsMutationUpdateGeoNodeArgs {
  id: Scalars['Int'];
  data: GeoNodeUpdateInput;
}


export interface OpsMutationMoveGeoNodeArgs {
  id: Scalars['Int'];
  data: GeoNodeMoveInput;
}


export interface OpsMutationDeleteGeoNodeArgs {
  id: Scalars['Int'];
}


export interface OpsMutationCreateDeclineReasonArgs {
  types?: Maybe<Array<DeclineReasonType>>;
  key: Scalars['String'];
  value?: Maybe<CreateDeclineReasonValueInput>;
}


export interface OpsMutationUpdateDeclineReasonArgs {
  id: Scalars['String'];
  types?: Maybe<Array<Maybe<DeclineReasonType>>>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<UpdateDeclineReasonValueInput>;
}


export interface OpsMutationEnableDeclineReasonArgs {
  id: Scalars['String'];
}


export interface OpsMutationDisableDeclineReasonArgs {
  id: Scalars['String'];
}


export interface OpsMutationDeleteDeclineReasonArgs {
  id: Scalars['String'];
}


export interface OpsMutationModifyBannerArgs {
  id: Scalars['String'];
  data?: Maybe<BannerModifyInput>;
}


export interface OpsMutationBannerChangeStatusArgs {
  id: Scalars['String'];
  status: BannerStatuses;
}


export interface OpsMutationDeletePendingBannerArgs {
  id: Scalars['String'];
}


export interface OpsMutationUpdateAccountEmailArgs {
  email: Scalars['String'];
  id: Scalars['Int'];
}


export interface OpsMutationUpdateAccountPasswordArgs {
  new_password: Scalars['String'];
  id: Scalars['Int'];
  inform_user?: Maybe<Scalars['Boolean']>;
}


export interface OpsMutationUpdateAccountPasswordByCurrentArgs {
  new_password: Scalars['String'];
  password: Scalars['String'];
}


export interface OpsMutationUpdateAccountOpsArgs {
  id: Scalars['Int'];
  ops_account_id: Scalars['Int'];
}


export interface OpsMutationRecoverAccountArgs {
  id: Scalars['Int'];
}


export interface OpsMutationDeleteAccountArgs {
  id: Scalars['Int'];
}


export interface OpsMutationDeleteAccountTokenArgs {
  id: Scalars['Int'];
  token_id: Scalars['String'];
}


export interface OpsMutationDisableAccountArgs {
  id: Scalars['Int'];
  reasons: Array<Maybe<Scalars['String']>>;
}


export interface OpsMutationEnableAccountArgs {
  id: Scalars['Int'];
}


export interface OpsMutationUnblockAccountArgs {
  id: Scalars['Int'];
}


export interface OpsMutationVerifyAccountArgs {
  id: Scalars['Int'];
}


export interface OpsMutationCreateOpsAccountArgs {
  data: CreateOpsAccountInput;
}


export interface OpsMutationUpdateOpsAccountArgs {
  id: Scalars['Int'];
  email: Scalars['String'];
  showname: Scalars['String'];
  role: OpsRole;
}


export interface OpsMutationUpdateAccountPreferencesArgs {
  id: Scalars['Int'];
  preferences: UpdateAccountPreferencesInput;
}


export interface OpsMutationUpdateAccountFlagsArgs {
  id: Scalars['Int'];
  flags: Array<Maybe<AccountFlags>>;
}


export interface OpsMutationUpdateAccountAutoapproveArgs {
  id: Scalars['Int'];
  values: Array<AccountAutoapproveValues>;
}


export interface OpsMutationCreateAccountUpdateOpsRequestArgs {
  account_id: Scalars['Int'];
  ops_account_id: Scalars['Int'];
}


export interface OpsMutationDeleteAccountUpdateOpsRequestArgs {
  account_id: Scalars['Int'];
}


export interface OpsMutationApproveAccountUpdateOpsRequestArgs {
  account_id: Scalars['Int'];
}


export interface OpsMutationDeclineAccountUpdateOpsRequestArgs {
  account_id: Scalars['Int'];
}


export interface OpsMutationUpdateOpsContactDataArgs {
  id?: Maybe<Scalars['Int']>;
  data?: Maybe<AccountOpsContactDataInput>;
}


export interface OpsMutationUpdateOpsSkypeStatusArgs {
  account_id: Scalars['Int'];
  status: AccountSkypeStatus;
}


export interface OpsMutationUpdateAccountEmailForOpsArgs {
  email: Scalars['String'];
  id: Scalars['Int'];
}


export interface OpsMutationUpdateAccountUsernameForOpsArgs {
  username: Scalars['String'];
  id: Scalars['Int'];
}


export interface OpsMutationUpdateLinkedAccountsArgs {
  account_id: Scalars['Int'];
  linked_account_ids: Array<Maybe<Scalars['Int']>>;
}


export interface OpsMutationDeleteEmailsArgs {
  ids: Array<Scalars['String']>;
}


export interface OpsMutationRestoreEmailsArgs {
  ids: Array<Scalars['String']>;
}


export interface OpsMutationDeleteSmsesArgs {
  ids: Array<Scalars['String']>;
}


export interface OpsMutationRestoreSmsesArgs {
  ids: Array<Scalars['String']>;
}


export interface OpsMutationMoveSmsesToTrashArgs {
  ids: Array<Scalars['String']>;
}


export interface OpsMutationMoveSmsesFromTrashArgs {
  ids: Array<Scalars['String']>;
}


export interface OpsMutationMarkThreadAsReadArgs {
  key: Scalars['String'];
}


export interface OpsMutationDebitBalanceArgs {
  account_id: Scalars['Int'];
  amount: Scalars['Int'];
  note: Scalars['String'];
}


export interface OpsMutationCreditBalanceArgs {
  account_id: Scalars['Int'];
  amount: Scalars['Int'];
  note: Scalars['String'];
}


export interface OpsMutationApplyProductDiscountArgs {
  order_id: Scalars['Int'];
  product_id: Scalars['Int'];
  note: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Int']>;
}


export interface OpsMutationApplyOrderDiscountArgs {
  order_id: Scalars['Int'];
  note: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Int']>;
}


export interface OpsMutationResetProductDiscountArgs {
  order_id: Scalars['Int'];
  product_id: Scalars['Int'];
}


export interface OpsMutationResetOrderDiscountArgs {
  order_id: Scalars['Int'];
}


export interface OpsMutationPayOrderWithBalanceArgs {
  order_id: Scalars['Int'];
}


export interface OpsMutationAddProductArgs {
  data: AddProductInput;
}


export interface OpsMutationAddProductsArgs {
  data: Array<Maybe<AddProductInput>>;
}


export interface OpsMutationRemoveProductArgs {
  order_id: Scalars['Int'];
  order_product_id: Scalars['Int'];
}


export interface OpsMutationMarkOrderWaitingForPaymentArgs {
  order_id: Scalars['Int'];
}


export interface OpsMutationCheckOrderBeforePaymentArgs {
  order_id: Scalars['Int'];
}


export interface OpsMutationMarkOrderInCartArgs {
  order_id: Scalars['Int'];
}


export interface OpsMutationDeleteOrderArgs {
  order_id: Scalars['Int'];
}

export interface OpsQuery {
  commentsByIds: Array<Maybe<Comment>>;
  commentById?: Maybe<Comment>;
  supportTicketComments: Array<Maybe<Comment>>;
  pendingComments: Array<Maybe<PendingComment>>;
  pendingCommentsCount?: Maybe<PendingCommentsCountResponse>;
  pendingReviewReplies: Array<Maybe<PendingReviewReply>>;
  pendingReviewRepliesCount?: Maybe<PendingReviewRepliesCountResponse>;
  commentsForMember?: Maybe<Array<Maybe<ProfileComment>>>;
  commentsForProfile?: Maybe<Array<Maybe<ProfileComment>>>;
  commentsForCompany?: Maybe<Array<Maybe<CompanyProfileComment>>>;
  reviewRepliesForMember?: Maybe<Array<Maybe<ReviewReply>>>;
  reviewRepliesForProfile?: Maybe<Array<Maybe<ReviewReply>>>;
  pendingCompanyComments: Array<Maybe<PendingCompanyComment>>;
  pendingCompanyCommentsCount?: Maybe<PendingCompanyCommentsCountResponse>;
  supportTicket?: Maybe<SupportTicket>;
  supportTickets?: Maybe<Array<SupportTicket>>;
  supportTicketsByAccount?: Maybe<SupportTicketsByAccountResponse>;
  supportTicketsByFilter?: Maybe<SupportTicketsByFilterResponse>;
  supportProblemReport?: Maybe<SupportProblemReport>;
  supportProblemReports?: Maybe<Array<SupportProblemReport>>;
  supportProblemReportsByFilter?: Maybe<SupportProblemReportsByFilterResponse>;
  supportSuspiciousPhotoRequest?: Maybe<SupportSuspiciousPhotoRequest>;
  supportSuspiciousPhotoRequests?: Maybe<Array<SupportSuspiciousPhotoRequest>>;
  supportSuspiciousPhotoRequestsByFilter?: Maybe<SupportSuspiciousPhotoRequestsByFilterResponse>;
  supportFeedback?: Maybe<SupportFeedbackRequest>;
  supportFeedbacks?: Maybe<Array<SupportFeedbackRequest>>;
  supportFeedbacksByFilter?: Maybe<SupportFeedbackFilterResponse>;
  memberProfileByRef?: Maybe<MemberProfile>;
  memberProfileByAccount?: Maybe<MemberProfile>;
  pendingMemberProfilePhotos?: Maybe<Array<Maybe<MemberProfilePendingPhoto>>>;
  pendingMemberProfilePhotosCount?: Maybe<PendingCountResponse>;
  companyProfileByRef?: Maybe<CompanyProfile>;
  companyProfileByAccount?: Maybe<CompanyProfile>;
  pendingNewCompanyProfiles?: Maybe<Array<Maybe<CompanyProfile>>>;
  pendingNewCompanyProfileCount?: Maybe<CompanyPendingCountResponse>;
  pendingModifiedCompanyProfiles?: Maybe<Array<Maybe<CompanyProfile>>>;
  pendingModifiedCompanyProfilesCount?: Maybe<CompanyPendingCountResponse>;
  pendingCompanyProfilePhotos?: Maybe<Array<Maybe<PendingCompanyProfilePhoto>>>;
  pendingCompanyProfilePhotosCount?: Maybe<CompanyPendingCountResponse>;
  profileByRef?: Maybe<Profile>;
  pendingNewProfiles?: Maybe<Array<Maybe<Profile>>>;
  pendingNewProfileCount?: Maybe<PendingCountResponse>;
  pendingModifiedProfiles?: Maybe<Array<Maybe<Profile>>>;
  pendingModifiedProfilesCount?: Maybe<PendingCountResponse>;
  profileByAccount?: Maybe<Profile>;
  profilesByFilters?: Maybe<ProfilesByFiltersResult>;
  pendingProfilePhotos?: Maybe<Array<Maybe<PendingProfilePhoto>>>;
  lastApprovedPhotos?: Maybe<Array<Maybe<LastApprovedPhoto>>>;
  pendingProfilePhotosCount?: Maybe<PendingCountResponse>;
  pendingProfileVideos?: Maybe<Array<Maybe<PendingProfileVideo>>>;
  pendingProfileVideosCount?: Maybe<PendingCountResponse>;
  pendingProfileNaturalPhotos?: Maybe<Array<Maybe<PendingProfileNaturalPhoto>>>;
  pendingProfileNaturalPhotosCount?: Maybe<PendingCountResponse>;
  storyPendingStories: Array<Maybe<PendingStories>>;
  storyPendingStoriesCount: StoryPendingCountResponse;
  storyByProfileIdForCellar?: Maybe<StoriesCellar>;
  storyByAccountId?: Maybe<StoriesByAccount>;
  storyByFiltersForCellar?: Maybe<StoryByFiltersForCellarResponse>;
  profileHappyHourByFiltersForCellar?: Maybe<ProfileHappyHourByFiltersForCellarResponse>;
  baseService?: Maybe<BaseService>;
  baseServices: BaseServicesResponse;
  baseServicesByOrderId: Array<Maybe<BaseService>>;
  gotdService?: Maybe<GotdService>;
  gotdServices: GotdServicesResponse;
  gotdServicesByOrderId: Array<Maybe<GotdService>>;
  votdService?: Maybe<VotdService>;
  votdServices: VotdServicesResponse;
  votdServicesByOrderId: Array<Maybe<VotdService>>;
  classifiedAdService?: Maybe<ClassifiedAdService>;
  classifiedAdServices: ClassifiedAdServicesResponse;
  classifiedAdServicesByOrderId: Array<Maybe<ClassifiedAdService>>;
  bumpSchedules: BumpSchedulesResponse;
  bumpCredits: BumpCreditsResponse;
  bumpCreditsHistory: BumpCreditsHistoryResponse;
  bumpsHistory: BumpsHistoryResponse;
  lastAndNextBumps?: Maybe<LastAndNextBumps>;
  onlineNewsById?: Maybe<OnlineNews>;
  onlineNewsByFilter?: Maybe<OnlineNewsResponse>;
  contactGroups: Array<Maybe<NotificationContactGroup>>;
  notificationContactGroup?: Maybe<NotificationContactGroup>;
  contactById?: Maybe<NotificationContact>;
  contactsByFilter: NotificationContactResponse;
  regularTemplate?: Maybe<RegularTemplate>;
  regularTemplatesByAccount?: Maybe<Array<RegularTemplate>>;
  regularTemplatesByType?: Maybe<Array<RegularTemplate>>;
  systemEmailTemplate?: Maybe<SystemEmailTemplate>;
  systemSmsTemplate?: Maybe<SystemSmsTemplate>;
  systemPushTemplate?: Maybe<SystemPushTemplate>;
  systemEmailTemplates?: Maybe<Array<SystemEmailTemplate>>;
  systemEmailTemplateLayouts?: Maybe<Array<SystemEmailTemplate>>;
  systemSmsTemplates?: Maybe<Array<SystemSmsTemplate>>;
  systemPushTemplates?: Maybe<Array<SystemPushTemplate>>;
  systemEmailTemplateBySlug?: Maybe<SystemEmailTemplate>;
  systemSmsTemplateBySlug?: Maybe<SystemSmsTemplate>;
  systemPushTemplateBySlug?: Maybe<SystemPushTemplate>;
  activityTemplate?: Maybe<ActivityTemplate>;
  activityTemplates?: Maybe<Array<Maybe<ActivityTemplate>>>;
  statusMessageByProfile?: Maybe<ProfileStatusMessage>;
  pendingStatusMessages?: Maybe<Array<Maybe<ProfileStatusMessage>>>;
  pendingStatusMessagesCount?: Maybe<PendingStatusMessagesCountResponse>;
  profileStatusMessageConfigs?: Maybe<ProfileStatusMessageConfig>;
  profileStatusMessagesByFilter?: Maybe<ProfileStatusMessagesFilterResult>;
  instantBookRequestHistory?: Maybe<InstantBookRequestsPagination>;
  profileReviewsStatisticByAccount?: Maybe<ProfileReviewsStatisticResponse>;
  profileReviewsByProfile?: Maybe<ProfileReviewsPaginationResponse>;
  profileReviewsByFilter?: Maybe<ProfileReviewsPaginationResponse>;
  profileReviewsByAccount?: Maybe<ProfileReviewsPaginationResponse>;
  profileReviewsByMemberAccount?: Maybe<ProfileReviewsPaginationResponse>;
  profileReview?: Maybe<ProfileReview>;
  pendingProfileReviews?: Maybe<Array<Maybe<ProfileReview>>>;
  pendingProfileReviewsCount?: Maybe<PendingProfileReviewsCountResponse>;
  profileReviewConfigs?: Maybe<ProfileReviewConfigs>;
  faq?: Maybe<Faq>;
  faqsByTitle?: Maybe<Array<Maybe<Faq>>>;
  faqsByAccountRole?: Maybe<Array<Maybe<Faq>>>;
  faqs: Array<Maybe<Faq>>;
  paymentMethods: Array<Maybe<PaymentMethod>>;
  paymentConfigs: PaymentConfigResponse;
  payment?: Maybe<Payment>;
  pendingPaymentCount: PendingPaymentCountResponse;
  pendingPayments: Array<Maybe<Payment>>;
  paymentsByAccountId: PaymentsByAccountIdResponse;
  paymentLink: PaymentLink;
  paymentLinksByAccountId: PaymentLinksByAccountIdResponse;
  blacklist?: Maybe<BlacklistedItem>;
  blacklistByType?: Maybe<Array<Maybe<BlacklistedItem>>>;
  blacklistedEntities?: Maybe<Array<Maybe<BlacklistedItem>>>;
  discount?: Maybe<Discount>;
  discounts?: Maybe<DiscountsListResponse>;
  classifiedAd?: Maybe<ClassifiedAd>;
  classifiedAdByRefId?: Maybe<ClassifiedAd>;
  classifiedAdsByFilter?: Maybe<ClassifiedAdsFilterResponse>;
  blacklistedClientsByFilter?: Maybe<BlacklistedClientsFilterResponse>;
  blacklistedClientById?: Maybe<BlacklistedClient>;
  blacklistedEntries?: Maybe<Array<Maybe<BlacklistedEntries>>>;
  pendingBlacklistedClientsCount?: Maybe<PendingBlacklistedClientsCountResponse>;
  blacklistedClientConfigs?: Maybe<BlacklistedClientConfig>;
  profileVerifiedPhotosByProfile?: Maybe<Array<Maybe<ProfileVerificationPhoto>>>;
  profileVerificationById?: Maybe<ProfileVerification>;
  profileVerificationsByIds?: Maybe<Array<Maybe<ProfileVerification>>>;
  profileVerificationByProfileId?: Maybe<ProfileVerification>;
  profileVerificationsByProfileIds?: Maybe<Array<Maybe<ProfileVerification>>>;
  pendingOneHundredVerifications?: Maybe<Array<Maybe<ProfileVerification>>>;
  pendingAgeVerifications?: Maybe<Array<Maybe<ProfileVerification>>>;
  pendingOneHundredVerificationsCount?: Maybe<PendingVerificationCountResponse>;
  pendingAgeVerificationsCount?: Maybe<PendingVerificationCountResponse>;
  oneHundredVerifications?: Maybe<OneHundredVerificationsResponse>;
  ageVerifications?: Maybe<AgeVerificationsResponse>;
  products?: Maybe<Array<Maybe<Product>>>;
  baseServiceProducts?: Maybe<Array<Maybe<BaseServiceProduct>>>;
  bannerProducts?: Maybe<Array<Maybe<BannerProduct>>>;
  classifiedAdProducts?: Maybe<Array<Maybe<BannerProduct>>>;
  gotdProduct?: Maybe<GotdProduct>;
  votdProduct?: Maybe<VotdProduct>;
  bumpBundleProduct?: Maybe<BumpBundleProduct>;
  productAdditionalOptions?: Maybe<Array<Maybe<ProductOption>>>;
  reservations?: Maybe<Array<Maybe<ReservationResponse>>>;
  availableBaseServiceProducts?: Maybe<Array<Maybe<ProductListItem>>>;
  inventory?: Maybe<Inventory>;
  activityAlerts?: Maybe<Array<Maybe<ActivityAlert>>>;
  activityLogsByFilter?: Maybe<ActivityLogFilterResponse>;
  activityLogConfigs?: Maybe<ActivityLogConfigs>;
  seoRobotText?: Maybe<SeoRobotsText>;
  seoTemplate?: Maybe<SeoTemplate>;
  seoTemplateInstance?: Maybe<SeoTemplateInstance>;
  seoTemplateByKey?: Maybe<SeoTemplate>;
  seoInstanceByTemplate?: Maybe<Array<Maybe<SeoTemplateInstance>>>;
  seoTemplatesList?: Maybe<Array<Maybe<SeoTemplate>>>;
  seoTemplateInstancesList?: Maybe<SeoTemplateInstancesListResponse>;
  getNotFoundRecords?: Maybe<SeoGetNotFoundRecordsResponse>;
  tourById?: Maybe<ProfileTour>;
  toursByIds?: Maybe<Array<Maybe<ProfileTour>>>;
  toursByProfile?: Maybe<Array<Maybe<ProfileTour>>>;
  memberFavoriteProfilesByAccount: MemberFavoriteProfilesResponse;
  findTop10CommentsForCellar: Top10CommentsForCellarResponse;
  checkEmailVerification?: Maybe<CheckEmailVerificationResponse>;
  checkPhoneNumberVerification?: Maybe<CheckPhoneNumberVerificationResponse>;
  newsletterPrototype?: Maybe<NewsletterPrototype>;
  newsletterPrototypes?: Maybe<Array<Maybe<NewsletterPrototype>>>;
  newsletterTemplate?: Maybe<NewsletterTemplate>;
  newsletterTemplates?: Maybe<Array<Maybe<NewsletterTemplate>>>;
  newsletterGroups?: Maybe<Array<Maybe<NewsletterGroup>>>;
  vacation?: Maybe<OpsToolsVacation>;
  vacations: Array<Maybe<OpsToolsVacation>>;
  privateMessage?: Maybe<OpsToolsPrivateMessage>;
  privateMessagesByFilter: OpsToolsPrivateMessagesByFilterResponse;
  image?: Maybe<Image>;
  images?: Maybe<Array<Image>>;
  document?: Maybe<Document>;
  documents?: Maybe<Array<Maybe<Document>>>;
  video?: Maybe<Video>;
  videos?: Maybe<Array<Maybe<Video>>>;
  ip2location?: Maybe<Ip2Location>;
  ips2locations?: Maybe<Array<Maybe<Ip2Location>>>;
  geoChildrenByIdAndTypes?: Maybe<Array<Maybe<GeoNode>>>;
  declineReasonsByTypes?: Maybe<Array<Maybe<DeclineReason>>>;
  declineReasonsList?: Maybe<Array<Maybe<DeclineReason>>>;
  declineReason?: Maybe<DeclineReason>;
  accountMatchedFrauds?: Maybe<Array<Maybe<AccountMatchedFraud>>>;
  accountMatchedIpFrauds?: Maybe<Array<Maybe<AccountMatchedIpFraud>>>;
  accountMatchedCidFrauds?: Maybe<AccountMatchedCidFraud>;
  accountMatchedIps?: Maybe<Array<Maybe<AccountMatchedIp>>>;
  matchedIpsCidsByFilter?: Maybe<MatchedIpsCidsResult>;
  cellarAggregatorPayments?: Maybe<CellarAggregatorPaymentResponse>;
  cellarAggregatorOrders?: Maybe<CellarAggregatorOrderResponse>;
  cellarAggregatorBaseServices?: Maybe<CellarAggregatorBaseServiceResponse>;
  cellarAggregatorGotdServices?: Maybe<CellarAggregatorGotdServiceResponse>;
  cellarAggregatorVotdServices?: Maybe<CellarAggregatorVotdServiceResponse>;
  cellarAggregatorClassifiedAdServices?: Maybe<CellarAggregatorClassifiedAdServiceResponse>;
  cellarAggregatorBanners?: Maybe<CellarAggregatorBannerResponse>;
  cellarAggregatorStatusMessages?: Maybe<CellarAggregatorStatusMessageResponse>;
  cellarAggregatorBumpBundles?: Maybe<CellarAggregatorBumpBundleResponse>;
  cellarAggregatorSearch?: Maybe<CellarAggregatorSearchResponse>;
  cellarAggregatorProfiles?: Maybe<CellarAggregatorProfilesResponse>;
  cellarAggregatorProfileWebsiteStatusToBeChecked?: Maybe<CellarAggregatorProfilesResponse>;
  cellarAggregatorCompanyProfiles?: Maybe<CellarAggregatorCompanyProfilesResponse>;
  cellarAggregatorMemberProfiles?: Maybe<CellarAggregatorMemberProfilesResponse>;
  cellarAggregatorChatMessages?: Maybe<CellarAggregatorChatMessagesResponse>;
  cellarAggregatorSalesMonthlyReportsFilter?: Maybe<CellarSalesMonthlyReportsFilterResponse>;
  bannerById?: Maybe<Banner>;
  bannerByRefId?: Maybe<Banner>;
  bannerFindByFilter?: Maybe<BannerFilterResponse>;
  pendingBannersCount?: Maybe<BannerCountResponse>;
  aiProfileModeratorByFilter?: Maybe<AiProfileResultsResponse>;
  aiStoryModeratorByFilter?: Maybe<AiStoryResultsResponse>;
  aiClassifiedAdModeratorByFilter?: Maybe<AiClassifiedAdResultsResponse>;
  aiCompanyModarationsByFilter?: Maybe<AiCompanyResultsResponse>;
  account?: Maybe<AccountForOps>;
  opsAccount?: Maybe<OpsAccount>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  accountValidTokens?: Maybe<Array<Maybe<AccountValidToken>>>;
  accountUpdateOpsRequests?: Maybe<Array<Maybe<Account>>>;
  opsList?: Maybe<Array<Maybe<OpsAccount>>>;
  findNotActiveAccounts?: Maybe<AccountsFilterResponse>;
  bannedAccounts?: Maybe<AccountsFilterResponse>;
  emails?: Maybe<NotificationEmailResponse>;
  notificationThread: Array<Maybe<NotificationSms>>;
  smsesInbox: NotificationSmsInboxResponse;
  smsesOutbox: NotificationSmsOutboxResponse;
  cart?: Maybe<FullOrderForOps>;
  order?: Maybe<FullOrderForOps>;
  balance?: Maybe<Balance>;
  orders?: Maybe<OrdersResponse>;
  deletedOrder?: Maybe<FullOrderForOps>;
  orderProductActivity?: Maybe<Array<Maybe<OrderProductActivityResponse>>>;
  transfers?: Maybe<TransfersResponse>;
  inCartProducts?: Maybe<Array<Maybe<InCartProduct>>>;
  enabledPaymentMethods: Array<PaymentEnabledMethod>;
  paymentUrl?: Maybe<PaymentUrlResponse>;
  paymentByReferenceId?: Maybe<PaymentByReferenceIdResponse>;
  dummy?: Maybe<Scalars['String']>;
}


export interface OpsQueryCommentsByIdsArgs {
  ids: Array<Scalars['String']>;
}


export interface OpsQueryCommentByIdArgs {
  id: Scalars['String'];
}


export interface OpsQuerySupportTicketCommentsArgs {
  ticket_id: Scalars['String'];
}


export interface OpsQueryCommentsForMemberArgs {
  account_id: Scalars['Int'];
}


export interface OpsQueryCommentsForProfileArgs {
  profile_id: Scalars['String'];
}


export interface OpsQueryCommentsForCompanyArgs {
  profile_id: Scalars['String'];
}


export interface OpsQueryReviewRepliesForMemberArgs {
  account_id: Scalars['Int'];
}


export interface OpsQueryReviewRepliesForProfileArgs {
  profile_id: Scalars['String'];
}


export interface OpsQuerySupportTicketArgs {
  ticket_id: Scalars['ID'];
}


export interface OpsQuerySupportTicketsArgs {
  ticket_ids: Array<Scalars['ID']>;
}


export interface OpsQuerySupportTicketsByAccountArgs {
  account_id: Scalars['Int'];
  filter?: Maybe<SupportTicketFilterInput>;
}


export interface OpsQuerySupportTicketsByFilterArgs {
  filter: SupportTicketFilterOpsInput;
}


export interface OpsQuerySupportProblemReportArgs {
  id: Scalars['ID'];
}


export interface OpsQuerySupportProblemReportsArgs {
  ids: Array<Scalars['ID']>;
}


export interface OpsQuerySupportProblemReportsByFilterArgs {
  filter?: Maybe<SupportProblemReportFilterInput>;
}


export interface OpsQuerySupportSuspiciousPhotoRequestArgs {
  id: Scalars['ID'];
}


export interface OpsQuerySupportSuspiciousPhotoRequestsArgs {
  ids: Array<Scalars['ID']>;
}


export interface OpsQuerySupportSuspiciousPhotoRequestsByFilterArgs {
  filter: SupportSuspiciousPhotoRequestFilterInput;
}


export interface OpsQuerySupportFeedbackArgs {
  id: Scalars['ID'];
}


export interface OpsQuerySupportFeedbacksArgs {
  ids: Array<Scalars['ID']>;
}


export interface OpsQuerySupportFeedbacksByFilterArgs {
  filter: SupportFeedbackFilterInput;
}


export interface OpsQueryMemberProfileByRefArgs {
  id: Scalars['Int'];
  show_deleted?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryMemberProfileByAccountArgs {
  account_id: Scalars['Int'];
  show_deleted?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryCompanyProfileByRefArgs {
  id: Scalars['Int'];
  show_deleted?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryCompanyProfileByAccountArgs {
  account_id: Scalars['Int'];
  show_deleted?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryProfileByRefArgs {
  id: Scalars['Int'];
  show_deleted?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryProfileByAccountArgs {
  account_id: Scalars['Int'];
  show_deleted?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryProfilesByFiltersArgs {
  filters: ProfileByFilterInput;
}


export interface OpsQueryLastApprovedPhotosArgs {
  from_date?: Maybe<Scalars['String']>;
}


export interface OpsQueryStoryByProfileIdForCellarArgs {
  profile_id: Scalars['ID'];
}


export interface OpsQueryStoryByAccountIdArgs {
  account_id: Scalars['Int'];
}


export interface OpsQueryStoryByFiltersForCellarArgs {
  filter?: Maybe<StoryFiltersForCellarInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryProfileHappyHourByFiltersForCellarArgs {
  filter?: Maybe<ProfileHappyHourFiltersForCellarInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryBaseServiceArgs {
  id: Scalars['Int'];
}


export interface OpsQueryBaseServicesArgs {
  account_id: Scalars['Int'];
  filter: QueryBaseServicesFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface OpsQueryBaseServicesByOrderIdArgs {
  order_id: Scalars['Int'];
}


export interface OpsQueryGotdServiceArgs {
  id: Scalars['Int'];
}


export interface OpsQueryGotdServicesArgs {
  account_id: Scalars['Int'];
  filter: QueryGotdServicesFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface OpsQueryGotdServicesByOrderIdArgs {
  order_id: Scalars['Int'];
}


export interface OpsQueryVotdServiceArgs {
  id: Scalars['Int'];
}


export interface OpsQueryVotdServicesArgs {
  account_id: Scalars['Int'];
  filter: QueryVotdServicesFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface OpsQueryVotdServicesByOrderIdArgs {
  order_id: Scalars['Int'];
}


export interface OpsQueryClassifiedAdServiceArgs {
  id: Scalars['Int'];
}


export interface OpsQueryClassifiedAdServicesArgs {
  account_id: Scalars['Int'];
  filter: QueryClassifiedAdServicesFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface OpsQueryClassifiedAdServicesByOrderIdArgs {
  order_id: Scalars['Int'];
}


export interface OpsQueryBumpSchedulesArgs {
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
}


export interface OpsQueryBumpCreditsArgs {
  account_id: Scalars['Int'];
}


export interface OpsQueryBumpCreditsHistoryArgs {
  account_id: Scalars['Int'];
  data: BumpCreditsHistoryInput;
}


export interface OpsQueryBumpsHistoryArgs {
  reference_id: Scalars['Int'];
  data: BumpsHistoryInput;
}


export interface OpsQueryLastAndNextBumpsArgs {
  reference_id: Scalars['Int'];
}


export interface OpsQueryOnlineNewsByIdArgs {
  id: Scalars['ID'];
}


export interface OpsQueryOnlineNewsByFilterArgs {
  filter: OnlineNewsFilter;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface OpsQueryNotificationContactGroupArgs {
  id: Scalars['Int'];
}


export interface OpsQueryContactByIdArgs {
  id: Scalars['ID'];
}


export interface OpsQueryContactsByFilterArgs {
  filter?: Maybe<ContactFilterInput>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface OpsQueryRegularTemplateArgs {
  id?: Maybe<Scalars['String']>;
}


export interface OpsQueryRegularTemplatesByTypeArgs {
  type: RegularTemplateType;
}


export interface OpsQuerySystemEmailTemplateArgs {
  id?: Maybe<Scalars['String']>;
}


export interface OpsQuerySystemSmsTemplateArgs {
  id?: Maybe<Scalars['String']>;
}


export interface OpsQuerySystemPushTemplateArgs {
  id?: Maybe<Scalars['String']>;
}


export interface OpsQuerySystemEmailTemplateBySlugArgs {
  slug: Scalars['String'];
}


export interface OpsQuerySystemSmsTemplateBySlugArgs {
  slug: Scalars['String'];
}


export interface OpsQuerySystemPushTemplateBySlugArgs {
  slug: Scalars['String'];
}


export interface OpsQueryActivityTemplateArgs {
  id: Scalars['ID'];
}


export interface OpsQueryStatusMessageByProfileArgs {
  profile_id: Scalars['String'];
}


export interface OpsQueryProfileStatusMessagesByFilterArgs {
  filter?: Maybe<ProfileStatusMessagesFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryInstantBookRequestHistoryArgs {
  data: InstantBookCellarRequestHistoryInput;
}


export interface OpsQueryProfileReviewsStatisticByAccountArgs {
  account_id: Scalars['Int'];
  is_member?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryProfileReviewsByProfileArgs {
  profile_id: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryProfileReviewsByFilterArgs {
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryProfileReviewsByAccountArgs {
  account_id: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryProfileReviewsByMemberAccountArgs {
  account_id: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryProfileReviewArgs {
  id: Scalars['String'];
}


export interface OpsQueryFaqArgs {
  id: Scalars['ID'];
}


export interface OpsQueryFaqsByTitleArgs {
  title: Scalars['String'];
}


export interface OpsQueryFaqsByAccountRoleArgs {
  account_role?: Maybe<FaqAccountRole>;
}


export interface OpsQueryPaymentArgs {
  id: Scalars['String'];
}


export interface OpsQueryPaymentsByAccountIdArgs {
  id: Scalars['Int'];
  filter: PaymentsByAccountIdFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface OpsQueryPaymentLinkArgs {
  id: Scalars['String'];
}


export interface OpsQueryPaymentLinksByAccountIdArgs {
  id: Scalars['Int'];
  filter: PaymentLinksByAccountIdFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface OpsQueryBlacklistArgs {
  id: Scalars['String'];
}


export interface OpsQueryBlacklistByTypeArgs {
  type?: Maybe<BlacklistedTypes>;
}


export interface OpsQueryDiscountArgs {
  id: Scalars['ID'];
}


export interface OpsQueryDiscountsArgs {
  filter: DiscountFilterInput;
}


export interface OpsQueryClassifiedAdArgs {
  id?: Maybe<Scalars['String']>;
}


export interface OpsQueryClassifiedAdByRefIdArgs {
  id?: Maybe<Scalars['Int']>;
}


export interface OpsQueryClassifiedAdsByFilterArgs {
  filter: ClassifiedAdsFilterInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryBlacklistedClientsByFilterArgs {
  data?: Maybe<BlacklistedClientsFilterInput>;
}


export interface OpsQueryBlacklistedClientByIdArgs {
  id: Scalars['String'];
}


export interface OpsQueryBlacklistedEntriesArgs {
  comment?: Maybe<Scalars['String']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryProfileVerifiedPhotosByProfileArgs {
  profile_id: Scalars['String'];
}


export interface OpsQueryProfileVerificationByIdArgs {
  id: Scalars['String'];
}


export interface OpsQueryProfileVerificationsByIdsArgs {
  ids: Array<Maybe<Scalars['String']>>;
}


export interface OpsQueryProfileVerificationByProfileIdArgs {
  id: Scalars['String'];
}


export interface OpsQueryProfileVerificationsByProfileIdsArgs {
  ids: Array<Maybe<Scalars['String']>>;
}


export interface OpsQueryPendingOneHundredVerificationsArgs {
  show_unaltered?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryOneHundredVerificationsArgs {
  filter?: Maybe<OneHundredVerificationFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryAgeVerificationsArgs {
  filter?: Maybe<AgeVerificationFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryProductsArgs {
  entities: Array<Maybe<RequestEntity>>;
}


export interface OpsQueryBaseServiceProductsArgs {
  entities: Array<Maybe<RequestEntity>>;
  load_inventory?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryBannerProductsArgs {
  entities: Array<Maybe<RequestEntity>>;
  load_inventory?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryClassifiedAdProductsArgs {
  entities: Array<Maybe<RequestEntity>>;
  load_inventory?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryGotdProductArgs {
  entities: Array<Maybe<RequestEntity>>;
}


export interface OpsQueryVotdProductArgs {
  entities: Array<Maybe<RequestEntity>>;
}


export interface OpsQueryBumpBundleProductArgs {
  entities: Array<Maybe<RequestEntity>>;
}


export interface OpsQueryProductAdditionalOptionsArgs {
  product_id: Scalars['Int'];
  entities: Array<Maybe<RequestEntity>>;
}


export interface OpsQueryReservationsArgs {
  inventory_id: Scalars['Int'];
  date_from?: Maybe<Scalars['Date']>;
  date_to: Scalars['Date'];
}


export interface OpsQueryInventoryArgs {
  id: Scalars['Int'];
}


export interface OpsQueryActivityAlertsArgs {
  account_id: Scalars['Int'];
  profile_id?: Maybe<Scalars['String']>;
  purpose?: Maybe<ActivityAlertPurpose>;
}


export interface OpsQueryActivityLogsByFilterArgs {
  data: ActivityLogFilter;
}


export interface OpsQuerySeoTemplateArgs {
  id?: Maybe<Scalars['ID']>;
}


export interface OpsQuerySeoTemplateInstanceArgs {
  id?: Maybe<Scalars['ID']>;
}


export interface OpsQuerySeoTemplateByKeyArgs {
  key: Scalars['String'];
}


export interface OpsQuerySeoInstanceByTemplateArgs {
  template_id: Scalars['ID'];
}


export interface OpsQuerySeoTemplateInstancesListArgs {
  filter: SeoTemplateInstanceFilterInput;
}


export interface OpsQueryGetNotFoundRecordsArgs {
  filter: SeoGetNotFoundRecordsInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryTourByIdArgs {
  id: Scalars['String'];
}


export interface OpsQueryToursByIdsArgs {
  ids: Array<Maybe<Scalars['String']>>;
}


export interface OpsQueryToursByProfileArgs {
  profile_id: Scalars['String'];
  statuses?: Maybe<Array<Maybe<ProfileTourStatuses>>>;
}


export interface OpsQueryMemberFavoriteProfilesByAccountArgs {
  account_id: Scalars['Int'];
}


export interface OpsQueryFindTop10CommentsForCellarArgs {
  profile_reference_id?: Maybe<Scalars['Int']>;
  member_account_id?: Maybe<Scalars['Int']>;
  comment_status?: Maybe<MemberFavoriteTopTenCommentStatuses>;
  comment_type?: Maybe<MemberFavoriteTopTenCommentTypes>;
}


export interface OpsQueryCheckEmailVerificationArgs {
  account_id: Scalars['Int'];
  email: Scalars['String'];
}


export interface OpsQueryCheckPhoneNumberVerificationArgs {
  account_id: Scalars['Int'];
  country_code: Scalars['String'];
  number: Scalars['String'];
  purpose: ContactVerificationPhoneNumberPurposes;
  re_verify?: Maybe<Scalars['Boolean']>;
}


export interface OpsQueryNewsletterPrototypeArgs {
  id?: Maybe<Scalars['ID']>;
}


export interface OpsQueryNewsletterTemplateArgs {
  id?: Maybe<Scalars['ID']>;
}


export interface OpsQueryVacationArgs {
  id: Scalars['ID'];
}


export interface OpsQueryPrivateMessageArgs {
  id: Scalars['ID'];
}


export interface OpsQueryPrivateMessagesByFilterArgs {
  filter: OpsToolsPrivateMessageFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface OpsQueryImageArgs {
  id: Scalars['String'];
}


export interface OpsQueryImagesArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsQueryDocumentArgs {
  id: Scalars['String'];
}


export interface OpsQueryDocumentsArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsQueryVideoArgs {
  id: Scalars['String'];
}


export interface OpsQueryVideosArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface OpsQueryIp2locationArgs {
  ip: Scalars['String'];
}


export interface OpsQueryIps2locationsArgs {
  ips: Array<Maybe<Scalars['String']>>;
}


export interface OpsQueryGeoChildrenByIdAndTypesArgs {
  id: Scalars['Int'];
  types: Array<GeoNodeTypes>;
}


export interface OpsQueryDeclineReasonsByTypesArgs {
  types?: Maybe<Array<Maybe<DeclineReasonType>>>;
}


export interface OpsQueryDeclineReasonArgs {
  id?: Maybe<Scalars['String']>;
}


export interface OpsQueryAccountMatchedFraudsArgs {
  account_id: Scalars['Int'];
  min_score?: Maybe<Scalars['Int']>;
}


export interface OpsQueryAccountMatchedIpFraudsArgs {
  account_id: Scalars['Int'];
}


export interface OpsQueryAccountMatchedCidFraudsArgs {
  account_id: Scalars['Int'];
}


export interface OpsQueryAccountMatchedIpsArgs {
  account_id: Scalars['Int'];
}


export interface OpsQueryMatchedIpsCidsByFilterArgs {
  filter: MatchedIpsCidsFilterInput;
}


export interface OpsQueryCellarAggregatorPaymentsArgs {
  filter?: Maybe<CellarAggregatorPaymentFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorOrdersArgs {
  filter?: Maybe<CellarAggregatorOrderFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorBaseServicesArgs {
  filter?: Maybe<CellarAggregatorBaseServiceFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorGotdServicesArgs {
  filter?: Maybe<CellarAggregatorGotdServiceFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorVotdServicesArgs {
  filter?: Maybe<CellarAggregatorVotdServiceFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorClassifiedAdServicesArgs {
  filter?: Maybe<CellarAggregatorClassifiedAdServiceFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorBannersArgs {
  filter?: Maybe<CellarAggregatorBannerFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorStatusMessagesArgs {
  filter?: Maybe<CellarAggregatorStatusMessageFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorBumpBundlesArgs {
  filter?: Maybe<CellarAggregatorBumpBundleFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorSearchArgs {
  query: Scalars['String'];
  min_score?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorProfilesArgs {
  filter?: Maybe<CellarAggregatorProfileFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorProfileWebsiteStatusToBeCheckedArgs {
  filter?: Maybe<CellarAggregatorProfileFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorCompanyProfilesArgs {
  filter?: Maybe<CellarAggregatorCompanyProfileFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorMemberProfilesArgs {
  filter?: Maybe<CellarAggregatorMemberProfileFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorChatMessagesArgs {
  filter?: Maybe<CellarAggregatorChatMessageFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryCellarAggregatorSalesMonthlyReportsFilterArgs {
  filter?: Maybe<CellarAggregatorSalesMonthlyReportsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryBannerByIdArgs {
  id: Scalars['String'];
}


export interface OpsQueryBannerByRefIdArgs {
  id: Scalars['Int'];
}


export interface OpsQueryBannerFindByFilterArgs {
  filter?: Maybe<BannerFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryAiProfileModeratorByFilterArgs {
  filter?: Maybe<AiModerationFiltersInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryAiStoryModeratorByFilterArgs {
  filter?: Maybe<AiModerationFiltersInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryAiClassifiedAdModeratorByFilterArgs {
  filter?: Maybe<AiModerationFiltersInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryAiCompanyModarationsByFilterArgs {
  filter?: Maybe<AiModerationFiltersInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface OpsQueryAccountArgs {
  id: Scalars['Int'];
}


export interface OpsQueryOpsAccountArgs {
  id: Scalars['Int'];
}


export interface OpsQueryAccountsArgs {
  accountIds?: Maybe<Array<Scalars['Int']>>;
}


export interface OpsQueryAccountValidTokensArgs {
  id: Scalars['Int'];
}


export interface OpsQueryOpsListArgs {
  filter?: Maybe<OpsListInput>;
}


export interface OpsQueryFindNotActiveAccountsArgs {
  filter: NotActiveAccountsInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface OpsQueryBannedAccountsArgs {
  filter: BannedAccountsInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}


export interface OpsQueryEmailsArgs {
  data?: Maybe<NotificationEmailInput>;
}


export interface OpsQueryNotificationThreadArgs {
  group_key: Scalars['String'];
}


export interface OpsQuerySmsesInboxArgs {
  data?: Maybe<NotificationSmsInboxInput>;
}


export interface OpsQuerySmsesOutboxArgs {
  data?: Maybe<NotificationSmsOutboxInput>;
}


export interface OpsQueryCartArgs {
  account_id: Scalars['Int'];
}


export interface OpsQueryOrderArgs {
  id: Scalars['Int'];
}


export interface OpsQueryBalanceArgs {
  account_id: Scalars['Int'];
}


export interface OpsQueryOrdersArgs {
  account_id: Scalars['Int'];
  filter: QueryOrderFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}


export interface OpsQueryDeletedOrderArgs {
  id: Scalars['Int'];
}


export interface OpsQueryOrderProductActivityArgs {
  order_id: Scalars['Int'];
}


export interface OpsQueryTransfersArgs {
  account_id: Scalars['Int'];
  filter: QueryTransferFilterInput;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}


export interface OpsQueryInCartProductsArgs {
  ref_type: ProductRefType;
  ref_id: Scalars['Int'];
}


export interface OpsQueryPaymentUrlArgs {
  order_id: Scalars['Int'];
  account_id: Scalars['Int'];
  payment_method: Scalars['String'];
}


export interface OpsQueryPaymentByReferenceIdArgs {
  account_id: Scalars['Int'];
  id: Scalars['Int'];
}

export enum OpsRole {
  Admin = 'ADMIN',
  Sales = 'SALES',
  Manager = 'MANAGER',
  Seo = 'SEO'
}

export interface OpsSubscription {
  dummy?: Maybe<Scalars['String']>;
}

export interface OpsToolsMutationResponse {
  result: Scalars['Boolean'];
}

export interface OpsToolsPrivateMessage {
  _id: Scalars['ID'];
  sender_account_id: Scalars['Int'];
  recipient_account_id: Scalars['Int'];
  subject: Scalars['String'];
  message: Scalars['String'];
  date_sent: Scalars['Date'];
  is_read: Scalars['Boolean'];
  is_urgent: Scalars['Boolean'];
  is_forwarded: Scalars['Boolean'];
}

export interface OpsToolsPrivateMessageCreateInput {
  recipient_account_id: Scalars['Int'];
  subject: Scalars['String'];
  message: Scalars['String'];
  is_urgent: Scalars['Boolean'];
}

export interface OpsToolsPrivateMessageCreateResponse {
  id: Scalars['ID'];
}

export interface OpsToolsPrivateMessageFilterInput {
  account_id?: Maybe<Scalars['Int']>;
  type?: Maybe<OpsToolsPrivateMessageType>;
  unread?: Maybe<Scalars['Boolean']>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export enum OpsToolsPrivateMessageType {
  Inbox = 'INBOX',
  Outbox = 'OUTBOX'
}

export interface OpsToolsPrivateMessagesByFilterResponse {
  count: Scalars['Int'];
  result: Array<Maybe<OpsToolsPrivateMessage>>;
}

export interface OpsToolsVacation {
  _id: Scalars['ID'];
  account_id: Scalars['Int'];
  from_date: Scalars['Date'];
  to_date: Scalars['Date'];
  backup_account_id: Scalars['Int'];
  status?: Maybe<OpsToolsVacationStatus>;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  date_approved?: Maybe<Scalars['Date']>;
  approved_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
}

export interface OpsToolsVacationCreateInput {
  account_id: Scalars['Int'];
  backup_account_id: Scalars['Int'];
  from_date: Scalars['Date'];
  to_date: Scalars['Date'];
}

export interface OpsToolsVacationCreateResponse {
  id: Scalars['ID'];
}

export enum OpsToolsVacationStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED'
}

export interface OpsToolsVacationUpdateInput {
  id: Scalars['ID'];
  account_id: Scalars['Int'];
  backup_account_id: Scalars['Int'];
  from_date: Scalars['Date'];
  to_date: Scalars['Date'];
}

export interface OptionPrice {
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  tax_percentage?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
}

export interface Order {
  id: Scalars['Int'];
  status: OrderStatus;
  hash: Scalars['String'];
  account_id: Scalars['Int'];
  date_created: Scalars['Date'];
  date_paid?: Maybe<Scalars['Date']>;
  price: Scalars['Float'];
  final_price: Scalars['Float'];
  currency: Scalars['String'];
  created_by: Scalars['Int'];
  payment_id?: Maybe<Scalars['String']>;
  is_moved?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface OrderProduct {
  id: Scalars['Int'];
}

export interface OrderProductActivityResponse {
  id: Scalars['Int'];
  name: Scalars['String'];
  product_id: Scalars['Int'];
  ref_type?: Maybe<Scalars['String']>;
  ref_id?: Maybe<Scalars['Int']>;
  geo_node_id?: Maybe<Scalars['Int']>;
  additional_geo_nodes_count?: Maybe<Scalars['Int']>;
  banner_zone_id?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  date_activation?: Maybe<Scalars['Date']>;
  created_by: Scalars['Int'];
  date_created: Scalars['Date'];
  deleted_by?: Maybe<Scalars['Int']>;
  date_deleted?: Maybe<Scalars['Date']>;
}

export interface OrderProductAd extends OrderProduct {
  id: Scalars['Int'];
  name: Scalars['String'];
  product_id: Scalars['Int'];
  price?: Maybe<Price>;
  ref_type?: Maybe<Scalars['String']>;
  ref_id?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<Maybe<OrderProductOption>>>;
  created_by: Scalars['Int'];
  profile?: Maybe<Profile>;
}

export interface OrderProductAdForOps extends OrderProductForOps {
  id: Scalars['Int'];
  name: Scalars['String'];
  product_id: Scalars['Int'];
  price?: Maybe<PriceForOps>;
  ref_type?: Maybe<Scalars['String']>;
  ref_id?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<Maybe<OrderProductOption>>>;
  created_by: Scalars['Int'];
  profile?: Maybe<Profile>;
}

export interface OrderProductBanner extends OrderProduct {
  id: Scalars['Int'];
  name: Scalars['String'];
  product_id: Scalars['Int'];
  price?: Maybe<Price>;
  ref_type?: Maybe<Scalars['String']>;
  ref_id?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<Maybe<OrderProductOption>>>;
  created_by: Scalars['Int'];
}

export interface OrderProductBannerForOps extends OrderProductForOps {
  id: Scalars['Int'];
  name: Scalars['String'];
  product_id: Scalars['Int'];
  price?: Maybe<PriceForOps>;
  ref_type?: Maybe<Scalars['String']>;
  ref_id?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<Maybe<OrderProductOption>>>;
  created_by: Scalars['Int'];
}

export interface OrderProductClassifiedAd extends OrderProduct {
  id: Scalars['Int'];
  name: Scalars['String'];
  product_id: Scalars['Int'];
  price?: Maybe<Price>;
  ref_type?: Maybe<Scalars['String']>;
  ref_id?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<Maybe<OrderProductOption>>>;
  created_by: Scalars['Int'];
}

export interface OrderProductClassifiedAdForOps extends OrderProductForOps {
  id: Scalars['Int'];
  name: Scalars['String'];
  product_id: Scalars['Int'];
  price?: Maybe<PriceForOps>;
  ref_type?: Maybe<Scalars['String']>;
  ref_id?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<Maybe<OrderProductOption>>>;
  created_by: Scalars['Int'];
}

export interface OrderProductForOps {
  id: Scalars['Int'];
}

export interface OrderProductOption {
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  inventory_id?: Maybe<Scalars['Int']>;
  price: OptionPrice;
}

export enum OrderStatus {
  InCart = 'IN_CART',
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  Paid = 'PAID',
  Canceled = 'CANCELED'
}

export interface OrdersResponse {
  result: Array<Maybe<Order>>;
  count: Scalars['Int'];
}

export interface Payment {
  _id: Scalars['String'];
  _meta?: Maybe<Scalars['JSONObject']>;
  _details?: Maybe<PaymentDetails>;
  _note?: Maybe<PaymentNote>;
  gateway?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentType>;
  ops_account_id?: Maybe<Scalars['Int']>;
  vendor_account_id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  status?: Maybe<PaymentStatus>;
  requested_amount?: Maybe<Scalars['Float']>;
  surcharge_amount?: Maybe<Scalars['Float']>;
  surcharge_percent?: Maybe<Scalars['Float']>;
  requested_currency?: Maybe<Scalars['String']>;
  actual_amount?: Maybe<Scalars['Float']>;
  actual_currency?: Maybe<Scalars['String']>;
  reference_id?: Maybe<Scalars['Int']>;
  checkout_type: PaymentCheckoutType;
  ip?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  created_by?: Maybe<Scalars['Int']>;
  date_paid?: Maybe<Scalars['Date']>;
  date_approved?: Maybe<Scalars['Date']>;
  approved_by?: Maybe<Scalars['Int']>;
  approve_reason?: Maybe<Scalars['String']>;
  date_declined?: Maybe<Scalars['Date']>;
  declined_by?: Maybe<Scalars['Int']>;
  decline_reason?: Maybe<Scalars['String']>;
  date_charged_back?: Maybe<Scalars['Date']>;
  charged_back_by?: Maybe<Scalars['Int']>;
  chargeback_reason?: Maybe<Scalars['String']>;
}

export interface PaymentAvailableAmount {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
}

export interface PaymentByReferenceIdResponse {
  data?: Maybe<Payment>;
}

export enum PaymentCheckoutType {
  Self = 'SELF',
  Manual = 'MANUAL',
  PaymentLink = 'PAYMENT_LINK'
}

export interface PaymentConfig {
  status_update_reason: PaymentTextFieldConfig;
}

export interface PaymentConfigResponse {
  payment?: Maybe<PaymentConfig>;
  payment_link?: Maybe<PaymentLinkConfig>;
}

export interface PaymentDetails {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  var_symbol?: Maybe<Scalars['String']>;
  pull_person?: Maybe<Scalars['String']>;
  mtcn?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  webmoney_account?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  gateway_ip?: Maybe<Scalars['String']>;
  credit_card_holder?: Maybe<Scalars['String']>;
  credit_card_provider?: Maybe<Scalars['String']>;
  credit_card_number?: Maybe<Scalars['String']>;
  credit_card_address?: Maybe<Scalars['String']>;
  credit_card_expiration_date?: Maybe<Scalars['String']>;
  credit_card_email?: Maybe<Scalars['String']>;
  admin_comment?: Maybe<Scalars['String']>;
}

export interface PaymentEnabledMethod {
  name: Scalars['String'];
  display_name: Scalars['String'];
  is_recommended: Scalars['Boolean'];
  surcharge_percents: Array<SurchargePercent>;
  available_for_services_and_amounts: Array<AvailableForServicesAndAmounts>;
  amount?: Maybe<PaymentAvailableAmount>;
  available_for_services?: Maybe<Array<Maybe<AvailableForService>>>;
}

export enum PaymentGateways {
  Banktransfer = 'BANKTRANSFER',
  Twispay = 'TWISPAY',
  Paysafe = 'PAYSAFE',
  Coinsome = 'COINSOME',
  Powercash = 'POWERCASH',
  Payabl = 'PAYABL',
  Mmg = 'MMG',
  Ecardon = 'ECARDON',
  Pushads = 'PUSHADS',
  Twint = 'TWINT',
  Wallee = 'WALLEE',
  Ivrtruesenses = 'IVRTRUESENSES',
  Postfinance = 'POSTFINANCE',
  Faxin = 'FAXIN',
  Epg = 'EPG',
  Cash2Code = 'CASH2CODE'
}

export interface PaymentLink {
  _id: Scalars['String'];
  amount?: Maybe<Scalars['Int']>;
  currency: Scalars['String'];
  payment_methods: Array<Maybe<Scalars['String']>>;
  account_id: Scalars['Int'];
  vendor_account_id: Scalars['Int'];
  status: PaymentLinkStatus;
  hash: Scalars['String'];
  internal_note?: Maybe<Scalars['String']>;
  external_note?: Maybe<Scalars['String']>;
  is_deleted: Scalars['Boolean'];
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
}

export interface PaymentLinkConfig {
  note: PaymentTextFieldConfig;
}

export interface PaymentLinkPublic {
  _id: Scalars['String'];
  amount?: Maybe<Scalars['Int']>;
  currency: Scalars['String'];
  payment_methods: Array<Maybe<Scalars['String']>>;
  hash: Scalars['String'];
  external_note?: Maybe<Scalars['String']>;
}

export enum PaymentLinkStatus {
  Pending = 'PENDING',
  Paid = 'PAID'
}

export interface PaymentLinksByAccountIdFilterInput {
  vendor_account_id?: Maybe<Scalars['Int']>;
  hash?: Maybe<Scalars['String']>;
  status?: Maybe<PaymentLinkStatus>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export interface PaymentLinksByAccountIdResponse {
  result: Array<Maybe<PaymentLink>>;
  count: Scalars['Int'];
}

export interface PaymentMethod {
  name: Scalars['String'];
  display_name: Scalars['String'];
}

export interface PaymentNote {
  body?: Maybe<Scalars['String']>;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
  date_deleted?: Maybe<Scalars['Date']>;
  deleted_by?: Maybe<Scalars['Int']>;
}

export enum PaymentStatus {
  Pending = 'PENDING',
  Paid = 'PAID',
  Approved = 'APPROVED',
  AutoApproved = 'AUTO_APPROVED',
  Declined = 'DECLINED',
  ChargedBack = 'CHARGED_BACK',
  Failed = 'FAILED'
}

export interface PaymentStatusUpdateResponse {
  result: Scalars['Boolean'];
}

export interface PaymentTextFieldConfig {
  max_length: Scalars['Int'];
}

export enum PaymentType {
  CreditCard = 'CREDIT_CARD',
  Paysafe = 'PAYSAFE',
  BankTransfer = 'BANK_TRANSFER',
  Cash = 'CASH',
  Moneygram = 'MONEYGRAM',
  Webmoney = 'WEBMONEY',
  SalesEinkassiert = 'SALES_EINKASSIERT',
  PhoneBilling = 'PHONE_BILLING',
  Postcheck = 'POSTCHECK',
  Vorauskasse = 'VORAUSKASSE',
  Faxin = 'FAXIN',
  Pushads = 'PUSHADS',
  Twint = 'TWINT',
  Wallee = 'WALLEE',
  Cash2Code = 'CASH2CODE',
  PsStripe = 'PS_STRIPE',
  PsWorldline = 'PS_WORLDLINE'
}

export interface PaymentUrlResponse {
  type: Scalars['String'];
  data: Scalars['String'];
  transaction_id?: Maybe<Scalars['String']>;
}

export interface PaymentsByAccountIdFilterInput {
  vendor_account_id?: Maybe<Scalars['Int']>;
  checkout_type?: Maybe<PaymentCheckoutType>;
  type?: Maybe<PaymentType>;
  gateway?: Maybe<Scalars['String']>;
  payer_name?: Maybe<Scalars['String']>;
  status?: Maybe<PaymentStatus>;
  transaction_id?: Maybe<Scalars['String']>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export interface PaymentsByAccountIdResponse {
  result: Array<Maybe<Payment>>;
  count: Scalars['Int'];
}

export interface PendingAgeVerificationsResponse {
  count: Scalars['Int'];
  result: Array<Maybe<ProfileVerification>>;
}

export interface PendingBlacklistedClientsCountResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface PendingComment {
  _id: Scalars['String'];
  entity_id: Scalars['ID'];
  entity_type: CommentEntityType;
  entity_account_id: Scalars['Int'];
  profile?: Maybe<Profile>;
  account_id: Scalars['Int'];
  body: Scalars['String'];
  date_created: Scalars['Date'];
  member_profile?: Maybe<MemberProfile>;
}

export interface PendingCommentsCountResponse {
  count: Scalars['Int'];
}

export interface PendingCompanyComment {
  _id: Scalars['String'];
  entity_id: Scalars['ID'];
  entity_type: CommentEntityType;
  entity_account_id: Scalars['Int'];
  company_profile?: Maybe<CompanyProfile>;
  account_id: Scalars['Int'];
  body: Scalars['String'];
  date_created: Scalars['Date'];
  member_profile?: Maybe<MemberProfile>;
}

export interface PendingCompanyCommentsCountResponse {
  count: Scalars['Int'];
}

export interface PendingCompanyProfilePhoto {
  _id: Scalars['String'];
  profile?: Maybe<CompanyProfile>;
  pending_company_photos?: Maybe<Array<CompanyProfilePhoto>>;
}

export interface PendingCountResponse {
  count: Scalars['Int'];
}

export interface PendingOneHundredVerificationsResponse {
  count: Scalars['Int'];
  result: Array<Maybe<ProfileVerification>>;
}

export interface PendingPaymentCountResponse {
  count: Scalars['Int'];
}

export interface PendingProfileNaturalPhoto {
  _id: Scalars['String'];
  profile?: Maybe<Profile>;
  pending_natural_photos?: Maybe<Array<ProfileNaturalPhoto>>;
}

export interface PendingProfilePhoto {
  _id: Scalars['String'];
  profile?: Maybe<Profile>;
  pending_photos?: Maybe<Array<ProfilePhoto>>;
}

export interface PendingProfileReviewsCountResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface PendingProfileVideo {
  _id: Scalars['String'];
  profile?: Maybe<Profile>;
  pending_videos?: Maybe<Array<ProfileVideo>>;
}

export interface PendingReviewRepliesCountResponse {
  count: Scalars['Int'];
}

export interface PendingReviewReply {
  _id: Scalars['String'];
  entity_id: Scalars['ID'];
  entity_type: CommentEntityType;
  entity_account_id: Scalars['Int'];
  review?: Maybe<ProfileReview>;
  profile?: Maybe<Profile>;
  account_id: Scalars['Int'];
  body: Scalars['String'];
  date_created: Scalars['Date'];
}

export interface PendingStatusMessagesCountResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface PendingStories {
  _id: Scalars['String'];
  profile?: Maybe<Profile>;
  pending_stories?: Maybe<Array<Maybe<Story>>>;
}

export interface PendingVerificationCountResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface Price {
  id: Scalars['Int'];
  price: Scalars['Float'];
  final_price: Scalars['Float'];
  currency: Scalars['String'];
  tax_percentage?: Maybe<Scalars['Float']>;
  discount: CommerceDiscount;
}

export interface PriceForOps {
  id: Scalars['Int'];
  price: Scalars['Float'];
  final_price: Scalars['Float'];
  currency: Scalars['String'];
  tax_percentage?: Maybe<Scalars['Float']>;
  discount: CommerceDiscountForOps;
}

export interface Product {
  id: Scalars['Int'];
  name: Scalars['String'];
  group: ProductGroup;
  inventory: Array<Maybe<Inventory>>;
}

export enum ProductGroup {
  BaseService = 'BASE_SERVICE',
  Generic = 'GENERIC'
}

export interface ProductListItem {
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface ProductManagerMutationResponse {
  result: Scalars['Boolean'];
}

export interface ProductOption {
  name: Scalars['String'];
  inventory: Array<Maybe<Inventory>>;
}

export enum ProductOptionName {
  AdditionalGeoNodesCount = 'ADDITIONAL_GEO_NODES_COUNT',
  BaseDuration = 'BASE_DURATION',
  DateActivation = 'DATE_ACTIVATION',
  AdditionalGeoNodes = 'ADDITIONAL_GEO_NODES'
}

export enum ProductRefType {
  Ad = 'AD',
  ClassifiedAd = 'CLASSIFIED_AD',
  Banner = 'BANNER'
}

export interface Profile {
  _id: Scalars['String'];
  ai_text_fields_moderation?: Maybe<ProfileTextFieldsModerationsResponses>;
  _meta?: Maybe<ProfileMeta>;
  company_profile?: Maybe<CompanyProfile>;
  showname?: Maybe<Scalars['String']>;
  slogan?: Maybe<Scalars['String']>;
  gender?: Maybe<ProfileGender>;
  age?: Maybe<Scalars['Int']>;
  ethnicity?: Maybe<ProfileEthnicity>;
  nationality?: Maybe<ProfileNationality>;
  home_city_id?: Maybe<Scalars['Int']>;
  home_city_geo_node?: Maybe<GeoNode>;
  home_city_geo_path?: Maybe<Array<GeoNode>>;
  hair_color?: Maybe<ProfileHairColor>;
  eye_color?: Maybe<ProfileEyeColor>;
  height?: Maybe<Scalars['Int']>;
  height_royal?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  weight_royal?: Maybe<Scalars['Int']>;
  dress_size?: Maybe<ProfileDressSize>;
  dress_size_royal?: Maybe<ProfileDressSizeRoyal>;
  shoe_size?: Maybe<Scalars['Int']>;
  shoe_size_royal?: Maybe<Scalars['Int']>;
  bust_size?: Maybe<Scalars['Int']>;
  waist_size?: Maybe<Scalars['Int']>;
  hip_size?: Maybe<Scalars['Int']>;
  cup_size?: Maybe<ProfileCupSize>;
  pubic_hair?: Maybe<ProfilePubicHair>;
  about_me?: Maybe<Array<ProfileAboutMe>>;
  smoking?: Maybe<ProfileSmoking>;
  drinking?: Maybe<ProfileDrinking>;
  special_characteristics?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<ProfileLanguage>>;
  areas?: Maybe<Array<ProfileArea>>;
  cities?: Maybe<Array<Maybe<ProfileCity>>>;
  available_for_incall?: Maybe<ProfileAvailableForIncall>;
  available_for_outcall?: Maybe<ProfileAvailableForOutcall>;
  sexual_orientation?: Maybe<ProfileSexualOrientation>;
  services_for?: Maybe<Array<ProfileServiceFor>>;
  provided_services?: Maybe<Array<ProfileProvidedService>>;
  additional_provided_services?: Maybe<Array<ProfileAdditionalProvidedService>>;
  available_24_7?: Maybe<Scalars['Boolean']>;
  show_as_night_escort?: Maybe<Scalars['Boolean']>;
  working_hours?: Maybe<Array<Maybe<ProfileWorkingHour>>>;
  rates_incall?: Maybe<Array<Maybe<ProfileRateIncall>>>;
  rates_outcall?: Maybe<Array<Maybe<ProfileRateOutcall>>>;
  phone_numbers?: Maybe<Array<ProfilePhoneNumber>>;
  phone_numbers_pending?: Maybe<Array<ProfilePhoneNumber>>;
  emails?: Maybe<Array<ProfileEmail>>;
  emails_pending?: Maybe<Array<ProfileEmail>>;
  website?: Maybe<Scalars['String']>;
  videogirlsch_url?: Maybe<Scalars['String']>;
  club_name?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  address_additional_details?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  country_visibility?: Maybe<ProfileCountryVisibility>;
  map_location?: Maybe<ProfileMapLocation>;
  address_location?: Maybe<ProfileAddressLocation>;
  skype_id?: Maybe<Scalars['String']>;
  galleries?: Maybe<Array<ProfileGallery>>;
  natural_photos?: Maybe<Array<ProfileNaturalPhoto>>;
  videos?: Maybe<Array<ProfileVideo>>;
  tours?: Maybe<Array<ProfileTour>>;
  status_message?: Maybe<ProfileStatusMessage>;
  _changes?: Maybe<ProfileChanges>;
  main_photo?: Maybe<ProfilePhoto>;
  verification?: Maybe<ProfileVerification>;
  aggregator_online?: Maybe<AggregatorOnlineProfile>;
  closest_base_service?: Maybe<BaseService>;
  latest_admin_comment?: Maybe<ActivityAlert>;
}

export interface ProfileAboutMe {
  id: Scalars['String'];
  language: GlobalEnabledLanguage;
  value?: Maybe<Scalars['String']>;
}

export interface ProfileAboutMeInput {
  id: Scalars['String'];
  language: GlobalEnabledLanguage;
  value?: Maybe<Scalars['String']>;
}

export interface ProfileAdditionalProvidedService {
  id: Scalars['String'];
  language: GlobalEnabledLanguage;
  value?: Maybe<Scalars['String']>;
}

export interface ProfileAdditionalProvidedServiceInput {
  id: Scalars['String'];
  language: GlobalEnabledLanguage;
  value?: Maybe<Scalars['String']>;
}

export interface ProfileAddressLocation {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
}

export interface ProfileAddressLocationInput {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}

export interface ProfileAdminDisableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileAdminEnableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileAgeVerificationKeepOnlineSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileAgeVerificationKeepOnlineUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileAiLinkBlacklist {
  link?: Maybe<ProfileAiTextFieldMatches>;
  blacklist?: Maybe<ProfileAiTextFieldMatches>;
}

export interface ProfileAiTextFieldMatch {
  match?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
}

export interface ProfileAiTextFieldMatches {
  matches?: Maybe<Array<Maybe<ProfileAiTextFieldMatch>>>;
}

export interface ProfileAiTextFieldsModerationResponse {
  _id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  moderation_type?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  field_value?: Maybe<Scalars['String']>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  ai_moderation_response?: Maybe<ProfileAiLinkBlacklist>;
}

export interface ProfileAllPhotosRotatableSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileAllPhotosRotatableUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileArea {
  id: Scalars['String'];
  value: Scalars['Int'];
  is_base?: Maybe<Scalars['Boolean']>;
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
}

export interface ProfileAreaInput {
  id: Scalars['String'];
  value: Scalars['Int'];
  is_base?: Maybe<Scalars['Boolean']>;
}

export interface ProfileAvailableForIncall {
  zip_code?: Maybe<Scalars['String']>;
  type?: Maybe<ProfileAvailableForIncallType>;
  hotel_room_stars?: Maybe<Scalars['Int']>;
  other?: Maybe<Scalars['String']>;
}

export interface ProfileAvailableForIncallInput {
  zip_code?: Maybe<Scalars['String']>;
  type?: Maybe<ProfileAvailableForIncallType>;
  hotel_room_stars?: Maybe<Scalars['Int']>;
  other?: Maybe<Scalars['String']>;
}

export enum ProfileAvailableForIncallType {
  PrivateApartment = 'PRIVATE_APARTMENT',
  HotelRoom = 'HOTEL_ROOM',
  ClubStudio = 'CLUB_STUDIO',
  Other = 'OTHER'
}

export interface ProfileAvailableForOutcall {
  type?: Maybe<ProfileAvailableForOutcallType>;
  other?: Maybe<Scalars['String']>;
}

export interface ProfileAvailableForOutcallInput {
  type?: Maybe<ProfileAvailableForOutcallType>;
  other?: Maybe<Scalars['String']>;
}

export enum ProfileAvailableForOutcallType {
  HotelVisitsOnly = 'HOTEL_VISITS_ONLY',
  HomeVisitsOnly = 'HOME_VISITS_ONLY',
  HotelAndHomeVisits = 'HOTEL_AND_HOME_VISITS',
  Other = 'OTHER'
}

export enum ProfileBreastType {
  Natural = 'NATURAL',
  Silicone = 'SILICONE'
}

export interface ProfileByFilterInput {
  account_id: Scalars['Int'];
  show_deleted: Scalars['Boolean'];
  is_online: Scalars['Boolean'];
  is_admin_disabled: Scalars['Boolean'];
  is_disabled: Scalars['Boolean'];
  text: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}

export interface ProfileCamplaceDisableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileCamplaceEnableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export enum ProfileChangeFieldStatus {
  Pending = 'PENDING',
  Declined = 'DECLINED'
}

export enum ProfileChangeGlobalStatus {
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export enum ProfileChangeStatus {
  Pending = 'PENDING',
  Processed = 'PROCESSED'
}

export interface ProfileChanges {
  status: ProfileChangeStatus;
  global_status: ProfileChangeGlobalStatus;
  v?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  fields?: Maybe<ProfileChangesField>;
}

export interface ProfileChangesField {
  showname?: Maybe<Array<Maybe<ProfileChangesFieldScalar>>>;
  age?: Maybe<Array<Maybe<ProfileChangesFieldScalar>>>;
  slogan?: Maybe<Array<Maybe<ProfileChangesFieldScalar>>>;
  special_characteristics?: Maybe<Array<Maybe<ProfileChangesFieldScalar>>>;
  website?: Maybe<Array<Maybe<ProfileChangesFieldScalar>>>;
  club_name?: Maybe<Array<Maybe<ProfileChangesFieldScalar>>>;
  street?: Maybe<Array<Maybe<ProfileChangesFieldScalar>>>;
  street_number?: Maybe<Array<Maybe<ProfileChangesFieldScalar>>>;
  available_for_incall?: Maybe<Array<Maybe<ProfileChangesFieldNested>>>;
  available_for_outcall?: Maybe<Array<Maybe<ProfileChangesFieldNested>>>;
  additional_provided_services?: Maybe<Array<Maybe<ProfileChangesFieldCollection>>>;
  about_me?: Maybe<Array<Maybe<ProfileChangesFieldCollection>>>;
  phone_numbers?: Maybe<Array<Maybe<ProfileChangesFieldCollection>>>;
  address_additional_details?: Maybe<Array<Maybe<ProfileChangesFieldScalar>>>;
  zip_code?: Maybe<Array<Maybe<ProfileChangesFieldScalar>>>;
}

export interface ProfileChangesFieldCollection {
  value?: Maybe<ProfileChangesFieldCollectionValue>;
  status?: Maybe<ProfileChangeFieldStatus>;
  date_changed?: Maybe<Scalars['Date']>;
  changed_by?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileChangesFieldCollectionValue {
  id?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface ProfileChangesFieldNested {
  value?: Maybe<ProfileChangesFieldNestedValue>;
  status?: Maybe<ProfileChangeFieldStatus>;
  date_changed?: Maybe<Scalars['Date']>;
  changed_by?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileChangesFieldNestedValue {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface ProfileChangesFieldProcess {
  showname?: Maybe<Array<Maybe<ProfileChangesFieldProcessScalar>>>;
  age?: Maybe<Array<Maybe<ProfileChangesFieldProcessScalar>>>;
  slogan?: Maybe<Array<Maybe<ProfileChangesFieldProcessScalar>>>;
  special_characteristics?: Maybe<Array<Maybe<ProfileChangesFieldProcessScalar>>>;
  website?: Maybe<Array<Maybe<ProfileChangesFieldProcessScalar>>>;
  club_name?: Maybe<Array<Maybe<ProfileChangesFieldProcessScalar>>>;
  street?: Maybe<Array<Maybe<ProfileChangesFieldProcessScalar>>>;
  street_number?: Maybe<Array<Maybe<ProfileChangesFieldProcessScalar>>>;
  available_for_incall?: Maybe<Array<Maybe<ProfileChangesFieldProcessNested>>>;
  available_for_outcall?: Maybe<Array<Maybe<ProfileChangesFieldProcessNested>>>;
  additional_provided_services?: Maybe<Array<Maybe<ProfileChangesFieldProcessCollection>>>;
  about_me?: Maybe<Array<Maybe<ProfileChangesFieldProcessCollection>>>;
  phone_numbers?: Maybe<Array<Maybe<ProfileChangesFieldProcessCollection>>>;
  address_additional_details?: Maybe<Array<Maybe<ProfileChangesFieldProcessScalar>>>;
  zip_code?: Maybe<Array<Maybe<ProfileChangesFieldProcessScalar>>>;
}

export interface ProfileChangesFieldProcessCollection {
  id?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  action?: Maybe<ProfilePartialProcessAction>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileChangesFieldProcessNested {
  field?: Maybe<Scalars['String']>;
  action?: Maybe<ProfilePartialProcessAction>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileChangesFieldProcessScalar {
  action?: Maybe<ProfilePartialProcessAction>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileChangesFieldScalar {
  value?: Maybe<ProfileChangesFieldScalarValue>;
  status?: Maybe<ProfileChangeFieldStatus>;
  date_changed?: Maybe<Scalars['Date']>;
  changed_by?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileChangesFieldScalarValue {
  value?: Maybe<Scalars['String']>;
}

export interface ProfileCity {
  id: Scalars['String'];
  value: Scalars['Int'];
  is_base?: Maybe<Scalars['Boolean']>;
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
}

export interface ProfileCityInput {
  id: Scalars['String'];
  value: Scalars['Int'];
  is_base?: Maybe<Scalars['Boolean']>;
}

export interface ProfileCityZone {
  id: Scalars['String'];
  value: Scalars['Int'];
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
}

export interface ProfileCityZoneInput {
  id: Scalars['String'];
  value: Scalars['Int'];
}

export interface ProfileComment {
  _id: Scalars['String'];
  entity_id: Scalars['ID'];
  entity_type: CommentEntityType;
  entity_account_id: Scalars['Int'];
  status: CommentStatus;
  is_deleted: Scalars['Boolean'];
  profile?: Maybe<Profile>;
  company_profile?: Maybe<CompanyProfile>;
  account_id: Scalars['Int'];
  body: Scalars['String'];
  date_created: Scalars['Date'];
  member_profile?: Maybe<MemberProfile>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  processed_by?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
}

export interface ProfileCommentCreateResponse {
  id?: Maybe<Scalars['String']>;
}

export interface ProfileCommentsDisableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileCommentsEnableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileCommentsResponse {
  result: Array<Maybe<Comment>>;
  count: Scalars['Int'];
}

export enum ProfileCompanyAdFilterStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum ProfileCompanyEntranceFee {
  Na = 'NA',
  Free = 'FREE',
  WithCost = 'WITH_COST'
}

export enum ProfileCompanyFoodDrink {
  Na = 'NA',
  Yes = 'YES',
  No = 'NO',
  Free = 'FREE',
  WithCost = 'WITH_COST'
}

export enum ProfileCompanyOutdoorArea {
  Na = 'NA',
  Yes = 'YES',
  No = 'NO'
}

export enum ProfileCompanyPhotoStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export enum ProfileCompanyType {
  ClubStudio = 'CLUB_STUDIO',
  Kontaktbar = 'KONTAKTBAR',
  Laufhaus = 'LAUFHAUS',
  Nightclub = 'NIGHTCLUB'
}

export enum ProfileCompanyWellness {
  Na = 'NA',
  Yes = 'YES',
  No = 'NO',
  Free = 'FREE',
  WithCost = 'WITH_COST'
}

export interface ProfileConfig {
  profile_fields: ProfileConfigProfileKey;
  company_profile_fields: ProfileConfigCompanyProfileKey;
  member_profile_fields: ProfileConfigMemberProfileKey;
  company_ads_count: ProfileConfigCompanyAdsCount;
  profile_photo: ProfileConfigProfilePhoto;
  company_profile_photo: ProfileConfigCompanyProfilePhoto;
  video: ProfileConfigVideo;
  video_cover: ProfileConfigVideoCover;
  natural_photo: ProfileConfigNaturalPhoto;
  gallery_title: ProfileConfigGalleryTitle;
  showname: ProfileConfigShowname;
  slogan: ProfileConfigSlogan;
  zip_code: ProfileConfigZipCode;
  age: ProfileConfigAge;
  height: ProfileConfigHeight;
  heights_royal?: Maybe<Array<Scalars['String']>>;
  weight: ProfileConfigWeight;
  weight_royal: ProfileConfigWeightRoyal;
  bust_size: ProfileConfigBustSize;
  waist_size: ProfileConfigWaistSize;
  hip_size: ProfileConfigHipSize;
  shoe_size: ProfileConfigShoeSize;
  shoe_size_royal: ProfileConfigShoeSizeRoyal;
  about_me: ProfileConfigAboutMe;
  about_me_company: ProfileConfigAboutMeCompany;
  about_me_member: ProfileConfigAboutMeMember;
  special_characteristics: ProfileConfigSpecialCharacteristic;
  available_for_incall_other_required_type: ProfileAvailableForIncallType;
  available_for_incall_hotel_stars_required_type: ProfileAvailableForIncallType;
  available_for_incall_hotel_stars?: Maybe<Array<Scalars['Int']>>;
  available_for_incall_other: ProfileConfigAvailableForIncallOther;
  available_for_outcall_other_required_type: ProfileAvailableForOutcallType;
  available_for_outcall_other: ProfileConfigAvailableForOutcallOther;
  additional_provided_services: ProfileConfigAdditionalProvidedServices;
  rate_types?: Maybe<Array<ProfileConfigRateType>>;
  profile_club_name: ProfileConfigProfileClubName;
  profile_street: ProfileConfigProfileStreet;
  profile_street_number: ProfileConfigProfileStreetNumber;
  profile_address_additional_details: ProfileConfigProfileAddressAdditionalDetails;
  company_profile_address_additional_details: ProfileConfigCompanyProfileAddressAdditionalDetails;
  profile_videogirlsch_url: ProfileConfigProfileVideogirlschUrl;
  profile_skype_id: ProfileConfigProfileSkypeId;
  profile_zip_code: ProfileConfigProfileZipCode;
  billing_info_name: ProfileConfigBillingInfoName;
  billing_info_surname: ProfileConfigBillingInfoSurName;
  billing_info_address: ProfileConfigBillingInfoAddress;
  billing_info_zip_code: ProfileConfigBillingInfoZipCode;
}

export interface ProfileConfigAboutMe {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigAboutMeCompany {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigAboutMeMember {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigAdditionalProvidedServices {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigAge {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigAvailableForIncallOther {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigAvailableForOutcallOther {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigBillingInfoAddress {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
}

export interface ProfileConfigBillingInfoName {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
}

export interface ProfileConfigBillingInfoSurName {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
}

export interface ProfileConfigBillingInfoZipCode {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  valid_pattern?: Maybe<Scalars['String']>;
}

export interface ProfileConfigBustSize {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigCompanyAdsCount {
  max: Scalars['Int'];
}

export interface ProfileConfigCompanyProfileAddressAdditionalDetails {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigCompanyProfileKey {
  showname: ProfileConfigCompanyProfileKeyShowname;
  display_name: ProfileConfigCompanyProfileKeyDisplayName;
  type: ProfileConfigCompanyProfileKeyType;
  about_me: ProfileConfigCompanyProfileKeyAboutMe;
  cities: ProfileConfigCompanyProfileKeyCity;
  areas: ProfileConfigCompanyProfileKeyArea;
  available_24_7: ProfileConfigCompanyProfileKeyAvailable_24_7;
  working_hours: ProfileConfigCompanyProfileKeyWorkingHour;
  rates_incall: ProfileConfigCompanyProfileKeyRateIncall;
  rates_outcall: ProfileConfigCompanyProfileKeyRateOutcall;
  phone_numbers: ProfileConfigCompanyProfileKeyPhoneNumber;
  emails: ProfileConfigCompanyProfileKeyEmail;
  website: ProfileConfigCompanyProfileKeyWebsite;
  street: ProfileConfigCompanyProfileKeyStreet;
  street_number: ProfileConfigCompanyProfileKeyStreetNumber;
  address_additional_details: ProfileConfigCompanyProfileKeyAddressAdditionalDetails;
  zip_code: ProfileConfigCompanyProfileKeyZipCode;
  is_club: ProfileConfigCompanyProfileKeyIsClub;
  entrance_fee: ProfileConfigCompanyProfileKeyEntranceFee;
  wellness: ProfileConfigCompanyProfileKeyWellness;
  food_and_drinks: ProfileConfigCompanyProfileKeyFoodAndDrinks;
  outdoor_area: ProfileConfigCompanyProfileKeyOutdoorArea;
}

export interface ProfileConfigCompanyProfileKeyAboutMe {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  need_moderation: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfileKeyAddressAdditionalDetails {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfileKeyArea {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  is_required: Scalars['Boolean'];
  count: Scalars['Int'];
}

export interface ProfileConfigCompanyProfileKeyAvailable_24_7 {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigCompanyProfileKeyCity {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  is_required: Scalars['Boolean'];
  count: Scalars['Int'];
}

export interface ProfileConfigCompanyProfileKeyDisplayName {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfileKeyEmail {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  need_verification: Scalars['Boolean'];
  check_uniqueness: Scalars['Boolean'];
  count: Scalars['Int'];
}

export interface ProfileConfigCompanyProfileKeyEntranceFee {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfileKeyFoodAndDrinks {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfileKeyIsClub {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
}

export interface ProfileConfigCompanyProfileKeyOutdoorArea {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfileKeyPhoneNumber {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  is_required: Scalars['Boolean'];
  need_moderation: Scalars['Boolean'];
  need_verification: Scalars['Boolean'];
  check_uniqueness: Scalars['Boolean'];
  count: Scalars['Int'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfileKeyRateIncall {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileConfigCompanyProfileKeyRateOutcall {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileConfigCompanyProfileKeyShowname {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfileKeyStreet {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfileKeyStreetNumber {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfileKeyType {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfileKeyWebsite {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
}

export interface ProfileConfigCompanyProfileKeyWellness {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfileKeyWorkingHour {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigCompanyProfileKeyZipCode {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigCompanyProfilePhoto {
  count: ProfileConfigCompanyProfilePhotoCount;
}

export interface ProfileConfigCompanyProfilePhotoCount {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigGalleryTitle {
  min: Scalars['Int'];
  max: Scalars['Int'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigHeight {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigHipSize {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigMemberProfileKey {
  showname: ProfileConfigMemberProfileKeyShowname;
  about_me: ProfileConfigMemberProfileKeyAboutMe;
  area_id: ProfileConfigMemberProfileKeyAreaId;
}

export interface ProfileConfigMemberProfileKeyAboutMe {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigMemberProfileKeyAreaId {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
}

export interface ProfileConfigMemberProfileKeyShowname {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigNaturalPhoto {
  count: ProfileConfigNaturalPhotoCount;
}

export interface ProfileConfigNaturalPhotoCount {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigProfileAddressAdditionalDetails {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigProfileClubName {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigProfileKey {
  showname: ProfileConfigProfileKeyShowname;
  slogan: ProfileConfigProfileKeySlogan;
  gender: ProfileConfigProfileKeyGender;
  age: ProfileConfigProfileKeyAge;
  ethnicity: ProfileConfigProfileKeyEthnicity;
  nationality: ProfileConfigProfileKeyNationality;
  home_city_id: ProfileConfigProfileKeyHomeCityId;
  hair_color: ProfileConfigProfileKeyHairColor;
  eye_color: ProfileConfigProfileKeyEyeColor;
  height: ProfileConfigProfileKeyHeight;
  height_royal: ProfileConfigProfileKeyHeightRoyal;
  weight: ProfileConfigProfileKeyWeight;
  weight_royal: ProfileConfigProfileKeyWeightRoyal;
  dress_size: ProfileConfigProfileKeyDressSize;
  dress_size_royal: ProfileConfigProfileKeyDressSizeRoyal;
  shoe_size: ProfileConfigProfileKeyShoeSize;
  shoe_size_royal: ProfileConfigProfileKeyShoeSizeRoyal;
  bust_size: ProfileConfigProfileKeyBustSize;
  waist_size: ProfileConfigProfileKeyWaistSize;
  hip_size: ProfileConfigProfileKeyHipSize;
  cup_size: ProfileConfigProfileKeyCupSize;
  pubic_hair: ProfileConfigProfileKeyPubicHair;
  about_me: ProfileConfigProfileKeyAboutMe;
  smoking: ProfileConfigProfileKeySmoking;
  drinking: ProfileConfigProfileKeyDrinking;
  special_characteristics: ProfileConfigProfileKeySpecialCharacteristic;
  languages: ProfileConfigProfileKeyLanguage;
  areas: ProfileConfigProfileKeyArea;
  cities: ProfileConfigProfileKeyCity;
  available_for_incall: ProfileConfigProfileKeyAvailableForIncall;
  available_for_outcall: ProfileConfigProfileKeyAvailableForOutcall;
  sexual_orientation: ProfileConfigProfileKeySexualOrientation;
  services_for: ProfileConfigProfileKeyServiceFor;
  provided_services: ProfileConfigProfileKeyProvidedService;
  additional_provided_services: ProfileConfigProfileKeyAdditionalProvidedService;
  available_24_7: ProfileConfigProfileKeyAvailable_24_7;
  show_as_night_escort: ProfileConfigProfileKeyShowAsNightEscort;
  working_hours: ProfileConfigProfileKeyWorkingHour;
  rates_incall: ProfileConfigProfileKeyRateIncall;
  rates_outcall: ProfileConfigProfileKeyRateOutcall;
  phone_numbers: ProfileConfigProfileKeyPhoneNumber;
  emails: ProfileConfigProfileKeyEmail;
  website: ProfileConfigProfileKeyWebsite;
  videogirlsch_url: ProfileConfigProfileKeyVideogirlschUrl;
  club_name: ProfileConfigProfileKeyClubName;
  street: ProfileConfigProfileKeyStreet;
  street_number: ProfileConfigProfileKeyStreetNumber;
  address_additional_details: ProfileConfigProfileKeyAddressAdditionalDetails;
  zip_code: ProfileConfigProfileKeyZipCode;
  skype_id: ProfileConfigProfileKeySkypeId;
  map_location: ProfileConfigProfileKeyMapLocation;
  country_visibility: ProfileConfigProfileKeyCountryVisibility;
}

export interface ProfileConfigProfileKeyAboutMe {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  need_moderation: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeyAdditionalProvidedService {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeyAddressAdditionalDetails {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeyAge {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
}

export interface ProfileConfigProfileKeyArea {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  is_required: Scalars['Boolean'];
  count: Scalars['Int'];
}

export interface ProfileConfigProfileKeyAvailableForIncall {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeyAvailableForOutcall {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeyAvailable_24_7 {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyBustSize {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyCity {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  is_required: Scalars['Boolean'];
  count: Scalars['Int'];
}

export interface ProfileConfigProfileKeyClubName {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeyCountryVisibility {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  modes?: Maybe<Array<ProfileCountryVisibilityMode>>;
  count: Scalars['Int'];
}

export interface ProfileConfigProfileKeyCupSize {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyDressSize {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyDressSizeRoyal {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyDrinking {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyEmail {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  need_verification: Scalars['Boolean'];
  check_uniqueness: Scalars['Boolean'];
  count: Scalars['Int'];
}

export interface ProfileConfigProfileKeyEthnicity {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyEyeColor {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyGender {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  is_required: Scalars['Boolean'];
  lock_after_first_input: Scalars['Boolean'];
}

export interface ProfileConfigProfileKeyHairColor {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyHeight {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyHeightRoyal {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyHipSize {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyHomeCityId {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyLanguage {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  is_required: Scalars['Boolean'];
}

export interface ProfileConfigProfileKeyMapLocation {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeyNationality {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyPhoneNumber {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  is_required: Scalars['Boolean'];
  need_moderation: Scalars['Boolean'];
  need_verification: Scalars['Boolean'];
  check_uniqueness: Scalars['Boolean'];
  count: Scalars['Int'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeyProvidedService {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileConfigProfileKeyPubicHair {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyRateIncall {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileConfigProfileKeyRateOutcall {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileConfigProfileKeyServiceFor {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  is_required: Scalars['Boolean'];
}

export interface ProfileConfigProfileKeySexualOrientation {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyShoeSize {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyShoeSizeRoyal {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyShowAsNightEscort {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyShowname {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeySkypeId {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeySlogan {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeySmoking {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeySpecialCharacteristic {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeyStreet {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeyStreetNumber {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeyVideogirlschUrl {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfileKeyWaistSize {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyWebsite {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
}

export interface ProfileConfigProfileKeyWeight {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyWeightRoyal {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
}

export interface ProfileConfigProfileKeyWorkingHour {
  type: ProfileFieldType;
  default_value?: Maybe<Array<Maybe<Scalars['String']>>>;
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigProfileKeyZipCode {
  type: ProfileFieldType;
  default_value?: Maybe<Scalars['String']>;
  need_moderation: Scalars['Boolean'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigProfilePhoto {
  count: ProfileConfigProfilePhotoCount;
}

export interface ProfileConfigProfilePhotoCount {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigProfileSkypeId {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigProfileStreet {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigProfileStreetNumber {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigProfileVideogirlschUrl {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigProfileZipCode {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigRateType {
  value: ProfileRateType;
  need_time: Scalars['Boolean'];
}

export interface ProfileConfigShoeSize {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigShoeSizeRoyal {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigShowname {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigSlogan {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigSpecialCharacteristic {
  min: Scalars['Int'];
  max: Scalars['Int'];
  valid_pattern: Scalars['String'];
}

export interface ProfileConfigVideo {
  count: ProfileConfigVideoCount;
  title: ProfileConfigVideoTitle;
}

export interface ProfileConfigVideoCount {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigVideoCover {
  count: ProfileConfigVideoCoverCount;
}

export interface ProfileConfigVideoCoverCount {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigVideoTitle {
  min: Scalars['Int'];
  max: Scalars['Int'];
  html_allowed_tags?: Maybe<Array<Scalars['String']>>;
  html_allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileConfigWaistSize {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigWeight {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigWeightRoyal {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileConfigZipCode {
  valid_pattern: Scalars['String'];
}

export interface ProfileCountryVisibility {
  mode: ProfileCountryVisibilityMode;
  ids?: Maybe<Array<Scalars['Int']>>;
}

export interface ProfileCountryVisibilityInput {
  mode: ProfileCountryVisibilityMode;
  ids?: Maybe<Array<Scalars['Int']>>;
}

export enum ProfileCountryVisibilityMode {
  Allow = 'ALLOW',
  Block = 'BLOCK'
}

export interface ProfileCreateFeedbackResponse {
  id?: Maybe<Scalars['String']>;
}

export interface ProfileCreateResponse {
  id: Scalars['String'];
}

export enum ProfileCupSize {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  Dd = 'DD',
  F = 'F',
  Ff = 'FF',
  G = 'G',
  H = 'H',
  J = 'J'
}

export interface ProfileDeleteResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileDisableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export enum ProfileDressSize {
  Xs = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  Xl = 'XL',
  Xxl = 'XXL'
}

export enum ProfileDressSizeRoyal {
  Xs = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  Xl = 'XL',
  Xxl = 'XXL'
}

export enum ProfileDrinking {
  Yes = 'YES',
  No = 'NO',
  Occasionally = 'OCCASIONALLY'
}

export interface ProfileEmail {
  id: Scalars['String'];
  value: Scalars['String'];
}

export interface ProfileEmailInput {
  id: Scalars['String'];
  value: Scalars['String'];
}

export interface ProfileEmailPublic {
  id: Scalars['String'];
}

export interface ProfileEmailUniquenessCheckResult {
  unique: Scalars['Boolean'];
}

export interface ProfileEnableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileEscortbookDisableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileEscortbookEnableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export enum ProfileEthnicity {
  Asian = 'ASIAN',
  Black = 'BLACK',
  White = 'WHITE',
  Latin = 'LATIN',
  Mixed = 'MIXED',
  Indian = 'INDIAN',
  Arabian = 'ARABIAN',
  Caucasian = 'CAUCASIAN'
}

export enum ProfileEyeColor {
  Black = 'BLACK',
  Brown = 'BROWN',
  Green = 'GREEN',
  Blue = 'BLUE',
  Gray = 'GRAY'
}

export enum ProfileFeedbackEntityType {
  Profile = 'PROFILE',
  CompanyProfile = 'COMPANY_PROFILE',
  Tour = 'TOUR'
}

export enum ProfileFieldType {
  Scalar = 'SCALAR',
  Set = 'SET',
  Nested = 'NESTED',
  Collection = 'COLLECTION'
}

export interface ProfileGallery {
  _id: Scalars['String'];
  title: Scalars['String'];
  is_public: Scalars['Boolean'];
  is_available_for_customer: Scalars['Boolean'];
  can_be_modified: Scalars['Boolean'];
  can_be_uploaded_into: Scalars['Boolean'];
  can_be_moved_into: Scalars['Boolean'];
  ordering: Scalars['Int'];
  profile_id: Scalars['String'];
  date_created?: Maybe<Scalars['Date']>;
  created_by: Scalars['Int'];
  photos?: Maybe<Array<ProfilePhoto>>;
}

export interface ProfileGalleryInput {
  title: Scalars['String'];
  is_public: Scalars['Boolean'];
}

export interface ProfileGalleryUpdateResponse {
  result: Scalars['Boolean'];
}

export enum ProfileGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Trans = 'TRANS'
}

export interface ProfileGotm {
  profile_id: Scalars['String'];
  profile?: Maybe<PublicProfile>;
  account_id: Scalars['Int'];
  count: Scalars['Int'];
  win_date: Scalars['String'];
  date_created: Scalars['Date'];
}

export interface ProfileGotmAddVotesResponse {
  result: Scalars['Boolean'];
}

export interface ProfileGotmHistoryResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<ProfileGotm>>>;
}

export interface ProfileGotmRating {
  profile_id: Scalars['String'];
  profile?: Maybe<PublicProfile>;
  count: Scalars['Int'];
}

export interface ProfileGotmSetWinnerResponse {
  result: Scalars['Boolean'];
}

export interface ProfileGotmVoteResponse {
  id: Scalars['String'];
}

export enum ProfileHairColor {
  Blond = 'BLOND',
  LightBrown = 'LIGHT_BROWN',
  Brunette = 'BRUNETTE',
  Black = 'BLACK',
  Red = 'RED',
  Other = 'OTHER'
}

export enum ProfileHairLength {
  VeryShort = 'VERY_SHORT',
  Short = 'SHORT',
  Medium = 'MEDIUM',
  Long = 'LONG',
  VeryLong = 'VERY_LONG'
}

export interface ProfileHappyHour {
  _id: Scalars['String'];
  profile_id: Scalars['String'];
  profile_reference_id: Scalars['Int'];
  account_id: Scalars['Int'];
  ops_account_id: Scalars['Int'];
  rates_incall?: Maybe<Array<Maybe<ProfileHappyHourRate>>>;
  rates_outcall?: Maybe<Array<Maybe<ProfileHappyHourRate>>>;
  day_of_week: GlobalWeekDay;
  from: ProfileHappyHourTime;
  to: ProfileHappyHourTime;
  recurring: Scalars['Boolean'];
  status: ProfileHappyHourStatuses;
  is_visible?: Maybe<Scalars['Boolean']>;
  date_created?: Maybe<Scalars['Date']>;
  created_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
  date_activated?: Maybe<Scalars['Date']>;
  date_expired?: Maybe<Scalars['Date']>;
}

export interface ProfileHappyHourByFiltersForCellarResponse {
  count?: Maybe<Scalars['Int']>;
  happyHours?: Maybe<Array<Maybe<ProfileHappyHour>>>;
}

export interface ProfileHappyHourConfig {
  duration?: Maybe<ProfileHappyHourDuration>;
  min_discount?: Maybe<Scalars['Int']>;
  company_profile_limits?: Maybe<Scalars['Int']>;
  incall_outcall_required?: Maybe<Scalars['Boolean']>;
  max_hh_count_per_rate_type?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<ProfileHappyHourStatuses>>;
}

export interface ProfileHappyHourCreateResult {
  id: Scalars['String'];
}

export interface ProfileHappyHourDeleteResponse {
  result: Scalars['Boolean'];
}

export interface ProfileHappyHourDuration {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
}

export interface ProfileHappyHourExpireResponse {
  result: Scalars['Boolean'];
  createdId?: Maybe<Scalars['String']>;
}

export interface ProfileHappyHourFiltersForCellarInput {
  ops_account_id?: Maybe<Scalars['Int']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['ID']>;
  status?: Maybe<Array<Maybe<ProfileHappyHourStatuses>>>;
}

export interface ProfileHappyHourHistoryFiltersInput {
  profile_reference_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['ID']>;
  status?: Maybe<Array<Maybe<ProfileHappyHourHistoryStatuses>>>;
}

export enum ProfileHappyHourHistoryStatuses {
  Expired = 'EXPIRED',
  Reseted = 'RESETED',
  Deleted = 'DELETED'
}

export interface ProfileHappyHourInput {
  profile_id: Scalars['String'];
  day_of_week: GlobalWeekDay;
  from: ProfileHappyHourTimeInput;
  to: ProfileHappyHourTimeInput;
  recurring: Scalars['Boolean'];
  rates_incall?: Maybe<Array<Maybe<ProfileHappyHourRateInput>>>;
  rates_outcall?: Maybe<Array<Maybe<ProfileHappyHourRateInput>>>;
}

export interface ProfileHappyHourModifyInput {
  recurring?: Maybe<Scalars['Boolean']>;
  day_of_week?: Maybe<GlobalWeekDay>;
  from: ProfileHappyHourTimeInput;
  to: ProfileHappyHourTimeInput;
  rates_incall?: Maybe<Array<Maybe<ProfileHappyHourRateInput>>>;
  rates_outcall?: Maybe<Array<Maybe<ProfileHappyHourRateInput>>>;
}

export interface ProfileHappyHourModifyResponse {
  result: Scalars['Boolean'];
}

export interface ProfileHappyHourRate {
  time?: Maybe<Scalars['Int']>;
  type: ProfileRateType;
  price: Scalars['Int'];
  base_price?: Maybe<Scalars['Int']>;
}

export interface ProfileHappyHourRateInput {
  time?: Maybe<Scalars['Int']>;
  type: ProfileRateType;
  price: Scalars['Int'];
}

export interface ProfileHappyHourResetResponse {
  result: Scalars['Boolean'];
}

export enum ProfileHappyHourStatuses {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Reseted = 'RESETED',
  Deleted = 'DELETED'
}

export interface ProfileHappyHourTime {
  hour: Scalars['Int'];
  minutes: Scalars['Int'];
}

export interface ProfileHappyHourTimeInput {
  hour: Scalars['Int'];
  minutes: Scalars['Int'];
}

export interface ProfileHappyHourVisibilityResponse {
  result: Scalars['Boolean'];
}

export interface ProfileInput {
  showname?: Maybe<Scalars['String']>;
  slogan?: Maybe<Scalars['String']>;
  gender?: Maybe<ProfileGender>;
  age?: Maybe<Scalars['Int']>;
  home_city_id?: Maybe<Scalars['Int']>;
  ethnicity?: Maybe<ProfileEthnicity>;
  nationality?: Maybe<ProfileNationality>;
  hair_color?: Maybe<ProfileHairColor>;
  eye_color?: Maybe<ProfileEyeColor>;
  height?: Maybe<Scalars['Int']>;
  height_royal?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  weight_royal?: Maybe<Scalars['Int']>;
  dress_size?: Maybe<ProfileDressSize>;
  dress_size_royal?: Maybe<ProfileDressSizeRoyal>;
  shoe_size?: Maybe<Scalars['Int']>;
  shoe_size_royal?: Maybe<Scalars['Int']>;
  bust_size?: Maybe<Scalars['Int']>;
  waist_size?: Maybe<Scalars['Int']>;
  hip_size?: Maybe<Scalars['Int']>;
  cup_size?: Maybe<ProfileCupSize>;
  pubic_hair?: Maybe<ProfilePubicHair>;
  about_me?: Maybe<Array<ProfileAboutMeInput>>;
  smoking?: Maybe<ProfileSmoking>;
  drinking?: Maybe<ProfileDrinking>;
  special_characteristics?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<ProfileLanguageInput>>;
  areas?: Maybe<Array<ProfileAreaInput>>;
  cities?: Maybe<Array<Maybe<ProfileCityInput>>>;
  available_for_incall?: Maybe<ProfileAvailableForIncallInput>;
  available_for_outcall?: Maybe<ProfileAvailableForOutcallInput>;
  sexual_orientation?: Maybe<ProfileSexualOrientation>;
  services_for?: Maybe<Array<ProfileServiceFor>>;
  provided_services?: Maybe<Array<ProfileProvidedServiceInput>>;
  additional_provided_services?: Maybe<Array<ProfileAdditionalProvidedServiceInput>>;
  available_24_7?: Maybe<Scalars['Boolean']>;
  show_as_night_escort?: Maybe<Scalars['Boolean']>;
  working_hours?: Maybe<Array<Maybe<ProfileWorkingHourInput>>>;
  rates_incall?: Maybe<Array<Maybe<ProfileRateIncallInput>>>;
  rates_outcall?: Maybe<Array<Maybe<ProfileRateOutcallInput>>>;
  phone_numbers?: Maybe<Array<ProfilePhoneNumberInput>>;
  emails?: Maybe<Array<ProfileEmailInput>>;
  website?: Maybe<Scalars['String']>;
  club_name?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  address_additional_details?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  skype_id?: Maybe<Scalars['String']>;
  videogirlsch_url?: Maybe<Scalars['String']>;
  country_visibility?: Maybe<ProfileCountryVisibilityInput>;
  map_location?: Maybe<ProfileMapLocationInput>;
  address_location?: Maybe<ProfileAddressLocationInput>;
}

export interface ProfileInstantBookActivatedEvent {
  id: Scalars['String'];
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  status: InstantBookAvailabilityStatuses;
}

export interface ProfileInstantBookCanceledEvent {
  id: Scalars['String'];
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
}

export interface ProfileInstantBookCreatedEvent {
  id: Scalars['String'];
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
}

export interface ProfileInstantBookExpiredEvent {
  id: Scalars['String'];
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  status: InstantBookAvailabilityStatuses;
}

export interface ProfileInstantBookRequestCreatedEvent {
  id: Scalars['String'];
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  profile?: Maybe<PublicProfile>;
}

export interface ProfileInstantBookRequestEvent {
  id: Scalars['String'];
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  hash: Scalars['String'];
  profile?: Maybe<PublicProfile>;
}

export interface ProfileInstantBookRequestGlobalEvent {
  hash: Scalars['String'];
  profile_id: Scalars['String'];
  profile?: Maybe<PublicProfile>;
}

export interface ProfileKeepVerificationSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileKeepVerificationUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileLanguage {
  id: Scalars['String'];
  language: ProfileSpokenLanguage;
  level: ProfileSpokenLanguageLevel;
}

export interface ProfileLanguageInput {
  id: Scalars['String'];
  language: ProfileSpokenLanguage;
  level: ProfileSpokenLanguageLevel;
}

export interface ProfileLastTopicFirstPost {
  post?: Maybe<ForumPost>;
  topic_slug?: Maybe<Scalars['String']>;
}

export interface ProfileMapLocation {
  is_visible?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  address?: Maybe<Scalars['String']>;
}

export interface ProfileMapLocationInput {
  is_visible: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  address: Scalars['String'];
}

export interface ProfileMemberCommentsResponse {
  result: Array<Maybe<MemberComment>>;
  count: Scalars['Int'];
}

export enum ProfileMemberPhotoStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export enum ProfileMemberRating {
  Trusted = 'TRUSTED',
  Junior = 'JUNIOR',
  Senior = 'SENIOR',
  Diamond = 'DIAMOND',
  Vip = 'VIP',
  VipPlus = 'VIP_PLUS'
}

export interface ProfileMeta {
  reference_id: Scalars['Int'];
  company_id?: Maybe<Scalars['String']>;
  account_id: Scalars['Int'];
  account?: Maybe<Account>;
  ops_account_id: Scalars['Int'];
  type: ProfileMetaType;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
  is_initial_create_done: Scalars['Boolean'];
  is_incomplete: Scalars['Boolean'];
  is_incomplete_pending: Scalars['Boolean'];
  is_deleted: Scalars['Boolean'];
  date_deleted?: Maybe<Scalars['Date']>;
  is_disabled: Scalars['Boolean'];
  is_admin_disabled: Scalars['Boolean'];
  date_admin_disabled?: Maybe<Scalars['Date']>;
  is_pseudo_escort: Scalars['Boolean'];
  is_age_verified: Scalars['Boolean'];
  age_verification_keep_online: Scalars['Boolean'];
  not_enough_approved_photos: Scalars['Boolean'];
  can_be_online: Scalars['Boolean'];
  are_comments_disabled: Scalars['Boolean'];
  are_reviews_disabled: Scalars['Boolean'];
  keep_100_verification: Scalars['Boolean'];
  show_bad_photo_history_icon: Scalars['Boolean'];
  is_under_investigation: Scalars['Boolean'];
  are_photos_suspicious: Scalars['Boolean'];
  suspicious_photos_reason?: Maybe<Scalars['String']>;
  show_suspicious_photos_reason: Scalars['Boolean'];
  is_vip: Scalars['Boolean'];
  is_escortbook_disabled: Scalars['Boolean'];
  is_camplace_disabled: Scalars['Boolean'];
  camplace_channel_url?: Maybe<Scalars['String']>;
  camplace_state?: Maybe<Scalars['String']>;
  sms_notify_package_expiration: Scalars['Boolean'];
  sms_notify_profile_online: Scalars['Boolean'];
  website_status?: Maybe<ProfileMetaWebsiteStatus>;
  all_photos_rotatable: Scalars['Boolean'];
  periodic_bumps_disabled?: Maybe<Scalars['Boolean']>;
  is_online?: Maybe<Scalars['Boolean']>;
  top_10_rating?: Maybe<Scalars['Int']>;
  no_new?: Maybe<Scalars['Boolean']>;
}

export enum ProfileMetaType {
  Company = 'COMPANY',
  CompanyAd = 'COMPANY_AD',
  Ad = 'AD',
  Member = 'MEMBER'
}

export enum ProfileMetaWebsiteStatus {
  Checked = 'CHECKED',
  Blocked = 'BLOCKED'
}

export enum ProfileNationality {
  Afghan = 'AFGHAN',
  Albanian = 'ALBANIAN',
  Algerian = 'ALGERIAN',
  American = 'AMERICAN',
  Andorran = 'ANDORRAN',
  Angolan = 'ANGOLAN',
  Antiguan = 'ANTIGUAN',
  Argentinian = 'ARGENTINIAN',
  Armenian = 'ARMENIAN',
  Australian = 'AUSTRALIAN',
  Austrian = 'AUSTRIAN',
  Azerbaijani = 'AZERBAIJANI',
  Bahamian = 'BAHAMIAN',
  Bahraini = 'BAHRAINI',
  Bangladeshi = 'BANGLADESHI',
  Barbadian = 'BARBADIAN',
  Barbudan = 'BARBUDAN',
  Batswana = 'BATSWANA',
  Belarussian = 'BELARUSSIAN',
  Belgian = 'BELGIAN',
  Belizean = 'BELIZEAN',
  Beninese = 'BENINESE',
  Bhutanese = 'BHUTANESE',
  Bolivian = 'BOLIVIAN',
  Bosnian = 'BOSNIAN',
  Brazilian = 'BRAZILIAN',
  British = 'BRITISH',
  Bruneian = 'BRUNEIAN',
  Bulgarian = 'BULGARIAN',
  Burkinabe = 'BURKINABE',
  Burmese = 'BURMESE',
  Burundian = 'BURUNDIAN',
  Cambodian = 'CAMBODIAN',
  Cameroonian = 'CAMEROONIAN',
  Canadian = 'CANADIAN',
  CapeVerdean = 'CAPE_VERDEAN',
  CentralAfrican = 'CENTRAL_AFRICAN',
  Chadian = 'CHADIAN',
  Chilean = 'CHILEAN',
  Chinese = 'CHINESE',
  Colombian = 'COLOMBIAN',
  Comoran = 'COMORAN',
  Congolese = 'CONGOLESE',
  CostaRican = 'COSTA_RICAN',
  Croatian = 'CROATIAN',
  Cuban = 'CUBAN',
  Cypriot = 'CYPRIOT',
  Czech = 'CZECH',
  Danish = 'DANISH',
  Djibouti = 'DJIBOUTI',
  Dominican = 'DOMINICAN',
  Dutch = 'DUTCH',
  EastTimorese = 'EAST_TIMORESE',
  Ecuadorean = 'ECUADOREAN',
  Egyptian = 'EGYPTIAN',
  Emirian = 'EMIRIAN',
  EquatorialGuinean = 'EQUATORIAL_GUINEAN',
  Eritrean = 'ERITREAN',
  Estonian = 'ESTONIAN',
  Ethiopian = 'ETHIOPIAN',
  Fijian = 'FIJIAN',
  Filipino = 'FILIPINO',
  Finnish = 'FINNISH',
  French = 'FRENCH',
  Gabonese = 'GABONESE',
  Gambian = 'GAMBIAN',
  Georgian = 'GEORGIAN',
  German = 'GERMAN',
  Ghanaian = 'GHANAIAN',
  Greek = 'GREEK',
  Grenadian = 'GRENADIAN',
  Guatemalan = 'GUATEMALAN',
  GuineaBissauan = 'GUINEA_BISSAUAN',
  Guinean = 'GUINEAN',
  Guyanese = 'GUYANESE',
  Haitian = 'HAITIAN',
  Herzegovinian = 'HERZEGOVINIAN',
  Honduran = 'HONDURAN',
  Hungarian = 'HUNGARIAN',
  IKiribati = 'I_KIRIBATI',
  Icelander = 'ICELANDER',
  Indian = 'INDIAN',
  Indonesian = 'INDONESIAN',
  Iranian = 'IRANIAN',
  Iraqi = 'IRAQI',
  Irish = 'IRISH',
  Israeli = 'ISRAELI',
  Italian = 'ITALIAN',
  Ivorian = 'IVORIAN',
  Jamaican = 'JAMAICAN',
  Japanese = 'JAPANESE',
  Jordanian = 'JORDANIAN',
  Kazakhstani = 'KAZAKHSTANI',
  Kenyan = 'KENYAN',
  KittianAndNevisian = 'KITTIAN_AND_NEVISIAN',
  Korean = 'KOREAN',
  Kuwaiti = 'KUWAITI',
  Kyrgyz = 'KYRGYZ',
  Laotian = 'LAOTIAN',
  Latvian = 'LATVIAN',
  Lebanese = 'LEBANESE',
  Liberian = 'LIBERIAN',
  Libyan = 'LIBYAN',
  Liechtensteiner = 'LIECHTENSTEINER',
  Lithuanian = 'LITHUANIAN',
  Luxembourger = 'LUXEMBOURGER',
  Macedonian = 'MACEDONIAN',
  Malagasy = 'MALAGASY',
  Malawian = 'MALAWIAN',
  Malaysian = 'MALAYSIAN',
  Maldivan = 'MALDIVAN',
  Malian = 'MALIAN',
  Maltese = 'MALTESE',
  Marshallese = 'MARSHALLESE',
  Mauritanian = 'MAURITANIAN',
  Mauritian = 'MAURITIAN',
  Mexican = 'MEXICAN',
  Micronesian = 'MICRONESIAN',
  Moldovian = 'MOLDOVIAN',
  Monacan = 'MONACAN',
  Mongolian = 'MONGOLIAN',
  Montenegrian = 'MONTENEGRIAN',
  Moroccan = 'MOROCCAN',
  Mosotho = 'MOSOTHO',
  Motswana = 'MOTSWANA',
  Mozambican = 'MOZAMBICAN',
  Namibian = 'NAMIBIAN',
  Nauruan = 'NAURUAN',
  Nepalese = 'NEPALESE',
  NewZealander = 'NEW_ZEALANDER',
  Nicaraguan = 'NICARAGUAN',
  Nigerian = 'NIGERIAN',
  Nigerien = 'NIGERIEN',
  NorthKorean = 'NORTH_KOREAN',
  NorthernIrish = 'NORTHERN_IRISH',
  Norwegian = 'NORWEGIAN',
  Omani = 'OMANI',
  Pakistani = 'PAKISTANI',
  Palauan = 'PALAUAN',
  Panamanian = 'PANAMANIAN',
  PapuaNewGuinean = 'PAPUA_NEW_GUINEAN',
  Paraguayan = 'PARAGUAYAN',
  Peruvian = 'PERUVIAN',
  Polish = 'POLISH',
  Portuguese = 'PORTUGUESE',
  PuertoRican = 'PUERTO_RICAN',
  Qatari = 'QATARI',
  Romanian = 'ROMANIAN',
  Russian = 'RUSSIAN',
  Rwandan = 'RWANDAN',
  SaintLucian = 'SAINT_LUCIAN',
  Salvadoran = 'SALVADORAN',
  Samoan = 'SAMOAN',
  SanMarinese = 'SAN_MARINESE',
  SaoTomean = 'SAO_TOMEAN',
  Saudi = 'SAUDI',
  Scottish = 'SCOTTISH',
  Senegalese = 'SENEGALESE',
  Serbian = 'SERBIAN',
  Seychellois = 'SEYCHELLOIS',
  SierraLeonean = 'SIERRA_LEONEAN',
  Singaporean = 'SINGAPOREAN',
  Slovak = 'SLOVAK',
  Slovenian = 'SLOVENIAN',
  SolomonIslander = 'SOLOMON_ISLANDER',
  Somali = 'SOMALI',
  SouthAfrican = 'SOUTH_AFRICAN',
  SouthKorean = 'SOUTH_KOREAN',
  Spanish = 'SPANISH',
  SriLankan = 'SRI_LANKAN',
  Sudanese = 'SUDANESE',
  Surinamer = 'SURINAMER',
  Swazi = 'SWAZI',
  Swedish = 'SWEDISH',
  Swiss = 'SWISS',
  Syrian = 'SYRIAN',
  Taiwanese = 'TAIWANESE',
  Tajik = 'TAJIK',
  Tanzanian = 'TANZANIAN',
  Thai = 'THAI',
  Togolese = 'TOGOLESE',
  Tongan = 'TONGAN',
  TrinidadianOrTobagonian = 'TRINIDADIAN_OR_TOBAGONIAN',
  Tunisian = 'TUNISIAN',
  Turkish = 'TURKISH',
  Tuvaluan = 'TUVALUAN',
  Ugandan = 'UGANDAN',
  Ukrainian = 'UKRAINIAN',
  Uruguayan = 'URUGUAYAN',
  Uzbekistani = 'UZBEKISTANI',
  Venezuelan = 'VENEZUELAN',
  Vietnamese = 'VIETNAMESE',
  Welsh = 'WELSH',
  Yemenite = 'YEMENITE',
  Zambian = 'ZAMBIAN',
  Zimbabwean = 'ZIMBABWEAN'
}

export interface ProfileNaturalPhoto {
  _id: Scalars['String'];
  account_id: Scalars['Int'];
  sales_account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  image_id: Scalars['String'];
  image?: Maybe<Image>;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
  status: ProfileNaturalPhotoStatus;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileNaturalPhotoApproveResponse {
  result: Scalars['Boolean'];
}

export interface ProfileNaturalPhotoCreateResponse {
  ids?: Maybe<Array<Scalars['String']>>;
}

export interface ProfileNaturalPhotoDeclineInput {
  id: Scalars['String'];
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileNaturalPhotoDeclineResponse {
  result: Scalars['Boolean'];
}

export interface ProfileNaturalPhotoDeleteResponse {
  result: Scalars['Boolean'];
}

export interface ProfileNaturalPhotoRestoreResponse {
  result: Scalars['Boolean'];
}

export enum ProfileNaturalPhotoStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export enum ProfilePartialProcessAction {
  Approve = 'APPROVE',
  Decline = 'DECLINE'
}

export interface ProfilePeriodicBumpsDisableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfilePeriodicBumpsEnableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfilePhoneNumber {
  id: Scalars['String'];
  country_code: Scalars['String'];
  number: Scalars['String'];
  apps?: Maybe<Array<GlobalPhoneApp>>;
  instruction?: Maybe<GlobalPhoneInstruction>;
  instruction_other?: Maybe<Scalars['String']>;
  no_withheld_numbers?: Maybe<Scalars['Boolean']>;
  international_number: Scalars['String'];
  is_visible?: Maybe<Scalars['Boolean']>;
}

export interface ProfilePhoneNumberInput {
  id: Scalars['String'];
  country_code: Scalars['String'];
  number: Scalars['String'];
  apps?: Maybe<Array<GlobalPhoneApp>>;
  instruction?: Maybe<GlobalPhoneInstruction>;
  instruction_other?: Maybe<Scalars['String']>;
  no_withheld_numbers?: Maybe<Scalars['Boolean']>;
  is_visible: Scalars['Boolean'];
}

export interface ProfilePhoneUniquenessCheckResult {
  unique: Scalars['Boolean'];
}

export interface ProfilePhoto {
  _id: Scalars['String'];
  account_id: Scalars['Int'];
  sales_account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  gallery_id: Scalars['String'];
  image_id: Scalars['String'];
  image?: Maybe<Image>;
  ai_media_moderation?: Maybe<ProfilePhotoAiMediaModeration>;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
  is_main: Scalars['Boolean'];
  status: ProfilePhotoStatus;
  is_rotatable: Scalars['Boolean'];
  need_to_be_retouched: Scalars['Boolean'];
  ordering: Scalars['Int'];
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  is_suspicious?: Maybe<Scalars['Boolean']>;
  is_under_investigation?: Maybe<Scalars['Boolean']>;
  date_deleted?: Maybe<Scalars['Date']>;
  deleted_by?: Maybe<Scalars['Int']>;
  use_for_gotd?: Maybe<Scalars['Boolean']>;
  use_for_votd?: Maybe<Scalars['Boolean']>;
}

export interface ProfilePhotoAiMediaModeration {
  _id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['ID']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<Scalars['String']>;
  image_id?: Maybe<Scalars['ID']>;
  image_path?: Maybe<Scalars['String']>;
  moderation_type?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  ai_moderation_response?: Maybe<ProfilePhotoAiMediaModerationSummaryResponse>;
}

export interface ProfilePhotoAiMediaModerationSummary {
  action?: Maybe<Scalars['String']>;
  reject_prob?: Maybe<Scalars['Float']>;
  reject_reason?: Maybe<Array<Maybe<ProfilePhotoAiRejectReasons>>>;
}

export interface ProfilePhotoAiMediaModerationSummaryResponse {
  summary?: Maybe<ProfilePhotoAiMediaModerationSummary>;
}

export interface ProfilePhotoAiRejectReasons {
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
}

export interface ProfilePhotoApproveResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoCreateResponse {
  ids?: Maybe<Array<Scalars['String']>>;
}

export interface ProfilePhotoDeclineInput {
  id: Scalars['String'];
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfilePhotoDeclineResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoDeleteResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoMoveResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoReorderResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoRestoreResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoRetouchResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoSetGotdResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoSetMainResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoSetRotatableResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoSetVotdResponse {
  result: Scalars['Boolean'];
}

export enum ProfilePhotoStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export interface ProfilePhotoUnsetGotdResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoUnsetRetouchResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoUnsetRotatableResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoUnsetVotdResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotoUpdateResponse {
  result: Scalars['Boolean'];
}

export interface ProfilePhotosSuspiciousSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfilePhotosSuspiciousUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export enum ProfilePiercing {
  No = 'NO',
  Yes = 'YES'
}

export enum ProfileProcessAction {
  Approve = 'APPROVE',
  Decline = 'DECLINE',
  Process = 'PROCESS'
}

export interface ProfileProcessInput {
  action: ProfileProcessAction;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  fields?: Maybe<ProfileChangesFieldProcess>;
}

export interface ProfileProcessResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileProvidedService {
  id: Scalars['String'];
  type: ProfileProvidedServiceType;
  value: ProfileProvidedServiceValue;
  surcharge_price?: Maybe<Scalars['Int']>;
  currency?: Maybe<GlobalCurrency>;
}

export enum ProfileProvidedServiceCommon {
  SixtyNinePosition = 'SIXTY_NINE_POSITION',
  AnalSex = 'ANAL_SEX',
  CumInMouth = 'CUM_IN_MOUTH',
  CumOnFace = 'CUM_ON_FACE',
  DildoPlayToys = 'DILDO_PLAY_TOYS',
  FrenchKissing = 'FRENCH_KISSING',
  GirlfriendExperienceGfe = 'GIRLFRIEND_EXPERIENCE_GFE',
  Kissing = 'KISSING',
  OralWithCondom = 'ORAL_WITH_CONDOM',
  OralWithoutCondom = 'ORAL_WITHOUT_CONDOM',
  OralWithoutCondomToCompletion = 'ORAL_WITHOUT_CONDOM_TO_COMPLETION',
  SexInDifferentPositions = 'SEX_IN_DIFFERENT_POSITIONS',
  CumOnBody = 'CUM_ON_BODY',
  EroticMassage = 'EROTIC_MASSAGE',
  IntimateMassage = 'INTIMATE_MASSAGE',
  KissingGoodChemistry = 'KISSING_GOOD_CHEMISTRY',
  Handjob = 'HANDJOB'
}

export enum ProfileProvidedServiceCompanion {
  WellnessVisitHotel = 'WELLNESS_VISIT_HOTEL',
  SportsActivities = 'SPORTS_ACTIVITIES',
  LunchDinner = 'LUNCH_DINNER',
  Meeting = 'MEETING'
}

export enum ProfileProvidedServiceExtra {
  BallLickingAndSucking = 'BALL_LICKING_AND_SUCKING',
  DeepThroat = 'DEEP_THROAT',
  Dirtytalk = 'DIRTYTALK',
  DoublePenetration = 'DOUBLE_PENETRATION',
  Extraball = 'EXTRABALL',
  Facesitting = 'FACESITTING',
  Fisting = 'FISTING',
  FullBodySensualMassage = 'FULL_BODY_SENSUAL_MASSAGE',
  Gangbang = 'GANGBANG',
  Kamasutra = 'KAMASUTRA',
  LesbianSexGames = 'LESBIAN_SEX_GAMES',
  Lingerie = 'LINGERIE',
  Masturbate = 'MASTURBATE',
  OralWithoutCondomSwallow = 'ORAL_WITHOUT_CONDOM_SWALLOW',
  OutdoorSex = 'OUTDOOR_SEX',
  PornstarExperiencePse = 'PORNSTAR_EXPERIENCE_PSE',
  PrivatePhotos = 'PRIVATE_PHOTOS',
  PrivateVideos = 'PRIVATE_VIDEOS',
  ProstateMassage = 'PROSTATE_MASSAGE',
  RimmingGive = 'RIMMING_GIVE',
  RimmingRecieve = 'RIMMING_RECIEVE',
  Snowballing = 'SNOWBALLING',
  StripteaseLapdance = 'STRIPTEASE_LAPDANCE',
  Tantric = 'TANTRIC',
  FoamMassage = 'FOAM_MASSAGE',
  Titjob = 'TITJOB',
  AnalMassageGive = 'ANAL_MASSAGE_GIVE',
  AnalMassageReceive = 'ANAL_MASSAGE_RECEIVE',
  ShowerService = 'SHOWER_SERVICE',
  Whirlpool = 'WHIRLPOOL',
  IntimateShaving = 'INTIMATE_SHAVING',
  Due = 'DUE'
}

export enum ProfileProvidedServiceFetish {
  Bdsm = 'BDSM',
  Bondage = 'BONDAGE',
  ClinicSex = 'CLINIC_SEX',
  Fetish = 'FETISH',
  FootFetish = 'FOOT_FETISH',
  GoldenShowerGive = 'GOLDEN_SHOWER_GIVE',
  GoldenShowerRecieve = 'GOLDEN_SHOWER_RECIEVE',
  LatherLatexPvc = 'LATHER_LATEX_PVC',
  MistressHard = 'MISTRESS_HARD',
  MistressSoft = 'MISTRESS_SOFT',
  RolePlayAndFantasy = 'ROLE_PLAY_AND_FANTASY',
  SpankingGive = 'SPANKING_GIVE',
  SpankingRecieve = 'SPANKING_RECIEVE',
  StrapOn = 'STRAP_ON',
  SubmissiveSlaveHard = 'SUBMISSIVE_SLAVE_HARD',
  SubmissiveSlaveSoft = 'SUBMISSIVE_SLAVE_SOFT',
  ScatGive = 'SCAT_GIVE',
  ScatReceive = 'SCAT_RECEIVE',
  Squirting = 'SQUIRTING'
}

export interface ProfileProvidedServiceInput {
  id: Scalars['String'];
  type: ProfileProvidedServiceType;
  value: ProfileProvidedServiceValue;
  surcharge_price?: Maybe<Scalars['Int']>;
  currency?: Maybe<GlobalCurrency>;
}

export enum ProfileProvidedServiceMassage {
  ClassicalMassage = 'CLASSICAL_MASSAGE',
  ThaiMassage = 'THAI_MASSAGE',
  HotStoneMassage = 'HOT_STONE_MASSAGE',
  ShiatsuMassage = 'SHIATSU_MASSAGE',
  SingingBowlMassage = 'SINGING_BOWL_MASSAGE',
  FootReflexMassage = 'FOOT_REFLEX_MASSAGE',
  Acupressure = 'ACUPRESSURE',
  TuinaMassage = 'TUINA_MASSAGE',
  BrushMassage = 'BRUSH_MASSAGE'
}

export enum ProfileProvidedServiceType {
  Common = 'COMMON',
  Extra = 'EXTRA',
  Fetish = 'FETISH',
  Virtual = 'VIRTUAL',
  Massage = 'MASSAGE',
  Companion = 'COMPANION'
}

export enum ProfileProvidedServiceValue {
  SixtyNinePosition = 'SIXTY_NINE_POSITION',
  AnalSex = 'ANAL_SEX',
  CumInMouth = 'CUM_IN_MOUTH',
  CumOnFace = 'CUM_ON_FACE',
  DildoPlayToys = 'DILDO_PLAY_TOYS',
  FrenchKissing = 'FRENCH_KISSING',
  GirlfriendExperienceGfe = 'GIRLFRIEND_EXPERIENCE_GFE',
  Kissing = 'KISSING',
  OralWithCondom = 'ORAL_WITH_CONDOM',
  OralWithoutCondom = 'ORAL_WITHOUT_CONDOM',
  OralWithoutCondomToCompletion = 'ORAL_WITHOUT_CONDOM_TO_COMPLETION',
  SexInDifferentPositions = 'SEX_IN_DIFFERENT_POSITIONS',
  CumOnBody = 'CUM_ON_BODY',
  EroticMassage = 'EROTIC_MASSAGE',
  IntimateMassage = 'INTIMATE_MASSAGE',
  KissingGoodChemistry = 'KISSING_GOOD_CHEMISTRY',
  Handjob = 'HANDJOB',
  BallLickingAndSucking = 'BALL_LICKING_AND_SUCKING',
  DeepThroat = 'DEEP_THROAT',
  Dirtytalk = 'DIRTYTALK',
  DoublePenetration = 'DOUBLE_PENETRATION',
  Extraball = 'EXTRABALL',
  Facesitting = 'FACESITTING',
  Fisting = 'FISTING',
  FullBodySensualMassage = 'FULL_BODY_SENSUAL_MASSAGE',
  Gangbang = 'GANGBANG',
  Kamasutra = 'KAMASUTRA',
  LesbianSexGames = 'LESBIAN_SEX_GAMES',
  Lingerie = 'LINGERIE',
  Masturbate = 'MASTURBATE',
  OralWithoutCondomSwallow = 'ORAL_WITHOUT_CONDOM_SWALLOW',
  OutdoorSex = 'OUTDOOR_SEX',
  PornstarExperiencePse = 'PORNSTAR_EXPERIENCE_PSE',
  PrivatePhotos = 'PRIVATE_PHOTOS',
  PrivateVideos = 'PRIVATE_VIDEOS',
  ProstateMassage = 'PROSTATE_MASSAGE',
  RimmingGive = 'RIMMING_GIVE',
  RimmingRecieve = 'RIMMING_RECIEVE',
  Snowballing = 'SNOWBALLING',
  StripteaseLapdance = 'STRIPTEASE_LAPDANCE',
  Tantric = 'TANTRIC',
  FoamMassage = 'FOAM_MASSAGE',
  Titjob = 'TITJOB',
  AnalMassageGive = 'ANAL_MASSAGE_GIVE',
  AnalMassageReceive = 'ANAL_MASSAGE_RECEIVE',
  ShowerService = 'SHOWER_SERVICE',
  Whirlpool = 'WHIRLPOOL',
  IntimateShaving = 'INTIMATE_SHAVING',
  Due = 'DUE',
  Bdsm = 'BDSM',
  Bondage = 'BONDAGE',
  ClinicSex = 'CLINIC_SEX',
  Fetish = 'FETISH',
  FootFetish = 'FOOT_FETISH',
  GoldenShowerGive = 'GOLDEN_SHOWER_GIVE',
  GoldenShowerRecieve = 'GOLDEN_SHOWER_RECIEVE',
  LatherLatexPvc = 'LATHER_LATEX_PVC',
  MistressHard = 'MISTRESS_HARD',
  MistressSoft = 'MISTRESS_SOFT',
  RolePlayAndFantasy = 'ROLE_PLAY_AND_FANTASY',
  SpankingGive = 'SPANKING_GIVE',
  SpankingRecieve = 'SPANKING_RECIEVE',
  StrapOn = 'STRAP_ON',
  SubmissiveSlaveHard = 'SUBMISSIVE_SLAVE_HARD',
  SubmissiveSlaveSoft = 'SUBMISSIVE_SLAVE_SOFT',
  ScatGive = 'SCAT_GIVE',
  ScatReceive = 'SCAT_RECEIVE',
  Squirting = 'SQUIRTING',
  Webcam = 'WEBCAM',
  VideoChat = 'VIDEO_CHAT',
  Phonesex = 'PHONESEX',
  Video = 'VIDEO',
  Photos = 'PHOTOS',
  OnlineDildoBj = 'ONLINE_DILDO_BJ',
  OnlineGoldenShowerActive = 'ONLINE_GOLDEN_SHOWER_ACTIVE',
  OnlineAnalDildoPlay = 'ONLINE_ANAL_DILDO_PLAY',
  OnlineMasturbation = 'ONLINE_MASTURBATION',
  OnlineRolePlay = 'ONLINE_ROLE_PLAY',
  OnlineStriptease = 'ONLINE_STRIPTEASE',
  OnlineCaviarActive = 'ONLINE_CAVIAR_ACTIVE',
  OnlineSlave = 'ONLINE_SLAVE',
  OnlineDomina = 'ONLINE_DOMINA',
  OnlineFootfetish = 'ONLINE_FOOTFETISH',
  OnlineDirtyTalk = 'ONLINE_DIRTY_TALK',
  ClassicalMassage = 'CLASSICAL_MASSAGE',
  ThaiMassage = 'THAI_MASSAGE',
  HotStoneMassage = 'HOT_STONE_MASSAGE',
  ShiatsuMassage = 'SHIATSU_MASSAGE',
  SingingBowlMassage = 'SINGING_BOWL_MASSAGE',
  FootReflexMassage = 'FOOT_REFLEX_MASSAGE',
  Acupressure = 'ACUPRESSURE',
  TuinaMassage = 'TUINA_MASSAGE',
  BrushMassage = 'BRUSH_MASSAGE',
  WellnessVisitHotel = 'WELLNESS_VISIT_HOTEL',
  SportsActivities = 'SPORTS_ACTIVITIES',
  LunchDinner = 'LUNCH_DINNER',
  Meeting = 'MEETING'
}

export enum ProfileProvidedServiceVirtual {
  Webcam = 'WEBCAM',
  VideoChat = 'VIDEO_CHAT',
  Phonesex = 'PHONESEX',
  Video = 'VIDEO',
  Photos = 'PHOTOS',
  OnlineDildoBj = 'ONLINE_DILDO_BJ',
  OnlineGoldenShowerActive = 'ONLINE_GOLDEN_SHOWER_ACTIVE',
  OnlineAnalDildoPlay = 'ONLINE_ANAL_DILDO_PLAY',
  OnlineMasturbation = 'ONLINE_MASTURBATION',
  OnlineRolePlay = 'ONLINE_ROLE_PLAY',
  OnlineStriptease = 'ONLINE_STRIPTEASE',
  OnlineCaviarActive = 'ONLINE_CAVIAR_ACTIVE',
  OnlineSlave = 'ONLINE_SLAVE',
  OnlineDomina = 'ONLINE_DOMINA',
  OnlineFootfetish = 'ONLINE_FOOTFETISH',
  OnlineDirtyTalk = 'ONLINE_DIRTY_TALK'
}

export interface ProfilePseudoEscortSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfilePseudoEscortUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export enum ProfilePubicHair {
  ShavedCompletely = 'SHAVED_COMPLETELY',
  ShavedMostly = 'SHAVED_MOSTLY',
  Trimmed = 'TRIMMED',
  AllNatural = 'ALL_NATURAL'
}

export interface ProfileRateIncall {
  id: Scalars['String'];
  time?: Maybe<Scalars['Int']>;
  type: ProfileRateType;
  price?: Maybe<Scalars['Int']>;
  currency: GlobalCurrency;
}

export interface ProfileRateIncallInput {
  id: Scalars['String'];
  time?: Maybe<Scalars['Int']>;
  type: ProfileRateType;
  price?: Maybe<Scalars['Int']>;
  currency: GlobalCurrency;
}

export interface ProfileRateOutcall {
  id: Scalars['String'];
  time?: Maybe<Scalars['Int']>;
  type: ProfileRateType;
  price?: Maybe<Scalars['Int']>;
  currency: GlobalCurrency;
}

export interface ProfileRateOutcallInput {
  id: Scalars['String'];
  time?: Maybe<Scalars['Int']>;
  type: ProfileRateType;
  price?: Maybe<Scalars['Int']>;
  currency: GlobalCurrency;
}

export enum ProfileRateType {
  Minutes = 'MINUTES',
  Hours = 'HOURS',
  Days = 'DAYS',
  AdditionalHour = 'ADDITIONAL_HOUR',
  Overnight = 'OVERNIGHT',
  DinnerDate = 'DINNER_DATE',
  Weekend = 'WEEKEND'
}

export interface ProfileReindexOnlineProfilesResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileRestoreResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileReview {
  _id: Scalars['String'];
  _meta: ProfileReviewMeta;
  profile?: Maybe<Profile>;
  member_profile?: Maybe<MemberProfile>;
  comment?: Maybe<Comment>;
  meeting_date: Scalars['Date'];
  city_id: Scalars['Int'];
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
  meeting_place: ProfileReviewMeetingPlaces;
  meeting_duration: ProfileReviewMeetingDuration;
  price: ProfileReviewPrice;
  look_rating: Scalars['Float'];
  service_rating: Scalars['Float'];
  services: Array<Maybe<ProfileReviewService>>;
  services_comment?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  confirmation_data: ProfileReviewConfirmationData;
  rate?: Maybe<Scalars['Float']>;
}

export enum ProfileReviewAnal {
  Yes = 'YES',
  No = 'NO',
  NotAvailable = 'NOT_AVAILABLE'
}

export interface ProfileReviewApproveResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export enum ProfileReviewAttitude {
  Friendly = 'FRIENDLY',
  Normal = 'NORMAL',
  Unfriendly = 'UNFRIENDLY',
  Poshy = 'POSHY'
}

export enum ProfileReviewAvailability {
  Easy = 'EASY',
  Normal = 'NORMAL',
  Hard = 'HARD',
  Sucks = 'SUCKS'
}

export enum ProfileReviewBlowjob {
  WithoutCondom = 'WITHOUT_CONDOM',
  WithCondom = 'WITH_CONDOM',
  No = 'NO'
}

export enum ProfileReviewBreast {
  Real = 'REAL',
  Silicon = 'SILICON',
  DontKnow = 'DONT_KNOW'
}

export interface ProfileReviewConfigComment {
  min: Scalars['Int'];
  max: Scalars['Int'];
  allowed_tags: Array<Maybe<Scalars['String']>>;
}

export interface ProfileReviewConfigConfirmData {
  contact: ProfileReviewConfigContact;
  meeting_place: ProfileReviewConfigMeetingPlace;
  comment: ProfileReviewConfigComment;
}

export interface ProfileReviewConfigContact {
  min: Scalars['Int'];
  max: Scalars['Int'];
  allowed_tags: Array<Maybe<Scalars['String']>>;
}

export interface ProfileReviewConfigDeclineReason {
  min: Scalars['Int'];
  max: Scalars['Int'];
  allowed_tags: Array<Maybe<Scalars['String']>>;
}

export interface ProfileReviewConfigLookRating {
  min: Scalars['Int'];
  max: Scalars['Int'];
  step: Scalars['Float'];
}

export interface ProfileReviewConfigMeetingPlace {
  min: Scalars['Int'];
  max: Scalars['Int'];
  allowed_tags: Array<Maybe<Scalars['String']>>;
}

export interface ProfileReviewConfigReplyText {
  min: Scalars['Int'];
  max: Scalars['Int'];
  allowed_tags: Array<Maybe<Scalars['String']>>;
  allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileReviewConfigServiceComment {
  min: Scalars['Int'];
  max: Scalars['Int'];
  allowed_tags: Array<Maybe<Scalars['String']>>;
  allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileReviewConfigServiceRating {
  min: Scalars['Int'];
  max: Scalars['Int'];
  step: Scalars['Float'];
}

export interface ProfileReviewConfigServices {
  kissing: Array<Maybe<Scalars['String']>>;
  blowjob: Array<Maybe<Scalars['String']>>;
  cumshot: Array<Maybe<Scalars['String']>>;
  sixty_nine: Array<Maybe<Scalars['String']>>;
  anal: Array<Maybe<Scalars['String']>>;
  sex: Array<Maybe<Scalars['String']>>;
  multiple_time_sex: Array<Maybe<Scalars['String']>>;
  breast: Array<Maybe<Scalars['String']>>;
  attitude: Array<Maybe<Scalars['String']>>;
  conversation: Array<Maybe<Scalars['String']>>;
  availability: Array<Maybe<Scalars['String']>>;
  photos: Array<Maybe<Scalars['String']>>;
}

export interface ProfileReviewConfigText {
  min: Scalars['Int'];
  max: Scalars['Int'];
  allowed_tags: Array<Maybe<Scalars['String']>>;
  allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileReviewConfigs {
  look_rating: ProfileReviewConfigLookRating;
  service_rating: ProfileReviewConfigServiceRating;
  services_comment: ProfileReviewConfigServiceComment;
  text: ProfileReviewConfigText;
  reply_text: ProfileReviewConfigReplyText;
  reason: ProfileReviewConfigDeclineReason;
  services: ProfileReviewConfigServices;
  confirmation_data: ProfileReviewConfigConfirmData;
}

export interface ProfileReviewConfirmationData {
  contact?: Maybe<Scalars['String']>;
  meeting_place?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
}

export interface ProfileReviewConfirmationDataInput {
  contact: Scalars['String'];
  meeting_place: Scalars['String'];
  comment: Scalars['String'];
}

export enum ProfileReviewConversation {
  VerySimple = 'VERY_SIMPLE',
  Intelligent = 'INTELLIGENT',
  Normal = 'NORMAL',
  LanguageProblems = 'LANGUAGE_PROBLEMS'
}

export interface ProfileReviewCreateResponse {
  id?: Maybe<Scalars['String']>;
}

export interface ProfileReviewCreatedEvent {
  id: Scalars['String'];
  profile_id: Scalars['String'];
  account_id: Scalars['Int'];
}

export enum ProfileReviewCumshot {
  InMouthSpit = 'IN_MOUTH_SPIT',
  InMouthSwallow = 'IN_MOUTH_SWALLOW',
  InFace = 'IN_FACE',
  No = 'NO'
}

export interface ProfileReviewDeclineResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileReviewDeleteResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileReviewFakeFreeResponse {
  result: Scalars['Boolean'];
}

export interface ProfileReviewForProfileResponse {
  count: Scalars['Int'];
  review?: Maybe<ProfileReviewPublic>;
}

export interface ProfileReviewInput {
  meeting_date: Scalars['Date'];
  city_id: Scalars['Int'];
  meeting_place: ProfileReviewMeetingPlaces;
  meeting_duration: ProfileReviewMeetingDurationInput;
  price: ProfileReviewPriceInput;
  look_rating: Scalars['Float'];
  service_rating: Scalars['Float'];
  services: Array<Maybe<ProfileReviewServicesInput>>;
  services_comment?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  confirmation_data: ProfileReviewConfirmationDataInput;
}

export enum ProfileReviewKissing {
  Yes = 'YES',
  No = 'NO',
  WithTongue = 'WITH_TONGUE'
}

export interface ProfileReviewMeetingDuration {
  value: Scalars['String'];
  unit: ProfileReviewMeetingDurationUnit;
}

export interface ProfileReviewMeetingDurationInput {
  value: Scalars['Int'];
  unit: ProfileReviewMeetingDurationUnit;
}

export enum ProfileReviewMeetingDurationUnit {
  Minutes = 'MINUTES',
  Hours = 'HOURS',
  Days = 'DAYS',
  Overnight = 'OVERNIGHT'
}

export enum ProfileReviewMeetingPlaces {
  MyFlatOrHouse = 'MY_FLAT_OR_HOUSE',
  HerApartment = 'HER_APARTMENT',
  HerHotel = 'HER_HOTEL',
  MyHotel = 'MY_HOTEL',
  SomewhereElse = 'SOMEWHERE_ELSE'
}

export interface ProfileReviewMeta {
  account_id: Scalars['Int'];
  profile_account_id: Scalars['Int'];
  profile_ops_account_id?: Maybe<Scalars['Int']>;
  date_created: Scalars['Date'];
  created_by?: Maybe<Scalars['Int']>;
  status: ProfileReviewStatus;
  is_deleted: Scalars['Boolean'];
  delete_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  date_deleted?: Maybe<Scalars['Date']>;
  deleted_by?: Maybe<Scalars['Int']>;
  profile_id: Scalars['String'];
  profile_reference_id?: Maybe<Scalars['Int']>;
  response?: Maybe<Scalars['String']>;
  responded_via?: Maybe<Scalars['String']>;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  fake_free?: Maybe<Scalars['Boolean']>;
}

export interface ProfileReviewMetaPublic {
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  profile_reference_id?: Maybe<Scalars['Int']>;
  date_created: Scalars['Date'];
}

export enum ProfileReviewMultipleTimeSex {
  YesMoreTimes = 'YES_MORE_TIMES',
  OnlyOnce = 'ONLY_ONCE',
  DontKnow = 'DONT_KNOW'
}

export enum ProfileReviewPhotos {
  Genuine = 'GENUINE',
  HardToBelieve = 'HARD_TO_BELIEVE',
  Fake = 'FAKE'
}

export interface ProfileReviewPrice {
  value: Scalars['Int'];
  currency: GlobalCurrency;
}

export interface ProfileReviewPriceInput {
  value: Scalars['Int'];
  currency: GlobalCurrency;
}

export interface ProfileReviewPublic {
  _id: Scalars['String'];
  _meta: ProfileReviewMetaPublic;
  member_profile?: Maybe<MemberProfile>;
  meeting_date: Scalars['Date'];
  look_rating: Scalars['Float'];
  service_rating: Scalars['Float'];
  services_comment?: Maybe<Scalars['String']>;
}

export interface ProfileReviewReplyResponse {
  id: Scalars['String'];
}

export interface ProfileReviewRespondResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export enum ProfileReviewResponse {
  Yes = 'YES',
  No = 'NO'
}

export interface ProfileReviewRestoreResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileReviewService {
  name: ProfileReviewServices;
  value?: Maybe<ProfileReviewServiceValues>;
}

export enum ProfileReviewServiceValues {
  Yes = 'YES',
  No = 'NO',
  WithTongue = 'WITH_TONGUE',
  WithoutCondom = 'WITHOUT_CONDOM',
  WithCondom = 'WITH_CONDOM',
  InMouthSpit = 'IN_MOUTH_SPIT',
  InMouthSwallow = 'IN_MOUTH_SWALLOW',
  InFace = 'IN_FACE',
  NotAvailable = 'NOT_AVAILABLE',
  Enthusiastic = 'ENTHUSIASTIC',
  Active = 'ACTIVE',
  Passive = 'PASSIVE',
  YesMoreTimes = 'YES_MORE_TIMES',
  OnlyOnce = 'ONLY_ONCE',
  DontKnow = 'DONT_KNOW',
  Real = 'REAL',
  Silicon = 'SILICON',
  Friendly = 'FRIENDLY',
  Normal = 'NORMAL',
  Unfriendly = 'UNFRIENDLY',
  Poshy = 'POSHY',
  VerySimple = 'VERY_SIMPLE',
  Intelligent = 'INTELLIGENT',
  LanguageProblems = 'LANGUAGE_PROBLEMS',
  Easy = 'EASY',
  Hard = 'HARD',
  Sucks = 'SUCKS',
  Genuine = 'GENUINE',
  HardToBelieve = 'HARD_TO_BELIEVE',
  Fake = 'FAKE'
}

export enum ProfileReviewServices {
  Kissing = 'KISSING',
  Blowjob = 'BLOWJOB',
  Cumshot = 'CUMSHOT',
  SixtyNine = 'SIXTY_NINE',
  Anal = 'ANAL',
  Sex = 'SEX',
  MultipleTimeSex = 'MULTIPLE_TIME_SEX',
  Breast = 'BREAST',
  Attitude = 'ATTITUDE',
  Conversation = 'CONVERSATION',
  Availability = 'AVAILABILITY',
  Photos = 'PHOTOS'
}

export interface ProfileReviewServicesInput {
  name: ProfileReviewServices;
  value: ProfileReviewServiceValues;
}

export enum ProfileReviewSex {
  Enthusiastic = 'ENTHUSIASTIC',
  Active = 'ACTIVE',
  Passive = 'PASSIVE'
}

export enum ProfileReviewSixtyNine {
  Yes = 'YES',
  No = 'NO',
  NotAvailable = 'NOT_AVAILABLE'
}

export enum ProfileReviewStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export interface ProfileReviewUpdateResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileReviewsDisableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileReviewsEnableResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileReviewsPaginationResponse {
  count: Scalars['Int'];
  result: Array<Maybe<ProfileReview>>;
}

export interface ProfileReviewsStatisticResponse {
  count: Scalars['Int'];
  pending: Scalars['Int'];
  approved: Scalars['Int'];
  declined: Scalars['Int'];
}

export enum ProfileServiceFor {
  Men = 'MEN',
  Women = 'WOMEN',
  Couples = 'COUPLES',
  Trans = 'TRANS',
  Gays = 'GAYS',
  Plus2 = 'PLUS2'
}

export enum ProfileSexualOrientation {
  Heterosexual = 'HETEROSEXUAL',
  Bisexual = 'BISEXUAL',
  Homosexual = 'HOMOSEXUAL'
}

export interface ProfileShowBadHistoryIconSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileShowBadHistoryIconUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export enum ProfileSmoking {
  Yes = 'YES',
  No = 'NO',
  Occasionally = 'OCCASIONALLY'
}

export interface ProfileSmsNotifyPackageExpirationSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileSmsNotifyPackageExpirationUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileSmsNotifyProfileOnlineSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileSmsNotifyProfileOnlineUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export enum ProfileSpokenLanguage {
  English = 'ENGLISH',
  French = 'FRENCH',
  German = 'GERMAN',
  Italian = 'ITALIAN',
  Portuguese = 'PORTUGUESE',
  Russian = 'RUSSIAN',
  Spanish = 'SPANISH',
  Arabic = 'ARABIC',
  Bulgarian = 'BULGARIAN',
  Chinese = 'CHINESE',
  Czech = 'CZECH',
  Croatian = 'CROATIAN',
  Dutch = 'DUTCH',
  Finnisch = 'FINNISCH',
  Greek = 'GREEK',
  Hungarian = 'HUNGARIAN',
  Indian = 'INDIAN',
  Japanese = 'JAPANESE',
  Latvinian = 'LATVINIAN',
  Polish = 'POLISH',
  Romanian = 'ROMANIAN',
  Turkish = 'TURKISH',
  Slovak = 'SLOVAK',
  Slovenian = 'SLOVENIAN',
  Danish = 'DANISH',
  Norwegian = 'NORWEGIAN',
  Swedish = 'SWEDISH',
  Albanian = 'ALBANIAN'
}

export enum ProfileSpokenLanguageLevel {
  Basic = 'BASIC',
  Fair = 'FAIR',
  Good = 'GOOD',
  ExcellentNative = 'EXCELLENT_NATIVE'
}

export interface ProfileStatusApproveResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileStatusCreateResponse {
  id: Scalars['String'];
}

export interface ProfileStatusDeclineResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileStatusDeleteResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileStatusMessage {
  _id: Scalars['String'];
  account_id: Scalars['Int'];
  ops_account_id: Scalars['Int'];
  profile_reference_id: Scalars['Int'];
  profile_id: Scalars['String'];
  profile?: Maybe<Profile>;
  short_message?: Maybe<Scalars['String']>;
  long_message?: Maybe<Scalars['String']>;
  new_message?: Maybe<Scalars['String']>;
  status: ProfileStatusMessageStatuses;
  date_created: Scalars['Date'];
  modified_by?: Maybe<Scalars['Int']>;
  processed_by?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
}

export interface ProfileStatusMessageConfig {
  short: ProfileStatusMessageShort;
  long: ProfileStatusMessageLong;
  allowed_tags?: Maybe<Array<Scalars['String']>>;
  allowed_attributes?: Maybe<Scalars['JSONObject']>;
}

export interface ProfileStatusMessageLong {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export interface ProfileStatusMessageShort {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export enum ProfileStatusMessageStatuses {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Deleted = 'DELETED'
}

export interface ProfileStatusMessagesFilter {
  ops_account_id?: Maybe<Scalars['Int']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  short_message?: Maybe<Scalars['String']>;
  status?: Maybe<ProfileStatusMessageStatuses>;
}

export interface ProfileStatusMessagesFilterResult {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ProfileStatusMessage>>>;
}

export interface ProfileStatusUpdateResponse {
  modified?: Maybe<Scalars['Boolean']>;
}

export interface ProfileStories {
  _id: Scalars['String'];
  profile?: Maybe<Profile>;
  stories?: Maybe<Array<Maybe<Story>>>;
}

export enum ProfileTattoo {
  No = 'NO',
  Yes = 'YES'
}

export interface ProfileTextFieldsModerationsResponses {
  showname?: Maybe<ProfileAiTextFieldsModerationResponse>;
  slogan?: Maybe<ProfileAiTextFieldsModerationResponse>;
  about_me?: Maybe<ProfileAiTextFieldsModerationResponse>;
  special_characteristics?: Maybe<ProfileAiTextFieldsModerationResponse>;
  additional_provided_services?: Maybe<ProfileAiTextFieldsModerationResponse>;
  phone_numbers?: Maybe<ProfileAiTextFieldsModerationResponse>;
  website?: Maybe<ProfileAiTextFieldsModerationResponse>;
  club_name?: Maybe<ProfileAiTextFieldsModerationResponse>;
  street?: Maybe<ProfileAiTextFieldsModerationResponse>;
  address_additional_details?: Maybe<ProfileAiTextFieldsModerationResponse>;
  available_for_incall?: Maybe<ProfileAiTextFieldsModerationResponse>;
  available_for_outcall?: Maybe<ProfileAiTextFieldsModerationResponse>;
}

export interface ProfileTour {
  _id: Scalars['String'];
  _meta?: Maybe<ProfileTourMeta>;
  date_from: Scalars['Date'];
  date_to: Scalars['Date'];
  city_id: Scalars['Int'];
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
  profile?: Maybe<Profile>;
  phone_numbers?: Maybe<Array<Maybe<ProfileTourPhoneNumber>>>;
  emails?: Maybe<Array<Maybe<ProfileTourEmail>>>;
}

export interface ProfileTourCreateResponse {
  id: Scalars['String'];
}

export interface ProfileTourDeleteResponse {
  result: Scalars['Boolean'];
}

export interface ProfileTourEmail {
  value: Scalars['String'];
}

export interface ProfileTourEmailExistence {
  id: Scalars['String'];
  exists: Scalars['Boolean'];
}

export interface ProfileTourEmailInput {
  value?: Maybe<Scalars['String']>;
}

export interface ProfileTourHistory {
  count: Scalars['Int'];
  result: Array<Maybe<ProfileTour>>;
}

export interface ProfileTourInput {
  date_from: Scalars['Date'];
  date_to: Scalars['Date'];
  city_id: Scalars['Int'];
  phone_numbers?: Maybe<Array<Maybe<ProfileTourPhoneNumberInput>>>;
  emails?: Maybe<Array<Maybe<ProfileTourEmailInput>>>;
}

export interface ProfileTourMeta {
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  status: ProfileTourStatuses;
  is_deleted: Scalars['Boolean'];
}

export interface ProfileTourPhoneNumber {
  country_code?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  apps?: Maybe<Array<Maybe<GlobalPhoneApp>>>;
  instruction?: Maybe<GlobalPhoneInstruction>;
  no_withheld_numbers?: Maybe<Scalars['Boolean']>;
}

export interface ProfileTourPhoneNumberInput {
  country_code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  apps?: Maybe<Array<Maybe<GlobalPhoneApp>>>;
  instruction?: Maybe<GlobalPhoneInstruction>;
  no_withheld_numbers?: Maybe<Scalars['Boolean']>;
}

export interface ProfileTourPublic {
  _id: Scalars['String'];
  _meta?: Maybe<ProfileTourMeta>;
  date_from: Scalars['Date'];
  date_to: Scalars['Date'];
  city_id: Scalars['Int'];
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
  profile?: Maybe<Profile>;
  phone_numbers?: Maybe<Array<Maybe<ProfileTourPhoneNumber>>>;
}

export enum ProfileTourStatuses {
  Pending = 'PENDING',
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE',
  Expired = 'EXPIRED'
}

export interface ProfileTourUpdateResponse {
  result: Scalars['Boolean'];
}

export enum ProfileType {
  Single = 'SINGLE',
  Duo = 'DUO'
}

export interface ProfileUnderInvestigationSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileUnderInvestigationUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileUpdateResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileUpdateWebsiteStatusResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileVacation {
  _id: Scalars['String'];
  profile_id: Scalars['String'];
  account_id: Scalars['Int'];
  ops_account_id: Scalars['Int'];
  date_from: Scalars['Date'];
  date_to: Scalars['Date'];
  status: ProfileVacationStatus;
  is_deleted: Scalars['Boolean'];
  date_created?: Maybe<Scalars['Date']>;
  created_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
  date_deleted?: Maybe<Scalars['Date']>;
  deleted_by?: Maybe<Scalars['Int']>;
}

export interface ProfileVacationCreateResponse {
  id: Scalars['String'];
}

export interface ProfileVacationDeleteResponse {
  result: Scalars['Boolean'];
}

export enum ProfileVacationStatus {
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE',
  Expired = 'EXPIRED'
}

export interface ProfileVacationUpdateResponse {
  result: Scalars['Boolean'];
}

export interface ProfileVerification {
  _id: Scalars['String'];
  account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  reference_id?: Maybe<Scalars['Int']>;
  profile?: Maybe<Profile>;
  documents_required: ProfileVerificationDocumentsRequired;
  documents?: Maybe<Array<Maybe<ProfileVerificationDocument>>>;
  documents_history?: Maybe<Array<Maybe<ProfileVerificationDocument>>>;
  decline_reasons: ProfileVerificationDeclineReasons;
  age_verification_status: ProfileVerificationStatuses;
  one_hundred_verification_status: ProfileVerificationStatuses;
  need_additional_documents: ProfileVerificationNeedAdditionalDocuments;
  need_additional_visa_documents: Scalars['Boolean'];
  unaltered_photos_required: Scalars['Boolean'];
  is_profile_deleted: Scalars['Boolean'];
  is_available_for_renew?: Maybe<Scalars['Boolean']>;
  date_processed?: Maybe<ProfileVerificationDateProcessed>;
  special_case?: Maybe<ProfileVerificationSpecialCase>;
  date_modified?: Maybe<ProfileVerificationDateModified>;
  processed_by?: Maybe<ProfileVerificationProcessedBy>;
}

export interface ProfileVerificationAdditionalDocumentsConfig {
  type?: Maybe<ProfileVerificationTypes>;
  documents?: Maybe<Array<Maybe<ProfileVerificationDocumentTypes>>>;
}

export interface ProfileVerificationApproveInput {
  profile_id: Scalars['String'];
  verification_type: ProfileVerificationTypes;
}

export interface ProfileVerificationApproveResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface ProfileVerificationConfigs {
  verification_documents?: Maybe<Array<Maybe<ProfileVerificationDocumentsConfig>>>;
  additional_documents?: Maybe<Array<Maybe<ProfileVerificationAdditionalDocumentsConfig>>>;
  visa_additional_documents?: Maybe<Array<Maybe<ProfileVerificationDocumentTypes>>>;
  document_required_fields?: Maybe<Array<Maybe<ProfileVerificationDocumentRequiredKeysConfig>>>;
  unaltered_documents_limit: Scalars['Int'];
  one_hundred_verification_renew_days: Scalars['Int'];
}

export enum ProfileVerificationDateFilterType {
  DateProcessed = 'DATE_PROCESSED',
  DateModified = 'DATE_MODIFIED'
}

export interface ProfileVerificationDateModified {
  age_verification?: Maybe<Scalars['String']>;
  one_hundred_verification?: Maybe<Scalars['String']>;
}

export interface ProfileVerificationDateProcessed {
  age_verification?: Maybe<Scalars['String']>;
  one_hundred_verification?: Maybe<Scalars['String']>;
}

export interface ProfileVerificationDeclineInput {
  profile_id: Scalars['String'];
  verification_type: ProfileVerificationTypes;
  decline_reasons: Array<Maybe<Scalars['String']>>;
  need_additional_documents?: Maybe<Scalars['Boolean']>;
  need_additional_visa_documents?: Maybe<Scalars['Boolean']>;
}

export interface ProfileVerificationDeclineReasons {
  age_verification?: Maybe<Array<Maybe<Scalars['String']>>>;
  one_hundred_verification?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileVerificationDeclineResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface ProfileVerificationDocument {
  _id?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['String']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  media_document_id?: Maybe<Scalars['String']>;
  photo_id?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  photo?: Maybe<ProfilePhoto>;
  type: ProfileVerificationDocumentTypes;
  comment?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['String']>;
  id_number?: Maybe<Scalars['String']>;
  status: ProfileVerificationStatuses;
  assigned: ProfileVerificationTypes;
  date_created?: Maybe<Scalars['String']>;
  date_processed?: Maybe<Scalars['String']>;
  processed_by?: Maybe<Scalars['Int']>;
}

export interface ProfileVerificationDocumentRequiredKeysConfig {
  type?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum ProfileVerificationDocumentTypes {
  Id = 'ID',
  FaceId = 'FACE_ID',
  Unaltered = 'UNALTERED',
  Verification = 'VERIFICATION',
  Private = 'PRIVATE',
  FaceIdVideo = 'FACE_ID_VIDEO',
  VisaId = 'VISA_ID',
  VisaStamp = 'VISA_STAMP',
  VisaNewspaper = 'VISA_NEWSPAPER',
  VisaVideo = 'VISA_VIDEO',
  Legacy = 'LEGACY',
  Unblured = 'UNBLURED',
  OptionalPhoto = 'OPTIONAL_PHOTO',
  OptionalVideo = 'OPTIONAL_VIDEO'
}

export interface ProfileVerificationDocumentsConfig {
  type?: Maybe<ProfileVerificationTypes>;
  documents?: Maybe<Array<Maybe<ProfileVerificationDocumentTypes>>>;
}

export interface ProfileVerificationDocumentsRequired {
  age_verification: Scalars['Boolean'];
  one_hundred_verification: Scalars['Boolean'];
}

export interface ProfileVerificationNeedAdditionalDocuments {
  age_verification: Scalars['Boolean'];
  one_hundred_verification: Scalars['Boolean'];
}

export interface ProfileVerificationPhoto {
  _id: Scalars['String'];
  photo_id: Scalars['String'];
  photo?: Maybe<ProfilePhoto>;
  profile_id: Scalars['String'];
  is_verified: Scalars['Boolean'];
  is_deleted: Scalars['Boolean'];
  status: ProfilePhotoStatus;
}

export interface ProfileVerificationPhotosApproveInput {
  verification_id: Scalars['String'];
  photos: Array<Maybe<ApprovePhotosInput>>;
}

export interface ProfileVerificationPhotosApproveResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface ProfileVerificationPhotosDeclineInput {
  verification_id: Scalars['String'];
  photos: Array<Maybe<DeclinePhotosInput>>;
}

export interface ProfileVerificationPhotosDeclineResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface ProfileVerificationProcessedBy {
  age_verification?: Maybe<Scalars['Int']>;
  one_hundred_verification?: Maybe<Scalars['Int']>;
}

export interface ProfileVerificationSpecialCase {
  age_verification?: Maybe<Scalars['Boolean']>;
  one_hundred_verification?: Maybe<Scalars['Boolean']>;
}

export enum ProfileVerificationStatuses {
  Pending = 'PENDING',
  Declined = 'DECLINED',
  Approved = 'APPROVED'
}

export enum ProfileVerificationTypes {
  AgeVerification = 'AGE_VERIFICATION',
  Verification_100 = 'VERIFICATION_100'
}

export interface ProfileVerificationUpdateIdDocumentInput {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['String']>;
  id_number?: Maybe<Scalars['String']>;
}

export interface ProfileVerificationUpdateIdDocumentResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface ProfileVideo {
  _id: Scalars['String'];
  profile_type: Scalars['String'];
  account_id: Scalars['Int'];
  ops_account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  media_video_id: Scalars['String'];
  ai_media_moderation?: Maybe<ProfileVideoAiMediaModeration>;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
  is_disabled: Scalars['Boolean'];
  status: ProfileVideoStatus;
  title?: Maybe<Scalars['String']>;
  cover_number: Scalars['Int'];
  video_cover_id?: Maybe<Scalars['String']>;
  cover?: Maybe<ProfileVideoCover>;
  covers?: Maybe<Array<Maybe<ProfileVideoCover>>>;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  date_deleted?: Maybe<Scalars['Date']>;
  deleted_by?: Maybe<Scalars['Int']>;
  date_restored?: Maybe<Scalars['Date']>;
  restored_by?: Maybe<Scalars['Int']>;
  date_disabled?: Maybe<Scalars['Date']>;
  disabled_by?: Maybe<Scalars['Int']>;
  date_enabled?: Maybe<Scalars['Date']>;
  enabled_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  video?: Maybe<Video>;
}

export interface ProfileVideoAiMediaModeration {
  _id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['ID']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<Scalars['String']>;
  media_video_id?: Maybe<Scalars['ID']>;
  video_path?: Maybe<Scalars['String']>;
  moderation_type?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  ai_moderation_response?: Maybe<ProfileVideoAiMediaModerationSummaryResponse>;
}

export interface ProfileVideoAiMediaModerationSummary {
  action?: Maybe<Scalars['String']>;
  reject_prob?: Maybe<Scalars['Float']>;
  reject_reason?: Maybe<Array<Maybe<ProfileVideoAiRejectReasons>>>;
}

export interface ProfileVideoAiMediaModerationSummaryResponse {
  summary?: Maybe<ProfileVideoAiMediaModerationSummary>;
}

export interface ProfileVideoAiRejectReasons {
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
}

export interface ProfileVideoApproveResponse {
  result: Scalars['Boolean'];
}

export interface ProfileVideoCover {
  _id: Scalars['String'];
  video_id: Scalars['String'];
  profile_type: Scalars['String'];
  account_id: Scalars['Int'];
  ops_account_id: Scalars['Int'];
  profile_id: Scalars['String'];
  image_id: Scalars['String'];
  image?: Maybe<Image>;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  is_deleted: Scalars['Boolean'];
  is_profile_deleted: Scalars['Boolean'];
  is_video_deleted: Scalars['Boolean'];
  status: ProfileVideoCoverStatus;
  date_processed?: Maybe<Scalars['Date']>;
  processed_by?: Maybe<Scalars['Int']>;
  date_deleted?: Maybe<Scalars['Date']>;
  deleted_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileVideoCoverApproveResponse {
  result: Scalars['Boolean'];
}

export interface ProfileVideoCoverCreateResponse {
  ids?: Maybe<Array<Scalars['String']>>;
}

export interface ProfileVideoCoverDeclineInput {
  id: Scalars['String'];
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileVideoCoverDeclineResponse {
  result: Scalars['Boolean'];
}

export interface ProfileVideoCoverDeleteResponse {
  result: Scalars['Boolean'];
}

export enum ProfileVideoCoverStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export interface ProfileVideoCreateResponse {
  ids?: Maybe<Array<Scalars['String']>>;
}

export interface ProfileVideoDeclineInput {
  id: Scalars['String'];
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface ProfileVideoDeclineResponse {
  result: Scalars['Boolean'];
}

export interface ProfileVideoDeleteResponse {
  result: Scalars['Boolean'];
}

export interface ProfileVideoDisableResponse {
  result: Scalars['Boolean'];
}

export interface ProfileVideoEnableResponse {
  result: Scalars['Boolean'];
}

export interface ProfileVideoInput {
  media_video_id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  cover_number?: Maybe<Scalars['Int']>;
}

export interface ProfileVideoRestoreResponse {
  result: Scalars['Boolean'];
}

export enum ProfileVideoStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export interface ProfileVideoUpdateInput {
  title?: Maybe<Scalars['String']>;
  cover_number?: Maybe<Scalars['Int']>;
  video_cover_id?: Maybe<Scalars['String']>;
}

export interface ProfileVideoUpdateResponse {
  result: Scalars['Boolean'];
}

export interface ProfileVipSetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileVipUnsetResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface ProfileWorkingHour {
  id: Scalars['String'];
  week_day: GlobalWeekDay;
  from: Scalars['String'];
  to: Scalars['String'];
  show_as_night_escort?: Maybe<Scalars['Boolean']>;
}

export interface ProfileWorkingHourInput {
  id: Scalars['String'];
  week_day: GlobalWeekDay;
  from: Scalars['String'];
  to: Scalars['String'];
  show_as_night_escort?: Maybe<Scalars['Boolean']>;
}

export interface ProfilesByFiltersResult {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<Profile>>>;
}

export interface ProfilesCountByCompanyResult {
  count: Scalars['Int'];
}

export interface ProfilesWithCountResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Profile>>;
}

export interface PublicCompanyProfile {
  _id: Scalars['String'];
  _meta?: Maybe<PublicCompanyProfileMeta>;
  showname?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  type?: Maybe<ProfileCompanyType>;
  zip_code?: Maybe<Scalars['String']>;
  is_club?: Maybe<Scalars['Boolean']>;
  entrance_fee?: Maybe<ProfileCompanyEntranceFee>;
  wellness?: Maybe<ProfileCompanyWellness>;
  food_and_drinks?: Maybe<ProfileCompanyFoodDrink>;
  outdoor_area?: Maybe<ProfileCompanyOutdoorArea>;
  about_me?: Maybe<Array<CompanyProfileAboutMe>>;
  cities?: Maybe<Array<CompanyProfileCity>>;
  areas?: Maybe<Array<CompanyProfileArea>>;
  available_24_7?: Maybe<Scalars['Boolean']>;
  working_hours?: Maybe<Array<Maybe<CompanyProfileWorkingHour>>>;
  rates_incall?: Maybe<Array<Maybe<CompanyProfileRateIncall>>>;
  rates_outcall?: Maybe<Array<Maybe<CompanyProfileRateOutcall>>>;
  phone_numbers?: Maybe<Array<Maybe<CompanyProfilePhoneNumber>>>;
  emails?: Maybe<Array<Maybe<CompanyProfileEmailPublic>>>;
  website?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  address_additional_details?: Maybe<Scalars['String']>;
  galleries?: Maybe<Array<CompanyProfileGallery>>;
  main_photo?: Maybe<CompanyProfilePhoto>;
}

export interface PublicCompanyProfileMeta {
  reference_id: Scalars['Int'];
  account_id: Scalars['Int'];
  account?: Maybe<Account>;
  type: ProfileMetaType;
  date_created: Scalars['Date'];
  date_modified?: Maybe<Scalars['Date']>;
  can_be_online: Scalars['Boolean'];
  are_comments_disabled: Scalars['Boolean'];
  are_reviews_disabled: Scalars['Boolean'];
  website_status?: Maybe<ProfileMetaWebsiteStatus>;
  old_reference_id?: Maybe<Scalars['Int']>;
  is_premium?: Maybe<Scalars['Boolean']>;
  show_in_club_directory?: Maybe<Scalars['Boolean']>;
  is_contact_info_hidden?: Maybe<Scalars['Boolean']>;
  is_approved?: Maybe<Scalars['Boolean']>;
  is_company_hidden?: Maybe<Scalars['Boolean']>;
}

export interface PublicMemberProfile {
  _id: Scalars['String'];
  _meta?: Maybe<PublicMemberProfileMeta>;
  showname?: Maybe<Scalars['String']>;
  area_id?: Maybe<Scalars['Int']>;
  geo_node?: Maybe<GeoNode>;
  geo_path?: Maybe<Array<Maybe<GeoNode>>>;
  about_me?: Maybe<Scalars['String']>;
  photo?: Maybe<MemberProfilePhoto>;
}

export interface PublicMemberProfileMeta {
  reference_id: Scalars['Int'];
  account_id: Scalars['Int'];
  account?: Maybe<Account>;
  type: ProfileMetaType;
  rating?: Maybe<ProfileMemberRating>;
  date_created: Scalars['Date'];
  date_modified?: Maybe<Scalars['Date']>;
  old_reference_id?: Maybe<Scalars['Int']>;
}

export interface PublicMutation {
  createSupportProblemReport?: Maybe<SupportCreateProblemReportResponse>;
  createSupportSuspiciousPhotoRequest?: Maybe<SupportSuspiciousPhotoRequestResponse>;
  supportTellAFriend?: Maybe<SupportTellAFriendResponse>;
  createStatisticProfileView?: Maybe<StatisticsCreateProfileActivityResponse>;
  createStatisticCall?: Maybe<StatisticsCreateProfileActivityResponse>;
  createStatisticWhatsappCall?: Maybe<StatisticsCreateProfileActivityResponse>;
  createFeedbackByEntityAndType?: Maybe<ProfileCreateFeedbackResponse>;
  storyLike?: Maybe<Story>;
  storyUnlike?: Maybe<Story>;
  storySeen?: Maybe<Story>;
  sendContactEmail: NotificationSendContactEmailResult;
  createInstantBookRequest?: Maybe<InstantBookRequestCreateResponse>;
  createClassifiedAdFeedback?: Maybe<CreateClassifiedAdFeedbackResponse>;
  createNotFoundRecord?: Maybe<SeoCreateNotFoundRecordResponse>;
  verifyEmail?: Maybe<VerifyEmailResponse>;
  authenticate?: Maybe<AccountToken>;
  createAccount?: Maybe<CreateAccountResponse>;
  sendPasswordRecoverySecret?: Maybe<SendPasswordRecoverySecretResponse>;
  updateAccountPasswordBySecret?: Maybe<MutationResponse>;
  dummy?: Maybe<Scalars['String']>;
}


export interface PublicMutationCreateSupportProblemReportArgs {
  data?: Maybe<SupportProblemReportCreateInput>;
}


export interface PublicMutationCreateSupportSuspiciousPhotoRequestArgs {
  profile_id: Scalars['ID'];
  links: Array<Scalars['String']>;
  comment: Scalars['String'];
}


export interface PublicMutationSupportTellAFriendArgs {
  data?: Maybe<SupportTellAFriendInput>;
}


export interface PublicMutationCreateStatisticProfileViewArgs {
  account_id: Scalars['Int'];
  profile_id: Scalars['ID'];
}


export interface PublicMutationCreateStatisticCallArgs {
  account_id: Scalars['Int'];
  profile_id: Scalars['ID'];
}


export interface PublicMutationCreateStatisticWhatsappCallArgs {
  account_id: Scalars['Int'];
  profile_id: Scalars['ID'];
}


export interface PublicMutationCreateFeedbackByEntityAndTypeArgs {
  entity_id: Scalars['String'];
  entity_type: ProfileFeedbackEntityType;
  entity_email_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  message: Scalars['String'];
  re_captcha_token: Scalars['String'];
}


export interface PublicMutationStoryLikeArgs {
  _id: Scalars['ID'];
}


export interface PublicMutationStoryUnlikeArgs {
  _id: Scalars['ID'];
}


export interface PublicMutationStorySeenArgs {
  _id: Scalars['ID'];
}


export interface PublicMutationSendContactEmailArgs {
  to: Scalars['String'];
  name: Scalars['String'];
  language: Scalars['String'];
  email: Scalars['String'];
  body: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  account_id?: Maybe<Scalars['Int']>;
  re_captcha_token: Scalars['String'];
}


export interface PublicMutationCreateInstantBookRequestArgs {
  data: CreateInstantBookRequestInput;
}


export interface PublicMutationCreateClassifiedAdFeedbackArgs {
  data: ClassifiedAdFeedbackInput;
}


export interface PublicMutationCreateNotFoundRecordArgs {
  url: Scalars['String'];
  full_url: Scalars['String'];
}


export interface PublicMutationVerifyEmailArgs {
  account_id: Scalars['Int'];
  verification_code: Scalars['String'];
}


export interface PublicMutationAuthenticateArgs {
  username: Scalars['String'];
  password: Scalars['String'];
  lifetime?: Maybe<Scalars['Int']>;
}


export interface PublicMutationCreateAccountArgs {
  data?: Maybe<CreateAccountInput>;
}


export interface PublicMutationSendPasswordRecoverySecretArgs {
  username_or_email: Scalars['String'];
  method?: Maybe<AccountPasswordRecoveryMethod>;
}


export interface PublicMutationUpdateAccountPasswordBySecretArgs {
  id: Scalars['Int'];
  recovery_secret: Scalars['String'];
  new_password: Scalars['String'];
}

export interface PublicProfile {
  _id: Scalars['String'];
  _meta?: Maybe<PublicProfileMeta>;
  company_profile?: Maybe<PublicCompanyProfile>;
  showname?: Maybe<Scalars['String']>;
  slogan?: Maybe<Scalars['String']>;
  gender?: Maybe<ProfileGender>;
  age?: Maybe<Scalars['Int']>;
  ethnicity?: Maybe<ProfileEthnicity>;
  nationality?: Maybe<ProfileNationality>;
  home_city_id?: Maybe<Scalars['Int']>;
  home_city_geo_node?: Maybe<GeoNode>;
  home_city_geo_path?: Maybe<Array<GeoNode>>;
  hair_color?: Maybe<ProfileHairColor>;
  eye_color?: Maybe<ProfileEyeColor>;
  height?: Maybe<Scalars['Int']>;
  height_royal?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  weight_royal?: Maybe<Scalars['Int']>;
  dress_size?: Maybe<ProfileDressSize>;
  dress_size_royal?: Maybe<ProfileDressSizeRoyal>;
  shoe_size?: Maybe<Scalars['Int']>;
  shoe_size_royal?: Maybe<Scalars['Int']>;
  bust_size?: Maybe<Scalars['Int']>;
  waist_size?: Maybe<Scalars['Int']>;
  hip_size?: Maybe<Scalars['Int']>;
  cup_size?: Maybe<ProfileCupSize>;
  pubic_hair?: Maybe<ProfilePubicHair>;
  about_me?: Maybe<Array<ProfileAboutMe>>;
  smoking?: Maybe<ProfileSmoking>;
  drinking?: Maybe<ProfileDrinking>;
  special_characteristics?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<ProfileLanguage>>;
  areas?: Maybe<Array<ProfileArea>>;
  cities?: Maybe<Array<Maybe<ProfileCity>>>;
  available_for_incall?: Maybe<ProfileAvailableForIncall>;
  available_for_outcall?: Maybe<ProfileAvailableForOutcall>;
  sexual_orientation?: Maybe<ProfileSexualOrientation>;
  services_for?: Maybe<Array<ProfileServiceFor>>;
  provided_services?: Maybe<Array<ProfileProvidedService>>;
  additional_provided_services?: Maybe<Array<ProfileAdditionalProvidedService>>;
  available_24_7?: Maybe<Scalars['Boolean']>;
  show_as_night_escort?: Maybe<Scalars['Boolean']>;
  working_hours?: Maybe<Array<Maybe<ProfileWorkingHour>>>;
  rates_incall?: Maybe<Array<Maybe<ProfileRateIncall>>>;
  rates_outcall?: Maybe<Array<Maybe<ProfileRateOutcall>>>;
  phone_numbers?: Maybe<Array<ProfilePhoneNumber>>;
  emails?: Maybe<Array<ProfileEmailPublic>>;
  website?: Maybe<Scalars['String']>;
  videogirlsch_url?: Maybe<Scalars['String']>;
  club_name?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
  address_additional_details?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  country_visibility?: Maybe<ProfileCountryVisibility>;
  map_location?: Maybe<ProfileMapLocation>;
  address_location?: Maybe<ProfileAddressLocation>;
  skype_id?: Maybe<Scalars['String']>;
  galleries?: Maybe<Array<ProfileGallery>>;
  natural_photos?: Maybe<Array<ProfileNaturalPhoto>>;
  videos?: Maybe<Array<ProfileVideo>>;
  tours?: Maybe<Array<ProfileTour>>;
  status_message?: Maybe<ProfileStatusMessage>;
  main_photo?: Maybe<ProfilePhoto>;
  verification?: Maybe<ProfileVerification>;
  aggregator_online?: Maybe<AggregatorOnlineProfile>;
  closest_base_service?: Maybe<BaseService>;
}

export interface PublicProfileMeta {
  reference_id: Scalars['Int'];
  company_id?: Maybe<Scalars['String']>;
  account_id: Scalars['Int'];
  account?: Maybe<Account>;
  type: ProfileMetaType;
  date_created: Scalars['Date'];
  date_modified?: Maybe<Scalars['Date']>;
  can_be_online: Scalars['Boolean'];
  are_comments_disabled: Scalars['Boolean'];
  are_reviews_disabled: Scalars['Boolean'];
  is_disabled: Scalars['Boolean'];
  is_admin_disabled: Scalars['Boolean'];
  keep_100_verification: Scalars['Boolean'];
  show_bad_photo_history_icon: Scalars['Boolean'];
  is_under_investigation: Scalars['Boolean'];
  are_photos_suspicious: Scalars['Boolean'];
  suspicious_photos_reason?: Maybe<Scalars['String']>;
  show_suspicious_photos_reason: Scalars['Boolean'];
  is_vip: Scalars['Boolean'];
  is_escortbook_disabled: Scalars['Boolean'];
  is_camplace_disabled: Scalars['Boolean'];
  camplace_channel_url?: Maybe<Scalars['String']>;
  camplace_state?: Maybe<Scalars['String']>;
  website_status?: Maybe<ProfileMetaWebsiteStatus>;
  all_photos_rotatable: Scalars['Boolean'];
  periodic_bumps_disabled?: Maybe<Scalars['Boolean']>;
  is_online?: Maybe<Scalars['Boolean']>;
  top_10_rating?: Maybe<Scalars['Int']>;
  no_new?: Maybe<Scalars['Boolean']>;
}

export interface PublicQuery {
  globalConfigs?: Maybe<GlobalConfig>;
  profileComments: ProfileCommentsResponse;
  companyProfileComments: CompanyProfileCommentsResponse;
  memberProfileComments: ProfileMemberCommentsResponse;
  commentConfigs: ConfigResponse;
  supportTicketConfigs?: Maybe<SupportTicketConfigs>;
  supportProblemReportConfigs: SupportProblemReportConfigs;
  supportTellAFriendConfigs: SupportTellAFriendConfigs;
  supportSuspiciousPhotoRequestConfigs: SupportSuspiciousPhotoRequestConfigs;
  supportFeedbackConfigs: SupportFeedbackConfigs;
  commentReactionsByAccount?: Maybe<Array<Maybe<StatisticCommentReactionByAccount>>>;
  profileViewsCount?: Maybe<StatisticProfileViewsCount>;
  profileConfigs?: Maybe<ProfileConfig>;
  memberProfile?: Maybe<PublicMemberProfile>;
  memberProfileByRef?: Maybe<PublicMemberProfile>;
  memberProfileByAccount?: Maybe<PublicMemberProfile>;
  memberProfileByOldShowname?: Maybe<PublicMemberProfile>;
  companyProfile?: Maybe<PublicCompanyProfile>;
  companyProfileByRef?: Maybe<PublicCompanyProfile>;
  companyProfileByAccount?: Maybe<PublicCompanyProfile>;
  companyProfilesByAccountIds?: Maybe<Array<Maybe<PublicCompanyProfile>>>;
  companyProfileByOldId?: Maybe<PublicCompanyProfile>;
  companyProfilePhotos?: Maybe<Array<Maybe<CompanyProfilePhoto>>>;
  profile?: Maybe<PublicProfile>;
  profileByRef?: Maybe<PublicProfile>;
  profileByAccount?: Maybe<PublicProfile>;
  profilesByCompany?: Maybe<Array<PublicProfile>>;
  profilePhoto?: Maybe<ProfilePhoto>;
  profilePhotos?: Maybe<Array<Maybe<ProfilePhoto>>>;
  profileVideo?: Maybe<ProfileVideo>;
  profileVideos?: Maybe<Array<Maybe<ProfileVideo>>>;
  profileNaturalPhoto?: Maybe<ProfileNaturalPhoto>;
  profileNaturalPhotos?: Maybe<Array<Maybe<ProfileNaturalPhoto>>>;
  profileVacationByProfileId?: Maybe<ProfileVacation>;
  profileHappyHourByProfileAndStatuses?: Maybe<Array<Maybe<ProfileHappyHour>>>;
  profileHappyHourConfigs?: Maybe<ProfileHappyHourConfig>;
  activeGotm?: Maybe<ProfileGotm>;
  profileGotmHistory?: Maybe<ProfileGotmHistoryResponse>;
  profileGotmRatings?: Maybe<Array<Maybe<ProfileGotmRating>>>;
  productManagerConfigs?: Maybe<Configs>;
  contactDetails: NotificationContactDetailsResponse;
  activeOnlineNews?: Maybe<OnlineNews>;
  notificationConfigs: NotificationConfigs;
  systemTemplateConfigs: SystemTemplateConfigs;
  statusMessageByProfile?: Maybe<ProfileStatusMessage>;
  instantBookConfigs?: Maybe<InstantBookConfig>;
  instantBookCheckRequestByHashAndProfile?: Maybe<InstantBookCheckRequestResponse>;
  instantBookActiveAvailabilityByProfileId?: Maybe<InstantBookAvailabilityPublic>;
  profileReviewForProfile?: Maybe<ProfileReviewForProfileResponse>;
  profileReviewConfigs?: Maybe<ProfileReviewConfigs>;
  onlineProfilesByAccount?: Maybe<Array<Maybe<AggregatorOnlineProfile>>>;
  onlineProfiles?: Maybe<Array<Maybe<AggregatorOnlineProfile>>>;
  aggregatorGeoNodes?: Maybe<Array<Maybe<AggregatorGeoNode>>>;
  aggregatorGetGeoNodesByProfileId?: Maybe<Array<Maybe<AggregatorGeoNodesByProfile>>>;
  aggregatorMostPopularGeoNodes?: Maybe<Array<Maybe<AggregatorGeoNode>>>;
  aggregatorProfiles?: Maybe<AggregatorProfileResult>;
  aggregatorGotdProfiles?: Maybe<AggregatorGotdProfileResult>;
  aggregatorVotdProfiles?: Maybe<AggregatorVotdProfileResult>;
  aggregatorStories?: Maybe<Array<Maybe<AggregatorStoriesResult>>>;
  aggregatorSalesWithMinAccounts?: Maybe<Scalars['Int']>;
  aggregatorProfilesByIds?: Maybe<Array<Maybe<AggregatorProfile>>>;
  aggregatorProfileCount?: Maybe<AggregatorProfileCountResult>;
  aggregatorProfilesPaging?: Maybe<AggregatorProfilePagingResult>;
  aggregatorProfilesByCompany?: Maybe<AggregatorCompanyProfilesResult>;
  aggregatorVideoFeed?: Maybe<AggregatorProfileVideoFeedResult>;
  aggregatorStatusMessages?: Maybe<AggregatorStatusMessagesResult>;
  aggregatorLateNightGirls?: Maybe<AggregatorLateNightGirlsResult>;
  aggregatorProfileAvailableFilters?: Maybe<AggregatorAvailableFilters>;
  aggregatorLatestCommentsProfiles?: Maybe<AggregatorLatestCommentProfileResult>;
  aggregatorTop10Profiles?: Maybe<AggregatorTop10ProfileResult>;
  aggregatorCompanyProfiles?: Maybe<AggregatorCompanyProfileResult>;
  aggregatorCompanyProfilesPaging?: Maybe<AggregatorCompanyProfilePagingResult>;
  aggregatorCompanyGeoNodes?: Maybe<Array<Maybe<AggregatorCompanyGeoNode>>>;
  aggregatorCompanyProfileCount?: Maybe<AggregatorCompanyProfileCountResult>;
  aggregatorConfigs?: Maybe<AggregatorConfigs>;
  discountConfigs?: Maybe<DiscountConfig>;
  checkActiveDiscountsWithPromoCode?: Maybe<DiscountWithPromoCodeExistResponse>;
  approvedClassifiedAdsByFilter?: Maybe<ClassifiedAdsFilterResponse>;
  approvedClassifiedAdsByFilterPaging?: Maybe<ClassifiedAdsFilterPagingResponse>;
  classifiedAdConfigs: ClassifiedAdConfigs;
  classifiedAdApprovedByRefId?: Maybe<ClassifiedAd>;
  existsByPhone: ExistsByPhoneResponse;
  profileVerificationConfigs?: Maybe<ProfileVerificationConfigs>;
  dictionaryList?: Maybe<Array<Maybe<Dictionary>>>;
  dictionaryRead?: Maybe<Dictionary>;
  dictionaryBySlug?: Maybe<Dictionary>;
  declineReasonsByKeys?: Maybe<Array<Maybe<Dictionary>>>;
  inventoryConfigs?: Maybe<InventoryConfigs>;
  baseServiceProductIds?: Maybe<Array<Scalars['Int']>>;
  lotteryConfigs?: Maybe<LotteryConfig>;
  seoRobotText?: Maybe<SeoRobotsText>;
  seoTemplateAndInstanceByKey?: Maybe<PublicSeoTemplate>;
  messengerAvailability: MessengerAvailabilityResponse;
  checkToursEmailExistenceByIds?: Maybe<Array<Maybe<ProfileTourEmailExistence>>>;
  tourById?: Maybe<ProfileTourPublic>;
  toursByIds?: Maybe<Array<Maybe<ProfileTourPublic>>>;
  toursByProfile?: Maybe<Array<Maybe<ProfileTourPublic>>>;
  memberFavoriteProfileConfigs: MemberFavoriteProfileConfigs;
  memberFavoriteProfileById: MemberFavoriteProfile;
  mediaConfigs?: Maybe<MediaConfig>;
  ip2location?: Maybe<Ip2Location>;
  ips2locations?: Maybe<Array<Maybe<Ip2Location>>>;
  geoNode?: Maybe<GeoNode>;
  geoChildrenByIdAndType?: Maybe<Array<Maybe<GeoNode>>>;
  geoNodePath?: Maybe<Array<Maybe<GeoNode>>>;
  geoNodePathsByIds?: Maybe<Array<Maybe<Array<Maybe<GeoNode>>>>>;
  geoNodesByCoordinates?: Maybe<Array<Maybe<GeoNodeCoordinates>>>;
  geoLeaves?: Maybe<Array<Maybe<GeoNode>>>;
  geoNodeNearbyLocations?: Maybe<Array<Maybe<GeoNode>>>;
  geoNodesByIds?: Maybe<Array<Maybe<GeoNode>>>;
  ixsBannersByZones?: Maybe<Array<Maybe<IxsBanner>>>;
  ixsBannerConfig?: Maybe<IxsBannerConfigResponse>;
  getBannerConfig?: Maybe<BannerConfigResponse>;
  aiModeratorGetConfigs?: Maybe<Scalars['String']>;
  sales?: Maybe<Array<Maybe<OpsAccountPublic>>>;
  accountConfigs?: Maybe<AccountConfigs>;
  checkUsername?: Maybe<CheckUsernameResponse>;
  checkEmail?: Maybe<CheckEmailResponse>;
  paymentLinkByHash?: Maybe<PaymentLinkPublic>;
  anonymousPaymentUrl?: Maybe<PaymentUrlResponse>;
  paymentUrlForPaymentLink?: Maybe<PaymentUrlResponse>;
  enabledPaymentMethods: Array<PaymentEnabledMethod>;
  commerceConfigs?: Maybe<CommerceConfigs>;
  dummy?: Maybe<Scalars['String']>;
}


export interface PublicQueryProfileCommentsArgs {
  profile_id: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}


export interface PublicQueryCompanyProfileCommentsArgs {
  profile_id: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}


export interface PublicQueryMemberProfileCommentsArgs {
  member_account_id: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}


export interface PublicQueryCommentReactionsByAccountArgs {
  comment_ids: Array<Scalars['ID']>;
  account_id: Scalars['Int'];
}


export interface PublicQueryProfileViewsCountArgs {
  profile_id: Scalars['ID'];
  date_from?: Maybe<Scalars['String']>;
  date_to?: Maybe<Scalars['String']>;
  tz?: Maybe<Scalars['String']>;
}


export interface PublicQueryMemberProfileArgs {
  id: Scalars['String'];
}


export interface PublicQueryMemberProfileByRefArgs {
  id: Scalars['Int'];
}


export interface PublicQueryMemberProfileByAccountArgs {
  account_id: Scalars['Int'];
}


export interface PublicQueryMemberProfileByOldShownameArgs {
  old_showname: Scalars['String'];
}


export interface PublicQueryCompanyProfileArgs {
  id: Scalars['String'];
}


export interface PublicQueryCompanyProfileByRefArgs {
  id: Scalars['Int'];
}


export interface PublicQueryCompanyProfileByAccountArgs {
  account_id: Scalars['Int'];
}


export interface PublicQueryCompanyProfilesByAccountIdsArgs {
  account_ids: Array<Scalars['Int']>;
}


export interface PublicQueryCompanyProfileByOldIdArgs {
  old_reference_id: Scalars['Int'];
}


export interface PublicQueryCompanyProfilePhotosArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface PublicQueryProfileArgs {
  id: Scalars['String'];
}


export interface PublicQueryProfileByRefArgs {
  id: Scalars['Int'];
}


export interface PublicQueryProfileByAccountArgs {
  account_id: Scalars['Int'];
}


export interface PublicQueryProfilesByCompanyArgs {
  company_id: Scalars['String'];
}


export interface PublicQueryProfilePhotoArgs {
  id: Scalars['String'];
}


export interface PublicQueryProfilePhotosArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface PublicQueryProfileVideoArgs {
  id: Scalars['String'];
}


export interface PublicQueryProfileVideosArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface PublicQueryProfileNaturalPhotoArgs {
  id: Scalars['String'];
}


export interface PublicQueryProfileNaturalPhotosArgs {
  ids?: Maybe<Array<Scalars['String']>>;
}


export interface PublicQueryProfileVacationByProfileIdArgs {
  profile_id: Scalars['String'];
}


export interface PublicQueryProfileHappyHourByProfileAndStatusesArgs {
  id: Scalars['String'];
  statuses?: Maybe<Array<ProfileHappyHourStatuses>>;
}


export interface PublicQueryProfileGotmHistoryArgs {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface PublicQueryStatusMessageByProfileArgs {
  profile_id: Scalars['String'];
}


export interface PublicQueryInstantBookCheckRequestByHashAndProfileArgs {
  hash: Scalars['String'];
  profile_id: Scalars['String'];
}


export interface PublicQueryInstantBookActiveAvailabilityByProfileIdArgs {
  profile_id: Scalars['String'];
}


export interface PublicQueryProfileReviewForProfileArgs {
  profile_id: Scalars['String'];
}


export interface PublicQueryOnlineProfilesByAccountArgs {
  account_id: Scalars['Int'];
}


export interface PublicQueryOnlineProfilesArgs {
  profile_ids: Array<Maybe<Scalars['String']>>;
}


export interface PublicQueryAggregatorGeoNodesArgs {
  geo?: Maybe<AggregatorGeoInput>;
  categories?: Maybe<AggregatorCategoriesInput>;
  filters?: Maybe<AggregatorProfileFiltersInput>;
  search?: Maybe<AggregatorProfileSearchInput>;
}


export interface PublicQueryAggregatorGetGeoNodesByProfileIdArgs {
  profile_id: Scalars['Int'];
}


export interface PublicQueryAggregatorMostPopularGeoNodesArgs {
  geo?: Maybe<AggregatorGeoInput>;
  categories?: Maybe<AggregatorCategoriesInput>;
}


export interface PublicQueryAggregatorProfilesArgs {
  geo?: Maybe<AggregatorGeoInput>;
  categories?: Maybe<AggregatorCategoriesInput>;
  filters?: Maybe<AggregatorProfileFiltersInput>;
  search?: Maybe<AggregatorProfileSearchInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  ordering?: Maybe<AggregatorProfileOrdering>;
  exclude?: Maybe<Array<Maybe<Scalars['Int']>>>;
}


export interface PublicQueryAggregatorGotdProfilesArgs {
  geo?: Maybe<AggregatorGeoInput>;
  categories?: Maybe<AggregatorCategoriesInput>;
  filters?: Maybe<AggregatorProfileFiltersInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface PublicQueryAggregatorVotdProfilesArgs {
  geo?: Maybe<AggregatorGeoInput>;
  categories?: Maybe<AggregatorCategoriesInput>;
  filters?: Maybe<AggregatorProfileFiltersInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface PublicQueryAggregatorStoriesArgs {
  geo?: Maybe<AggregatorGeoInput>;
  profile_id?: Maybe<Scalars['Int']>;
  categories?: Maybe<AggregatorCategoriesInput>;
}


export interface PublicQueryAggregatorProfilesByIdsArgs {
  ids: Array<Scalars['String']>;
}


export interface PublicQueryAggregatorProfileCountArgs {
  geo?: Maybe<AggregatorGeoInput>;
  categories?: Maybe<AggregatorCategoriesInput>;
  filters?: Maybe<AggregatorProfileFiltersInput>;
  search?: Maybe<AggregatorProfileSearchInput>;
  ordering?: Maybe<AggregatorProfileOrdering>;
}


export interface PublicQueryAggregatorProfilesPagingArgs {
  geo?: Maybe<AggregatorGeoInput>;
  categories?: Maybe<AggregatorCategoriesInput>;
  filters?: Maybe<AggregatorProfileFiltersInput>;
  search?: Maybe<AggregatorProfileSearchInput>;
  ordering?: Maybe<AggregatorProfileOrdering>;
  pid: Scalars['Int'];
}


export interface PublicQueryAggregatorProfilesByCompanyArgs {
  company_profile_id: Scalars['String'];
  exclude_profile_id?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface PublicQueryAggregatorVideoFeedArgs {
  geo?: Maybe<AggregatorGeoInput>;
  categories?: Maybe<AggregatorCategoriesInput>;
  filters?: Maybe<AggregatorProfileFiltersInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface PublicQueryAggregatorStatusMessagesArgs {
  geo?: Maybe<AggregatorGeoInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface PublicQueryAggregatorLateNightGirlsArgs {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface PublicQueryAggregatorProfileAvailableFiltersArgs {
  geo?: Maybe<AggregatorGeoInput>;
  categories?: Maybe<AggregatorCategoriesInput>;
  filters?: Maybe<AggregatorProfileFiltersInput>;
  search?: Maybe<AggregatorProfileSearchInput>;
}


export interface PublicQueryAggregatorLatestCommentsProfilesArgs {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface PublicQueryAggregatorTop10ProfilesArgs {
  show?: Maybe<AggregatorTop10ProfileShow>;
  area_id?: Maybe<Scalars['Int']>;
  showname?: Maybe<Scalars['String']>;
  with_comments_only?: Maybe<Scalars['Boolean']>;
  active_girls_only?: Maybe<Scalars['Boolean']>;
  sort_by?: Maybe<AggregatorTop10ProfileSortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface PublicQueryAggregatorCompanyProfilesArgs {
  geo?: Maybe<AggregatorCompanyGeoInput>;
  categories?: Maybe<AggregatorCompanyCategoriesInput>;
  filters?: Maybe<AggregatorCompanyProfileFiltersInput>;
  search?: Maybe<AggregatorCompanyProfileSearchInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  ordering?: Maybe<AggregatorCompanyProfileOrdering>;
  exclude?: Maybe<Array<Maybe<Scalars['Int']>>>;
}


export interface PublicQueryAggregatorCompanyProfilesPagingArgs {
  geo?: Maybe<AggregatorCompanyGeoInput>;
  categories?: Maybe<AggregatorCompanyCategoriesInput>;
  filters?: Maybe<AggregatorCompanyProfileFiltersInput>;
  search?: Maybe<AggregatorCompanyProfileSearchInput>;
  ordering?: Maybe<AggregatorCompanyProfileOrdering>;
  pid?: Maybe<Scalars['Int']>;
}


export interface PublicQueryAggregatorCompanyGeoNodesArgs {
  geo?: Maybe<AggregatorCompanyGeoInput>;
  categories?: Maybe<AggregatorCompanyCategoriesInput>;
  filters?: Maybe<AggregatorCompanyProfileFiltersInput>;
  search?: Maybe<AggregatorCompanyProfileSearchInput>;
}


export interface PublicQueryAggregatorCompanyProfileCountArgs {
  geo?: Maybe<AggregatorCompanyGeoInput>;
  categories?: Maybe<AggregatorCompanyCategoriesInput>;
  filters?: Maybe<AggregatorCompanyProfileFiltersInput>;
  search?: Maybe<AggregatorCompanyProfileSearchInput>;
}


export interface PublicQueryApprovedClassifiedAdsByFilterArgs {
  filter: ClassifiedAdsPublicFilterInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}


export interface PublicQueryApprovedClassifiedAdsByFilterPagingArgs {
  filter: ClassifiedAdsPublicFilterInput;
  caid?: Maybe<Scalars['Int']>;
}


export interface PublicQueryClassifiedAdApprovedByRefIdArgs {
  id: Scalars['Int'];
}


export interface PublicQueryExistsByPhoneArgs {
  phone: Scalars['String'];
}


export interface PublicQueryDictionaryListArgs {
  categories?: Maybe<Array<Maybe<DictionaryCategories>>>;
}


export interface PublicQueryDictionaryReadArgs {
  id: Scalars['String'];
}


export interface PublicQueryDictionaryBySlugArgs {
  slug: Scalars['String'];
  category: DictionaryCategories;
  language: Scalars['String'];
}


export interface PublicQueryDeclineReasonsByKeysArgs {
  keys?: Maybe<Array<Scalars['String']>>;
}


export interface PublicQuerySeoTemplateAndInstanceByKeyArgs {
  key: Scalars['String'];
  lang: Scalars['String'];
  entity_id?: Maybe<Scalars['Int']>;
}


export interface PublicQueryMessengerAvailabilityArgs {
  account_id: Scalars['Int'];
}


export interface PublicQueryCheckToursEmailExistenceByIdsArgs {
  ids: Array<Scalars['String']>;
}


export interface PublicQueryTourByIdArgs {
  id: Scalars['String'];
}


export interface PublicQueryToursByIdsArgs {
  ids: Array<Maybe<Scalars['String']>>;
}


export interface PublicQueryToursByProfileArgs {
  profile_id: Scalars['String'];
  statuses?: Maybe<Array<Maybe<ProfileTourStatuses>>>;
}


export interface PublicQueryMemberFavoriteProfileByIdArgs {
  id: Scalars['String'];
}


export interface PublicQueryIp2locationArgs {
  ip: Scalars['String'];
}


export interface PublicQueryIps2locationsArgs {
  ips: Array<Maybe<Scalars['String']>>;
}


export interface PublicQueryGeoNodeArgs {
  id: Scalars['Int'];
}


export interface PublicQueryGeoChildrenByIdAndTypeArgs {
  id: Scalars['Int'];
  type?: Maybe<GeoNodeTypes>;
}


export interface PublicQueryGeoNodePathArgs {
  id: Scalars['Int'];
}


export interface PublicQueryGeoNodePathsByIdsArgs {
  ids: Array<Maybe<Scalars['Int']>>;
}


export interface PublicQueryGeoNodesByCoordinatesArgs {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}


export interface PublicQueryGeoNodeNearbyLocationsArgs {
  id: Scalars['Int'];
  filter?: Maybe<GeoFilter>;
}


export interface PublicQueryGeoNodesByIdsArgs {
  ids: Array<Maybe<Scalars['Int']>>;
}


export interface PublicQueryIxsBannersByZonesArgs {
  zone_ids: Array<Scalars['String']>;
}


export interface PublicQueryCheckUsernameArgs {
  username: Scalars['String'];
}


export interface PublicQueryCheckEmailArgs {
  email: Scalars['String'];
}


export interface PublicQueryPaymentLinkByHashArgs {
  hash: Scalars['String'];
}


export interface PublicQueryAnonymousPaymentUrlArgs {
  amount: Scalars['Float'];
  payment_method: Scalars['String'];
}


export interface PublicQueryPaymentUrlForPaymentLinkArgs {
  id: Scalars['String'];
  amount: Scalars['Int'];
  payment_method: Scalars['String'];
}


export interface PublicQueryEnabledPaymentMethodsArgs {
  account_id?: Maybe<Scalars['Int']>;
}

export enum PublicRole {
  Member = 'MEMBER',
  Company = 'COMPANY',
  Escort = 'ESCORT'
}

export interface PublicSeoTemplate {
  title?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}

export interface PublicSubscription {
  dummy?: Maybe<Scalars['String']>;
}

export enum PushAdsPaymentStatuses {
  Initiated = 'INITIATED',
  Paid = 'PAID',
  Successful = 'SUCCESSFUL',
  Error = 'ERROR'
}

export interface Query {
  my?: Maybe<AccountQuery>;
  public?: Maybe<PublicQuery>;
  ops?: Maybe<OpsQuery>;
}

export interface QueryBaseServicesFilterInput {
  ref_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Array<Maybe<ServiceStatus>>>;
}

export interface QueryClassifiedAdServicesFilterInput {
  ref_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Array<Maybe<ServiceStatus>>>;
}

export interface QueryGotdServicesFilterInput {
  ref_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Array<Maybe<ServiceStatus>>>;
}

export interface QueryOrderFilterInput {
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  status?: Maybe<Array<Maybe<OrderStatus>>>;
  moved_orders?: Maybe<Scalars['Boolean']>;
}

export interface QueryTransferFilterInput {
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  purpose?: Maybe<Array<Maybe<TransferPurpose>>>;
  type?: Maybe<TransferType>;
}

export interface QueryVotdServicesFilterInput {
  ref_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Array<Maybe<ServiceStatus>>>;
}

export interface RecurringBumpSchedule {
  _id?: Maybe<Scalars['String']>;
  account_id: Scalars['Int'];
  reference_id: Scalars['Int'];
  city_id: Scalars['Int'];
  geo_node?: Maybe<GeoNode>;
  weekday: Scalars['Int'];
  frequency: Scalars['Int'];
  status: RecurringBumpScheduleStatus;
  start_time: Scalars['String'];
  end_time?: Maybe<Scalars['String']>;
  type: BumpScheduleType;
}

export enum RecurringBumpScheduleStatus {
  Active = 'ACTIVE',
  Paused = 'PAUSED'
}

export interface RegularTemplate {
  _id: Scalars['String'];
  account_id?: Maybe<Scalars['Int']>;
  type?: Maybe<RegularTemplateType>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  created_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
}

export interface RegularTemplateResponseBoolean {
  result?: Maybe<Scalars['Boolean']>;
}

export enum RegularTemplateType {
  Sms = 'SMS',
  Email = 'EMAIL',
  Issue = 'ISSUE',
  Pa = 'PA'
}

export interface RequestEntity {
  type: EntityType;
  id: Scalars['Int'];
}

export interface RequestUnalteredPhotosInput {
  profile_id: Scalars['String'];
  documents: Array<Maybe<UnalteredDocumentInput>>;
}

export interface RequestUnalteredPhotosResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface ReservationResponse {
  count: Scalars['Int'];
  date: Scalars['Date'];
}

export interface RestoreEmailsResponse {
  result: Scalars['Boolean'];
}

export interface RestoreSmsesResponse {
  result: Scalars['Boolean'];
}

export interface RestrictPaymentMethodsResponse {
  result: Scalars['Boolean'];
}

export interface ReviewReply {
  _id: Scalars['String'];
  entity_id: Scalars['ID'];
  entity_type: CommentEntityType;
  entity_account_id: Scalars['Int'];
  review?: Maybe<ProfileReview>;
  status: CommentStatus;
  is_deleted: Scalars['Boolean'];
  profile?: Maybe<Profile>;
  account_id: Scalars['Int'];
  body: Scalars['String'];
  date_created: Scalars['Date'];
}

export interface RotateDocumentResponse {
  result: Scalars['Boolean'];
}

export interface SendEmailResponse {
  result: Scalars['Boolean'];
}

export interface SendEmailVerificationResponse {
  sent?: Maybe<Scalars['Boolean']>;
  verification_code?: Maybe<Scalars['String']>;
}

export interface SendEmailsResponse {
  result: Scalars['Boolean'];
}

export interface SendPasswordRecoverySecretResponse {
  account_id: Scalars['Int'];
  result: Scalars['Boolean'];
  phone_last_four?: Maybe<Scalars['String']>;
}

export interface SendPhoneNumberVerificationResponse {
  sent?: Maybe<Scalars['Boolean']>;
  call_phone_number?: Maybe<Scalars['String']>;
  verification_code?: Maybe<Scalars['String']>;
}

export interface SendPushResponse {
  result: Scalars['Boolean'];
}

export interface SendSmSesToContactsInput {
  body: Scalars['String'];
  group_id: Scalars['Int'];
  contact_state?: Maybe<ContactState>;
  limit?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
}

export interface SendSmSesToContactsResponse {
  result: Scalars['Boolean'];
}

export interface SendSmsResponse {
  result: Scalars['Boolean'];
}

export interface SendSmsesResponse {
  result: Scalars['Boolean'];
}

export interface SeoCreateNotFoundRecordResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface SeoCreateRobotsTextResponse {
  id: Scalars['ID'];
}

export interface SeoCreateTemplateInstanceResponse {
  id: Scalars['ID'];
}

export interface SeoCreateTemplateResponse {
  id: Scalars['ID'];
}

export interface SeoDeleteTemplateInstanceResponse {
  deleted: Scalars['Boolean'];
}

export interface SeoDeleteTemplateResponse {
  deleted: Scalars['Boolean'];
}

export interface SeoGetNotFoundRecordsInput {
  url?: Maybe<Scalars['String']>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
}

export interface SeoGetNotFoundRecordsResponse {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<SeoNotFoundRecord>>>;
}

export interface SeoLanguageInput {
  de: Scalars['String'];
  en: Scalars['String'];
  es: Scalars['String'];
  fr: Scalars['String'];
  ro: Scalars['String'];
  it: Scalars['String'];
  hu: Scalars['String'];
}

export interface SeoNotFoundRecord {
  url: Scalars['String'];
  full_url: Scalars['String'];
  hit_count: Scalars['Int'];
  additional_data?: Maybe<Array<Maybe<SeoNotFoundRecordAdditionalData>>>;
}

export interface SeoNotFoundRecordAdditionalData {
  date?: Maybe<Scalars['Date']>;
}

export interface SeoRobotsText {
  _id?: Maybe<Scalars['ID']>;
  body?: Maybe<Scalars['String']>;
  google_id?: Maybe<Scalars['String']>;
}

export interface SeoTemplate {
  _id: Scalars['ID'];
  key: Scalars['String'];
  entity_type?: Maybe<SeoTemplateEntityType>;
  can_have_instances: Scalars['Boolean'];
  de: SeoTemplateInstanceLanguage;
  en: SeoTemplateInstanceLanguage;
  es: SeoTemplateInstanceLanguage;
  fr: SeoTemplateInstanceLanguage;
  ro: SeoTemplateInstanceLanguage;
  it: SeoTemplateInstanceLanguage;
  hu: SeoTemplateInstanceLanguage;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
}

export enum SeoTemplateEntityType {
  Profile = 'PROFILE',
  MemberProfile = 'MEMBER_PROFILE',
  AgencyProfile = 'AGENCY_PROFILE',
  Region = 'REGION',
  Area = 'AREA',
  Canton = 'CANTON',
  City = 'CITY',
  CityZone = 'CITY_ZONE'
}

export interface SeoTemplateInstance {
  _id: Scalars['ID'];
  template_id: Scalars['ID'];
  entity_id: Scalars['Int'];
  entity_type: SeoTemplateEntityType;
  de?: Maybe<SeoTemplateInstanceLanguage>;
  en?: Maybe<SeoTemplateInstanceLanguage>;
  es?: Maybe<SeoTemplateInstanceLanguage>;
  fr?: Maybe<SeoTemplateInstanceLanguage>;
  ro?: Maybe<SeoTemplateInstanceLanguage>;
  it?: Maybe<SeoTemplateInstanceLanguage>;
  hu?: Maybe<SeoTemplateInstanceLanguage>;
  use_about_me_text: Scalars['Boolean'];
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
}

export interface SeoTemplateInstanceFilterInput {
  template_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  entity_id?: Maybe<Scalars['Int']>;
  entity_types?: Maybe<Array<SeoTemplateEntityType>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface SeoTemplateInstanceInput {
  template_id: Scalars['ID'];
  entity_id: Scalars['Int'];
  title: SeoLanguageInput;
  keywords: SeoLanguageInput;
  description: SeoLanguageInput;
  use_about_me_text: Scalars['Boolean'];
}

export interface SeoTemplateInstanceLanguage {
  title: Scalars['String'];
  keywords: Scalars['String'];
  description: Scalars['String'];
}

export interface SeoTemplateInstancesListResponse {
  count?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<SeoTemplateInstance>>>;
}

export interface SeoUpdateRobotsTextResponse {
  updated: Scalars['Boolean'];
}

export interface SeoUpdateTemplateInstanceResponse {
  updated: Scalars['Boolean'];
}

export interface SeoUpdateTemplateResponse {
  updated: Scalars['Boolean'];
}

export enum ServiceStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Canceled = 'CANCELED'
}

export interface SetBillingInfoResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface SetMessengerAdminDisabledResponse {
  result: Scalars['Boolean'];
}

export interface SetMessengerDisabledResponse {
  result: Scalars['Boolean'];
}

export interface SetMessengerThreadFavoriteResponse {
  result: Scalars['Boolean'];
}

export interface SetMessengerThreadReadResponse {
  result: Scalars['Boolean'];
}

export interface SimpleProfile {
  _id: Scalars['String'];
  _meta?: Maybe<SimpleProfileMeta>;
  showname?: Maybe<Scalars['String']>;
  main_photo?: Maybe<ProfilePhoto>;
}

export interface SimpleProfileMeta {
  reference_id: Scalars['Int'];
  account_id: Scalars['Int'];
  type: ProfileMetaType;
}

export interface StatisticCommentReactionByAccount {
  comment_id: Scalars['ID'];
  type: StatisticCommentReactionType;
}

export interface StatisticCommentReactionCount {
  key: StatisticCommentReactionType;
  doc_count: Scalars['Int'];
}

export enum StatisticCommentReactionType {
  Like = 'LIKE',
  Dislike = 'DISLIKE'
}

export interface StatisticCommentReactionsCount {
  comment_id: Scalars['ID'];
  reactions_count?: Maybe<Array<Maybe<StatisticCommentReactionCount>>>;
}

export interface StatisticProfileActivitiesCount {
  key: StatisticProfileActivityType;
  doc_count: Scalars['Float'];
}

export enum StatisticProfileActivityType {
  View = 'VIEW',
  Call = 'CALL',
  InstantBookingRequest = 'INSTANT_BOOKING_REQUEST',
  MessageReceived = 'MESSAGE_RECEIVED',
  Whatsapp = 'WHATSAPP'
}

export interface StatisticProfileViewsCount {
  key: StatisticProfileActivityType;
  doc_count: Scalars['Int'];
}

export interface StatisticsCommentReactionResponse {
  created: Scalars['Boolean'];
  updated: Scalars['Boolean'];
}

export interface StatisticsCreateProfileActivityResponse {
  created: Scalars['Boolean'];
}

export interface StatisticsMemberWatchedProfile {
  profile_id: Scalars['ID'];
  doc_count: Scalars['Int'];
}

export interface StatisticsMemberWatchedProfileResponse {
  created: Scalars['Boolean'];
}

export interface StoriesByAccount {
  live?: Maybe<Array<Maybe<ProfileStories>>>;
  pending?: Maybe<Array<Maybe<ProfileStories>>>;
  declined?: Maybe<Array<Maybe<ProfileStories>>>;
}

export interface StoriesCellar {
  live?: Maybe<Array<Maybe<Story>>>;
  pending?: Maybe<Array<Maybe<Story>>>;
  declined?: Maybe<Array<Maybe<Story>>>;
}

export interface StoriesInterior {
  live?: Maybe<Array<Maybe<Story>>>;
  pending?: Maybe<Array<Maybe<Story>>>;
  declined?: Maybe<Array<Maybe<Story>>>;
  draft?: Maybe<Array<Maybe<Story>>>;
}

export interface Story {
  _id: Scalars['ID'];
  account_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['ID']>;
  media_id?: Maybe<Scalars['ID']>;
  media_type?: Maybe<Scalars['String']>;
  ai_text_fields_moderation?: Maybe<StoryAiTextFieldsModerationResponse>;
  media?: Maybe<StoryMediaTypeInterface>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  profile_city_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  ops_account_id?: Maybe<Scalars['Int']>;
  profile_status?: Maybe<Scalars['String']>;
  moderation_status?: Maybe<Scalars['String']>;
  is_expired?: Maybe<Scalars['Boolean']>;
  caption?: Maybe<Scalars['String']>;
  is_muted?: Maybe<Scalars['Boolean']>;
  date_muted?: Maybe<Scalars['Date']>;
  muted_by?: Maybe<Scalars['Int']>;
  date_activation?: Maybe<Scalars['Date']>;
  date_expired?: Maybe<Scalars['Date']>;
  date_created?: Maybe<Scalars['Date']>;
  created_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
  modified_by?: Maybe<Scalars['Int']>;
  date_approved?: Maybe<Scalars['Date']>;
  approved_by?: Maybe<Scalars['Int']>;
  date_declined?: Maybe<Scalars['Date']>;
  declined_by?: Maybe<Scalars['Int']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  view_count?: Maybe<Scalars['Int']>;
  like_count?: Maybe<Scalars['Int']>;
  views?: Maybe<Array<Maybe<Ip>>>;
  likes?: Maybe<Array<Maybe<Ip>>>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  date_deleted?: Maybe<Scalars['Date']>;
  deleted_by?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['Int']>;
  profile?: Maybe<Profile>;
}

export enum StoryActionEnum {
  Draft = 'DRAFT',
  Publish = 'PUBLISH'
}

export interface StoryAiLinkBlacklist {
  link?: Maybe<StoryAiTextFieldMatches>;
  blacklist?: Maybe<StoryAiTextFieldMatches>;
}

export interface StoryAiMediaModeration {
  _id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  story_id?: Maybe<Scalars['ID']>;
  image_id?: Maybe<Scalars['ID']>;
  media_video_id?: Maybe<Scalars['ID']>;
  image_path?: Maybe<Scalars['String']>;
  moderation_type?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  ai_moderation_response?: Maybe<StoryAiMediaModerationSummaryResponse>;
}

export interface StoryAiMediaModerationSummary {
  action?: Maybe<Scalars['String']>;
  reject_prob?: Maybe<Scalars['Float']>;
  reject_reason?: Maybe<Array<Maybe<StoryAiRejectReasons>>>;
}

export interface StoryAiMediaModerationSummaryResponse {
  summary?: Maybe<StoryAiMediaModerationSummary>;
}

export interface StoryAiRejectReasons {
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
}

export interface StoryAiTextFieldMatch {
  match?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
}

export interface StoryAiTextFieldMatches {
  matches?: Maybe<Array<Maybe<StoryAiTextFieldMatch>>>;
}

export interface StoryAiTextFieldsModerationResponse {
  _id?: Maybe<Scalars['ID']>;
  account_id?: Maybe<Scalars['Int']>;
  story_id?: Maybe<Scalars['ID']>;
  moderation_type?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  is_ai_approved?: Maybe<Scalars['Boolean']>;
  ai_moderation_response?: Maybe<StoryAiLinkBlacklist>;
}

export interface StoryByFiltersForCellarResponse {
  count?: Maybe<Scalars['Int']>;
  stories?: Maybe<Array<Maybe<Story>>>;
}

export interface StoryDataType {
  media_id: Scalars['ID'];
  media_type: StoryMediaTypeEnum;
  action: StoryActionEnum;
  ordering: Scalars['Int'];
  caption?: Maybe<Scalars['String']>;
}

export interface StoryFiltersForCellarInput {
  ops_account_id?: Maybe<Scalars['Int']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  moderation_status?: Maybe<StoryModerationStatusEnum>;
}

export interface StoryHistoryByProfile {
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  profile_id: Scalars['ID'];
}

export interface StoryId {
  _id: Scalars['ID'];
}

export interface StoryIdResponse {
  _id: Scalars['ID'];
}

export interface StoryIdsResponseWithWarning {
  ids?: Maybe<Array<Maybe<StoryIdResponse>>>;
  warning?: Maybe<Scalars['String']>;
}

export enum StoryMediaTypeEnum {
  Video = 'VIDEO',
  Image = 'IMAGE'
}

export interface StoryMediaTypeImage extends StoryMediaTypeInterface {
  media_id?: Maybe<Scalars['ID']>;
  image?: Maybe<Image>;
  ai_media_moderation_story?: Maybe<StoryAiMediaModeration>;
}

export interface StoryMediaTypeInterface {
  media_id?: Maybe<Scalars['ID']>;
}

export interface StoryMediaTypeVideo extends StoryMediaTypeInterface {
  media_id?: Maybe<Scalars['ID']>;
  video?: Maybe<Video>;
  ai_media_moderation_story?: Maybe<StoryAiMediaModeration>;
}

export enum StoryModerationStatusEnum {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export interface StoryPendingCountResponse {
  count: Scalars['Int'];
}

export enum StoryProfileStatusEnum {
  Online = 'ONLINE',
  Offline = 'OFFLINE'
}

export interface Subscription {
  supportTicketCellarUserRepliedForWS: SupportTicketCellarUserRepliedEvent;
  profileInstantBookCreatedForWS: ProfileInstantBookCreatedEvent;
  profileInstantBookCanceledForWS: ProfileInstantBookCanceledEvent;
  profileInstantBookActivatedForWS: ProfileInstantBookActivatedEvent;
  profileInstantBookExpiredForWS: ProfileInstantBookExpiredEvent;
  profileInstantBookRequestCreatedForWS: ProfileInstantBookRequestCreatedEvent;
  profileInstantBookRequestDeclinedForWS: ProfileInstantBookRequestEvent;
  profileInstantBookRequestDeclinedForMemberForWS: ProfileInstantBookRequestEvent;
  profileInstantBookRequestDeclinedGlobalForWS: ProfileInstantBookRequestGlobalEvent;
  profileInstantBookRequestAcceptedForWS: ProfileInstantBookRequestEvent;
  profileInstantBookRequestAcceptedForMemberForWS: ProfileInstantBookRequestEvent;
  profileInstantBookRequestAcceptedGlobalForWS: ProfileInstantBookRequestGlobalEvent;
  profileInstantBookRequestNotAnsweredForWS: ProfileInstantBookRequestEvent;
  profileInstantBookRequestNotAnsweredForMemberForWS: ProfileInstantBookRequestEvent;
  profileInstantBookRequestNotAnsweredGlobalForWS: ProfileInstantBookRequestGlobalEvent;
  profileReviewCreatedForWS: ProfileReviewCreatedEvent;
  profileOnlineForWS: AggregatorWorkerProfileOnlineEvent;
  profileOfflineForWS: AggregatorWorkerProfileOfflineEvent;
  lotteryWinnerForWS: LotteryWinnerEvent;
  messengerMessageCreatedSelfForWS: MessengerMessageCreatedResponse;
  messengerMessageCreatedForWS: MessengerMessageCreatedResponse;
  messengerEnabledForWS: MessengerEventPayload;
  messengerDisabledForWS: MessengerEventPayload;
  opsToolsPrivateMessageCreatedForWS: OpsToolsPrivateMessage;
  mediaVideoProgressForWS: MediaVideoProgressEvent;
  mediaVideoProgressForRoleForWS: MediaVideoProgressEvent;
  mediaVideoProcessFinishedForWS: MediaVideoProcessFinishedEvent;
  mediaVideoProcessFinishedForRoleForWS: MediaVideoProcessFinishedEvent;
  mediaVideoProcessErrorForWS: MediaVideoProcessErrorEvent;
  mediaVideoProcessErrorForRoleForWS: MediaVideoProcessErrorEvent;
  moverProfileMovedFromForWS: MoverProfileMovedResponse;
  moverProfileMovedFromForRoleForWS: MoverProfileMovedResponse;
  moverProfileMovedToForWS: MoverProfileMovedResponse;
  moverProfileMovedToForRoleForWS: MoverProfileMovedResponse;
  accountDeletedForWS: AccountDeletedResponse;
  accountDisabledForWS: AccountDisabledResponse;
  cartUpdatedForWS: CartUpdatedForWsResponse;
  my?: Maybe<AccountSubscription>;
  public?: Maybe<PublicSubscription>;
  ops?: Maybe<OpsSubscription>;
}

export interface SupportCreateCommentResponse {
  id: Scalars['ID'];
}

export interface SupportCreateProblemReportResponse {
  id: Scalars['ID'];
}

export interface SupportCreateTicketResponse {
  id: Scalars['ID'];
}

export interface SupportFeedbackBlacklist {
  value: Scalars['String'];
  startIndex: Scalars['Int'];
  endIndex: Scalars['Int'];
  type: Scalars['String'];
  key: Scalars['String'];
}

export interface SupportFeedbackConfigs {
  name: SupportFeedbackNameConfigs;
  message: SupportFeedbackMessageConfigs;
}

export interface SupportFeedbackFilterInput {
  reference_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  to_email?: Maybe<Scalars['String']>;
  status?: Maybe<SupportFeedbackStatuses>;
  purpose?: Maybe<SupportFeedbackPurposeType>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface SupportFeedbackFilterResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<SupportFeedbackRequest>>>;
}

export interface SupportFeedbackInput {
  name: Scalars['String'];
  email: Scalars['String'];
  message: Scalars['String'];
  profile_id: Scalars['ID'];
}

export interface SupportFeedbackMessageConfigs {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
  allowed_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface SupportFeedbackNameConfigs {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
  allowed_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum SupportFeedbackPurposeType {
  Profile = 'PROFILE',
  CompanyProfile = 'COMPANY_PROFILE',
  Tour = 'TOUR',
  ClassifiedAd = 'CLASSIFIED_AD'
}

export interface SupportFeedbackRequest {
  _id: Scalars['ID'];
  profile_id: Scalars['ID'];
  profile_reference_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  email: Scalars['String'];
  message: Scalars['String'];
  purpose: SupportFeedbackPurposeType;
  status: SupportFeedbackStatuses;
  ip?: Maybe<Scalars['String']>;
  ip2location?: Maybe<Ip2Location>;
  date_created: Scalars['Date'];
  processed_by?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
  blacklist?: Maybe<Array<SupportFeedbackBlacklist>>;
}

export interface SupportFeedbackSetStatusResponse {
  result: Scalars['Boolean'];
}

export enum SupportFeedbackStatuses {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export interface SupportProblemReport {
  _id: Scalars['ID'];
  profile_id: Scalars['ID'];
  profile_reference_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  email: Scalars['String'];
  description: Scalars['String'];
  replies: Array<Maybe<SupportProblemReportReply>>;
  status: SupportProblemReportStatusType;
  type?: Maybe<SupportProblemReportTypesType>;
  purpose: SupportProblemReportEntityType;
  date_created: Scalars['Date'];
  date_checked?: Maybe<Scalars['Date']>;
  created_by: Scalars['Int'];
  checked_by?: Maybe<Scalars['Int']>;
}

export interface SupportProblemReportBooleanResponse {
  result: Scalars['Boolean'];
}

export interface SupportProblemReportConfigs {
  name: SupportProblemReportNameConfigs;
  description: SupportProblemReportDescriptionConfigs;
  reply: SupportProblemReportReplyConfigs;
}

export interface SupportProblemReportCreateInput {
  name: Scalars['String'];
  email: Scalars['String'];
  profile_id: Scalars['ID'];
  description: Scalars['String'];
  type?: Maybe<SupportProblemReportTypesType>;
  entity_type?: Maybe<SupportProblemReportEntityType>;
}

export interface SupportProblemReportDescriptionConfigs {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
  allowed_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum SupportProblemReportEntityType {
  Profile = 'PROFILE',
  ClassifiedAd = 'CLASSIFIED_AD'
}

export interface SupportProblemReportFilterInput {
  reference_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  purpose?: Maybe<SupportProblemReportEntityType>;
  type?: Maybe<SupportProblemReportTypesType>;
  status?: Maybe<SupportProblemReportStatusType>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface SupportProblemReportNameConfigs {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
}

export interface SupportProblemReportReply {
  text: Scalars['String'];
  date_replied: Scalars['Date'];
  replied_by: Scalars['Int'];
}

export interface SupportProblemReportReplyConfigs {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
  allowed_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum SupportProblemReportStatusType {
  Pending = 'PENDING',
  Checked = 'CHECKED'
}

export enum SupportProblemReportTypesType {
  DataEntryErrors = 'DATA_ENTRY_ERRORS',
  CantOpenPhotos = 'CANT_OPEN_PHOTOS',
  ReviewAndComments = 'REVIEW_AND_COMMENTS'
}

export interface SupportProblemReportsByFilterResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<SupportProblemReport>>>;
}

export interface SupportSuspiciousPhotoRequest {
  _id: Scalars['ID'];
  profile_id: Scalars['ID'];
  profile_reference_id?: Maybe<Scalars['Int']>;
  profile?: Maybe<Profile>;
  links: Array<Scalars['String']>;
  comment: Scalars['String'];
  status: SupportSuspiciousPhotoRequestStatuses;
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  processed_by?: Maybe<Scalars['Int']>;
  date_processed?: Maybe<Scalars['Date']>;
}

export interface SupportSuspiciousPhotoRequestCommentConfigs {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
  allowed_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface SupportSuspiciousPhotoRequestConfigs {
  comment: SupportSuspiciousPhotoRequestCommentConfigs;
}

export interface SupportSuspiciousPhotoRequestFilterInput {
  reference_id?: Maybe<Scalars['Int']>;
  status?: Maybe<SupportSuspiciousPhotoRequestStatuses>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface SupportSuspiciousPhotoRequestResponse {
  id: Scalars['ID'];
}

export interface SupportSuspiciousPhotoRequestSetStatusResponse {
  result: Scalars['Boolean'];
}

export enum SupportSuspiciousPhotoRequestStatuses {
  Pending = 'PENDING',
  NotSuspicious = 'NOT_SUSPICIOUS',
  Suspicious = 'SUSPICIOUS',
  Unknown = 'UNKNOWN',
  UnderInvestigation = 'UNDER_INVESTIGATION'
}

export interface SupportSuspiciousPhotoRequestsByFilterResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<SupportSuspiciousPhotoRequest>>>;
}

export enum SupportSuspiciousPhotoTypesType {
  FakePhotos = 'FAKE_PHOTOS'
}

export interface SupportTellAFriendConfigs {
  name: SupportTellAFriendNameConfigs;
  message: SupportTellAFriendMessageConfigs;
}

export interface SupportTellAFriendInput {
  name: Scalars['String'];
  email: Scalars['String'];
  friend_name: Scalars['String'];
  friend_email: Scalars['String'];
  message: Scalars['String'];
  url: Scalars['String'];
}

export interface SupportTellAFriendMessageConfigs {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
  allowed_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface SupportTellAFriendNameConfigs {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
}

export interface SupportTellAFriendResponse {
  result: Scalars['Boolean'];
}

export interface SupportTicket {
  _id: Scalars['ID'];
  reference_id: Scalars['Int'];
  account_id: Scalars['Int'];
  ops_account_id?: Maybe<Scalars['Int']>;
  initial_ops_account_id?: Maybe<Scalars['Int']>;
  created_by: Scalars['Int'];
  deleted_by?: Maybe<Scalars['Int']>;
  enabled_by?: Maybe<Scalars['Int']>;
  disabled_by?: Maybe<Scalars['Int']>;
  restored_by?: Maybe<Scalars['Int']>;
  date_created: Scalars['Date'];
  date_enabled?: Maybe<Scalars['Date']>;
  date_disabled?: Maybe<Scalars['Date']>;
  date_restored?: Maybe<Scalars['Date']>;
  date_deleted?: Maybe<Scalars['Date']>;
  date_closed?: Maybe<Scalars['Date']>;
  status: SupportTicketStatusType;
  progress_status: SupportTicketProgressStatusType;
  subject: Scalars['String'];
  message: Scalars['String'];
  is_client_read: Scalars['Boolean'];
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
  attached_files?: Maybe<Array<SupportTicketAttachedFiles>>;
  username?: Maybe<Scalars['String']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  profile_type?: Maybe<ProfileMetaType>;
}

export interface SupportTicketAttachedFiles {
  _id: Scalars['ID'];
  document?: Maybe<Document>;
}

export interface SupportTicketBooleanResponse {
  result: Scalars['Boolean'];
}

export interface SupportTicketCellarUserRepliedEvent {
  account_id: Scalars['Int'];
  ticket_id: Scalars['String'];
}

export interface SupportTicketCommentConfigs {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
  allowed_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface SupportTicketConfigs {
  subject: SupportTicketSubjectConfigs;
  message: SupportTicketMessageConfigs;
  comment: SupportTicketCommentConfigs;
  ops_account_name_for_members: Scalars['String'];
}

export interface SupportTicketFilterInput {
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  text?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  status?: Maybe<SupportTicketStatusType>;
}

export interface SupportTicketFilterOpsInput {
  username?: Maybe<Scalars['String']>;
  profile_reference_id?: Maybe<Scalars['Int']>;
  reference_id?: Maybe<Scalars['Int']>;
  account_id?: Maybe<Scalars['Int']>;
  ops_account_id?: Maybe<Scalars['Int']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<SupportTicketStatusType>;
  progress_status?: Maybe<SupportTicketProgressStatusType>;
  text?: Maybe<Scalars['String']>;
  date_from?: Maybe<Scalars['Date']>;
  date_to?: Maybe<Scalars['Date']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface SupportTicketMessageConfigs {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
  allowed_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export enum SupportTicketProgressStatusType {
  Unread = 'UNREAD',
  ReadNotReplied = 'READ_NOT_REPLIED',
  ReadReplied = 'READ_REPLIED',
  NeedReply = 'NEED_REPLY'
}

export enum SupportTicketStatusType {
  Pending = 'PENDING',
  Closed = 'CLOSED'
}

export interface SupportTicketSubjectConfigs {
  min_length: Scalars['Int'];
  max_length: Scalars['Int'];
  allowed_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface SupportTicketsByAccountResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<SupportTicket>>>;
}

export interface SupportTicketsByFilterResponse {
  count: Scalars['Int'];
  result?: Maybe<Array<Maybe<SupportTicket>>>;
}

export interface SurchargePercent {
  range: Array<Scalars['Float']>;
  percent: Scalars['Float'];
}

export enum SystemEmailPurposeType {
  IndependentEscort = 'INDEPENDENT_ESCORT',
  Agency = 'AGENCY',
  Member = 'MEMBER',
  CellarUser = 'CELLAR_USER'
}

export interface SystemEmailTemplate {
  _id: Scalars['String'];
  slug: Scalars['String'];
  from?: Maybe<Scalars['String']>;
  is_layout: Scalars['Boolean'];
  is_default_layout: Scalars['Boolean'];
  purpose?: Maybe<Array<Maybe<SystemEmailPurposeType>>>;
  note?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  de?: Maybe<SystemEmailTemplateSubjectBody>;
  en?: Maybe<SystemEmailTemplateSubjectBody>;
  es?: Maybe<SystemEmailTemplateSubjectBody>;
  fr?: Maybe<SystemEmailTemplateSubjectBody>;
  ro?: Maybe<SystemEmailTemplateSubjectBody>;
  it?: Maybe<SystemEmailTemplateSubjectBody>;
  hu?: Maybe<SystemEmailTemplateSubjectBody>;
  created_by: Scalars['Int'];
  date_created: Scalars['Date'];
  modified_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
}

export interface SystemEmailTemplateBody {
  plain?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
}

export interface SystemEmailTemplateConfigs {
  slug: SystemTemplateMinMaxConfig;
  body: SystemTemplateMinMaxConfig;
  subject: SystemTemplateMinMaxConfig;
  layout: SystemTemplateMinMaxConfig;
  note: SystemTemplateMinMaxConfig;
}

export interface SystemEmailTemplateResponseBoolean {
  result?: Maybe<Scalars['Boolean']>;
}

export interface SystemEmailTemplateSubjectBody {
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<SystemEmailTemplateBody>;
}

export interface SystemPushTemplate {
  _id: Scalars['String'];
  slug: Scalars['String'];
  created_by: Scalars['Int'];
  date_created: Scalars['Date'];
  modified_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
  de?: Maybe<SystemPushTemplateBodyHeading>;
  en?: Maybe<SystemPushTemplateBodyHeading>;
  es?: Maybe<SystemPushTemplateBodyHeading>;
  fr?: Maybe<SystemPushTemplateBodyHeading>;
  ro?: Maybe<SystemPushTemplateBodyHeading>;
  it?: Maybe<SystemPushTemplateBodyHeading>;
  hu?: Maybe<SystemPushTemplateBodyHeading>;
}

export interface SystemPushTemplateBodyHeading {
  body?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
}

export interface SystemPushTemplateConfigs {
  slug: SystemTemplateMinMaxConfig;
  heading: SystemTemplateMinMaxConfig;
  body: SystemTemplateMinMaxConfig;
}

export interface SystemPushTemplateResponseBoolean {
  result?: Maybe<Scalars['Boolean']>;
}

export interface SystemSmsTemplate {
  _id: Scalars['String'];
  slug: Scalars['String'];
  created_by: Scalars['Int'];
  date_created: Scalars['Date'];
  modified_by?: Maybe<Scalars['Int']>;
  date_modified?: Maybe<Scalars['Date']>;
  de?: Maybe<SystemSmsTemplateBody>;
  en?: Maybe<SystemSmsTemplateBody>;
  es?: Maybe<SystemSmsTemplateBody>;
  fr?: Maybe<SystemSmsTemplateBody>;
  ro?: Maybe<SystemSmsTemplateBody>;
  it?: Maybe<SystemSmsTemplateBody>;
  hu?: Maybe<SystemSmsTemplateBody>;
}

export interface SystemSmsTemplateBody {
  body?: Maybe<Scalars['String']>;
}

export interface SystemSmsTemplateConfigs {
  slug: SystemTemplateMinMaxConfig;
  body: SystemTemplateMinMaxConfig;
}

export interface SystemSmsTemplateResponseBoolean {
  result?: Maybe<Scalars['Boolean']>;
}

export interface SystemTemplateConfigs {
  sms: SystemSmsTemplateConfigs;
  push: SystemPushTemplateConfigs;
  email: SystemEmailTemplateConfigs;
}

export interface SystemTemplateMinMaxConfig {
  min: Scalars['Int'];
  max: Scalars['Int'];
}

export enum SystemTemplateType {
  Sms = 'SMS',
  Email = 'EMAIL',
  Push = 'PUSH'
}

export interface Top10CommentsForCellarResponse {
  count: Scalars['Int'];
  result: Array<Maybe<MemberFavoriteProfileTopTen>>;
}

export interface Transfer {
  account_id: Scalars['Int'];
  type: TransferType;
  amount: Scalars['Float'];
  payment_id?: Maybe<Scalars['String']>;
  purpose: TransferPurpose;
  ref_id?: Maybe<Scalars['Int']>;
  amount_before: Scalars['Float'];
  amount_after: Scalars['Float'];
  date_created: Scalars['Date'];
  created_by: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  order_hash?: Maybe<Scalars['String']>;
}

export enum TransferPurpose {
  Order = 'ORDER',
  BalanceCorrection = 'BALANCE_CORRECTION',
  BalanceTopUp = 'BALANCE_TOP_UP'
}

export enum TransferType {
  In = 'IN',
  Out = 'OUT'
}

export interface TransfersResponse {
  result: Array<Maybe<Transfer>>;
  count: Scalars['Int'];
}

export interface UnalteredDocumentInput {
  photo_id: Scalars['String'];
}

export interface UnblockMessengerThreadResponse {
  result: Scalars['Boolean'];
}

export interface UnreadMessengerMessagesCountResponse {
  count: Scalars['Int'];
}

export interface UnsetMessengerAdminDisabledResponse {
  result: Scalars['Boolean'];
}

export interface UnsetMessengerDisabledResponse {
  result: Scalars['Boolean'];
}

export interface UnsetMessengerThreadFavoriteResponse {
  result: Scalars['Boolean'];
}

export interface UpdateAccountPreferencesInput {
  enable_two_factor_auth?: Maybe<Scalars['Boolean']>;
  newsletters?: Maybe<AccountPreferencesNewsletter>;
  watched_profiles_ttl?: Maybe<AccountPreferencesWatchedProfilesTtl>;
  language?: Maybe<AccountPreferencesLanguage>;
  alert_delivery?: Maybe<Array<Maybe<AccountPreferencesAlertDelivery>>>;
}

export interface UpdateActivityTemplateResponse {
  result: Scalars['Boolean'];
}

export interface UpdateAgencyInstantBookSettingsInput {
  receive_email_notifications: Scalars['Boolean'];
  receive_sms_notifications: Scalars['Boolean'];
  use_agency_profile_phone_number: Scalars['Boolean'];
  use_profile_phone_number: Scalars['Boolean'];
  use_account_email: Scalars['Boolean'];
  use_agency_profile_email: Scalars['Boolean'];
  use_profile_email: Scalars['Boolean'];
  country_code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
}

export interface UpdateCaption {
  _id: Scalars['ID'];
  caption?: Maybe<Scalars['String']>;
}

export interface UpdateClassifiedAdResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface UpdateContactGroupResponse {
  result: Scalars['Boolean'];
}

export interface UpdateContactResponse {
  result: Scalars['Boolean'];
}

export interface UpdateDeclineReasonValueInput {
  de: Scalars['String'];
  en: Scalars['String'];
  es: Scalars['String'];
  fr: Scalars['String'];
  ro: Scalars['String'];
  it: Scalars['String'];
  hu: Scalars['String'];
}

export interface UpdateEmailResponse {
  result: Scalars['Boolean'];
  require_verification?: Maybe<Scalars['Boolean']>;
}

export interface UpdateEscortInstantBookSettingsInput {
  receive_email_notifications: Scalars['Boolean'];
  receive_sms_notifications: Scalars['Boolean'];
  use_profile_phone_number: Scalars['Boolean'];
  use_account_email: Scalars['Boolean'];
  use_profile_email: Scalars['Boolean'];
  country_code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
}

export interface UpdateGotdServiceInput {
  ref_id: Scalars['Int'];
  geo_node_id: Scalars['Int'];
  date: Scalars['Date'];
}

export interface UpdateIsConnectedResponse {
  result: Scalars['Boolean'];
}

export interface UpdateIsMuted {
  _id: Scalars['ID'];
  is_muted: Scalars['Boolean'];
}

export interface UpdateMemberAlertResponse {
  result: Scalars['Boolean'];
}

export interface UpdateNoteResponse {
  result: Scalars['Boolean'];
}

export interface UpdateOnlineNewsResponse {
  result: Scalars['Boolean'];
}

export interface UpdatePaymentLinkInput {
  amount?: Maybe<Scalars['Int']>;
  payment_methods?: Maybe<Array<Maybe<Scalars['String']>>>;
  internal_note?: Maybe<Scalars['String']>;
  external_note?: Maybe<Scalars['String']>;
}

export interface UpdatePaymentLinkResponse {
  result: Scalars['Boolean'];
}

export interface UpdateProductInput {
  id: Scalars['Int'];
  order_id: Scalars['Int'];
  product: AddProductProductInput;
}

export interface UpdateStoryModerationInput {
  story_id: Scalars['ID'];
  caption?: Maybe<Scalars['String']>;
  is_muted?: Maybe<Scalars['Boolean']>;
  decline_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  moderation_status?: Maybe<StoryModerationStatusEnum>;
}

export interface UpdateVerificationInput {
  profile_id: Scalars['String'];
  verification_type: ProfileVerificationTypes;
  documents: Array<Maybe<DocumentInput>>;
}

export interface UpdateVerificationResponse {
  count?: Maybe<Scalars['Int']>;
}

export interface UpdateVerificationSpecialCaseResponse {
  result?: Maybe<Scalars['Boolean']>;
}

export interface UpdateVotdServiceInput {
  ref_id: Scalars['Int'];
  geo_node_id: Scalars['Int'];
  date: Scalars['Date'];
}

export interface VerifyEmailResponse {
  is_verified?: Maybe<Scalars['Boolean']>;
}

export interface VerifyPhoneNumberResponse {
  is_verified?: Maybe<Scalars['Boolean']>;
}

export interface Video {
  _id: Scalars['String'];
  account_id: Scalars['Int'];
  created_by: Scalars['Int'];
  status: Scalars['String'];
  purpose: Scalars['String'];
  originalname?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  hash: Scalars['String'];
  ext: Scalars['String'];
  path: Scalars['String'];
  source_meta?: Maybe<VideoSourceMeta>;
  date_created: Scalars['Date'];
  progress: Scalars['String'];
  duration?: Maybe<Scalars['String']>;
  thumbs?: Maybe<Array<Maybe<VideoThumb>>>;
  videos?: Maybe<Array<Maybe<VideoConverted>>>;
}

export interface VideoConverted {
  _id?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface VideoSourceMeta {
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  file_type?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  video_bit_rate?: Maybe<Scalars['Int']>;
  audio_bit_rate?: Maybe<Scalars['Int']>;
}

export interface VideoThumb {
  _id?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
}

export interface VotdInventory {
  id: Scalars['Int'];
  price: Scalars['Int'];
  slots_limit: Scalars['Int'];
  geo_node_id: Scalars['Int'];
}

export interface VotdProduct {
  id: Scalars['Int'];
  inventory: Array<Maybe<VotdInventory>>;
}

export interface VotdService {
  id: Scalars['Int'];
  ref_id?: Maybe<Scalars['Int']>;
  status: ServiceStatus;
  date_activation: Scalars['Date'];
  date_expiration: Scalars['Date'];
  inventory_id: Scalars['Int'];
  account_id: Scalars['Int'];
  order_product_id: Scalars['Int'];
  geo_node_id: Scalars['Int'];
  order_id?: Maybe<Scalars['Int']>;
  profile?: Maybe<Profile>;
  reason?: Maybe<Scalars['String']>;
  date_canceled?: Maybe<Scalars['Date']>;
  canceled_by?: Maybe<Scalars['Int']>;
}

export interface VotdServicesResponse {
  result: Array<Maybe<VotdService>>;
  count: Scalars['Int'];
}


export interface CheckPushAdsPaymentStatusResponse {
  status: PushAdsPaymentStatuses;
}
